import React from 'react'
import style from './style.module.sass'
import LinguisticParams from './LinguisticParams'
import HIXWrapper from './HIXWrapper'
import FLESCHWrapper from './FLESCHWrapper'
import { useSelector } from 'react-redux'
import CLIXWrapper from './CLIXWrapper'
import { roundWithPrecision } from '../../../utils'

export default function TopIndicatorsBar ({
  extResultData = undefined,
  extCurrentBenchmarkSettings = undefined,
  showLinguisticParams = true
}) {

  const reduxResultData = useSelector(state => state.analyseData.result.resultData)
  const resultData = extResultData ? extResultData : reduxResultData

  const reduxCurrentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
  const currentBenchmarkSettings = extCurrentBenchmarkSettings ? extCurrentBenchmarkSettings : reduxCurrentBenchmarkSettings

  const targetHix = currentBenchmarkSettings?.settings?.formulaHix?.values?.target || 0
  const targetFlesch = currentBenchmarkSettings?.settings?.formulaFlesch?.values?.target || 0
  const targetCLIX = currentBenchmarkSettings?.settings?.formulaCLIX?.values?.target || 0

  return (
    <div className={`${style.mainCont}`}>
      <div className={`d-flex ${style.barsCont}`}>
        {resultData['formulaHix'] !== undefined ?
          <HIXWrapper hix={roundWithPrecision(resultData.formulaHix, 2)} target={targetHix}/> :
          (resultData['formulaFlesch'] !== undefined &&
            <FLESCHWrapper flesch={roundWithPrecision(resultData.formulaFlesch, 2)} target={targetFlesch}/>)
        }
        {resultData['formulaCLIX'] !== undefined &&
          <CLIXWrapper clix={roundWithPrecision(resultData.formulaCLIX, 2)} target={targetCLIX}/>}
      </div>
      {showLinguisticParams ? <LinguisticParams/> : <></>}
    </div>
  )
}

