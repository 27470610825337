import style from "./style.module.sass"
import { Button } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import React from "react"
import { useTranslation } from "react-i18next"

export default function AddNew({ setTerminologyModal, setCurrentTerm,
  mainBox, setMainBox,
  categoryType,
  setCategoryType,
  color, setColor,
  visibility, setVisibility
}) {
  const { t } = useTranslation()
  return (
    <Button className={`${style.filterBtn}`} variant="contained"
      color="primary"
      style={{ fontSize: "13px", whiteSpace: "nowrap" }}
      onClick={() => {
        setMainBox([])
        setTerminologyModal(s => !s)
        setCurrentTerm()
        setCategoryType('')
        setColor('')
        setVisibility(false)
      }}
      startIcon={<AddIcon />}>
      {t('add-new')}
    </Button>
  )
}
