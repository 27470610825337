
export function getFolderFilter(id, filters){
    if (id === -1) {
        return { ...filters, folder_id: undefined }
    } else if (id === 0) {
        return { ...filters, folder_id: { value: id, filter: [['|folder_id', `=`], ['|folder_id', `=0`]] } }
    } else {
        return{ ...filters, folder_id: { value: id, filter: [['&folder_id', `=${id}`]] } }
    }
}

export function checkArchiveRights(type, userType, userArchives){
    if (userType === 1) {
        return true
    }
    switch (type) {
        case 'group':
            return userArchives?.group_view_archives === 1
        case 'company':
            return userArchives?.company_view_archives === 1
        default:
            return true
    }
}