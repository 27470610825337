import React, { useEffect, useMemo, useState } from 'react'
import ColumnChart from '../UI/ColumnChart'
import {
  isObjectEmpty,
  checkClixNotZero,
  setFunctionsByReturnValueType,
  convertLanguagesToFull,
} from '../../../utils'
import { useTheme } from '@material-ui/core/styles'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useActions } from '../../../utils/action-helper'
import {
  dashboardSetResultList,
  dashboardSetOldIdList,
  dashboardSetArchiveResult
} from '../../../actions'
import { setSeries } from '../utils'
import Spinner from '../../Spinner';
import config from '../../../config'


export default function ComparePanel({ panel }) {
  const { theme: { colors } } = useTheme()
  const tlService = React.useContext(TLServiceComponent)
  const { t } = useTranslation()
  const [submitted, setSubmitted] = useState(false)

  const idList = useSelector(state => state.dashboardData.idList[panel])
  const language = useSelector(state => state.dashboardData.language)
  const token = useSelector(state => state.userData.token)
  const resultList = useSelector(state => state.dashboardData.resultList[panel])
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const returnValues = useSelector(state => state.openData.return_values)
  const oldIdList = useSelector(state => state.dashboardData.oldIdList[panel])

  const {
    actionDashboardSetResultList,
    actionDashboardSetOldIdList,
    actionDashboardSetArchiveResult
  } = useActions({
    actionDashboardSetResultList: dashboardSetResultList,
    actionDashboardSetOldIdList: dashboardSetOldIdList,
    actionDashboardSetArchiveResult: dashboardSetArchiveResult
  })

  useEffect(() => {
    const getArchives = async (idList) => {
      const exceptionList = [
        'moreSentencesInWords', 'moreSentencesInClauses', 'moreWordsInLetters',
        'countSentences', 'countClauses', 'countWords']

      let setValuesClix = new Set()
      let setDataCorporateLanguageClix = new Set()
      let setDataTermsClix = new Set()
      let setAllIds = new Set()

      // get list of benchmarks
      let resBenchmarks
      try {
        resBenchmarks = await tlService.getArchiveStatistic(token,
          JSON.stringify(idList),
          '["id","nodata:benchmark_id"]',
          0, 'create_time', language)
      } catch (err) {
        console.log(err)
      }


      if (resBenchmarks?.results?.benchmarkId &&
        !isObjectEmpty(resBenchmarks.results.benchmarkId)) {
        const listOfBenchmarks = Object.keys(resBenchmarks.results.benchmarkId)



        // prepare data
        if (listOfBenchmarks.length === 1 &&
          !isObjectEmpty(benchmarks[parseInt(listOfBenchmarks[0])])) {
          const benchmarkSettings = benchmarks[parseInt(listOfBenchmarks[0])]
          // if only one text type in result

          const {
            valuesClix,
            dataCorporateLanguageClix,
            dataTermsClix,
          } = checkClixNotZero(benchmarkSettings.settings)
          for (const valuesClixVal of valuesClix) {
            // filter for duplicates
            if (exceptionList.indexOf(valuesClixVal) === -1) {
              setValuesClix.add(valuesClixVal)
            }
          }
          setDataCorporateLanguageClix = new Set(dataCorporateLanguageClix)
          setDataTermsClix = new Set(dataTermsClix)
          setAllIds = new Set([...dataTermsClix, ...dataCorporateLanguageClix])
        }

        const additionalValues = {
          "formulaHix": "result.formulaHix/mean",
          "formulaFlesch": "result.formulaFlesch/mean",
          "formulaCLIX": "result.formulaCLIX/mean",
          "dataHix": "result.dataHix",
          "moreSentencesInWords": 'nodata:result.moreSentencesInWords/arraysizesum/arraymax/arraymaxlist',
          "moreSentencesInClauses": 'nodata:result.moreSentencesInClauses/arraysizesum/arraymax/arraymaxlist',
          "moreWordsInLetters": 'nodata:result.moreWordsInLetters/arraysizesum/arraymax/arraymaxlist',
          "countSentences": 'nodata:result.countSentences/sum',
          "countClauses": 'nodata:result.countClauses/sum',
          "dataColorWords": 'nodata:result.dataColorWords/sum',
          "dataTokenizer": 'result.dataTokenizer/sum',
          'countConjunctions': 'result.countConjunctions/sum',
          "countPrepositions": 'result.countPrepositions/sum',
          "countParticles": 'result.countParticles/sum',
          "countArticles": 'result.countArticles/sum',
          "countPronouns": 'result.countPronouns/sum',
          "countNouns": 'result.countNouns/sum',
          "countVerbs": 'result.countVerbs/sum',
          "countAdjectives": 'result.countAdjectives/sum',
          "countAdverbs": 'nodata:result.countAdverbs/sum',
          "countWords": 'result.countWords/sum'
        }
        const valuesArray = ["id", "benchmark_id", "archive_name"]
        const fullLanguage = convertLanguagesToFull(language)

        for (const valKey of Object.keys(additionalValues)) {
          if (returnValues[valKey] && returnValues[valKey]?.settings?.languages &&
            returnValues[valKey].settings.languages.includes(fullLanguage)) {
            valuesArray.push(additionalValues[valKey])
          }
        }
        if (setValuesClix.size > 0) {
          for (const curr of Array.from(setValuesClix)) {
            // If the value is needed then do not add nodata:
            if(!config.neededValues.includes(curr)){
              valuesArray.push(`nodata:result.${curr}/${setFunctionsByReturnValueType(returnValues, curr)}`)
            }
          }
        }
        if (setDataCorporateLanguageClix.size > 0) {
          for (const curr of Array.from(setDataCorporateLanguageClix)) {
            valuesArray.push(`nodata:result.dataCorporateLanguage.${curr}/categorysizesum`)
          }
        }
        if (setDataTermsClix.size > 0) {
          for (const curr of Array.from(setDataTermsClix)) {
            valuesArray.push(`nodata:result.dataTerms.${curr}/categorysizesum`)
          }
        }
        if (setAllIds.size > 0) {
          valuesArray.push(`nodata:result.dataTerms/${Array.from(setAllIds).join('/')}`)
        }
        // run the main request
        let res
        try {
          // dataTerms dataCorporateLanguage
          res = await tlService.getArchiveStatistic(token,
            JSON.stringify(idList),
            JSON.stringify(valuesArray),
            0, 'create_time', language)
        } catch (err) {
          console.log(err)
        }
        // get single archive for dashboard pdf display:
        if (idList?.length === 1) {
          const getSingleArchive = async (idList) => {
            let pageResponse;
            try {
              pageResponse = await tlService.getArchiveResult(token, {}, { id: idList[0] });
            } catch (e) {
              console.log("error", e)
            }
            if (pageResponse?.data[0]?.result) {
              let parsedResult;
              try {
                parsedResult = JSON.parse(pageResponse.data[0].result);
              } catch (e) {
                parsedResult = {}
              }
              // add benchmark for find finding benchmark setting later in dashboard comparison:
              parsedResult.benchmarkId = pageResponse.data[0].benchmark_id;
              parsedResult.name = pageResponse.data[0].archive_name;
              actionDashboardSetArchiveResult(parsedResult)
            }
          }
          getSingleArchive(idList)
        }
        // set the values!
        if (res?.data?.length) {
          actionDashboardSetResultList(panel, res)
        } else {
          actionDashboardSetResultList(panel, [{}])
        }
      }
    }

    if (idList?.length && oldIdList !== idList && !isObjectEmpty(benchmarks)) {
      // Only call request when new idList is added:
      actionDashboardSetOldIdList(panel, idList)
      setSubmitted(true)
      getArchives(idList).then(() => setSubmitted(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idList, language])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const seriesHIX = useMemo(() => setSeries('formulaHix', resultList?.data, benchmarks, colors), [resultList, benchmarks])
  const seriesCLIX = useMemo(() => setSeries('formulaCLIX', resultList?.data, benchmarks, colors), [resultList, benchmarks])
  const seriesFlesch = useMemo(() => setSeries('formulaFlesch', resultList?.data, benchmarks, colors), [resultList, benchmarks])

  if (submitted) {
    return <Spinner />
  }

  return (
    <>
      <div style={{ gridGap: '30px', margin: '0 0 30px' }}>
        {isObjectEmpty(seriesHIX[0]) ? <div /> :
        <ColumnChart
        withNavLink={`/dashboard/compare/HIX?single=${panel}`}
        mainText={t('dash_hix_main_text')} series={seriesHIX[0]}
        mean={seriesHIX[1]} height={350}
        chartId={t('HIX_dashboard_chartID')} min={0} max={config.chartMax.hix} />}
      </div>
      <div style={{ gridGap: '30px', margin: '0 0 30px' }}>
        {isObjectEmpty(seriesFlesch[0]) ? <div /> :
      <ColumnChart
            // withNavLink={`/dashboard/compare/HIX?single=${panel}`}
            mainText={t('dash_flesch_main_text')} series={seriesFlesch[0]}
            mean={seriesFlesch[1]} height={350}
            chartId={t('FLESCH_dashboard_chartID')} min={0} max={config.chartMax.flesch} />}
          </div>
      <div style={{ gridGap: '30px', margin: '0 0 30px' }}>
        {isObjectEmpty(seriesCLIX[0]) ? <div /> : <ColumnChart
          withNavLink={`/dashboard/compare/CLIX?single=${panel}`}
          mainText={t('dash_clix_main_text')} series={seriesCLIX[0]}
          mean={seriesCLIX[1]} height={350}
          chartId={t('CLIX_dashboard_chartID')} min={0} max={config.chartMax.clix} />}
      </div>
    </>
  )
}