import {
    Button,
    Collapse,
    IconButton,
    Modal,
    Switch,
    TextField,
} from '@material-ui/core'
import style from './style.module.scss'
import React, { useEffect, useState, useRef } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import config from '../../../config'
import { allowlistText } from '../../../actions'
import {
    convertFromErrorObject,
    convertArrayToText,
    getTreeTaggerResult,
    isObjectEmpty,
    removeTagMark, getTCLocaleName, convertLanguagesToShort,
} from '../../../utils'
import { Add } from '@material-ui/icons'
import Autocomplete from '@mui/material/Autocomplete'
import { useParams } from 'react-router'

export default function TermModal(
    {
        currentTerm,
        addAPI,
        editAPI,
        localeName,
        setDeleteRecall,
        currentAllowedList,
        open,
        setOpen,
        callEndPoint,
        selectedText,
        component,
        editor,
    }) {
    const {
        theme: { bgColors, colors },
    } = useTheme()
    const dispatch = useDispatch()
    const tlService = React.useContext(TLServiceComponent)
    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation()

    // reducers
    const token = useSelector((state) => state.userData.token)
    const userId = useSelector((state) => state.userData.user.id)
    const allowListText = useSelector((state) => state.analyseData.allowListText)
    const resultData = useSelector(
        (state) => state.analyseData.result.resultData
    )
    const textToneData = useSelector((state) => state.userData.textTone) || []
    const allowedListsData =
        useSelector((state) => state.userData.allowedLists) || []
    const userType = useSelector((state) => state.userData.user.user_type_id)
    const TermCatTexttone = useSelector(
        (state) => state.analyseData.termCatTexttone
    )

    // get popover
    const popups = useSelector((state) => state.analyseData.popover.popups)
    const currentPopup = useSelector(
        (state) => state.analyseData.popover.currentPopup
    )
    const popUpData = !!popups[currentPopup] && popups[currentPopup].data
    // states
    const [checked, setChecked] = useState(false)
    const [wordData, setWordData] = useState([])
    const [lemmaData, setLemmaData] = useState([])
    const [tagData, setTagData] = useState([])
    const [descriptionIs, setDescriptionIs] = useState('')
    const [errorState, setErrorState] = useState('')
    const [replacementBox, setReplacementBox] = useState(['first'])
    const [replacementText, setReplacementText] = useState('')
    const [replacementIs, setReplacementIs] = useState([])
    const [deletedReplacement, setDeletedReplacement] = useState([])
    const [newReplacement, setNewReplacement] = useState([])
    const [deleteAlertMsg, setDeleteAlertMsg] = useState()
    const [idIs, setIdIs] = useState()
    const [localeNameIs, setLocaleNameIs] = useState('')
    const [termIs, setTermIs] = useState('')
    const [firstTermIs, setFirstTermIs] = useState('')
    const { categoryID } = useParams()
    const [currentLemma, setCurrentLemma] = useState([])
    const [currentWord, setCurrentWord] = useState([])
    const [oldWord, setOldWord] = useState([])
    const [currentTag, setCurrentTag] = useState([])
    const [showInput, setShowInput] = useState(false)
    const [unknownLemmaWords, setUnknownLemmaWords] = useState()
    const [rawContent, setRawContent] = useState()

    /************************************* TEXT TONE ****************************************/
    const [termName, setTermName] = useState()
    const [checkwordIs, setCheckwordIs] = useState(false)
    const [termIdIs, setTermIdIs] = useState()
    const [term_replacement, setTerm_replacement] = useState()
    const termError = useRef(false)

    // prepare  list for selector
    const allowTextToneOptions = textToneData.map((el, index) => {
        const obj = {}
        obj.id = index + 1
        obj.locale_name = el.locale_name
        obj.categoryId = el.id
        obj.description = el.description[i18nLanguage]
        obj.settings = el.settings
        // if(userType === 1){
        obj.name = `(${el.id}) ${el.name[i18nLanguage]}`
        // }else{
        // obj.name = el.name[i18nLanguage]
        // }
        return obj
    })
    // To get Array list for term :
    const allowTextToneOptionsArray = []
    allowTextToneOptions.map((el) => {
        if (!allowTextToneOptionsArray.includes(el.name)) {
            allowTextToneOptionsArray.push(el.name)
        }
    })
    /************************************* TEXT TONE ****************************************/
        // prepare  list for selector
    const allowCategoriesOptions = allowedListsData.map((el, index) => {
            const obj = {}
            obj.id = index + 1
            obj.categoryId = el.id
            obj.locale_name = el.locale_name
            obj.description = el.description[i18nLanguage]
            obj.settings = el.settings
            if (userType === 1) {
                obj.name = `(${el.id}) ${el.name[i18nLanguage]}`
            } else {
                obj.name = el.name[i18nLanguage]
            }
            return obj
        })
    // To get Array list for term :
    const allowCategoriesOptionsArray = []
    allowCategoriesOptions.map((el) => {
        if (!allowCategoriesOptionsArray.includes(el.name)) {
            allowCategoriesOptionsArray.push(el.name)
        }
    })
    /************************************* ALLOWED LIST ****************************************/

    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************
    /************************************* TEXT TONE START****************************************/
    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************

    /************************************* ANALYSE TEXT BIN START ****************************************/

    //*************************************  SETTING WORD LEMMA TAG WHEN LANDING ON PAGE *****************************/
    useEffect(() => {
        // Finding the start and end position of the selected Text:
        if (selectedText !== undefined) {
            const oldFullContent = editor.getContent()
            const content = selectedText.getContent()
            const clearedContent = removeTagMark(content)
            const bm = selectedText.getBookmark()
            const bookmarkedContent = removeTagMark(editor.getBody().innerHTML.replaceAll('&nbsp;', ' '))
            const re = new RegExp(`<span data-mce-type="bookmark" id="${bm.id}_start" data-mce-style="overflow:hidden;line-height:0px" style="overflow: hidden; line-height: 0px;"></span>`)

            const startIndex = bookmarkedContent.search(re)
            const endIndex = startIndex + clearedContent.length

            editor.setContent(oldFullContent)
            const lemmaIs = []
            const wordIs = []
            const tagIs = []
            if (resultData?.dataTokenizer?.length) {
                for (const el of resultData.dataTokenizer) {
                    if (el.begin >= startIndex && el.end <= endIndex) {
                        if (el.type !== 'sgml') {
                            lemmaIs.push(el.lemma)
                            wordIs.push(el.word)
                            tagIs.push(el.tag)
                        }
                    }
                }
            }
            setLemmaData(lemmaIs)
            setWordData(wordIs)
            setTagData(tagIs)
            setRawContent(content)

        }
        //getTreeTaggerResults()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const handleChange = () => {
    //   setChecked(!checked)
    // }

    // save the new Allow Record
    const postAnalyseTextTone = async () => {
        if (!!idIs) {
            const element = allowTextToneOptions[parseInt(idIs - 1)]
            // const description = allowListText?.length ? popUpData?.term?.description : element.description;
            const settings = allowListText?.length
                ? popUpData?.term?.settings
                : element.settings
            //   const checkWords = (allowListText?.length ? popUpData?.term?.check_words : checked) ? 1 : 0;
            const catId = element.categoryId
            if (replacementText?.length) {
                replacementIs.push(replacementText)
            }
            let words = currentWord?.length ? currentWord : wordData;
            let lemma  = currentLemma?.length ? currentLemma : lemmaData;
            let tag = currentTag?.length ? currentTag : tagData;
            if(words.length === 0) {
                const resTT = await callTreeTragger(element.locale_name, rawContent)
                words = resTT.word;
                lemma = resTT.lemma;
                tag = resTT.tag;
                setLemmaData(lemma);
                setWordData(words);
                setTagData(tag);
            }

            const checkWords = checked ? 1 : lemma.includes('<unknown>') ? 1 : 0

            try {
                await tlService.addTextTone(
                    token,
                    words,
                    lemma,
                    tag,
                    catId,
                    checkWords,
                    userId,
                    replacementIs,
                    descriptionIs,
                    settings
                )
                setOpen(false)
                setShowInput(false)
                dispatch(allowlistText(''))
            } catch (e) {
                setErrorState(convertFromErrorObject(t, e))
                setTimeout(() => {
                    setErrorState('')
                }, config.timeOutDuration)
            }
        } else {
            setDeleteAlertMsg(t('Term-cat-select'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
    }
    /************************************* ANALYSE TEXT BIN  ENDS ****************************************/

    /************************************* PRETTY ADMIN TEXT BIN  START****************************************/
    useEffect(() => {
        if (!isObjectEmpty(currentTerm) && open && component === 'AdminTextbin') {
            let replacementBoxArray = []
            let replacementList = []
            let joinText = currentTerm.words.join(' ')
            let replacementArray = currentTerm.replacements

            setTerm_replacement(replacementArray?.length ? replacementArray : [])
            setNewReplacement(replacementArray?.length ? replacementArray : [])
            setTermIs(joinText)
            setDescriptionIs(currentTerm?.description)
            setTermIdIs(currentTerm?.id)
            setChecked(currentTerm.check_words)
            // Setting default term list:
            allowTextToneOptions.map((el) => {
                // if(userType === 1){
                if (
                    el.name ===
                    `(${currentTerm.term_category_id}) ${currentTerm.term_category_name[i18nLanguage]}`
                ) {
                    setIdIs(el.id)
                    setLocaleNameIs(el.locale_name)
                    setTermName(el.name)
                }
            })
            replacementArray?.length &&
            replacementArray.map((a, i) => {
                if (!replacementArray[i]?.includes(null)) {
                    // let joinText = replacementArray[i]?.join(' ')
                    replacementList.push(replacementArray[i])
                    replacementBoxArray.push(i)
                }
            })
            if (replacementList[0] !== '') {
                // Checking whether there is replacment or not:
                setReplacementIs([...replacementIs, ...replacementList])
                setReplacementBox([...replacementBox, ...replacementBoxArray])
            }
            setCheckwordIs(currentTerm.check_words === 1)
        } else if (open && component === 'AnalyseTextbin') {
            for (let el of allowTextToneOptions) {
                if (el?.name?.includes(TermCatTexttone)) {
                    setTimeout(() => {
                        setTermName(el.name)
                        setIdIs(el.id)
                        setLocaleNameIs(el.locale_name)
                    }, 100)
                    break
                }
            }
        }
        // If create new textbin, just add empty ID:
        if (isObjectEmpty(currentTerm)) {
            setIdIs('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTerm, open])


    const addAdminTextbin = async () => {
        const element = allowTextToneOptions[parseInt(idIs - 1)]
        const catId = element?.categoryId && element.categoryId
        // let termId;
        let replacementArray = newReplacement
        // When User click save without clicking PLUS btn :
        if (replacementText !== '') {
            replacementArray.push(replacementText)
        }
        setReplacementText('')
        // User must fill Term word first:
        // if (termIs?.length) {
        let tokenizeTerm
        const categoryLocaleName = await getTCLocaleName(tlService, token, catId)
        if (!categoryLocaleName) {
            return false
        }

        if (termIs?.length) {
            tokenizeTerm = await getTreeTaggerResult(
                tlService,
                token,
                categoryLocaleName,
                termIs
            )
            if (!tokenizeTerm?.error) {
                if (isObjectEmpty(currentTerm)) {
                    if (!!catId) {
                        try {
                            await addAPI(
                                token,
                                tokenizeTerm.word,
                                tokenizeTerm.lemma,
                                tokenizeTerm.tag,
                                catId,
                                checkwordIs ? 1 : 0,
                                userId,
                                replacementArray,
                                descriptionIs,
                                {}
                            )
                            // termId = res.id;

                            // To check whether any Eror occur for adding Terms:
                            termError.current = false
                            // if (!replacementArray?.length) {
                            // setTimeout(() => {
                            setOpen(false)
                            setShowInput(false)
                            defaultBox()
                            setTermIs('')
                            setDescriptionIs('')
                            // setDeleteRecall(Math.floor(Math.random() * 10000))
                            // },100)
                            callEndPoint()
                            // }
                        } catch (e) {
                            // If error are there for adding Terms then no need for adding replacemnt as no term ID is available:
                            termError.current = true
                            console.log(e)
                            setDeleteAlertMsg(convertFromErrorObject(t, e))
                            setTimeout(() => {
                                setDeleteAlertMsg('')
                            }, config.timeOutDuration)
                        }
                        try {
                            await tlService.clearAllCache(
                                token,
                                1,
                                Number(currentTerm.term_category_id)
                            )
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        setDeleteAlertMsg(t('Term-cat-select'))
                        setTimeout(() => {
                            setDeleteAlertMsg('')
                        }, config.timeOutDuration)
                    }
                } else {
                    try {
                        await editAPI(
                            token,
                            termIdIs,
                            tokenizeTerm.word,
                            tokenizeTerm.lemma,
                            tokenizeTerm.tag,
                            catId,
                            checkwordIs ? 1 : 0,
                            userId,
                            descriptionIs,
                            replacementArray,
                            {}
                        )
                        // if (!replacementArray?.length) {
                        setOpen(false)
                        setShowInput(false)
                        defaultBox()
                        setTermIs('')
                        setDescriptionIs('')
                        // }
                        // during edit, it should recall even when no replceament present :
                        // setDeleteRecall(Math.floor(Math.random() * 10000))
                        callEndPoint()
                    } catch (e) {
                        console.log(e)

                        setDeleteAlertMsg(convertFromErrorObject(t, e))
                        setTimeout(() => {
                            setDeleteAlertMsg('')
                        }, config.timeOutDuration)
                    }
                    try {
                        await tlService.clearAllCache(
                            token,
                            1,
                            Number(currentTerm.term_category_id)
                        )
                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                return false
            }
        } else {
            setDeleteAlertMsg(t('term-select'))
        }

    }
    /************************************* PRETTY ADMIN TEXT BIN ENDS ****************************************/
    const addNewBox = () => {
        if (!!replacementText) {
            setNewReplacement([...newReplacement, replacementText])
            setReplacementIs([...replacementIs, replacementText])
            setReplacementBox([...replacementBox, Math.floor(Math.random() * 10000)])
            setReplacementText('')
        } else {
            setDeleteAlertMsg(t('fill-option'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
    }
    // Set the Box Input to default state :
    const defaultBox = () => {
        setReplacementText('')
        setDeletedReplacement([])
        setNewReplacement([])
        setReplacementBox([Math.floor(Math.random() * 10000)])
        setReplacementIs([])
        setTermIs('')
        setDescriptionIs('')
        setCheckwordIs(false)
        // ALLOWED LIST
        setCurrentWord([])
        setCurrentLemma([])
        setOldWord([])
        setCurrentTag([])
    }

    const removeReplacement = async (term) => {
        let indexIs = replacementIs.indexOf(term)
        let replacementIndex = newReplacement.indexOf(term)
        setDeletedReplacement([...deletedReplacement, term])
        // Remove from UI :
        newReplacement.splice(replacementIndex, 1)
        replacementIs.splice(indexIs, 1)
        replacementBox.splice(indexIs, 1)
        // setDeleteRecall(Math.floor(Math.random() * 10000));
        // callEndPoint()
    }

    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************
    /************************************* TEXT TONE END****************************************/
    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************

    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************
    /************************************* ALLOWED LIST START****************************************/
    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************

    /************************************* PRETTY ADMIN ALLOWED LIST START ****************************************/
    useEffect(() => {
        if (!!currentAllowedList && open) {
            setCheckwordIs(currentAllowedList.check_words === 1)
            // try{
            //   setCurrentWord(JSON.parse(currentAllowedList.words).join(' '));
            // }catch(err){
            //   console.log(err)
            // }
            setCurrentWord(currentAllowedList.words)
            setOldWord(currentAllowedList.words)
            setCurrentLemma(currentAllowedList.lemma)
            setCurrentTag(currentAllowedList.tag)
            setChecked(currentAllowedList.check_words)
        }
    }, [currentAllowedList, open])

    // Set default allowed list benchmark for Edit allowed lsit:
    useEffect(() => {
        if (!!currentAllowedList) {
            // Setting dfault term list:
            allowCategoriesOptions?.length &&
            allowCategoriesOptions.map((el) => {
                if (userType === 1) {
                    if (
                        el.name ===
                        `(${currentAllowedList.term_category_id}) ${currentAllowedList.term_category_name[i18nLanguage]}`
                    ) {
                        setIdIs(el.categoryId)
                        setTermName(el.name)
                        setLocaleNameIs(el.locale_name)
                    }
                } else {
                    if (
                        el.name === currentAllowedList.term_category_name[i18nLanguage]
                    ) {
                        setIdIs(el.categoryId)
                        setLocaleNameIs(el.locale_name)
                        setTermName(el.name)
                    }
                }
            })
        } else {
            setTermName('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAllowedList, open])

    const addAdminAllowedList = async () => {
        let wordIs = []
        let lemmaIs = []
        let tagIs = []
        let tokenizeTerm
        // if(!showInput){
        if (Array.isArray(currentWord)) {
            wordIs = currentWord
            lemmaIs = currentLemma
            tagIs = currentTag
        } else {
            const locale = localeNameIs ? localeNameIs : localeName
            tokenizeTerm = await getTreeTaggerResult(
                tlService,
                token,
                locale,
                currentWord
            )
            if (!tokenizeTerm?.error) {
                wordIs = tokenizeTerm.word
                lemmaIs = tokenizeTerm.lemma
                tagIs = tokenizeTerm.tag
            } else {
                console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                return false
            }
        }
        // }
        if (!!currentAllowedList) {
            try {
                const res = await editAPI(
                    token,
                    currentAllowedList.id,
                    currentWord !== oldWord ? wordIs : currentAllowedList.words,
                    // have to check word comparision because User only input this:
                    currentWord !== oldWord ? lemmaIs : currentAllowedList.lemma,
                    currentWord !== oldWord ? tagIs : currentAllowedList.tag,
                    // [],
                    // currentAllowedList.term_category_id,
                    idIs,
                    checkwordIs ? 1 : 0,
                    userId,
                    '',
                    {}
                )
                setOpen(false)
                setShowInput(false)
                defaultBox()
                // during edit, it should recall even when no replceament present :
                setDeleteRecall(res)
                callEndPoint()
            } catch (e) {
                console.log(e)
                setDeleteAlertMsg(convertFromErrorObject(t, e))
                setTimeout(() => {
                    setDeleteAlertMsg('')
                }, config.timeOutDuration)
            }
        } else {
            if (!!idIs) {
                try {
                    const res = await addAPI(
                        token,
                        !showInput ? wordIs : currentWord,
                        !showInput ? lemmaIs : currentLemma,
                        !showInput ? tagIs : currentTag,
                        // [],
                        idIs,
                        checkwordIs ? 1 : 0,
                        userId,
                        '',
                        {}
                    )
                    setOpen(false)
                    setShowInput(false)
                    defaultBox()
                    setDeleteRecall(res)
                } catch (e) {
                    console.log(e)
                    setDeleteAlertMsg(convertFromErrorObject(t, e))
                    setTimeout(() => {
                        setDeleteAlertMsg('')
                    }, config.timeOutDuration)
                }
            } else {
                setDeleteAlertMsg(t('Allowed-select'))
                setTimeout(() => {
                    setDeleteAlertMsg('')
                }, config.timeOutDuration)
            }
        }
        setShowInput(false)
    }
    /************************************* PRETTY ADMIN ALLOWED LIST ENDS ****************************************/

    /************************************* ANALYSE ALLOWED LIST START ****************************************/
    const handleChange = () => {
        setChecked(!checked)
    }

    // save the new Allow Record
    const postAnalyseAllowedList = async () => {
        const element = allowCategoriesOptions.filter(
            (el) => el.categoryId === idIs
        )
        // const description = allowListText?.length ? popUpData?.term?.description : element.description;
        const settings = allowListText?.length
            ? popUpData?.term?.settings
            : element?.settings
        //   const checkWords = (allowListText?.length ? popUpData?.term?.check_words : checked) ? 1 : 0;


        const catId = element[0]?.categoryId && element[0].categoryId
        if (!!catId) {
            let wordIs
            let lemmaIs
            let tagIs
            let checkWords = 0
            if (currentLemma?.length === 0) {
                try {
                    const locale = localeNameIs ? localeNameIs : localeName
                    const shortLocale = convertLanguagesToShort(locale)
                    let tokenizeTerm = await getTreeTaggerResult(
                        tlService,
                        token,
                        locale,
                        currentWord?.length
                            ? convertArrayToText(currentWord, shortLocale)
                            : allowListText?.length
                                ? convertArrayToText(allowListText, shortLocale)
                                : wordData?.length
                                    ? convertArrayToText(wordData, shortLocale)
                                    : ''
                    )
                    if (!tokenizeTerm?.error) {
                        wordIs = tokenizeTerm.word
                        lemmaIs = tokenizeTerm.lemma
                        tagIs = tokenizeTerm.tag
                    } else {
                        console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                    }
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                }
            }

            // needs to refactor to universal function
            if(currentLemma?.length) {
                checkWords = checked ? 1 : currentLemma.includes('<unknown>') ? 1 : 0
            } else {
                if(lemmaIs?.length) {
                    checkWords = checked ? 1 : lemmaIs.includes('<unknown>') ? 1 : 0
                }
            }

            try {
                await tlService.addAllowedList(
                    token,
                    currentWord?.length ? currentWord : wordIs,
                    currentLemma?.length ? currentLemma : lemmaIs,
                    currentTag?.length ? currentTag : tagIs,
                    catId,
                    checkWords,
                    userId,
                    '',
                    settings
                )
                setOpen('')
                dispatch(allowlistText(''))
            } catch (e) {
                setErrorState(convertFromErrorObject(t, e))
                setTimeout(() => {
                    setErrorState('')
                }, config.timeOutDuration)
            }
        } else {
            setDeleteAlertMsg(t('Allowed-select'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
    }
    /************************************* ANALYSE ALLOWED LIST ENDS ****************************************/
        //   const choosingOption = (e) => {
        //     if(e.target.value !== 0){
        //       setIdIs(e.target.value);
        //     }
        //   }
        // const selectList = (value) => {
        //   allowCategoriesOptions.map(el => {
        //     if(el.name === value){
        //       setIdIs(el.id);
        //     }
        //   })
        // }
    const callTreeTragger = async (lang, text) => {
            let tokenizeTerm = await getTreeTaggerResult(
                tlService,
                token,
                lang,
                text
            )
            if (!tokenizeTerm?.error) {
                setCurrentWord(tokenizeTerm.word)
                setCurrentLemma(tokenizeTerm.lemma)
                setCurrentTag(tokenizeTerm.tag)
                addingUnknownLemma(tokenizeTerm.lemma, tokenizeTerm.word)
                return tokenizeTerm;
            } else {
                console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                return false
            }
    }

    const selectAllowedList = (value) => {
        allowCategoriesOptions.map((el) => {
            if (el.name === String(value)) {
                setIdIs(el.categoryId)
                setLocaleNameIs(el.locale_name)
            }
        })
    }

    const addingUnknownLemma = (lemma, word) => {
        // Adding unknown lemma words:
        let words = []
        lemma?.length && lemma.map((el, i) => {
            if (el === '<unknown>') {
                words.push(word[i])
            }
        })
        if (words?.length) {
            setUnknownLemmaWords(words.reduce((acc, v) => acc + `"${v}", `, '').replace(/, $/, ''))
        }
    }

    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************
    /************************************* ALLOWED LIST END****************************************/
    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************

    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************
    /************************************* TERM LIST START****************************************/
    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************

    useEffect(() => {
        if (!isObjectEmpty(currentTerm) && open && component === 'TermList') {
            let replacementBoxArray = []
            let replacementList = []
            let joinText = currentTerm?.words.join(' ')
            setTerm_replacement(
                currentTerm.term_replacement?.length ? currentTerm.term_replacement : []
            )
            setTermIs(joinText)
            setFirstTermIs(joinText)
            setDescriptionIs(currentTerm?.description)
            setTermIdIs(currentTerm?.id)
            setChecked(currentTerm.check_word)
            setCurrentLemma(currentTerm.lemma)
            setCurrentTag(currentTerm.tag)
            setCurrentWord(currentTerm.words)
            currentTerm?.term_replacement.map((a, i) => {
                if (!currentTerm?.term_replacement[i]?.words.includes(null)) {
                    let joinText = currentTerm?.term_replacement[i]?.words.join(' ')
                    replacementList.push(joinText)
                    replacementBoxArray.push(i)
                }
            })
            setReplacementIs([...replacementIs, ...replacementList])
            setReplacementBox([...replacementBox, ...replacementBoxArray])
            setCheckwordIs(currentTerm.check_word === 1)

            addingUnknownLemma(currentTerm.lemma, currentTerm.words)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTerm, open])

    const addTermList = async () => {
        let termId
        let newTermId
        let replacementArray = newReplacement
        // When User click save without clicking PLUS btn :
        if (replacementText !== '') {
            replacementArray.push(replacementText)
        }
        // newReplacement.push(replacementText)
        // setNewReplacement([...newReplacement, replacementText])
        setReplacementText('')
        // User must fill Term word first:
        if (termIs?.length) {
            // if(Object.keys(currentTerm).length !== 0 ){
            if (!isObjectEmpty(currentTerm)) {
                // EDIT:
                if (firstTermIs === termIs) {
                    try {
                        await tlService.editTerm(
                            token,
                            termIdIs,
                            Number(categoryID),
                            currentWord,
                            currentLemma,
                            currentTag,
                            (checked || currentLemma.includes('<unknown>')) ? 1 : 0,
                            descriptionIs,
                            1
                        )
                        if (!replacementArray?.length) {
                            setOpen(false)
                            defaultBox()
                            setTermIs('')
                            setDescriptionIs('')
                        }
                        // during edit, it should recall even when no replceament present :
                        // setDeleteRecall(Math.floor(Math.random() * 10000))
                        // callEndPoint()
                    } catch (e) {
                        console.log(e)
                        setDeleteAlertMsg(convertFromErrorObject(t, e))
                        setTimeout(() => {
                            setDeleteAlertMsg('')
                        }, config.timeOutDuration)
                    }

                } else {
                    // Call treetager if User Edit Term name:
                    const locale = localeNameIs ? localeNameIs : localeName
                    let tokenizeTerm = await getTreeTaggerResult(
                        tlService,
                        token,
                        locale,
                        termIs
                    )
                    if (!tokenizeTerm?.error) {
                        try {
                            let responseIs = await tlService.editTerm(
                                token,
                                termIdIs,
                                Number(categoryID),
                                tokenizeTerm.word,
                                tokenizeTerm.lemma,
                                tokenizeTerm.tag,
                                (checked || tokenizeTerm.lemma.includes('<unknown>')) ? 1 : 0,
                                descriptionIs,
                                1
                            )
                            // New TermID:
                            newTermId = responseIs.id
                            if (!replacementArray?.length) {
                                setOpen(false)
                                defaultBox()
                                setTermIs('')
                                setDescriptionIs('')
                            }
                            // during edit, it should recall even when no replceament present :
                            // setDeleteRecall(Math.floor(Math.random() * 10000))
                            // callEndPoint()
                        } catch (e) {
                            console.log(e)
                            setDeleteAlertMsg(convertFromErrorObject(t, e))
                            setTimeout(() => {
                                setDeleteAlertMsg('')
                            }, config.timeOutDuration)
                        }
                    } else {
                        console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                        return false
                    }
                }
                try {
                    await tlService.clearAllCache(token, 1, Number(categoryID))
                } catch (error) {
                    console.log(error)
                }
            } else {
                // ADD:
                const locale = localeNameIs ? localeNameIs : localeName
                let tokenizeTerm = await getTreeTaggerResult(
                    tlService,
                    token,
                    locale,
                    termIs
                )
                if (!tokenizeTerm?.error) {
                    try {
                        const res = await tlService.addTerm(
                            token,
                            Number(categoryID),
                            tokenizeTerm.word,
                            tokenizeTerm.lemma,
                            tokenizeTerm.tag,
                            (checked || tokenizeTerm.lemma.includes('<unknown>')) ? 1 : 0,
                            {},
                            descriptionIs,
                            1
                        )
                        termId = res.data.id

                        await tlService.calculateCategory(token, Number(categoryID))
                        // To check whether any Eror occur for adding Terms:
                        termError.current = false
                        if (!replacementArray?.length) {
                            setOpen(false)
                            defaultBox()
                            setTermIs('')
                            setDescriptionIs('')
                            // setDeleteRecall(Math.floor(Math.random() * 10000))
                            // callEndPoint()
                        }
                    } catch (e) {
                        // If error are there for adding Terms then no need for adding replacemnt as no term ID is available:
                        termError.current = true
                        console.log(e)
                        setDeleteAlertMsg(convertFromErrorObject(t, e))
                        setTimeout(() => {
                            setDeleteAlertMsg('')
                        }, config.timeOutDuration)
                    }
                } else {
                    console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                    return false
                }
                try {
                    await tlService.clearAllCache(token, 1, Number(categoryID))
                } catch (error) {
                    console.log(error)
                }
            }
            // go through replacements
            if (replacementArray?.length && !termError.current) {
                for (const element of replacementArray) {
                    const locale = localeNameIs ? localeNameIs : localeName
                    const tokenizeTerm = await getTreeTaggerResult(
                        tlService,
                        token,
                        locale,
                        element
                    )
                    if (!tokenizeTerm?.error) {
                        try {
                            await tlService.addTermReplacement(
                                token,
                                (!isObjectEmpty(currentTerm) && (firstTermIs !== termIs)) ?
                                    newTermId :
                                    (!isObjectEmpty(currentTerm) && (firstTermIs === termIs)) ?
                                        termIdIs : termId,
                                categoryID,
                                tokenizeTerm.word,
                                tokenizeTerm.lemma,
                                tokenizeTerm.tag
                            )
                            defaultBox()
                            setOpen(false)
                            setTermIs('')
                            setDescriptionIs('')
                        } catch (e) {
                            setDeleteAlertMsg(convertFromErrorObject(t, e))
                            setTimeout(() => {
                                setDeleteAlertMsg('')
                            }, config.timeOutDuration)
                            return false
                        }
                    } else {
                        console.log(
                            'error ' + convertFromErrorObject(t, tokenizeTerm.error)
                        )
                        return false
                    }
                }
            }
            // Remove from DB the deleted replacments :
            if (deletedReplacement?.length) {
                term_replacement?.length &&
                term_replacement.map(async (element, i) => {
                    deletedReplacement?.length &&
                    deletedReplacement.map(async (deletedElement) => {
                        if (element?.words?.join(' ') === deletedElement) {
                            // idIs = element.id
                            try {
                                await tlService.deleteTermReplacement(
                                    token,
                                    element.id,
                                    (firstTermIs !== termIs) ? newTermId :
                                        termIdIs,
                                    categoryID
                                )
                            } catch (error) {
                                console.log(convertFromErrorObject(t, error))
                            }
                        }
                    })
                    // If index is last element, then run recall once:
                    if (i === term_replacement?.length - 1) {
                        // setDeleteRecall(Math.floor(Math.random() * 10000))
                        // callEndPoint()
                    }
                })
            }
        } else {
            setDeleteAlertMsg(t('fill-option'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
        callEndPoint()
    }
    // *****************************************************************************************
    // *****************************************************************************************
    // *****************************************************************************************
    /************************************* TERM LIST END****************************************/
        // *****************************************************************************************
        // *****************************************************************************************
        // *****************************************************************************************

    const InputBox = () => {
            return (
                replacementBox?.length &&
                replacementBox.map((el, index) => {
                    return (
                        <div key={index}>
                            {replacementBox.indexOf(el) < replacementBox?.length - 1 ? (
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        size="small"
                                        disabled
                                        value={replacementIs[index]}
                                        style={{ width: '100%', marginBottom: '20px' }}
                                        onChange={(e) => setReplacementText(e.target.value)}
                                        label={t('replacement')}
                                        type="text"
                                        variant="outlined"
                                    />
                                    <button
                                        onClick={() => removeReplacement(replacementIs[index])}
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        style={{ marginLeft: '10px', marginBottom: '20px' }}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ) : (
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        size="small"
                                        value={replacementText}
                                        style={{ width: '100%', marginBottom: '20px' }}
                                        onChange={(e) => setReplacementText(e.target.value)}
                                        label={t('replacement')}
                                        type="text"
                                        variant="outlined"
                                    />
                                </div>
                            )}
                        </div>
                    )
                })
            )
        }

    const selectTexttoneList = (value) => {
        allowTextToneOptions.map((el) => {
            if (el.name === value) {
                setIdIs(el.id)
                setLocaleNameIs(el.locale_name)
            }
        })
    }
    const locale = localeNameIs ? localeNameIs : localeName
    const shortLocale = convertLanguagesToShort(locale)

    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
                setShowInput(false)
                dispatch(allowlistText(''))
                defaultBox()
            }}
        >
            <div
                className={`${style.mainCont} p-3`}
                style={{ backgroundColor: bgColors.bgPrimary0 }}
            >
                <div className={`${style.head} d-flex justify-content-between mb-2`}>
                    {component === 'AdminAllowedlist' ||
                    component === 'AnalyseAllowedList' ? (
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: colors.colorBlue1000 }}
                        >
                            {currentAllowedList
                                ? t('edit-allowedList')
                                : t('add-allowedList')}
                        </p>
                    ) : component === 'AnalyseTextbin' ? (
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: colors.colorBlue1000 }}
                        >
                            {t('Report-term-violation')}
                        </p>
                    ) : component === 'TermList' && !isObjectEmpty(currentTerm) ? (
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: colors.colorBlue1000 }}
                        >
                            {t('edit-term')}
                        </p>
                    ) : component === 'TermList' ? (
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: colors.colorBlue1000 }}
                        >
                            {t('add-term')}
                        </p>
                    ) : component === 'AdminTextbin' && !isObjectEmpty(currentTerm) ? (
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: colors.colorBlue1000 }}
                        >
                            {t('edit-textbin')}
                        </p>
                    ) : (
                        component === 'AdminTextbin' && (
                            <p
                                className={`m-0 ${style.title}`}
                                style={{ color: colors.colorBlue1000 }}
                            >
                                {t('add-textbin')}
                            </p>
                        )
                    )}
                    <IconButton
                        className={`p-0 m-0`}
                        onClick={() => {
                            setOpen(false)
                            setShowInput(false)
                            dispatch(allowlistText(''))
                            defaultBox()
                        }}
                    >
                        <Close/>
                    </IconButton>
                </div>
                <div>
                    {component === 'TermList' ? (
                        <>
                            <br/>
                            <TextField
                                size="small"
                                value={termIs}
                                onChange={(e) => setTermIs(e.target.value)}
                                fullWidth
                                variant="outlined"
                                label={t('term')}
                                placeholder={t('All the best wishes')}
                            />
                        </>
                    ) : component === 'AdminAllowedlist' ||
                    component === 'AnalyseAllowedList' ? (
                        <>
                            <br/>
                            {allowCategoriesOptions?.length ? (
                                <Autocomplete
                                    // disablePortal
                                    freeSolo
                                    id="combo-box-demo"
                                    options={allowCategoriesOptionsArray}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        selectAllowedList(newValue)
                                    }}
                                    value={termName}
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'lightGray',
                                        borderRadius: '10px',
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: { fontWeight: 'bold', borderRadius: '10px' },
                                            }}
                                            label={t('Allowed list')}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            ) : (
                                <h4 style={{ textAlign: 'center' }}>
                                    {t('no-term-category-found')}
                                </h4>
                            )}
                        </>
                    ) : (
                        (component === 'AdminTextbin' || component === 'AnalyseTextbin') &&
                        (!isObjectEmpty(currentTerm) || wordData?.length || allowListText ? (
                            <>
                                {/************************************* TEXT TONE ****************************************/}
                                <br/>
                                {allowTextToneOptions?.length ? (
                                    <Autocomplete
                                        freeSolo
                                        id="combo-box-demo"
                                        options={allowTextToneOptionsArray}
                                        sx={{ width: 300 }}
                                        onChange={(event, newValue) => {
                                            selectTexttoneList(newValue)
                                        }}
                                        value={termName}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'lightGray',
                                            borderRadius: '10px',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontWeight: 'bold', borderRadius: '10px' },
                                                }}
                                                label={t('Allowed list')}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <h4 style={{ textAlign: 'center' }}>
                                        {t('no-term-category-found')}
                                    </h4>
                                )}
                                <br/>
                                {
                                    <TextField
                                        size="small"
                                        value={
                                            currentWord?.length
                                                ? convertArrayToText(currentWord, shortLocale)
                                                : allowListText?.length
                                                    ? convertArrayToText(allowListText, shortLocale)
                                                    : wordData?.length
                                                        ? convertArrayToText(wordData, shortLocale)
                                                        : ''
                                        }
                                        onChange={(e) => {
                                            let wordIs = []
                                            wordIs.push(e.target.value)
                                            setCurrentWord(wordIs)
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        label={t('term')}
                                        placeholder={t('All the best wishes')}
                                    />
                                }
                            </>
                        ) : (
                            <>
                                {allowTextToneOptions?.length ? (
                                    <Autocomplete
                                        freeSolo
                                        id="combo-box-demo"
                                        options={allowTextToneOptionsArray}
                                        sx={{ width: 300 }}
                                        onChange={(event, newValue) => {
                                            selectTexttoneList(newValue)
                                        }}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'lightGray',
                                            borderRadius: '10px',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontWeight: 'bold', borderRadius: '10px' },
                                                }}
                                                label={t('Allowed list')}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <h4 style={{ textAlign: 'center' }}>
                                        {t('no-term-category-found')}
                                    </h4>
                                )}
                                <br/>
                                <TextField
                                    size="small"
                                    onChange={(e) => setTermIs(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    label={t('term')}
                                    placeholder={t('All the best wishes')}
                                />
                                {/************************************* TEXT TONE ****************************************/}
                            </>
                        ))
                    )}
                    {component === 'AdminAllowedlist' ||
                    component === 'AnalyseAllowedList' ? (
                        <div>
                            <br/>
                            <div>
                                <TextField
                                    size="small"
                                    value={
                                        currentWord?.length
                                            ? convertArrayToText(currentWord, shortLocale)
                                            : allowListText?.length
                                                ? convertArrayToText(allowListText, shortLocale)
                                                : wordData?.length
                                                    ? convertArrayToText(wordData, shortLocale)
                                                    : ''
                                    }
                                    name={'words'}
                                    control={'text'}
                                    placeholder={t('words')}
                                    onChange={(e) => {
                                        let wordIs = []
                                        wordIs.push(e.target.value)
                                        setCurrentWord(wordIs)
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    label={t('term')}
                                />
                            </div>
                            <br/>
                            <br/>
                            <div>
                                <div
                                    style={{
                                        float: 'right',
                                        marginTop: '-5px',
                                        width: '300px',
                                        paddingLeft: '20px',
                                    }}
                                >
                                    {!showInput ? (
                                        <>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={`ml-2`}
                                                    onClick={() => {
                                                        setShowInput(true)

                                                        const locale = localeNameIs ? localeNameIs : localeName
                                                        if (currentWord?.length) {
                                                            if (Array.isArray(currentWord))
                                                                callTreeTragger(locale, currentWord.join(' '))
                                                            else
                                                                callTreeTragger(locale, currentWord)
                                                        } else if (allowListText?.length) {
                                                            callTreeTragger(locale, allowListText)
                                                        } else if (wordData?.length) {
                                                            callTreeTragger(locale, wordData.join(' '))
                                                        }
                                                    }}
                                                >
                                                    {t('expert-details')}
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={`ml-2`}
                                                    onClick={() => {
                                                        setShowInput(false)
                                                    }}
                                                >
                                                    {t('hide-expert-details')}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <br/>
                            <br/>
                            {/* {(userType === 1 || userType === 2) && */}
                            {showInput && (
                                <>
                                    <div>
                                        <br/>
                                        <br/>
                                        {currentLemma?.includes('<unknown>') ?
                                            <>
                                                <b>{t('Check-Words')} : </b> {t('use-lemma')}
                                                <Switch
                                                    disabled
                                                    checked={true}
                                                    onChange={() => setCheckwordIs(!checkwordIs)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    className={'m-0'}
                                                    label={'Find in text by lemmas'}
                                                />
                                                {t('only exact wording')}

                                                <div style={{
                                                    color: 'firebrick',
                                                    font: 'status-bar'

                                                }}>
                                                    {t('lemma-unknown', {
                                                        term: `"${currentWord?.length
                                                            ? convertArrayToText(currentWord, shortLocale)
                                                            : allowListText?.length
                                                                ? convertArrayToText(allowListText, shortLocale)
                                                                : wordData?.length
                                                                    ? convertArrayToText(wordData, shortLocale)
                                                                    : ''}."`,
                                                        token: unknownLemmaWords
                                                    })}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <b>{t('Check-Words')} : </b> {t('use-lemma')}
                                                <Switch
                                                    checked={!!checkwordIs}
                                                    onChange={() => setCheckwordIs(!checkwordIs)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    className={'m-0'}
                                                    label={'Find in text by lemmas'}
                                                />
                                                {t('only exact wording')}
                                            </>
                                        }
                                        <br/>
                                        <br/>
                                        <b> {t('lemma')} : </b>
                                        {/* <br/> */}
                                        <TextField
                                            disabled
                                            size="small"
                                            value={currentLemma?.length ? convertArrayToText(currentLemma, shortLocale) :
                                                lemmaData?.length
                                                    ? convertArrayToText(lemmaData, shortLocale)
                                                    : ''}
                                            name={'lemma'}
                                            placeholder={t('lemma')}
                                            onChange={(e) => {
                                                setCurrentLemma(!!e.target.value ? e.target.value : [])
                                            }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                    <br/>
                                    <div>
                                        <b> {t('tag')} : </b>
                                        <br/>
                                        <TextField
                                            disabled
                                            size={'small'}
                                            value={currentTag?.length ? convertArrayToText(currentTag, shortLocale) :
                                                tagData?.length
                                                    ? convertArrayToText(tagData, shortLocale)
                                                    : ''}
                                            name={'tag'}
                                            placeholder={t('tag')}
                                            //  className={style.inputfield}
                                            onChange={(e) => {
                                                setCurrentTag(e.target.value)
                                            }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        (component === 'AdminTextbin' ||
                            component === 'AnalyseTextbin' ||
                            component === 'TermList') && (
                            <>
                                <br/>
                                <br/>
                                <br/>
                                <div
                                    style={{
                                        float: 'right',
                                        marginTop: '-35px',
                                        width: '300px',
                                        paddingLeft: '20px',
                                    }}
                                >
                                    {!showInput ? (
                                        <>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={`ml-2`}
                                                    onClick={() => {
                                                        setShowInput(true)
                                                        const locale = localeNameIs ? localeNameIs : localeName
                                                        if (!!termIs) {
                                                            callTreeTragger(locale, termIs)
                                                        } else if (currentWord?.length) {
                                                            if (Array.isArray(currentWord))
                                                                callTreeTragger(locale, currentWord.join(' '))
                                                            else
                                                                callTreeTragger(locale, currentWord)
                                                        } else if (allowListText?.length) {
                                                            callTreeTragger(locale, allowListText)
                                                        } else if (wordData?.length) {
                                                            callTreeTragger(locale, wordData.join(' '))
                                                        }
                                                    }}
                                                >
                                                    {t('expert-details')}
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={`ml-2`}
                                                    onClick={() => {
                                                        setShowInput(false)
                                                    }}
                                                >
                                                    {t('hide-expert-details')}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <br/>
                                {showInput && (
                                    <>
                                        <div>
                                            <br/>
                                            <br/>
                                            <div>
                                                {/* Set check word to 0 and disable the checkword if lemma is Unknown:*/}
                                                {(currentTerm?.lemma?.includes('<unknown>') || currentLemma?.includes('<unknown>')) ?
                                                    <>
                                                        <b>{t('Check-Words')} : </b> {t('use-lemma')}
                                                        <Switch
                                                            disabled
                                                            checked={true}
                                                            onChange={handleChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        {t('only exact wording')}
                                                        <div style={{
                                                            color: 'firebrick',
                                                            font: 'status-bar'

                                                        }}>
                                                            {t('lemma-unknown', {
                                                                term: `"${currentWord?.length
                                                                    ? convertArrayToText(currentWord, shortLocale)
                                                                    : allowListText?.length
                                                                        ? convertArrayToText(allowListText, shortLocale)
                                                                        : wordData?.length
                                                                            ? convertArrayToText(wordData, shortLocale)
                                                                            : ''}."`,
                                                                token: unknownLemmaWords
                                                            })}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <b>{t('Check-Words')} : </b> {t('use-lemma')}

                                                        <Switch
                                                            checked={!!checked}
                                                            onChange={handleChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        {t('only exact wording')}
                                                    </>
                                                }
                                                <br/>
                                                <br/>
                                            </div>
                                            <b> {t('lemma')} : </b>
                                            {/* <br/> */}
                                            <TextField
                                                disabled
                                                size="small"
                                                value={
                                                    currentLemma?.length ? convertArrayToText(currentLemma, shortLocale) :
                                                        lemmaData?.length
                                                            ? convertArrayToText(lemmaData, shortLocale)
                                                            : ''}
                                                name={'lemma'}
                                                placeholder={t('lemma')}
                                                onChange={(e) => {
                                                    setCurrentLemma(
                                                        !!e.target.value ? e.target.value : []
                                                    )
                                                }}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </div>
                                        <br/>
                                        <div>
                                            <b> {t('tag')} : </b>
                                            <br/>
                                            <TextField
                                                disabled
                                                size={'small'}
                                                value={currentTag?.length ? convertArrayToText(currentTag, shortLocale) :
                                                    tagData?.length
                                                        ? convertArrayToText(tagData, shortLocale)
                                                        : ''}
                                                name={'tag'}
                                                placeholder={t('tag')}
                                                //  className={style.inputfield}
                                                onChange={(e) => {
                                                    setCurrentTag(e.target.value)
                                                }}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </div>
                                    </>
                                )}
                                <br/>
                                <br/>
                                <div>
                                    <b> {t('description')} : </b>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        control={'text'}
                                        name={'description'}
                                        placeholder={t('description')}
                                        className={style.inputfield}
                                        style={{
                                            borderRadius: '6px',
                                            height: '40px',
                                            width: '80%',
                                        }}
                                        value={descriptionIs}
                                        onChange={(e) => setDescriptionIs(e.target.value)}
                                    />
                                </div>
                                <br/>
                                <br/>
                                <div>
                                    <b> {t('replacement')} : </b>
                                    <div
                                        style={{
                                            margin: '0 auto',
                                            marginLeft: '18%',
                                            marginRight: '-10px',
                                            marginTop: '-20px',
                                            overflowY: 'scroll',
                                        }}
                                        className={style.InputReplacement}
                                    >
                                        <br/>
                                        {InputBox()}
                                    </div>
                                    <div
                                        className={style.firstPlus}
                                        onClick={() => addNewBox()}
                                        style={{
                                            margin: '0 auto',
                                            marginLeft: '88%',
                                            marginTop: '30px',
                                            marginBottom: '30px',
                                        }}
                                    >
                                        <Add
                                            style={{ fontSize: '55px', fill: '#707070' }}
                                            className="mr-2"
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    )}
                    <div className={`d-flex justify-content-end align-items-center mt-2`}>
                        {/* wordData.length === 0 || allowTextToneOptions.length === 0 || disabled={!!idIs} */}
                        {/* {allowTextToneOptions?.length ? */}
                        {component === 'AdminAllowedlist' ? (
                            <Button
                                className={`mr-2`}
                                onClick={() =>
                                    //  postAnalyseTextTone()
                                    addAdminAllowedList()
                                }
                                variant="contained"
                                color="primary"
                            >
                                {t('save')}
                            </Button>
                        ) : component === 'AdminTextbin' ? (
                            <Button
                                className={`mr-2`}
                                onClick={() => addAdminTextbin()}
                                variant="contained"
                                color="primary"
                            >
                                {t('save')}
                            </Button>
                        ) : component === 'AnalyseTextbin' ? (
                            <Button
                                className={`mr-2`}
                                onClick={() => postAnalyseTextTone()}
                                variant="contained"
                                color="primary"
                            >
                                {t('save')}
                            </Button>
                        ) : component === 'AnalyseAllowedList' ? (
                            <Button
                                className={`mr-2`}
                                onClick={() => postAnalyseAllowedList()}
                                variant="contained"
                                color="primary"
                            >
                                {t('save')}
                            </Button>
                        ) : (
                            component === 'TermList' && (
                                <Button
                                    className={`mr-2`}
                                    onClick={() => addTermList()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('save')}
                                </Button>
                            )
                        )}
                        {/* //   : */}
                        {/* //   <Button disabled className={`mr-2`} */}
                        {/* //   variant='contained' color='primary'>{t('save')}</Button>} */}
                    </div>
                    <div className={style.error}>
                        <Collapse in={!!errorState}>
                            <Alert className={`m-0`} severity="error">
                                <p className={`m-0`}>{errorState}</p>
                            </Alert>
                        </Collapse>
                        <Collapse in={!!deleteAlertMsg}>
                            <Alert className={`m-0`} severity="error">
                                <p className={`m-0`}>{deleteAlertMsg}</p>
                            </Alert>
                        </Collapse>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
