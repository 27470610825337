import style from "./style.module.scss"
import {useTheme} from "@material-ui/core/styles";
import {useState} from "react";
import {ArrowDropDown} from "@material-ui/icons";
import {useTranslation} from "react-i18next";

export default function WordsTable({wordsWithFilters}) {
  const [sortBy, setSortBy] = useState({type: "text", direction: true})
  const {theme: {bgColors}} = useTheme();
  const {t} = useTranslation();

  function compare(a, b) {
    if (sortBy) {
      if (sortBy.direction) {
        if (a[sortBy.type] < b[sortBy.type]) {
          return -1;
        }
        if (a[sortBy.type] > b[sortBy.type]) {
          return 1;
        }
      } else {
        if (a[sortBy.type] > b[sortBy.type]) {
          return -1;
        }
        if (a[sortBy.type] < b[sortBy.type]) {
          return 1;
        }
      }
    }
    return 0
  }

  return (
    <div className={`${style.mainCont} w-100`}>
      <div style={{backgroundColor: bgColors.bgBlue200}} className={`${style.head}`}>
        <p onClick={() => setSortBy(s => {
          return {
            type: "text", direction: !s.direction
          }
        })} className={`d-flex align-items-center`}>{t("Word")}
          <ArrowDropDown
            style={{transform: (sortBy.type === "text" && sortBy.direction) ? "rotate(0deg)" : "rotate(180deg)"}}/></p>
        <p onClick={() => setSortBy(s => {
          return {
            type: "type", direction: !s.direction
          }
        })} className={`d-flex align-items-center`}>{t("POS")}
          <ArrowDropDown
            style={{transform: (sortBy.type === "type" && sortBy.direction) ? "rotate(0deg)" : "rotate(180deg)"}}/></p>
        <p onClick={() => setSortBy(s => {
          return {
            type: "length", direction: !s.direction
          }
        })} className={`d-flex align-items-center`}>{t("Word length")}
          <ArrowDropDown
            style={{transform: (sortBy.type === "length" && sortBy.direction) ? "rotate(0deg)" : "rotate(180deg)"}}/>
        </p>
        <p onClick={() => setSortBy(s => {
          return {
            type: "num", direction: !s.direction
          }
        })} className={`d-flex align-items-center`}>{t("Freq")}
          <ArrowDropDown
            style={{transform: (sortBy.type === "num" && sortBy.direction) ? "rotate(0deg)" : "rotate(180deg)"}}/></p>
      </div>
      <div className={`${style.rows}`}>
        {
          wordsWithFilters.sort(compare).map(
            (word, i) => (
              <div style={{backgroundColor: (i % 2 === 0) ? bgColors.bgBlue100 : bgColors.bgPrimary0}}
                   className={`${style.row}`} key={i}>
                     {/* key={`${word.id}-words-in-table`} */}
                <p>{word.text}</p>
                <p>{t(word.type)}</p>
                <p>{word.length}</p>
                <p>{word.num}</p>
              </div>
            )
          )
        }
      </div>
    </div>
  )
};
