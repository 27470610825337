import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import logo from './assets/group372.svg'
import text from './assets/TEXTLAB-LOGIN.svg'
import submitIcon from './assets/anmeldenIcon.svg'
import { TextField } from '@material-ui/core'
import Footer from '../Footer/Footer'
import { useTheme } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Alert } from '@material-ui/lab'
import ResetModal from './ResetModal/ResetModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import { useActions } from '../../utils/action-helper'
import { loginUser, loginUserLDAP, loginUserShibboleth } from '../../actions'
import { Redirect, useRouteMatch } from 'react-router-dom'
import Spinner from '../Spinner'
import { convertFromErrorObject, isObjectEmpty, parseErrorObject } from '../../utils'
import RestoreModal from './RestoreModal/RestoreModal'

export default function AuthForm () {
  const { t, i18n } = useTranslation()
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  const { params: { code } } = useRouteMatch()

  // states
  const [submitted, setSubmitted] = useState(false)
  const [openAlert, setOpenAlert] = useState('')
  const [openResetPass, setOpenPass] = useState(false)
  const [openRestorePass, setOpenRestorePass] = useState(!!code)
  const [toggle, setToggle] = useState(false)
  const [externalRedirect, setExternalRedirect] = useState('')
  const [totpRedirect, setTotpRedirect] = useState('')
  const [extraAuthRedirect, setExtraAuthRedirect] = useState('')

  // get reducers
  const errorUser = useSelector(state => state.userData.error)
  const token = useSelector(state => state.userData.token)
  const thinclient = useSelector(state => state.openData.ping?.thinclient)
  const serviceExport = useSelector(state => state.openData.ping?.serviceExport || {})
  const autoLogin = useSelector(state => state.openData.ping?.autoLogin || false)
  const [autoLoginIndex, setAutoLoginIndex] = useState(0)

  // bind actions
  const tlService = React.useContext(TLServiceComponent)
  const { actionLoginUser, actionLoginUserLDAP, actionLoginUserShibboleth } = useActions({
    actionLoginUser: loginUser(tlService, i18n),
    actionLoginUserLDAP: loginUserLDAP(tlService, i18n),
    actionLoginUserShibboleth: loginUserShibboleth(tlService, i18n)
  })

  //state validation by YUP
  const validationSchema = yup.object({
    name: yup.string()
      .required(t('Required field')),
    password: yup.string()
      .required(t('Required field')),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      adoption: false,
      language: '',
      textSort: '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ name, password }) => {
      setExtraAuthRedirect('')
      setSubmitted(true)
      setOpenAlert('')
      actionLoginUser(name, password, true)
      // add true to remember!!
    },
  })

  // auto login sequence
  useEffect(() => {
    if (!isObjectEmpty(serviceExport)) {
      const servicesArray = Object.entries(serviceExport)
      if (!token && autoLogin && (autoLoginIndex === 0 || !!errorUser) && autoLoginIndex < servicesArray.length) {
        // try to log in with all enabled services
        const servAutoLogin = servicesArray[autoLoginIndex][1]?.auto_login
        if (!submitted && autoLoginIndex < servicesArray.length && servAutoLogin) {
          // try to connect
          console.log('try', servicesArray[autoLoginIndex])
          setSubmitted(true)
          const serviceId = servicesArray[autoLoginIndex][1]['service_id']
          if (servicesArray[autoLoginIndex][0].includes('LDAP')) {
            actionLoginUserLDAP(serviceId, false)
          } else if (servicesArray[autoLoginIndex][0].includes('Shibboleth')) {
            actionLoginUserShibboleth(serviceId, false)
          }
          setAutoLoginIndex(autoLoginIndex + 1)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, serviceExport, errorUser, submitted])

  // effect check error user login
  useEffect(() => {
    if (!!errorUser) {
      const data = parseErrorObject(errorUser)
      if (submitted) {
        // fo GA temporary
        if (data?.type_2fa === 'ga') {
          setSubmitted(false)
          setTotpRedirect('/authorization/2fa/ga')
          return
        }
      }
      setSubmitted(false)
      setOpenAlert(convertFromErrorObject(t, errorUser))
      if (extraAuthRedirect){
        setExternalRedirect(extraAuthRedirect)
      }
    }
    // eslint-disable-next-line
  }, [errorUser])

  // Check for LDAP value:
  useEffect(() => {
    if (!isObjectEmpty(serviceExport)) {
      for (let el of Object.keys(serviceExport)) {
        if (el.includes('LDAP') || el.includes('Shibboleth')) {
          setToggle(true)
          break
        }
      }
    }
  }, [serviceExport])

  const handleDifferentLoginTypes = async (extraValues, service) => {
    setSubmitted(true)
    setOpenAlert('')
    if (service.includes('LDAP')) {
      actionLoginUserLDAP(extraValues['service_id'], false)
      setExtraAuthRedirect('')
    } else if (service.includes('Shibboleth')) {
      actionLoginUserShibboleth(extraValues['service_id'], false)
      setExtraAuthRedirect(extraValues['secure_url'])
    }
  }

  if (externalRedirect){
    window.location.href = externalRedirect;
    return null;
  }

  if (totpRedirect) {
    return <Redirect to={totpRedirect}/>
  }

  if (token && !openRestorePass) {
    return <Redirect to="/"/>
  }

  return (
    <div style={{ backgroundColor: bgColors.bgPrimary100 }} className={style.mainCont}>
      {submitted && <Spinner/>}
      <ResetModal setOpen={setOpenPass} open={openResetPass}/>
      <RestoreModal setOpen={setOpenRestorePass} open={openRestorePass}/>
      <form onSubmit={formik.handleSubmit} className={style.form}>
        <div style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.161)', backgroundColor: bgColors.bgPrimary0 }}>
          <div className={style.blueRec}>
            <img src={logo} className={style.logo} alt="logo"/>
            <img src={text} className={style.text} alt="text"/>
          </div>
          <div className={style.fields}>
            {!!openAlert && <Alert
              className={`m-0`}
              severity="error">
              <p className={`m-0`}>{openAlert}</p>
            </Alert>}
            {toggle ?
              <div>
                <div className={`${style.fieldsActions} mb-3`}>
                  <p style={{ color: colors.colorPrimary300, textAlign: 'center' }}
                     dangerouslySetInnerHTML={{ __html: t('login-top-toggle-text') }}/>
                </div>
                <br/>
                <div className={`${style.submitCont} mt-2 mb-3`} style={{ paddingBottom: '170px' }}>
                  {!isObjectEmpty(serviceExport) && Object.entries(serviceExport).map(([key, value]) => {
                      if (key !== 'Local') {
                        return (
                          <button type="submit" className={style.submitEasyStart}
                                  onClick={() => handleDifferentLoginTypes(value, key)}>
                            <img src={submitIcon} alt="submit svg"/>
                            {t('lets-start-with-service {{service}}', { service: key })}
                          </button>
                        )
                      }
                    }
                  )}
                </div>
                <p style={{ color: colors.colorPrimary300, textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                  setToggle(false)
                }}>
                  {t('redirect-login')}
                </p>
              </div> :
              <>
                <TextField
                  helperText={formik.touched.name && formik.errors.name}
                  error={formik.touched.name && !!formik.errors.name}
                  onChange={formik.handleChange}
                  name={'name'}
                  variant="filled"
                  label={t('Login or e-mail')}
                  style={{ marginBottom: '30px' }}
                />
                <TextField
                  type="password"
                  helperText={formik.touched.password && formik.errors.password}
                  error={formik.touched.password && !!formik.errors.password}
                  onChange={formik.handleChange}
                  name={'password'}
                  className={`mt-3`}
                  variant="filled"
                  label={t('Password')}
                  style={{ marginBottom: '20px' }}
                />
                {thinclient ? <p
                  style={{ color: colors.colorPrimary200 }}
                  className={`${style.password}`}
                  onClick={() => {
                    setOpenPass(!openResetPass)
                  }}>
                  {t('Forgot Your Password?')}
                </p> : null}
                <div>
                  <div className={`${style.submitCont} mb-4`}>
                    <button type="submit" className={style.submit} key={'Local'}>
                      <img src={submitIcon} alt="submit svg"/>
                      {t('Login')}
                    </button>
                  </div>
                  <div className={`${style.submitCont}  mb-0`}>
                    {!isObjectEmpty(serviceExport) && Object.entries(serviceExport).map(([key, value]) => {
                      if (key !== 'Local')
                        return (
                          <button type="button" onClick={() => handleDifferentLoginTypes(value, key)} key={key}
                                  className={style.submitLDAP}>
                            <img src={submitIcon} alt="submit svg"/>
                            {t(key)}
                          </button>
                        )
                    })}
                  </div>
                </div>
                <div className={`${style.fieldsActions} `}>
                  <div style={{ borderColor: borderColors.borderPrimary300 }} className={style.news}>
                    <p dangerouslySetInnerHTML={{ __html: t('Login Page Bottom Text') }}
                       style={{ color: colors.colorPrimary300, textAlign: 'center' }}/>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className={`pt-1`}>
          <Footer/>
        </div>
      </form>
    </div>
  )
}


