import React, { useEffect } from 'react'
import style from './style.module.sass'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import FolderSelectionTable from './FolderSelectionTable'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useActions } from '../../../utils/action-helper'
import {
  dashboardGetFoldersByFilter,
  dashboardSetFilter,
  dashboardSetFolderSelected,
  dashboardSetFolderType,
  dashboardSetOpenedFolders
} from '../../../actions'
import { deepCopy } from '../../../utils'
import TLServiceComponent from '../../../contexts/TLServiceComponent'

export default function DashFilterHeader ({ panel }) {
  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)

  const {
    aDashboardSetOpenedFolders,
    aDashboardSetFolderSelected,
    aDashboardSetFolderType,
    aDashboardSetFilter,
    aDashboardGetFoldersByFilter
  } = useActions({
    aDashboardSetFolderType: dashboardSetFolderType,
    aDashboardSetFilter: dashboardSetFilter,
    aDashboardSetFolderSelected: dashboardSetFolderSelected,
    aDashboardSetOpenedFolders: dashboardSetOpenedFolders,
    aDashboardGetFoldersByFilter: dashboardGetFoldersByFilter(tlService)
  })

  const token = useSelector(state => state.userData.token)
  const filter = useSelector(state => state.dashboardData.filter[panel])
  const activeTab = useSelector(state => state.dashboardData.folderType[panel])
  const userId = useSelector(state => state.userData.user.id)
  const userGroup = useSelector(state => state.userData.user.user_group_id)
  const userCompany = useSelector(state => state.userData.user.company_id)

  const toggle = tab => {
    aDashboardSetFolderType(panel, tab)
  }

  useEffect(() => {
    let folderFilter
    if (activeTab === 'personal')
      folderFilter = { user_id: userId }
    else if (activeTab === 'group')
      folderFilter = { user_group_id: userGroup }
    else if (activeTab === 'company')
      folderFilter = { company_id: userCompany }
    // clear select
    aDashboardSetFolderSelected(panel, [])
    aDashboardSetOpenedFolders(panel, [])
    // get folders
    aDashboardGetFoldersByFilter(token, panel, folderFilter)
    // update main filter
    let mainFilter = deepCopy(filter)
    delete mainFilter['user_id']
    delete mainFilter['user_group_id']
    delete mainFilter['company_id']
    aDashboardSetFilter(panel, { ...mainFilter, ...folderFilter })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  return (
    <>
      <div className={`${style.tableWrapper__head} d-flex align-items-center`}>
        <h5 className={`m-0 mr-auto`}>{t('Filters')}&nbsp;</h5>
        {/*
                <Button color='primary' variant='contained' onClick={() => setOpenFilter(s => !s)}>
                    <FilterList className={`mr-1`}/>
                    extended filter
                </Button>
                */}
      </div>
      {/*<ExtendedFilter handleChangeExtendedFilter={handleChangeExtendedFilter}/>*/}
      <div className={`w-100 p-0 ${style.selectionModal}`}>
        <div className={`${style.tabs}`}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'personal' ? 'active' : ''}
                onClick={() => {
                  toggle('personal')
                }}
              >{t('Personal archive')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'group' ? 'active' : ''}
                onClick={() => {
                  toggle('group')
                }}
              >{t('Group archive')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'company' ? 'active' : ''}
                onClick={() => {
                  toggle('company')
                }}
              >{t('Company archive')}</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="personal">
              {activeTab === 'personal' && <FolderSelectionTable panel={panel}/>}
            </TabPane>
            <TabPane tabId="group">
              {activeTab === 'group' && <FolderSelectionTable panel={panel}/>}
            </TabPane>
            <TabPane tabId="company">
              {activeTab === 'company' && <FolderSelectionTable panel={panel}/>}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  )
}