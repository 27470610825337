import MainPageWrapper from "../../components/Main-page-wrapper";
import React from "react";
import TonalityComponent from "../../components/Dashboard/Tonality";

export default function Tonality(){

  return (
    <MainPageWrapper>
      <TonalityComponent/>
    </MainPageWrapper>
  )
}