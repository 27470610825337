import React from "react";
import CRTable from "./Table/Table";
import CRWrapper from "./CRWrapper/CRWrapper";

export default function CleaningRulesTable({id}) {
  return (
    <CRWrapper>
      <CRTable id={id}/>
    </CRWrapper>
  )
}
