import React, {useState, useEffect, useRef} from "react";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import {ArrowDropDown} from "@material-ui/icons";
import {IconButton, TextField, withStyles} from "@material-ui/core";
import style from "./style.module.sass"
import {useTheme} from "@material-ui/core/styles"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const CustomTextField = withStyles({
  // root: {
  //   '& label': {
  //     fontSize: "14px"
  //   },
  // },
})(TextField);

export default function SelectWithTextField({formik, name, label, options, status, setAutoFillState, withDate, withTime}) {
  const {theme: {bgColors}} = useTheme();
  const [selectedDate, setSelectedDate] = React.useState(new Date('2000-01-01T00:00:00'));
  const [selectedTime, setSelectedTime] = React.useState('24:00');
  const [value, setValue] = useState({name: ""});
  const [defValue, setdDefValue] = useState(null);
  const [fieldHandler, setFieldHandler] = useState("")
  const [open, setOpen] = useState(false);

  const handleDateChange = (date) => {
    setAutoFillState && setAutoFillState(null)
    setFieldHandler("date")
    setSelectedDate(date)
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false)
    return document.addEventListener("mousedown", handleClick, false)
  }, [])

  useEffect(() => {
    if (status === "group") {
      setFieldHandler(null)
      setdDefValue(options[0])
    } else {
      setdDefValue(null)
    }
  }, [options, status])

  const node = useRef()

  function handleClick(e) {
    if (node && node.current && node.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  function isNaN(date) {
    return Number.isNaN(date)
  }

  const day = isNaN(selectedDate?.getDate()) ?
    "" :
    selectedDate?.getDate() > 9 ? selectedDate?.getDate() :
      "0" + selectedDate?.getDate(),
    month = isNaN(selectedDate?.getMonth()) ?
      "" :
      (selectedDate?.getMonth() + 1) > 9 ?
        (selectedDate?.getMonth() + 1) : "0" + (selectedDate?.getMonth() + 1),
    year = isNaN(selectedDate?.getFullYear()) ?
      "" :
      selectedDate?.getFullYear()
  const date = selectedDate ? `${day}-${month}-${year}` : "01-06-2021"

  return (
    <FormControl ref={node} fullWidth className={`position-relative`}
                 error={name && formik.touched[name] && Boolean(formik.errors[name])}>
      <CustomTextField
        variant="outlined"
        label={label}
        type={"text"}
        value={
          status === "group" ?
            typeof defValue?.value === "string" ?
              defValue?.value :
              defValue?.name :
            fieldHandler === "date" ?
              date :
              fieldHandler === "time" ?
                selectedTime :
                typeof value?.value === "string" ?
                  value.value :
                  value?.name
        }
        onClick={() => setOpen(!open)}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        InputProps={{
          endAdornment: (
            <IconButton><ArrowDropDown/></IconButton>
          )
        }}
        fullWidth
      />
      <ul style={{backgroundColor: bgColors.bgPrimary0}}
          className={`p-1 position-absolute ${open ? "d-grid" : "d-none"} ${style.modalSelectWithTextField}`}>
        {options.map((o, index) => (
          <span key={index}>
            <li className={`pl-3 pr-2 d-flex flex-column align-items-start`} onClick={() => {
              setFieldHandler(null)
              setAutoFillState && setAutoFillState(null)
              setValue(o)
            }} key={index}>
              {o.name}
            </li>
            <div className={`${style.border}`}/>
          </span>
        ))}
        <li className={`${withDate ? "p-0" : "pl-3 pr-0"}`}>
          {
            withDate ?
              <MuiPickersUtilsProvider className={`m-0 p-0`} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  fullWidth
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider> :
              withTime ?
                <TextField
                  id="time"
                  type="time"
                  fullWidth
                  onChange={e => {
                    setFieldHandler("time")
                    setAutoFillState && setAutoFillState(null)
                    setSelectedTime(e.target.value)
                  }}
                  className={`mr-2`}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                /> :
                <TextField
                  fullWidth
                  label="customized pattern (RegEx)"
                  variant="standard"
                  type="text"
                  onChange={e => {
                    setAutoFillState && setAutoFillState(null)
                    setFieldHandler("select")
                    setValue({name: e.target.value})
                  }}
                />

          }
        </li>
      </ul>
      <FormHelperText
        id="outlined-adornment-password">{name && formik.touched[name] && formik.errors[name]}</FormHelperText>
    </FormControl>
  )
}
