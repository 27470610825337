import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import {Box, Button, TableRow, LinearProgress} from "@material-ui/core";
import React, {useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {useParams} from "react-router";
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import {useSelector} from "react-redux";
import {downloadTerms} from "./functions"

export default function AddNew({termCategoryName, setUploadModal, setAddNewTermModal,setCurrentTerm, termRights, termParentId, termOwner, localeName}) {
  const {theme: {bgColors, colors}} = useTheme();
  const { t } = useTranslation();
  const {categoryID} = useParams();
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const userTypeId = useSelector(state => state.userData.user.user_type_id);

  const companyId = useSelector(state => state.userData.user.company_id);
  const userGroupId = useSelector(state => state.userData.user.user_group_id);
  const userId = useSelector(state => state.userData.user.id);

  //handleDownload
  const [downloadBarNow, setDownloadBarNow] = useState(0);
  const [downloadBarVisible, setDownloadBarVisible] = useState(false);

  const handleDownload = (downloadFormat) =>{
    downloadTerms(tlService, token, categoryID, setDownloadBarVisible, setDownloadBarNow, downloadFormat, 1, localeName);
  }

  // enable a "Download" button rules
  let enableDownloadButton = false;
  if (userTypeId === 1){
    enableDownloadButton = true;
  } else if (userTypeId === 2){
    if (termParentId !== undefined && termParentId === 0){
      if (termOwner === userId){
        enableDownloadButton = true;
      } else if (termRights?.length){
        for (let i of termRights){
          if (i[0][0] === userId || i[1][0] === userGroupId || i[2][0] === companyId){
            enableDownloadButton = true;
            break;
          }
        }
      }
    }
  }

  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.tableFilter}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box style={{color: colors.colorPrimary500}}
               className={`d-flex justify-content-between align-items-center p-1 pt-2 pr-3`} margin={1}>
            <h5 className={`pl-2 m-0`}>
              {t('terms-by-category')} ({categoryID}, {termCategoryName})
              </h5>
            <Button onClick={() => setUploadModal(true)} color='secondary' size="small" variant='contained' style={{marginLeft: "auto"}}>
              {t('upload')}
            </Button>
            {enableDownloadButton && <>
              <Button disabled={downloadBarVisible} onClick={() =>handleDownload("SimpleXLSX")} className={`ml-2`} size="small" variant='contained'>
                {t('download as xlsx')}
              </Button>
              <Button disabled={downloadBarVisible} onClick={() =>handleDownload("SimpleCSV")} className={`ml-2`} size="small" variant='contained'>
                {t('download as csv')}
              </Button>
            </>}
            <Button onClick={() => {setAddNewTermModal(true); setCurrentTerm()}} className={`ml-2`} size="small" variant="contained"
                    color="primary">
              {t('add-new')}
            </Button>
          </Box>
          {downloadBarVisible && <div style={{width: "100%", justifyContent: "flex-end", display: "flex", alignItems: "end"}}>
            <div style={{marginRight: "103px", height: "5px", width: "300px"}}><LinearProgress color="primary" variant="determinate" value={downloadBarNow}/></div>
          </div>}
        </TableCell>
        </TableRow>
      </TableHead>
    </>
  )
}
