import React, { useEffect, useState } from 'react'
import ProfileWrapper from './ProfileWrapper'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode'
import { Button } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import { Alert } from '@material-ui/lab'
import { useActions } from '../../utils/action-helper'
import {
  meUser
} from '../../actions'
import { parseErrorObject } from '../../utils'

export default function Profile2FAGAComponent () {
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector((state) => state.userData.token)
  const enabled2FA = useSelector((state) => state.userData.user['2fa']) || {}
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])

  const { theme: { borderColors, colors } } = useTheme()
  const { t } = useTranslation()

  const [qrCode, setQrCode] = useState('')
  const [secret, setSecret] = useState('')
  const [qrNumber, setQrNumber] = useState('')
  const [alert, setAlert] = useState('')
  const [totp, setTotp] = useState('')
  const [attempts, setAttempts] = useState(0)

  const { aMeUser } = useActions({
    aMeUser: meUser(tlService),
  })

  const setQR = async token => {
    try {
      const qr = await tlService.getGAQA(token)
      if (qr?.qr?.length) {
        setSecret(qr.secret)
        const textQr = await QRCode.toDataURL(qr.qr)
        setQrCode(textQr)
        setQrNumber(new URL(qr.qr)?.searchParams.get('secret'))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const setProofNumber = (e) => {
    const input = e.target.value
    setTotp(input.replaceAll(/[^0-9]/g, ''))
  }

  const setProofRequest = async (token, totp, secret, type) => {
    try {
      await tlService.proofGAQA(token, totp, secret, type)
      aMeUser(token)
    } catch (e) {
      const message = parseErrorObject(e)
      if (message?.number === 403) {
        setAlert(t(message.message))
        setAttempts(0)
        setSecret('')
      } else if (message?.number === 401) {
        setAlert(t(message.message))
        if (message?.secret?.length) {
          const messageAttempts = parseInt(message?.attempts) || 0
          setAttempts(messageAttempts)
          setSecret(message.secret)
        } else {
          setAttempts(0)
        }
      } else {
        console.log(e)
      }
    }
    setTotp('')
  }

  const enableGA = async () => {
    if (totp.length && secret.length){
      await setProofRequest(token, totp, secret, 'enable')
    }

  }

  const disableGA = async () => {
    if (totp.length && secret.length){
      await setProofRequest(token, totp, secret, 'disable')
    }
  }

  const disableGARequest = async () => {
    //disableGAQA
    try {
      await tlService.disableGAQA(token)
    } catch (e) {
      const message = parseErrorObject(e)
      if (message?.number === 401 && message?.secret?.length) {
        // send proof
        setSecret(message.secret)
      } else if (message?.number === 403){
        setAlert(t(message.message))
        setSecret('')
      } else {
        console.log(e)
      }
    }
  }

  const handleOnEnter = (event, func) => {
    if (event.keyCode === 13) {
      func()
    }
  }

  useEffect(() => {
    if (enabled2FA?.ga) {
      disableGARequest()
    } else {
      setQR(token)
    }
  }, [enabled2FA?.ga])

  // if enable function is disabled
  if (!enabled2FA?.ga && enabledSections.indexOf('ga_2fa_enabled') === -1){
    return (
      <ProfileWrapper>
        <div
          style={{
            color: colors.colorPrimary500,
            borderColor: borderColors.borderPrimary1000,
          }}
          className={style.blockInternal}
        >
          <div className={'d-flex align-items-center w-100'}>
            <div dangerouslySetInnerHTML={{__html:t('ga_2fa_enabled_not_enabled')}} className={'text-center p-2 m-2'} />
          </div>
        </div>
      </ProfileWrapper>
    )
  }

  // if enable function is disabled
  if (enabled2FA?.ga && enabledSections.indexOf('ga_2fa_disabled') === -1){
    return (
      <ProfileWrapper>
        <div
          style={{
            color: colors.colorPrimary500,
            borderColor: borderColors.borderPrimary1000,
          }}
          className={style.blockInternal}
        >
          <div className={'d-flex align-items-center w-100'}>
            <div dangerouslySetInnerHTML={{__html:t('ga_2fa_disabled_not_enabled')}} className={'text-center p-2 m-2'} />
          </div>
        </div>
      </ProfileWrapper>
    )
  }

  return (
    <ProfileWrapper>
      <div
        style={{
          color: colors.colorPrimary500,
          borderColor: borderColors.borderPrimary1000,
        }}
        className={style.blockInternal}
      >
        {!!alert && <Alert
          className={`mb-1`}
          severity="error"
        >{t(alert)} {attempts > 0 && t('2fa_ga_attempts_left {{attempts}}', { attempts })}
        </Alert>}
        {(!enabled2FA?.ga) ?
          <>
            {(qrCode && secret) &&
              <div className={'d-flex align-items-center w-100'}>
                <div className={'p-2'}>
                  <div className={'text-center'}><img src={qrCode} alt={''}/></div>
                  <div className={'text-center'}>{qrNumber}</div>
                </div>
                <div className={'p-2'}>
                  <div className={'p-1'}>
                    <InputBase
                      onKeyUp={e => handleOnEnter(e, enableGA)}
                      autoFocus={true}
                      className={`${style.input2FA}`}
                      value={totp}
                      type="text"
                      onChange={setProofNumber}
                    /></div>
                  <div className={'p-1'}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={enableGA}
                      disabled={(!totp.length || !secret.length)}
                    >
                      {t('2fa_ga_enable_button')}
                    </Button>
                  </div>
                </div>
                <div className={'p-2'} dangerouslySetInnerHTML={{ __html: t('2fa_ga_enable_description') }}/>
              </div>}
          </>
          :
          <div className={'d-flex align-items-center w-100'}>
            <div className={'p-2'}>
              <div className={'p-1'}>
                <InputBase
                  onKeyUp={e => handleOnEnter(e, disableGA)}
                  className={`${style.input2FA}`}
                  value={totp}
                  type="text"
                  onChange={setProofNumber}
                /></div>
              <div className={'p-1'}>
                <Button color="secondary"
                        variant="contained"
                        onClick={disableGA}
                        disabled={(!totp.length || !secret.length)}
                >
                  {t('2fa_ga_disable_button')}
                </Button>
              </div>
            </div>
            <div className={'p-2'} dangerouslySetInnerHTML={{ __html: t('2fa_ga_disable_description') }}/>
          </div>
        }
      </div>
    </ProfileWrapper>
  )
}

