import { useTranslation } from 'react-i18next'
import style from './style.module.sass'
import { useSelector } from 'react-redux'
import { useActions } from '../../utils/action-helper'
import { analyseSetProgressBar, archiveSetArchiveType, archiveSetPredefined, tableSetFilters } from '../../actions'
import { useEffect, useState } from 'react'
import { isObjectEmpty } from '../../utils'
import { getFolderFilter } from '../../utils/archives'
import { Redirect } from 'react-router-dom'

export default function ArchiveProgressbar () {
  const { t } = useTranslation()

  const analyseProgressBar = useSelector(state => state.analyseData.analyseProgressBar)
  const {
    aArchiveSetArchiveType, aArchiveSetPredefined, aTableSetFilters, aAnalyseSetProgressBar
  } = useActions({
    aArchiveSetArchiveType: archiveSetArchiveType,
    aTableSetFilters: tableSetFilters,
    aArchiveSetPredefined: archiveSetPredefined,
    aAnalyseSetProgressBar: analyseSetProgressBar
  })
  const filters = useSelector(state => state.tableData.filters)
  const [redirect, setRedirect] = useState('')

  const [countdown, setCountdown] = useState(100)

  useEffect(() => {
    if (!isObjectEmpty(analyseProgressBar)) {
      if (countdown <= 0) {
        aAnalyseSetProgressBar({})
      } else {
        setTimeout(() => setCountdown(countdown - 5), 1000)
      }
    }
  }, [analyseProgressBar, countdown])

  const handleProgressbarClick = () => {

    if (!isObjectEmpty(analyseProgressBar)) {
      aArchiveSetPredefined(analyseProgressBar.folder)
      aArchiveSetArchiveType(analyseProgressBar.type)
      aTableSetFilters(getFolderFilter(analyseProgressBar.folder, filters))
      setTimeout(() => setRedirect(`/archive`), 100)
      setTimeout(() => aAnalyseSetProgressBar({}), 200)
    }
  }

  if (redirect)
    return <Redirect to={redirect}/>

  return (
    <div className={`${style.MoveArchiveAlertSuccess}`} onClick={handleProgressbarClick}>
                <span className={style.title}>
                    {t('success-folder-Msg')}
                </span>
      <div className={`${style.bar}`}>
        <div className={style.back}></div>
        <div className={style.front} style={{ width: countdown + '%' }}></div>
      </div>
    </div>
  )
}