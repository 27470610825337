import React, { useState } from "react";
import style from "../style.module.sass"
import { useTheme } from "@material-ui/core/styles";
import TypeOfField from "../../../../../components/FormikFields/TypeOfField";
import { useTranslation } from "react-i18next";

function UserData({ formik }) {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const { theme: { bgColors, colors } } = useTheme();
  const fields = [
    { id: "1", control: "text", name: "username", label: t("Username") },
    { id: "4", control: "text", name: "email", label: t("Email") },
    { id: "2", control: "text", name: "firstName", label: t("first-name") },
    { id: "3", control: "text", name: "lastName", label: t("last-name") },
    { id: "5", control: "password", name: "password", label: t("Password"), withAlert: true },
    { id: "6", control: "password", name: "repeatedPassword", label: t("Repeat password"), withAlert: false },
  ];
  return (
    <div style={{ backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50 }} onMouseEnter={() => setBg(true)}
      onMouseLeave={() => setBg(false)} className={`${style.borderTop}`}>
      <h4 style={{ color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500 }}
        className={`${style.title} ${style.borderTop} mb-3`}>
        {t('user-data')}
      </h4>
      <ul className={`mb-0`}>
        {
          fields.map(
            f => (
              <li key={f.id}>
                <TypeOfField control={f.control} formik={formik} name={f.name} label={f.label} {...f} />
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

export default UserData;
