import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { useTheme } from '@material-ui/core/styles'
import deleteIcon from '../assets/deleteIcon.svg'
import { Edit } from '@material-ui/icons'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Button, Box, Collapse, Modal } from '@material-ui/core'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import closeIcon from '../../../../assets/close-circle.svg'
import config from '../../../../config'
import { convertArrayToText, convertFromErrorObject, convertLanguagesToShort } from '../../../../utils'

function Rows (
  {
    localeName,
    row,
    callEndPointReCall,
    setCurrentTerm,
    setAddNewTermModal,
    isItemSelected,
    labelId,
    handleClick,
    isEven,
    setSelected
  }) {

  const shortLocaleName = convertLanguagesToShort(localeName)
  const { theme: { bgColors, colors } } = useTheme()
  const [open, setOpen] = useState(false)
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const [termModal, setTermModal] = useState(false)
  const [deleteAlertMsg, setDeleteAlertMsg] = useState()
  const { t } = useTranslation()
  const { categoryID } = useParams()
  const [termReplacement, setTermReplacement] = useState()

  const deleteTerm = async (e, id) => {
    e.preventDefault()
    try {
      await tlService.deleteTerm(token, categoryID, id)
      await tlService.calculateCategory(token, Number(categoryID))
      setTermModal(false)
      // setDeleteRecall(Math.floor(Math.random() * 10000));
      callEndPointReCall('delete')
      setSelected([])
    } catch (e) {
      setDeleteAlertMsg(convertFromErrorObject(t, e))
      setTimeout(() => {
        setDeleteAlertMsg('')
      }, config.timeOutDuration)
    }
  }

  // Display Term Replacement with | :
  useEffect(() => {
    let ary = []
    if (row.term_replacement?.length) {
      row.term_replacement.map(element => {
        ary.push(element.words)
      })
    } else {
      // When land on page, if data have no term replacement then set empty string:
      setTermReplacement('')
    }
    let result = ary.filter(element => !element.includes(null))
    let joinedText = result.map(el => convertArrayToText(el, shortLocaleName))
    joinedText = joinedText?.length && joinedText?.join(' | ')
    if (!!joinedText) {
      let resultData = String(joinedText).substr(0, 100)
      if (joinedText?.length > 100) {
        resultData = resultData + '...'
      }
      setTermReplacement(resultData)
      // termReplacement.current = resultData;
    }
  }, [row])

  const collapseControl = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <Modal open={termModal} onClose={() => setTermModal(false)}
             style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
        <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
               style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
              {t('clear-terms')}
            </p>
            <button className={`border-0 bg-transparent`} onClick={() => setTermModal(false)}>
              <img src={closeIcon} alt=""/>
            </button>
          </div>
          <br/>
          <div className={style.error}>
            <Collapse in={!!deleteAlertMsg}>
              <Alert
                className={`m-0`}
                severity="error">
                <h6 className={`m-0`}>
                  {deleteAlertMsg}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div>
            <Button color="primary" variant="contained" onClick={(e) => deleteTerm(e, row.id)}
                    style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('yes')}
            </Button>
            <Button color="primary" variant="contained" onClick={() => {
              setTermModal(false)
            }} style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Modal>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        className={`${style.row}`}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell className={style.cell}
                   onClick={() => collapseControl()}
                   style={{
                     color: colors.colorStatusInfo,
                     cursor: 'pointer',
                     lineBreak: 'anywhere',
                     width: '100%'
                   }} component="th"
                   id={labelId} scope="row" padding="none"
        >
          {!!row.words && convertArrayToText(row.words, shortLocaleName)}
        </TableCell>
        <TableCell style={{ minWidth: 400, maxWidth: 200 }} className={style.cell} align="left">
          <div className={`d-flex align-items-center`}>
            &nbsp; &nbsp;
            <p className={`m-0`}>{termReplacement}</p>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 400, maxWidth: 200 }} className={style.cell} align="left">
          {row.description}
        </TableCell>
        <TableCell className={`${style.cell}`} align="right">
          <div className={`d-flex align-items-center`}>
            <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                    onClick={() => {
                      setAddNewTermModal(true)
                      setCurrentTerm(row)
                    }}
                    style={{ backgroundColor: bgColors.bgBlue1000 }}>
              <Edit style={{ fill: '#fff', width: 22, height: 22 }}/>
            </button>
            <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                    style={{ backgroundColor: bgColors.bgBlue1000 }}
                    onClick={() => {
                      setTermModal(true)
                    }
                    }>
              <img src={deleteIcon} alt=""/>
            </button>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
                style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : 'blue' ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
                 className={`mb-4 d-flex justify-content-between pt-2`}
                 margin={1}>
              <div
                style={{ marginLeft: '30px', marginTop: '30px', marginBottom: '30px' }}
              >
                <b>ID &nbsp; : </b> &nbsp; {row.id}
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <b>{t('words')} &nbsp; : </b> &nbsp;
                {row?.words?.length && convertArrayToText(row.words, shortLocaleName)}
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <b>{t('lemma')} &nbsp; : </b> &nbsp;
                {row?.lemma?.length && convertArrayToText(row.lemma, shortLocaleName)}
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <b>{t('tag')} &nbsp; : </b> &nbsp;
                {row?.tag?.length && row.tag.join(', ')}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default Rows
