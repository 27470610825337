import { getMHTdocument, wrapTpl } from './utils'

import documentXmlRels from './assets/document.xml.rels'
import contentTypesXml from './assets/content_types.xml'
import relsXml from './assets/rels.xml'
import documentTemplate from './templates/document'

import _ from 'lodash'

export async function generateDocument (zip) {
  const buffer = await zip.generateAsync({
    type: 'arraybuffer',
  })
  if (global.Blob) {
    return new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    })
  } else if (global.Buffer) {
    return new Buffer(new Uint8Array(buffer))
  } else {
    throw new Error(
      'Neither Blob nor Buffer are accessible in this environment. ' +
      'Consider adding Blob.js shim')
  }
}

export function renderDocumentFile (documentOptions = {}) {
  const templateData = _.merge({
    margins: {
      top: 1440,
      right: 1440,
      bottom: 1440,
      left: 1440,
      header: 720,
      footer: 720,
      gutter: 0,
    },
  }, (function () {
    switch (documentOptions.orientation) {
      case 'landscape':
        return {
          height: 12240,
          width: 15840,
          orient: 'landscape',
        }
      default:
        return {
          width: 12240,
          height: 15840,
          orient: 'portrait',
        }
    }
  })(), {
    margins: documentOptions.margins,
  })
  return wrapTpl(documentTemplate, templateData)
}

export function addFiles (zip, htmlSource, documentOptions) {
  zip.file('[Content_Types].xml', contentTypesXml)
  zip.folder('_rels').file('.rels', relsXml)
  return zip.folder('word').
    file('document.xml', renderDocumentFile(documentOptions)).
    file('afchunk.mht', getMHTdocument(htmlSource)).
    folder('_rels').
    file('document.xml.rels', documentXmlRels)
}

