import { convertedValue } from "../../../../utils"

const RenderSection = ({ sections, row, i18nLanguage, t, form = false }) => {
  return sections?.map((section, idx) => (
    <div key={idx} style={{ display: 'flex', padding: '5px', marginBottom: '10px' }}>
      {convertedValue(form ? row.enabled_frontend_sections?.includes(section) : row.frontend_options?.includes(section), 'boolean', i18nLanguage, t)}
      <div className="ml-1">{t(`admin_group_${section}`)}</div>
    </div>
  ))
}

export default RenderSection