import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "../withBigRow.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {Box} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from "react-i18next";

function TableFilter() {
  const {theme: {bgColors, colors}} = useTheme();

  const { t } = useTranslation();

  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.tableFilter} ${style.borderTop}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box style={{color: colors.colorPrimary500}}
               className={`d-flex align-items-center p-2 pl-3`} margin={1}>
            {/*<p style={{color: colors.colorPrimary1000}} className={`m-0 mr-4`}>User specific view</p>*/}
            {/*<Autocomplete*/}
            {/*  value={selectedGroup}*/}
            {/*  onChange={(e, newValue) => setSelectedGroup(newValue)}*/}
            {/*  id="combo-box-demo"*/}
            {/*  options={selectVariants}*/}
            {/*  className={`d-flex align-items-center mr-3 mb-1`}*/}
            {/*  style={{width: 300, height: "52px"}}*/}
            {/*  renderInput={(params) => <TextField {...params} style={{height: "52px"}} label="User specific view"*/}
            {/*                                      variant="outlined"/>}*/}
            {/*/>*/}
            <div style={{margin: "0 0 0 auto"}} className={`d-flex align-items-center justify-content-center`}>
              <p style={{color: colors.colorPrimary1000}} className={`m-0 mt-1`}>
                {t('auto-translate')}
              </p>
              <Switch color="primary"/>
            </div>
          </Box>
        </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

export default TableFilter
