const updateModalData = (state, action) => {

  const defaultState = {
    modal: [],
  }

  if (state === undefined) {
    return defaultState
  }

  const id = action.payload && action.payload.id !== undefined ? action.payload.id : false
  const data = action.payload && action.payload.data !== undefined ? action.payload.data : false
  let newModal = [...state.modalData.modal]

  switch (action.type) {

    case 'MODAL_CLEAN_ALL':
      return defaultState

    case 'MODAL_OPEN_CLOSE':
      if (newModal[id]) {
        newModal.splice(id, 1, {
          ...newModal[id],
          show: !!data
        })
      }
      return { modal: newModal }

    case 'MODAL_UPDATE':
      if (newModal[id]) {
        newModal.splice(id, 1, {
          ...newModal[id],
          ...data
        })
      }
      return { modal: newModal }

    case 'MODAL_REPLACE':
      if (newModal[id]) {
        newModal.splice(id, 1, data)
      }
      return { modal: newModal }

    case 'MODAL_UPDATE_DATA':
      if (newModal[id]) {
        newModal.splice(id, 1, {
          ...newModal[id],
          data: {
            ...newModal[id].data,
            ...data
          }
        })
      }
      return { modal: newModal }

    case 'MODAL_REPLACE_DATA':
      if (newModal[id]) {
        newModal.splice(id, 1, {
          ...newModal[id],
          data: data
        })
      }
      return { modal: newModal }

    case 'MODAL_DELETE':
      if (newModal[id]) {
        newModal.splice(id, 1)
      }
      return { modal: newModal }

    case 'MODAL_ADD':
      newModal.push(data)
      return { modal: newModal }

    default:
      return state.modalData
  }
}

export default updateModalData