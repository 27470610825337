import React from "react"
import MainPageWrapper from "../../../Main-page-wrapper"
import Form from "./Form/Form"

function FirmaUpdateComponent() {
  return (
    <MainPageWrapper>
      <Form />
    </MainPageWrapper>
  )
}

export default FirmaUpdateComponent
