import React, {useEffect, useMemo, useState} from "react";
import style from "./style.module.scss"
import {Button} from "@material-ui/core";
import {BarChart, Timeline} from "@material-ui/icons";
import {generateLinearSeries, updateData} from "../utils";
import ColumnChart from "./ColumnChart";
import {useRouteMatch} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import ComparisonMultiLinearChart from "./ComparisonMultiLinearChart";
import {useTranslation} from "react-i18next";

export default function ComparisonDashDoubledChard(
  {chartId, chartContent: {withChartTypes, withTotalOver, withTimeInterval}, seriesNames}) {
  const {theme: {colors}} = useTheme();
  const [totalOverTime, setTotalOverTime] = useState(false);
  const [chartType, setChartType] = useState(false);
  const [timeInterval, setTimeInterval] = useState("all");
  const rewrittenId = `${chartId}${chartType ? "Column" : "Linear"}`;
  const {params: {type}} = useRouteMatch();
  const timeIntervals = [
    {label: "1M", value: "one_month"},
    {label: "6M", value: "six_months"},
    {label: "1J", value: "one_year"},
    {label: "2J", value: "ytd"},
    {label: "ALL", value: "all"}];

  const { t } = useTranslation();

  const series = useMemo(() => {
    return type === "texts" ? [
        generateLinearSeries('Auswahll 1', 10, false, colors.colorBlue1000),
        generateLinearSeries('Auswahll 2', 10, false, colors.colorProgress60)
      ]
      : seriesNames?.map(name => generateLinearSeries(name, 12, true, colors.colorBlue1000));
  }, [type, colors, seriesNames])

  useEffect(() => {
    updateData(timeInterval, rewrittenId, series?.[0]?.data?.[0].x, series?.[0]?.data?.[series?.[0]?.data?.length - 1].x)
  }, [timeInterval, series, chartId, rewrittenId])

  return (
    <div className={style.comparisonDashDoubledChard}>
      <div className={style.head}>
        {
          withTotalOver &&
          <div>
            <Button size='small' color={totalOverTime ? 'primary' : 'default'}
                    onClick={() => setTotalOverTime(true)}>
                      {t('total')}
                    </Button>
            <Button size='small' color={!totalOverTime ? 'primary' : 'default'}
                    onClick={() => setTotalOverTime(false)}>
              {t('over time')}
            </Button>
          </div>
        }
        {
          withChartTypes &&
          <div className={style.chartType}>
            <Button size='small' color={chartType ? "primary" : 'default'} onClick={() => setChartType(true)}>
              <BarChart/>
            </Button>
            <Button size='small' color={!chartType ? "primary" : 'default'} onClick={() => setChartType(false)}>
              <Timeline/>
            </Button>
          </div>
        }
        {
          withTimeInterval &&
          <div className={style.timeIntervals}>
            {timeIntervals.map((c, i) =>
              <Button
                key={`${i}-ComparisonDashDoubledChartIntervalButtons`} size='small'
                color={c.value === timeInterval ? "primary" : "default"}
                onClick={() => setTimeInterval(state => state === c.value ? "all" : c.value)}>
                {c.label}
              </Button>)}
          </div>
        }
      </div>

      <div className={`${style.charts}`}>
        <div style={{width: "100%"}}>
          {chartType ?
            <ColumnChart series={series} height={350} chartId={rewrittenId}/> :
            <ComparisonMultiLinearChart customSeries={series} height={350} chartId={rewrittenId}
                                   title={''} chartContent={{withTotalOver: false, overTimeOppositeSide: ""}}
                                   seriesNames={null} withoutDashboard={true}
            />
          }
        </div>
      </div>
    </div>
  )
};
