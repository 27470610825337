import React, { useEffect, useState } from "react"
import style from "./style.module.sass"
import arrow from "../../../../Profile/ProfileWrapper/assets/arrow.svg"
import { Redirect, NavLink } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import companyIcon from "../../../Info/assets/companyIcon.svg"
import { Button } from "@material-ui/core"
import saveIcon from "../../../Info/assets/saveIcon.svg"
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import TLServiceComponent from "../../../../../contexts/TLServiceComponent"
import { useParams } from 'react-router'
import CompanyProfile from "./CompanyProfile/CompanyProfile"
import Options from "./Options"
import { toast, ToastContainer } from "react-toastify"
import { checkUserRight, convertFromErrorObject, isObjectEmpty, isObjectsEqual } from "../../../../../utils"
import { Add } from "@material-ui/icons"
import GroupList from "./GroupList"



//state validation by YUP
const validationSchema = yup.object({
  companyName: yup
    .string('Enter your group name')
    .required('Group name is required'),
  companyLicenses: yup
    .array()
    .required('Licenses are required')
    .min(1, 'Licenses are required')
    .default([]),
})

function Form() {
  const { theme: { bgColors, colors } } = useTheme()
  const { t } = useTranslation()
  const [licenseList, setLicenseList] = useState([])
  const [licenseIds, setLicenseIds] = useState([])
  const user = useSelector(state => state.userData.user)
  const token = useSelector((state) => state.userData.token)
  const companies = useSelector((state) => state.adminData.companies)
  const { id } = useParams()

  const tlService = React.useContext(TLServiceComponent)

  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyId: "",
      companyLicenses: [],
      create_time: null,
      update_time: null,
      enabled_frontend_sections: [],
      maximum_users: '',
      expired_time: null,
      edit_archives: '',
      view_archives: '',
      enable_textbin: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      postCompany(values)
    },
  })

  const fillFormik = async () => {
    let company

    if (!!id) {
      try {
        company = await tlService.getCompanyById(token, id)
        setLicenseIds(company.company_licenses)
      } catch (e) {
        console.log('error', e)
      }
      // Set the Values if User try to EDIT:
      formik.initialValues.companyName = company.name
      formik.initialValues.companyId = company.id
      formik.initialValues.companyLicenses = company.company_licenses
      formik.initialValues.create_time = company.create_time ? company.create_time : null
      formik.initialValues.update_time = company.update_time ? company.update_time : null
      if (!isObjectEmpty(company.company_options)) {
        for (const tmpOption of Object.keys(company.company_options)) {
          formik.initialValues[tmpOption] = company.company_options[tmpOption]
        }
      }

    } else {
      // Set the Values to empty if User try to ADD new User:
      formik.initialValues.companyName = ''
      formik.initialValues.companyLicenses = []
      formik.initialValues.enabled_frontend_sections = []
      formik.initialValues.edit_archives = ''
      formik.initialValues.view_archives = ''
      formik.initialValues.enable_textbin = ''
    }
    // Get license list:
    let licenses
    try {
      licenses = await tlService.getLicenses()
    } catch (e) {
      console.log('error', e)
    }
    // add translation to license names
    licenses = licenses.data.map(license => { return { ...license, name: t(license.name) } })
    setLicenseList(licenses)
  }

  const postCompany = async (values) => {
    const options = Object.keys(values).filter(option => option !== 'companyName' && option !== 'companyLicenses' && option !== 'create_time' && option !== 'update_time' && option !== 'companyId')

    if (!!id) {
      if (checkUserRight(user, [308])) {
        try {
          await tlService.updateCompany(token, parseInt(id), values.companyName)
          toast.success(t('admin_company_update-success-msg'))
        } catch (e) {
          console.log('error', e)
          toast.error(convertFromErrorObject(t, e))
          return false
        }
      }

      //license & options
      if (checkUserRight(user, [309])) {

        if (!isObjectsEqual(licenseIds, values.companyLicenses)) {

          // find which licenses to add and which to delete
          const toAdd = values.companyLicenses.filter(x => !licenseIds.includes(x))
          const toDelete = licenseIds.filter(x => !values.companyLicenses.includes(x))

          for (let license_id of toAdd) {
            try {
              await tlService.addCompanyLicense(token, parseInt(id), license_id)
            } catch (e) {
              console.log('error', e)
              toast.error(convertFromErrorObject(t, e))
              return false
            }

          }
          for (let license_id of toDelete) {
            try {
              await tlService.deleteCompanyLicense(token, parseInt(id), license_id)
            } catch (e) {
              console.log('error', e)
              toast.error(convertFromErrorObject(t, e))
              return false
            }
          }
        }

        try {
          await tlService.deleteAllCompanyOption(token, parseInt(id))
        } catch (e) {
          console.log('error ' + convertFromErrorObject(t, e))
          toast.error(convertFromErrorObject(t, e))
          return false
        }

        for (let tmpOption of options) {
          const value = values[tmpOption]
          if (isValidValue(value)) {
            const value = typeof values[tmpOption] === 'object' ? JSON.stringify(values[tmpOption]) : String(values[tmpOption]).trim()
            try {
              await tlService.addCompanyOption(token, parseInt(id), tmpOption, value)
            } catch (e) {
              console.log('error', e)
              toast.error(convertFromErrorObject(t, e))
              return false
            }
          }
        }
      }
      return true
    }
    else {
      let companyId = false
      try {
        const newLicenses = values.companyLicenses.map(license => parseInt(license))
        const res = await tlService.addCompany(token, newLicenses, values.companyName)
        toast.success(t('admin_company_add-success-msg'))
        companyId = res.id
      }
      catch (e) {
        console.log('error', e)
        toast.error(convertFromErrorObject(t, e))
        return false
      }

      if (checkUserRight(user, [309])) {
        for (let tmpOption of options) {
          const value = values[tmpOption]
          if (isValidValue(value)) {
            const value = typeof values[tmpOption] === 'object' ? JSON.stringify(values[tmpOption]) : String(values[tmpOption]).trim()
            try {
              await tlService.addCompanyOption(token, parseInt(companyId), tmpOption, value)
            } catch (e) {
              console.log('error', e)
              toast.error(convertFromErrorObject(t, e))
              return false
            }
          }
        }
      }
      return true
    }
  }

  function isValidValue(value) {
    return value !== undefined && value !== '' && (typeof value !== 'object' || Object.keys(value).length > 0)
  }

  useEffect(() => {
    fillFormik()
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [])

  const isSingleCompanyUser = Object.keys(companies).length === 1

  if (!checkUserRight(user, [306, 307, 308, 309]))
    return <Redirect to={"/administration"} />

  return (
    <>
      <ToastContainer autoClose={5000} position="top-center" hideProgressBar={false} draggable pauseOnHover closeOnClick />
      <form className={`pb-5 ${style.mainCont}`} onSubmit={formik.handleSubmit}>
        <NavLink className={`${style.breadcrumb}`} to={
          isSingleCompanyUser ? "/administration" : "/administration/company"
        } exact={true}>
          <Button color="primary" variant="contained">
            <img src={arrow} className={`mr-3`} alt="" />
            {
              isSingleCompanyUser ? t('administration') : t('Companies')
            }
          </Button>
        </NavLink>
        {
          !!id && isSingleCompanyUser && (
            <div style={{ float: 'right', padding: '5px' }}>
              {checkUserRight(user, [307]) &&
                <NavLink to={`/administration/company/update/add-new-company`} exact={true}>
                  <Button variant="contained"
                    style={{ whiteSpace: "nowrap" }}
                    color="primary"
                    startIcon={<Add />}>
                    {t('admin_company_add-new-company')}
                  </Button>
                </NavLink>
              }
            </div>
          )
        }
        <div className={`mt-5`}>
          <div className={`d-flex align-items-center justify-content-between mb-3`}>
            <h3 style={{ color: colors.colorPrimary1000 }} className={`${style.main_title} d-flex align-items-center`}>
              <img style={{ height: 50, width: 50 }}
                className={`mr-3`} src={companyIcon} alt="" />
              {t('companies')}</h3>
            {checkUserRight(user, [307, 308, 309]) && <Button type="submit" variant={"contained"} color={"primary"}
              style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
              className={`mr-2`}>
              <img src={saveIcon} className={`mr-2`} alt="" /> {t('save-changes')}
            </Button>}
          </div>
          <div style={{ backgroundColor: bgColors.bgPrimary500 }}
            className={`pl-0 mb-4 pb-3 pr-0 col-12 ${style.borderTop}`}>
            <CompanyProfile formik={formik} licenses={licenseList} id={id} />
            <Options formik={formik} id={id} />
            {!!id && <GroupList id={id} />}
          </div>
          {checkUserRight(user, [307, 308, 309]) &&
            <div className={`${style.saveChangesBtn} mb-5`}>
              <Button type="submit" variant={"contained"} color={"primary"}
                style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
                className={`mr-2`}>
                <img src={saveIcon} className={`mr-2`} alt="" /> {t('save-changes')}
              </Button>
            </div>
          }
        </div>
      </form>
    </>
  )
}

export default Form
