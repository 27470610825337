import React, { Fragment, useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Box, Button, Collapse, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useActions } from '../../../utils/action-helper'
import {
  analyseClearAccordionItemsWithCells,
  analyseSetBodyBorderShow, analyseSetFileName,
  analyseSetModalAccordion,
  analyseSetResult, analyseSetSaveArchive,
  analyseSetText,
  analyseSetTopAccordion,
  tableSetData,
  tableSetDialog,
  tableSetOrderBy,
  tableSetRedirect,
  tableSetSelectAll,
  tableSetSelected,
} from '../../../actions'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import Checkbox from '@material-ui/core/Checkbox'
import {
  convertFromErrorObject,
  convertLanguagesToShort,
  convertedValue, isObjectEmpty, onlyUnique,  
} from '../../../utils'
import TableContainer from '@material-ui/core/TableContainer'
import { Delete, PlayArrow } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import TableHead from '@material-ui/core/TableHead'
import CircularProgress from '@mui/material/CircularProgress'
import styles from '../../common/ArchiveManager/style.module.sass'

export default function TableInnerComponent ({
  row,
  index,
  isBlueRow,
  handleChangeValue
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)

  const selected = useSelector(state => state.tableData.selected)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const data = useSelector(state => state.tableData.data)
  const columns = useSelector(state => state.tableData.columns)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const token = useSelector(state => state.userData.token)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)

  const [extraData, setExtraData] = useState({})
  const [spinner, setSpinner] = useState(false)

  const {
    aTableSetSelected, aAnalyseSetText, aAnalyseSetResult,
    aTableSetData, aAnalyseSetBodyBorderShow,
    aTableSetDialog, aAnalyseSetTopAccordion,
    aAnalyseSetModalAccordion, aTableSetRedirect,
    aAnalyseSetSaveArchive, aAnalyseSetFileName,
    aAnalyseClearAccordionItemsWithCells
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetOrderBy: tableSetOrderBy,
    aTableSetSelected: tableSetSelected,
    aTableSetDialog: tableSetDialog,
    aAnalyseSetBodyBorderShow: analyseSetBodyBorderShow,
    aAnalyseSetTopAccordion: analyseSetTopAccordion,
    aAnalyseSetModalAccordion: analyseSetModalAccordion,
    aAnalyseSetText: analyseSetText,
    aAnalyseSetResult: analyseSetResult,
    aTableSetRedirect: tableSetRedirect,
    aAnalyseSetSaveArchive: analyseSetSaveArchive,
    aAnalyseSetFileName: analyseSetFileName,
    aAnalyseClearAccordionItemsWithCells: analyseClearAccordionItemsWithCells

  })

  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)
  const archiveType = useSelector(state => state.archiveData.archiveType)
  const extraFilters = useSelector(state => state.tableData.extraFilters)
  const [editValue, setEditValue] = useState('')
  const [editMenu, setEditMenu] = useState('')
  const [editIndex, setEditIndex] = useState(-1)

  const cellStyles = {
    archive_name: style.archiveName,
    owner: style.otherColumns,
    benchmark_id: style.otherColumns,
    create_time: style.otherColumns,
    formulaSPIX: style.otherColumns,
    formulaHix: style.otherColumns,
    formulaCLIX: style.otherColumns,
    formulaFlesch: style.otherColumns,
  }

  useEffect(() => {

    const getAllVersions = async () => {
      const tData = [...data]
      try {
        const return_values = JSON.stringify(
          ['id', 'archive_name', 'analyse_id', 'benchmark_id', 'owner', 'update_time', 'create_time', 'version',
            'result.formulaHix', 'result.formulaCLIX', 'result.formulaSPIX', 'benchmark_settings_hash', 'tag_name'])
        const res = await tlService.getFilteredArchive(token, { order: 1, order_by: 'update_time' }, {
          exact_result: 0,
          analyse_id: row.analyse_id,
          return_values
        })
        if (res?.data?.length) {
          tData[index].versions = res.data
          aTableSetData(tData)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (row.opened && isObjectEmpty(row.versions)) {
      getAllVersions()
    }
  }, [row.opened])

  // dialog menu
  useEffect(() => {
    async function fetchData() {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'deleteFullArchive':
            if (dialogResponse && !isObjectEmpty(extraData)) {
              const analyse_id = extraData.analyse_id
              try {
                await tlService.deleteArchive(token, analyse_id)
                const tData = [...data]
                tData.splice(index, 1)
                aTableSetData(tData)
              } catch (error) {
                console.log('deleteArchive', error)
                aTableSetDialog({
                  type: 'errorDeleteV',
                  open: true,
                  RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                  response: null,
                  buttons: {
                    yes: false,
                    no: false,
                    close: true,
                    save: false
                  }
                })
              }
            }
            break
          case 'deleteArchiveVersion':
            if (dialogResponse && !isObjectEmpty(extraData)) {
              const version = data[index].versions[extraData.index].version
              const analyse_id = data[index].versions[extraData.index].analyse_id
              try {
                await tlService.deleteArchiveVersion(token, analyse_id, version)
                const tData = [...data]
                if (tData[index].versions.length > 1) {
                  tData[index].versions.splice(extraData.index, 1)
                } else {
                  tData.splice(index, 1)
                }
                aTableSetData(tData)
              } catch (error) {
                console.log('deleteArchiveVersion', error)
                aTableSetDialog({
                  type: 'errorDeleteVersion',
                  open: true,
                  RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                  response: null,
                  buttons: {
                    yes: false,
                    no: false,
                    close: true,
                    save: false
                  }
                })
              }
            }
            break
          case 'benchmarkResultHasWrongFormat':
          case 'benchmarkNotExist':
            if (dialogResponse && extraData?.text) {
              aAnalyseSetSaveArchive({
                folder: extraData.folder_id,
                id: extraData.analyse_id,
                version: extraData.version,
                tags: extraData.tag_name,
                type: archiveType,
                name: extraData.archive_name
              })
              aAnalyseSetText(extraData.text)
              aAnalyseSetResult({})
              aAnalyseSetBodyBorderShow(false)
              aAnalyseSetTopAccordion('')
              aAnalyseSetModalAccordion('')
              aAnalyseClearAccordionItemsWithCells()
              setTimeout(() => {
                aTableSetRedirect('/')
              }, 100)
            }
            break
          case 'benchmarkWasChanged':
            if (dialogResponse && extraData?.text) {
              aAnalyseSetSaveArchive({
                folder: extraData.folder_id,
                id: extraData.analyse_id,
                version: extraData.version,
                tags: extraData.tag_name,
                type: archiveType,
                name: extraData.archive_name
              })
              aAnalyseSetText(extraData.text)
              aAnalyseSetResult(extraData.result, extraData.benchmark_id, benchmarks[extraData.benchmark_id])
              aAnalyseSetBodyBorderShow(false)
              aAnalyseSetTopAccordion('')
              aAnalyseSetModalAccordion('')
              aAnalyseClearAccordionItemsWithCells()
              setTimeout(() => {
                aTableSetRedirect('/')
              }, 100)
            }
            break
          default:
            break
        }
        setExtraData({})
      }
    }
    fetchData()
  }, [dialogOpen, dialogType]);

  /** HANDLE FUNCTIONS **/
  async function handleOpenAnalyse (row) {

    // get all data
    let error = false
    let fullData = {}
    try {
      const res = await tlService.getArchiveById(token, row.id)
      if (!isObjectEmpty(res.data)) {
        fullData = res.data[0]
      } else {
        error = true
      }
    } catch (e) {
      console.log(e)
      error = true
    }

    // archive is not loaded!!
    if (error) {
      setExtraData(fullData)
      aTableSetDialog({
        type: 'archiveNotExist',
        open: true,
        RequestComponent: () => <>{t('archive_not_exist')}</>,
        response: null,
        buttons: {
          yes: false,
          no: false,
          close: true,
          save: false
        }
      })
      setExtraData({})
      return false
    }

    //first check if the benchmark still exist
    if (benchmarks[row.benchmark_id] === undefined) {
      setExtraData(fullData)
      aTableSetDialog({
        type: 'benchmarkNotExist',
        open: true,
        RequestComponent: () => <>{t('benchmark_not_exist_anymore_open_as_text')}</>,
        response: null,
        buttons: {
          yes: true,
          no: true,
          close: false,
          save: false
        }
      })
      return false
    }

    // convert result to JSON
    let jsonResult = {}
    try {
      jsonResult = JSON.parse(fullData.result)
    } catch (e) {
      setExtraData(fullData)
      aTableSetDialog({
        type: 'benchmarkResultHasWrongFormat',
        open: true,
        RequestComponent: () => <>{t('benchmark_result_has_wrong_format_open')}</>,
        response: null,
        buttons: {
          yes: true,
          no: true,
          close: false,
          save: false
        }
      })
      return false
    }

    fullData['result'] = jsonResult

    // check if the benchmark hash is same still exist
    if (benchmarks[row.benchmark_id]?.settings_hash !== row?.benchmark_settings_hash) {
      setExtraData(fullData)
      aTableSetDialog({
        type: 'benchmarkWasChanged',
        open: true,
        RequestComponent: () => <>{t('benchmark_settings_was_changed_reload')}</>,
        response: null,
        buttons: {
          yes: true,
          no: true,
          close: false,
          save: false
        }
      })
      return false
    }

    //

    aAnalyseSetSaveArchive({
      folder: fullData.folder_id,
      id: fullData.analyse_id,
      version: fullData.version,
      tags: isObjectEmpty(fullData.tag_name) ? [] : fullData.tag_name.sort(),
      type: archiveType,
      name: fullData.archive_name
    })
    aAnalyseSetFileName(fullData.archive_name)
    aAnalyseSetText(fullData.text)
    aAnalyseSetResult(jsonResult, fullData.benchmark_id, benchmarks[fullData.benchmark_id])
    aAnalyseSetBodyBorderShow(false)
    aAnalyseSetTopAccordion('')
    aAnalyseSetModalAccordion('')
    aAnalyseClearAccordionItemsWithCells()

    setTimeout(() => aTableSetRedirect('/'), 100)

    return true
  }

  const handleSelectItem = (id) => {
    const tSelected = [...selected]
    const idx = tSelected.indexOf(id)
    if (idx > -1) {
      tSelected.splice(idx, 1)
    } else {
      tSelected.push(id)
    }
    aTableSetSelected(tSelected.sort())
  }

  const handleOpenItem = (inx) => {
    const tData = [...data]
    tData[inx]['opened'] = !row?.opened
    aTableSetData(tData)
  }

  const handleDeleteVersion = (inx) => {
    setExtraData({ index: inx })
    aTableSetDialog({
      type: 'deleteArchiveVersion',
      open: true,
      RequestComponent: () => <>{t('delete_archive_version')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }

  const handleDeleteAnalyse = (row) => {
    setExtraData(row)
    aTableSetDialog({
      type: 'deleteFullArchive',
      open: true,
      RequestComponent: () => <>{t('delete_full_archive')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }

  const handleEditVersion = (inx, type) => {
    setEditIndex(inx)
    if (type === 'tag_name') {
      setEditValue(data[index].versions[inx][type].join(', '))
    } else {
      setEditValue(data[index].versions[inx][type])
    }
    setEditMenu(type)
  }

  const handleEditArchive = (row) => {

    setEditMenu('archive_name')
    setEditValue(row.archive_name)
  }

  const handleEditVersionBlur = () => {
    setEditValue('')
    setEditMenu('')
    setEditIndex(-1)
  }

  const handleEditVersionSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (editMenu === 'archive_name') {
      try {
        await tlService.editArchive(token, row.analyse_id, {
          name: editValue
        })
        const tData = [...data]
        tData[index].archive_name = editValue
        aTableSetData(tData)
      } catch (error) {
        console.log('editArchiveVersion', error)
        aTableSetDialog({
          type: 'errorUpdateVersion',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
      }

    } else if (editMenu === 'version') {
      if (editIndex < 0) {
        return false
      }
      const currentVersion = { ...data[index].versions[editIndex] }
      try {
        await tlService.editArchiveVersion(token, row.analyse_id, currentVersion.version, {
          version: editValue
        })
        const tData = [...data]
        tData[index].versions[editIndex]['version'] = editValue
        aTableSetData(tData)
      } catch (error) {
        console.log('editArchiveVersion', error)
        aTableSetDialog({
          type: 'errorUpdateVersion',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
      }
    } else if (editMenu === 'tag_name') {
      if (editIndex < 0) {
        return false
      }
      const currentVersion = { ...data[index].versions[editIndex] }

      let splitKeywords = []
      if (editValue.trim().length > 0) {
        splitKeywords = editValue.split(',')
          .map(v => v.trim())
          .filter(v => v?.length)
          .filter(onlyUnique)
          .sort()
      }

      try {
        await tlService.editArchiveVersion(token, row.analyse_id, currentVersion.version, {
          tags: splitKeywords
        })
        const tData = [...data]
        tData[index].versions[editIndex]['tag_name'] = splitKeywords
        aTableSetData(tData)
      } catch (error) {
        console.log('editArchiveVersion', error)
        aTableSetDialog({
          type: 'errorUpdateVersion',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
      }
    }
    handleEditVersionBlur()
  }

  const handleSwitchToVersion = (inx) => {
    const tData = [...data]
    let copyVersion = tData[index].versions[inx]
    tData[index] = {
      ...copyVersion,
      versions: tData[index].versions,
      opened: tData[index].opened
    }
    aTableSetData(tData)
  }

  const handleSearchByTag = async (word) => {
    const extraData = extraFilters.find(v => v.id === 'tag_name')
    handleChangeValue(extraData, word, word, true)
  }

  const EmptyCell = () => {
    return <TableCell
      onClick={() => handleOpenItem(index)}
      className={style.emptyColumns}
      component="th"
      scope="row"
      padding="none"/>
  }

  /* VIEW */
  const isItemSelected = selected.includes(row.id)
  const shortArcLan = convertLanguagesToShort(archiveLanguage)

  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><CircularProgress/></div>

  return (
    <>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={() => handleSelectItem(row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </TableCell>
        {columns.map((col, ind) =>
          <Fragment key={ind}>
            {disabledColumns.includes(col.id) ? <EmptyCell/> :
              <>
                {(col.id === 'archive_name' && editMenu === 'archive_name') ?
                  <EditForm
                  editValue={editValue}
                  setEditValue={setEditValue}
                  size={30}
                  handleEditSubmit={(e) => handleEditVersionSubmit(e)}
                  handleEditBlur={() => handleEditVersionBlur()}
                />
                  :
                  <TableCell
                    onClick={() => handleOpenItem(index)}
                    className={cellStyles[col.id]}
                    component="th"
                    scope="row"
                    padding="none">
                    {convertedValue(row[col.id], col.type, i18nLanguage, t, benchmarks)}
                  </TableCell>
                }
              </>
            }
          </Fragment>)
        }
        <TableCell className={`${style.cell} d-flex`} align="right">
          <div className={style.buttonCell}>
            <Button variant="contained" color="success" size="small"
                    style={{
                      padding: '4px',
                      minWidth: '30px',
                      backgroundColor: bgColors.bgProgress100,
                      color: colors.colorPrimary0
                    }}
                    onClick={() => handleOpenAnalyse(row)}>
              <PlayArrow fontSize="small"/>
            </Button>
          </div>
          <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
                    style={{
                      padding: '4px',
                      minWidth: '30px',
                      backgroundColor: bgColors.bgProgress20,
                      color: colors.colorPrimary0
                    }}
                    onClick={() => handleDeleteAnalyse(row)}>
              <Delete fontSize="small"/>
            </Button>
          </div>
          <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
                    style={{
                      padding: '4px',
                      minWidth: '30px',
                      backgroundColor: bgColors.bgBlue1000,
                      color: colors.colorPrimary0
                    }} onClick={() => handleEditArchive(row)}>
              <EditIcon fontSize="small"/>
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
                style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse className={'p-1'} in={row.opened} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
                 className={`mb-4 d-flex justify-content-between pt-2`}
                 margin={1}>
              <div className={'d-flex text-center w-100'}>
                <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
                  <TableContainer>
                    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={style.smallTableHeader}>{t('version')}</TableCell>
                          <TableCell className={style.smallTableHeader}>{t('tag-name')}</TableCell>
                          <TableCell className={style.smallTableHeader}>{t('date')}</TableCell>
                          <TableCell className={style.smallTableHeader}>{t('clix')}</TableCell>
                          {shortArcLan === 'de' && <TableCell className={style.smallTableHeader}>{t('hix')}</TableCell>}
                          {shortArcLan === 'en' &&
                            <TableCell className={style.smallTableHeader}>{t('spix')}</TableCell>}
                          <TableCell className={style.smallTableHeader}>{t('edit')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.opened && row.versions.map((ver, ind) =>
                          <Fragment key={ind}>
                            <TableRow
                              className={ver.id === row.id ? style.current : ''}
                              key={ind}>
                              <TableCell
                                onClick={() => handleSwitchToVersion(ind)}
                                className={style.smallTable}>
                                {(editMenu === 'version' && editIndex === ind) ?
                                  <div className={'d-flex w-100'}>
                                    <EditForm
                                      editValue={editValue}
                                      setEditValue={setEditValue}
                                      size={30}
                                      handleEditSubmit={(e) => handleEditVersionSubmit(e)}
                                      handleEditBlur={() => handleEditVersionBlur()}
                                    />
                                  </div>
                                  :
                                  <div className={'d-flex w-100'}>
                                    <div className={'w-100'}>{ver.version}</div>
                                    <div>
                                      <EditIcon
                                        onClick={() => handleEditVersion(ind, 'version')}
                                        className={style.icons}
                                        fontSize={'small'}/>
                                    </div>
                                  </div>
                                }

                              </TableCell>
                              <TableCell className={style.smallTableNoPointer}>
                                {(editMenu === 'tag_name' && editIndex === ind) ?
                                  <div className={'d-flex w-100'}>
                                    <EditForm
                                      editValue={editValue}
                                      setEditValue={setEditValue}
                                      size={30}
                                      handleEditSubmit={(e) => handleEditVersionSubmit(e)}
                                      handleEditBlur={() => handleEditVersionBlur()}
                                    />
                                  </div>
                                  :
                                  <div className={'d-flex w-100'}>
                                    <div className={'w-100 d-flex flex-wrap'}>
                                      {ver?.tag_name && ver.tag_name.sort().map((word, idx) => <div
                                        onClick={() => handleSearchByTag(word)}
                                        key={idx}
                                        style={{ backgroundColor: '#c9c9c9', fontSize: '10px', cursor: 'pointer' }}
                                        className={'text-nowrap mr-1 mb-1 p-1 rounded'}>
                                        {word}
                                      </div>)}
                                    </div>
                                    <div>
                                      <EditIcon
                                        onClick={() => handleEditVersion(ind, 'tag_name')}
                                        className={style.icons}
                                        fontSize={'small'}/>
                                    </div>
                                  </div>}
                              </TableCell>
                              <TableCell
                                style={{ width: '130px' }}
                                onClick={() => handleSwitchToVersion(ind)}
                                className={style.smallTable}>{convertedValue(ver.update_time, 'date', i18nLanguage, t)}</TableCell>
                              <TableCell
                                style={{ width: '60px' }}
                                onClick={() => handleSwitchToVersion(ind)}
                                className={style.smallTable}>{convertedValue(ver.formulaCLIX, 'numeric', i18nLanguage, t)}</TableCell>
                              {shortArcLan === 'de' && <TableCell
                                style={{ width: '60px' }}
                                onClick={() => handleSwitchToVersion(ind)}
                                className={style.smallTable}>{convertedValue(ver.formulaHix, 'numeric', i18nLanguage, t)}</TableCell>}
                              {shortArcLan === 'en' && <TableCell
                                style={{ width: '60px' }}
                                onClick={() => handleSwitchToVersion(ind)}
                                className={style.smallTable}>{convertedValue(ver.formulaSPIX, 'numeric', i18nLanguage, t)}</TableCell>}
                              <TableCell style={{ width: '40px', textAlign: 'center' }}
                                         className={style.smallTableIcons}>
                                <Delete onClick={() => handleDeleteVersion(ind)} className={style.icons}
                                        fontSize={'small'}/>
                              </TableCell>
                            </TableRow>
                          </Fragment>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>

                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
const EditForm = (
  {
    editValue,
    setEditValue,
    editName,
    handleEditSubmit,
    handleEditBlur,
    fieldId,
    size = 25
  }
) => {

  return (
    <div className={styles.name}>
      <form onSubmit={handleEditSubmit}>
        <input
          autoFocus={true}
          key={fieldId}
          type="text"
          size={size}
          value={editValue}
          onChange={e => setEditValue(e.target.value)}
          onBlur={handleEditBlur}
          placeholder={editName}/>
      </form>
    </div>
  )

}
/*
analyse_id(pin): "87216671fc774ef58b4bb4622b00bc0a"
archive_name(pin): "1aa_nachher R+V24 - Bestätigen Sie bitte Ihren Abschluss (erste Erinnerung).docx"
benchmark_id(pin): 4
benchmark_settings_hash(pin): "A7DJTyAThUmD1xjsRajITQ=="
create_time(pin): "2024-02-12 11:46:37:UTC"
formulaCLIX(pin): 94.60407239819004
formulaHix(pin): 14.608572990408284
id(pin): 63
owner(pin): 2
update_time(pin): "2024-02-12 11:46:37:UTC"
version(pin): "1"
* */