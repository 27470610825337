import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useSelector } from 'react-redux'
import { MenuItem, Paper, Select } from '@material-ui/core'
import Spinner from '../../Spinner'
import { useActions } from '../../../utils/action-helper'
import {
  archiveSetFolderForceReload,
  tableSetData, tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount, tableSetSelectAll, tableSetSelectAllIds,
  userSetArchiveLanguage
} from '../../../actions'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import UniversalTable from '../../common/UniversalTable'
import { useTranslation } from 'react-i18next'
import TableInnerComponent from '../TableInnerComponent/TableInnerComponent'
import TableMassActionComponent from '../TableMassActionComponent/TableMassActionComponent'
import { convertJSONToObject, convertLanguagesToFull, isObjectEmpty } from '../../../utils'

export default function Table () {

  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const token = useSelector(state => state.userData.token)
  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order)
  const orderBy = useSelector(state => state.tableData.orderBy || 'analyse_id')
  const currentPage = useSelector(state => state.tableData.currentPage)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const otherData = useSelector(state => state.tableData.otherData)
  let textDisabledArchiveColumns = useSelector(state => state.userData.user?.user_options?.disabled_archive_columns)

  if(textDisabledArchiveColumns === undefined) {
    textDisabledArchiveColumns = "[\"formulaSPIX\"]"
  }
  const disabledArchiveColumns = convertJSONToObject(textDisabledArchiveColumns, [])

  const {
    actionUserSetArchiveLanguage,
    aTableSetDefaults, aArchiveSetFolderForceReload,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll
  } = useActions({
    actionUserSetArchiveLanguage: userSetArchiveLanguage,
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
    aArchiveSetFolderForceReload: archiveSetFolderForceReload
  })

  const defaultPagination = [
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'archive_name',
      type: 'string',
      disablePadding: true,
      label: `${t('file-name')}`,
      filter_value: 'archive_name',
      orderBy_value: 'archive_name'
    },
    {
      id: 'owner',
      type: 'numeric',
      unSortable: true,
      disablePadding: false,
      label: `${t('author')}`,
      filter_value: 'owner',
      orderBy_value: 'owner'
    },
    {
      id: 'benchmark_id',
      type: 'benchmark',
      disablePadding: false,
      label: `${t('text-variety')}`,
      filter_value: 'benchmark_id',
      orderBy_value: 'benchmark_id'
    },
    {
      id: 'formulaCLIX',
      type: 'numeric',
      disablePadding: false,
      label: `${t('clix')}`,
      filter_value: 'result.formulaCLIX',
      orderBy_value: 'clix'
    },
    {
      id: 'formulaSPIX',
      type: 'numeric',
      disablePadding: false,
      label: `${t('spix')}`,
      filter_value: 'result.formulaSPIX',
      orderBy_value: 'spix',
    },
    {
      id: 'update_time',
      type: 'date',
      disablePadding: false,
      label: `${t('date')}`,
      filter_value: 'update_time',
      orderBy_value: 'update_time'
    },
    {
      id: 'create_time',
      type: 'date',
      disablePadding: false,
      label: `${t('create_date')}`,
      filter_value: 'create_time',
      orderBy_value: 'create_time'
    },
  ]
  const defaultFilters = [
    {
      id: 'benchmark_id',
      name: 'Text-type',
      type: 'catalog',
      searchField: 'benchmark_id',
      searchTemplate: '={value}',
      source: 'benchmarks',
      label: t('Text-type')
    },
    {
      id: 'date_from',
      name: 'expiry-date-from',
      type: 'date',
      searchField: 'create_time',
      searchTemplate: '>={value}',
      source: -1,
      label: t('expiry-date-from')
    },
    {
      id: 'date_to',
      name: 'expiry-date-to',
      type: 'date',
      searchField: 'create_time',
      searchTemplate: '<={value}',
      source: 1,
      label: t('expiry-date-to')
    },
    {
      id: 'tag_name',
      name: 'tag-name',
      type: 'string',
      searchField: 'tag_name',
      searchTemplate: 'lc={value}',
      source: 0,
      label: t('tag-name')
    },
  ]
  const extraSettings = {
    language: archiveLanguage,
    fullSearchFilter: [['|archive_name', 'lc=%{value}%'], ['|version', 'lc=%{value}%']]
  }

  // eng /de
  // formulaFlesch
  switch (archiveLanguage) {
    case 'en_GB':
      defaultColumns.splice(-2, 0, {
        id: 'formulaFlesch',
        type: 'numeric',
        disablePadding: false,
        label: `${t('flesch')}`,
        filter_value: 'result.formulaFlesch',
        orderBy_value: 'flesch'
      })
      defaultFilters.push({
        id: 'Flesch_from',
        name: 'Flesch-higher-than',
        type: 'number',
        searchField: 'result.formulaFlesch',
        searchTemplate: '>={value}',
        source: '',
        label: t('Flesch-higher-than')
      })
      defaultFilters.push({
        id: 'Flesch_to',
        name: 'Flesch-lower-than',
        type: 'number',
        searchField: 'result.formulaFlesch',
        searchTemplate: '<={value}',
        source: '',
        label: t('Flesch-lower-than')
      })
      break
    case 'de_DE':
    default:
      defaultColumns.splice(-2, 0, {
        id: 'formulaHix',
        type: 'numeric',
        disablePadding: false,
        label: `${t('hix')}`,
        filter_value: 'result.formulaHix',
        orderBy_value: 'hix'
      })
      defaultFilters.push({
        id: 'HIX_from',
        name: 'HIX-higher-than',
        type: 'number',
        searchField: 'result.formulaHix',
        searchTemplate: '>={value}',
        source: '',
        label: t('HIX-higher-than')
      })
      defaultFilters.push({
        id: 'HIX_to',
        name: 'HIX-lower-than',
        type: 'number',
        searchField: 'result.formulaHix',
        searchTemplate: '<={value}',
        source: '',
        label: t('HIX-lower-than')
      })
      break
  }

  const [spinner, setSpinner] = useState(false)

  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, defaultFilters, disabledArchiveColumns, defaultColumns, 'desc', 'update_time')
  }, [archiveLanguage])

  const runRequest = async (limit, requestLimits, requestFilters) => {
    // make a request
    try {
      const res = await tlService.getFilteredArchive(token, requestLimits, requestFilters)
      if (res?.data?.length) {
        const currentData = res.data.map(d => {
          return { ...d, opened: false, versions: [] }
        })
        aTableSetData(currentData)
        aTableSetOtherData({ archives_fullcount: res.archives_fullcount, versions_fullcount: res.versions_fullcount })
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.archives_fullcount / limit))
        }
      }
      return true
    } catch (e) {
      console.log(e)
    }
    aTableSetData([])
    aTableSetOtherData({})
    aTableSetRowCount(0)
  }

  const prepareRequest = (locale_name, limit, order, orderBy, currentPage, filters, return_values = '["id"]', exact_result = 2, by_last = 0) => {
    const requestLimits = {
      limit,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy,
      offset: limit * (currentPage - 1)
    }
    //filters
    let extendedFilter = []
    for (const fil of Object.values(filters)) {
      if (fil !== undefined && !isObjectEmpty(fil.filter)) {
        extendedFilter = [...extendedFilter, ...fil.filter]
      }
    }
    const requestFilters = {
      locale_name,
      return_values,
      exact_result,
      by_last,
      extended_filter: JSON.stringify(extendedFilter)
    }
    return { requestLimits, requestFilters }
  }

  const makeRequest = async (locale_name, limit, order, orderBy, currentPage, filters, return_values = '["id"]', exact_result = 2, by_last = 0) => {
    const {
      requestLimits,
      requestFilters
    } = prepareRequest(locale_name, limit, order, orderBy, currentPage, filters, return_values, exact_result, by_last)
    await runRequest(limit, requestLimits, requestFilters)
  }

  const getAllIds = async (locale_name, fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest(locale_name, limit, order, orderBy, currentPage, filters, '["id"]', 2, 0)
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredArchive(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        }
      } catch (e) {
        console.log(e)
        return []
      }
    }
    return ids
  }

  function reloadAll (locale_name, limit, order, orderBy, currentPage, filters, setSpinner) {
    if (!isObjectEmpty(filters)) {
      const returnValues = JSON.stringify(
        ['id', 'archive_name', 'analyse_id', 'benchmark_id', 'owner', 'update_time', 'create_time', 'version',
          'result.formulaHix', 'result.formulaCLIX', 'result.formulaSPIX', 'result.formulaFlesch', 'benchmark_settings_hash', 'tag_name'])
      setSpinner(true)
      makeRequest(locale_name, limit, order, orderBy, currentPage, filters, returnValues).then(() => setSpinner(false))
    }
  }

  // filter if change
  useEffect(() => {
    reloadAll(convertLanguagesToFull(archiveLanguage), limit, order, orderBy, currentPage, filters, setSpinner)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, archiveLanguage])

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = otherData?.archives_fullcount
    if (!selectAll) {
      const allIds = await getAllIds(convertLanguagesToFull(archiveLanguage), fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  function handleOnChangeLanguage (e) {
    actionUserSetArchiveLanguage(e.target.value)
    aArchiveSetFolderForceReload(true)
  }

// <UniversalTable/>
  return (
    <div className={`${style.mainCont}`}>
      <Paper className={`mb-5`}>
        {spinner && <Spinner/>}
        <div style={{ float: 'right', marginBottom: '10px' }}>
          <Select
            onChange={handleOnChangeLanguage}
            labelId="language-select-label"
            id="language-select"
            defaultValue={archiveLanguage}
            style={{ borderRadius: 18, width: '220px' }}
            variant="outlined"
            className={`p-0`}
          >
            {
              Object.entries(backendLanguages).map(([key, val]) =>
                <MenuItem key={key} value={key}>{val['nativeName']}</MenuItem>
              )
            }
          </Select>
        </div>
        <UniversalTable
          defaultColumns={defaultColumns}
          reloadAll={reloadAll}
          handleSelectAll={handleSelectAll}
          getAllIds={getAllIds}
          noDataText={t('no-data-archive')}
          selectAllText={t('select-all-pages')}
          MassActionComponent={TableMassActionComponent}
          InnerComponent={TableInnerComponent}
          extraSettings={extraSettings}
          style={style}
          enableExtraFilters
        />
      </Paper>
    </div>
  )
}