import { convertArrayToText, convertFromErrorObject, convertLanguagesToShort, isObjectEmpty } from '../../../../utils'
import * as XLSX from 'xlsx'
import config from '../../../../config'

export const prepareUploadFile = (e, setUploadFile) => {
  setUploadFile(e.target.files[0])
}

export const prepareForCompare = (value) => {
  if (typeof value === 'object') {
    try {
      return JSON.stringify(value)
    } catch (e) {
      return ''
    }
  }
  return value
}

const putXLSX = (dataResult, filename) => {
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(dataResult), 'Sheet1')
  const element = document.createElement('a')
  const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  element.href = URL.createObjectURL(new Blob([bin], { type: 'application/vnd.ms-excel' }))
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const filterToSimple = (dataResult, checkWords, localeName) => {
  const simpleResult = []
  const termHeaders = config.termHeaders
  for (const res of dataResult) {
    if (!res['words'] || !res['lemma'])
      continue
    try {
      let replacement = ''
      if (!isObjectEmpty(res['term_replacement'])) {
        for (const rep of res['term_replacement']) {
          if (!isObjectEmpty(rep['words'])) {
            replacement += convertArrayToText(rep['words'], convertLanguagesToShort(localeName)) + '|'
          }
        }
      }
      replacement = replacement.replace(/\|$/, '')
      const words = checkWords ? convertArrayToText(JSON.parse(res['words']), convertLanguagesToShort(localeName)) : convertArrayToText(JSON.parse(res['lemma']), convertLanguagesToShort(localeName))

      simpleResult.push({
        [termHeaders['words'][localeName]]: words,
        [termHeaders['description'][localeName]]: res['description'] || '',
        [termHeaders['replacements'][localeName]]: replacement
      })
    } catch (e) {
      console.log(e)
    }
  }
  return simpleResult
}

const putSimpleXLSX = (dataResult, filename, checkWords, localeName) => {

  const simpleResult = filterToSimple(dataResult, checkWords, localeName)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(simpleResult), 'Sheet1')
  const element = document.createElement('a')
  const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  element.href = URL.createObjectURL(new Blob([bin], { type: 'application/vnd.ms-excel' }))
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()

}

const putSimpleCSV = (dataResult, filename, checkWords, localeName) => {
  const termHeaders = config.termHeaders
  const columns = [termHeaders['words'][localeName], termHeaders['description'][localeName], termHeaders['replacements'][localeName]]

  const simpleResult = filterToSimple(dataResult, checkWords, localeName)

  let stringResult = columns.join(',') + '\n'
  for (const res of simpleResult) {
    let tmpString = ''
    for (const col of columns) {
      tmpString += res[col] !== undefined ? '"' + res[col].toString().replaceAll('"', '""') + '",' : '"",'
    }
    stringResult += tmpString.substring(0, tmpString.length - 1) + '\n'
  }

  const blob = new Blob(['\ufeff' + stringResult.substring(0, stringResult.length - 1)], { type: 'text/csv' })
  const element = document.createElement('a')
  element.href = URL.createObjectURL(blob)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const putCSV = (dataResult, filename) => {
  const columns = ['id', 'check_words', 'description', 'enabled', 'global_visible', 'lemma', 'settings', 'tag', 'wordcount', 'words',
    'replacement_description', 'replacement_global_visible', 'replacement_id', 'replacement_lemma',
    'replacement_settings', 'replacement_tag', 'replacement_wordcount', 'replacement_words']

  let stringResult = columns.join(',') + '\n'
  for (const res of dataResult) {
    let tmpString = ''
    for (const col of columns) {
      tmpString += res[col] !== undefined ? '"' + res[col].toString().replaceAll('"', '""') + '",' : '"",'
    }
    stringResult += tmpString.substring(0, tmpString.length - 1) + '\n'
  }

  const blob = new Blob(['\ufeff' + stringResult.substring(0, stringResult.length - 1)], { type: 'text/csv' })
  const element = document.createElement('a')
  element.href = URL.createObjectURL(blob)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const downloadTerms = async (tlService, token, category_id, setDownloadBarVisible, setDownloadBarNow, downloadFormat, checkWords, localeName) => {
  let limit = 1000
  let offset = -limit
  let fullcount = 0
  let dataResult = []
  setDownloadBarVisible(true)
  do {
    offset += limit
    try{
    const categoryData = await tlService.getFilteredTermByCategory(token,
      { limit, offset },
      { category_id: category_id })
    if (categoryData['data'] && categoryData['data'].length > 0) {
      fullcount = categoryData['fullcount']
      // convert data

      for (const dataTmp of categoryData['data']) {
        let returnVal = {}
        for (const [key, value] of Object.entries(dataTmp)) {
          if (key === 'lemma' && value.length > 0) {
            returnVal['lemma'] = JSON.stringify(value)
          } else if (key === 'words' && value.length > 0) {
            returnVal['words'] = JSON.stringify(value)
          } else if (key === 'tag' && value.length > 0) {
            returnVal['tag'] = JSON.stringify(value)
          } else if (key === 'settings' && !isObjectEmpty(value)) {
            returnVal['settings'] = JSON.stringify(value)
          } else if (!isObjectEmpty(value)) {
            returnVal = { ...returnVal, [key]: value }
          }
        }
        dataResult.push(returnVal)

        // add replacements
        if (dataTmp['term_replacement'] && dataTmp['term_replacement'].length > 0) {
          let returnValReplacement = {}
          for (let dataReplacement of dataTmp['term_replacement']) {
            for (const [key, value] of Object.entries(dataReplacement)) {
              if (key === 'lemma' && value.length > 0) {
                returnValReplacement['replacement_lemma'] = JSON.stringify(value)
              } else if (key === 'words' && value.length > 0) {
                returnValReplacement['replacement_words'] = JSON.stringify(value)
              } else if (key === 'tag' && value.length > 0) {
                returnValReplacement['replacement_tag'] = JSON.stringify(value)
              } else if (key === 'settings' && !isObjectEmpty(value)) {
                returnValReplacement['replacement_settings'] = JSON.stringify(value)
              } else {
                if (key !== 'id' && !isObjectEmpty(value))
                  returnValReplacement = { ...returnValReplacement, ['replacement_' + key]: value }
              }
            }
            dataResult.push(returnValReplacement)
          }

        }

      }
      setDownloadBarNow(Math.round((offset + limit) / (fullcount / 100)))
    } else {
      break
    }
  } catch (e) {
    console.log(e)
    break
  }
  } while (fullcount > (offset + limit))

  setDownloadBarVisible(false)

  if (downloadFormat === 'CSV')
    putCSV(dataResult, `downloadTerm${category_id}.csv`)
  else if (downloadFormat === 'XLSX')
    putXLSX(dataResult, `downloadTerm${category_id}.xlsx`)
  else if (downloadFormat === 'SimpleXLSX')
    putSimpleXLSX(dataResult, `simpleDownloadTerm${category_id}.xlsx`, checkWords, localeName)
  else if (downloadFormat === 'SimpleCSV')
    putSimpleCSV(dataResult, `simpleDownloadTerm${category_id}.csv`, checkWords, localeName)
}

export const clearCache = async (token, category_id, tlService) => {
  await tlService.clearAllCache(token, 1, category_id === undefined ? undefined : parseInt(category_id))
}

export const calculateSize = async (token, category_id, tlService) => {
  await tlService.calculateCategory(token, parseInt(category_id))
}
// calculateAllSizes
export const calculateAllSizes = async (token, tlService, setDisableButtons) => {
  setDisableButtons(true)
  try {
    const ret = await tlService.getFilteredTermCategories(token)
    for (const cat of ret.data) {
      await calculateSize(token, cat['id'], tlService)
    }
  } catch (e) {
    console.log(e.toString())
  }
  setDisableButtons(false)
}

export const clearAllCaches = async (token, tlService, setDisableButtons) => {
  setDisableButtons(true)
  await clearCache(token, undefined, tlService)
  setDisableButtons(false)
}

export const runBunchUpload = async (tlService, token, categoryID, fullCounter, sendJSON, setUploadBarText, t, updateAlert) => {
  let resTermBunch
  try {
    resTermBunch = await tlService.termBunch(token, categoryID, { line: fullCounter, data: sendJSON })
  } catch (err) {
    updateAlert(convertFromErrorObject(t, err))
    return false
  }
  if (resTermBunch?.data?.length) {
    for (let err of resTermBunch.data) {
      setUploadBarText(s => s + convertFromErrorObject(t, err, {}, false) + '</br>')
    }
  }
  return true
}

export const clrModal = (setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t) => {
  setUploadBarNow(0)
  setUploadBarVisible(false)
  setDisableUpload(false)
  setDisableButton(true)
  setUploadFile(null)
  setFullCounterText('')
  setUploadText(t('Data Upload'))
}

