import style from "./style.module.sass"
import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import closeIcon from "../../../assets/close-circle.svg";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TLServiceComponent from "../../../contexts/TLServiceComponent";
import { Collapse, TextField } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import config from '../../../config'
import { uploadAsFile } from '../../../utils'

const ExportIssueDetails = ({ setOpen, modalState, text }) => {
    const { theme: { bgColors } } = useTheme();
    const { t } = useTranslation();
    const tlService = React.useContext(TLServiceComponent);
    const token = useSelector(state => state.userData.token);
    const analyseResult = useSelector(state => state.analyseData.result);
    const [comments, setComments] = useState('')
    const [errorState, setErrorState] = useState()
    const enabledTemplate = useSelector(state => state.userData.user.enabled_templates);

    const wordExport = async () => {
        let b;
        let res;
        if (comments.length) {
            try {
                res = await tlService.benchmarkExportData(token, String(comments), enabledTemplate["export_template_single"], JSON.stringify(analyseResult))
            } catch (e) {
                console.log('error', e)
            }
            if (res?.resultfile?.length) {
                b = Buffer.from(res.resultfile, 'base64').toString('utf-8');
                uploadAsFile(t('issue-details'), b.toString(), 'docx')
            }
            setComments('')
            setOpen('')
        } else {
            setErrorState(`${t('comments-required')}`)
            setTimeout(() => {
                setErrorState('')
            }, config.timeOutDuration)
        }
    }

    return (
        <Modal open={modalState} onClose={() => setOpen('')}>
            <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
                <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
                    style={{ backgroundColor: bgColors.bgPrimary400, marginBottom: "10px" }}>
                    <p className={`m-0 ${style.title}`}>{text}</p>
                    <button className={`border-0 bg-transparent`} onClick={() => setOpen('')}>
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <h6
                        style={{ marginLeft: "5%" }}
                    > {t("comment")} : </h6>
                    <br />
                    <TextField
                        onChange={e => setComments(e.target.value)}
                        style={{ width: "90%", marginLeft: "5%" }}
                        id="outlined-multiline-static"
                        label={t("comment")}
                        multiline
                        rows={10}
                        fullWidth
                        variant="outlined"
                        value={comments}
                    />
                </div>
                <div className={`d-flex justify-content-center`}>
                    <Button color='primary' variant='contained'
                        onClick={wordExport}
                    >
                        {t("Export archive button")}
                    </Button>
                </div>
                <br />
                <div className={style.error}>
                    <Collapse in={!!errorState}>
                        <Alert
                            className={`m-0`}
                            severity='error'>
                            <p className={`m-0`}>{errorState}</p>
                        </Alert>
                    </Collapse>
                </div>
            </div>
        </Modal>
    )
}

export default ExportIssueDetails;