import updateUserData from './user'
import updateOpenData from './open'
import updateAdminData from './admin'
import updateModalData from './modal'
import updateAnalyseData from './analyse'
import updateBatchData from './batch'
import updateArchiveData from './archive'
import updateDashboardData from './dashboard'
import updateTableData from './table'

const reducer = (state, action) => {
  if (action.type === 'CLEAR_ALL') {
    return {
      dashboardData: updateDashboardData(undefined, action),
      userData: updateUserData(undefined, action),
      openData: updateOpenData(undefined, action),
      adminData: updateAdminData(undefined, action),
      modalData: updateModalData(undefined, action),
      analyseData: updateAnalyseData(undefined, action),
      batchData: updateBatchData(undefined, action),
      archiveData: updateArchiveData(undefined, action),
      tableData: updateTableData(undefined, action)
    }
  } else if (action.type === 'USER_LOGOUT_CLEAR') {
    return {
      dashboardData: updateDashboardData(undefined, action),
      userData: updateUserData(undefined, action),
      adminData: updateAdminData(undefined, action),
      modalData: updateModalData(undefined, action),
      analyseData: updateAnalyseData(undefined, action),
      batchData: updateBatchData(undefined, action),
      archiveData: updateArchiveData(undefined, action),
      tableData: updateTableData(undefined, action),
      openData: state.openData
    }
  }
  return {
    dashboardData: updateDashboardData(state, action),
    userData: updateUserData(state, action),
    openData: updateOpenData(state, action),
    adminData: updateAdminData(state, action),
    modalData: updateModalData(state, action),
    analyseData: updateAnalyseData(state, action),
    batchData: updateBatchData(state, action),
    archiveData: updateArchiveData(state, action),
    tableData: updateTableData(state, action)
  }
}

export default reducer
