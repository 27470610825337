import MainPageWrapper from "../../components/Main-page-wrapper";
import VocabularyComp from "../../components/Dashboard/Vocabulary";

export default function Vocabulary(){

  return (
    <MainPageWrapper>
      <VocabularyComp/>
    </MainPageWrapper>
  )
}