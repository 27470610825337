import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTranslation } from 'react-i18next'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { Button, IconButton, Modal } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { ArrowRight, Close } from '@material-ui/icons'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useActions } from '../../../utils/action-helper'
import {
  dashboardClearAll,
  dashboardSetFilter,
  dashboardSetIdList,
  dashboardSetLanguage,
  dashboardSetType, tableSetDialog, tableSetOtherData
} from '../../../actions'



export default function ExportExcelModal ({
  exportExcelModal,
  setExportExcelModal,
  handleStartExport
}) {
  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)
  const { theme: { bgColors, colors, borderColors } } = useTheme()

  const {
     aTableSetOtherData
  } = useActions({
    aDashboardClearAll: dashboardClearAll,
    aDashboardSetFilter: dashboardSetFilter,
    aDashboardSetType:dashboardSetType,
    aDashboardSetLanguage:dashboardSetLanguage,
    aDashboardSetIdList:dashboardSetIdList,
    aTableSetDialog:tableSetDialog,
    aTableSetOtherData:tableSetOtherData
  })

  const otherData = useSelector(state => state.tableData.otherData)

  const exportList =  otherData.exportList

  const handleSelectRoot = (inx) =>{
    const tOtherData = {...otherData}
    const selected = tOtherData.exportList[inx].selected
    tOtherData.exportList[inx].selected = !selected
    tOtherData.exportList[inx].children =
      tOtherData.exportList[inx].children.map(v=>{return {...v, selected: !selected}})
    aTableSetOtherData(tOtherData)
  }

  const handleSelectChild = (rootInx, childInx) =>{
    const tOtherData = {...otherData}
    tOtherData.exportList[rootInx].children[childInx].selected = !tOtherData.exportList[rootInx].children[childInx].selected
    aTableSetOtherData(tOtherData)
  }

  return (
    <Modal open={exportExcelModal} onClose={() => setExportExcelModal(false)}
           className={`d-flex align-items-center justify-content-center`} >
      <div className={style.container}>
        <div className={style.exportModal}>
          <div className={`${style.exportModal__head} d-flex align-items-center justify-content-between`}>
            <p className='m-0'>{t('excel-export')}</p>
            <IconButton onClick={() => { setExportExcelModal(false) }}>
              <Close />
            </IconButton>
          </div>
          <p className={`m-0 ${style.exportModal__subtitle}`}>{t('choose-criteria')}</p>
          <div className={'m-0 pl-5'}>
            {exportList && exportList.map((v, rootInx) => <div className={'m-0 pb-3 block'} key={rootInx}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={'m-0 p-0'}
                    checked={v.selected}
                    id={v.name}
                    onChange={e => handleSelectRoot(rootInx)}
                    color="primary"
                  />}
                label={<div className={'p-0 m-0'} style={{fontSize:'14px'}}>
                  {t(v.translation)}
                </div>}
              />
              <div className={'pl-3 m-0'}>
                {v.children.map( (child, childInx) => <div key={childInx} className={'p-0 m-0'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={'m-0 p-0'}
                        checked={child.selected}
                        id={child.name}
                        onChange={e => handleSelectChild(rootInx, childInx)}
                        color="primary"
                      />}
                    label={<div className={'p-0 m-0'}  style={{fontSize:'14px'}}>
                      {child.useTranslation ?  t(child.translation) : child.translation}
                     </div>}
                  />
                </div>)}
              </div>
            </div>)}

          </div>

        </div>
        <div className={style.exportBtn}>
          <Button onClick={() => handleStartExport()} color='primary' variant='contained'>{t('export')}</Button>
        </div>
      </div>
    </Modal>

    )
}