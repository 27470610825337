import React, {useState} from 'react';
import style from "./style.module.sass";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from "@material-ui/core/Checkbox";
import {useTheme} from '@material-ui/core/styles';

function Rows({row, isItemSelected, labelId, handleClick, isEven}) {
  const {theme: {bgColors, colors}} = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <TableRow
        style={{backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100}}
        hover
        role="checkbox"
        className={`${style.row}`}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{'aria-labelledby': labelId}}
          />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={style.cell}
                   style={{maxWidth: "248px", color: colors.colorStatusInfo, cursor: "pointer"}} component="th"
                   id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell style={{width: "150px"}} className={style.cell} align="center">
          {row.userId}
        </TableCell>
        <TableCell style={{width: "200px"}} className={style.cell} align="left">{row.userName}</TableCell>
        <TableCell style={{width: "250px"}} className={style.cell} align="left">
          {row.logType}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.data.map((d, i) => <div key={i}>{d}</div>)}
        </TableCell>
        <TableCell className={style.cell} align="left">{row.date}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Rows;
