import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Select as SelectField } from '@material-ui/core';
import { useTranslation } from "react-i18next";

function Select({ label, options, value, disabled, handleChange, ...rest }) {
  const { t } = useTranslation();

  return (
    <FormControl id="name-label" fullWidth variant="outlined" disabled={disabled} {...rest}>
      <InputLabel id="name-label">
        {label}
      </InputLabel>
      <SelectField
        labelId="name-label"
        id="name"
        fullWidth
        value={value}
        onChange={value => handleChange(value)}
        labelWidth={label.length * 11}
      >
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {label === t('category_type') ? t(o.name) : (o.name)}
          </MenuItem>
        ))}
      </SelectField>
    </FormControl>
  )
}

export default Select;
