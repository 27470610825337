import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Paper } from '@material-ui/core'
import TLServiceComponent from "../../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { checkUserRight, convertJSONToObject, isObjectEmpty, onlyUnique } from "../../../../../utils"
import { useActions } from "../../../../../utils/action-helper"
import {
  tableSetData, tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount, tableSetSelectAll, tableSetSelectAllIds
} from "../../../../../actions"
import Spinner from "../../../../Spinner"
import UniversalTable from "../../../../common/UniversalTable"
import TableMassActionComponent from "./TableMassActionComponent/TableMassActionComponent"
import TableInnerComponent from "./TableInnerComponent/TableInnerComponent"
import style from './style.module.sass'
import { Redirect } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { Add } from "@material-ui/icons"

export default function Table() {

  const { t } = useTranslation()
  const tlService = useContext(TLServiceComponent)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const token = useSelector(state => state.userData.token)

  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order || 'asc')
  const orderBy = useSelector(state => state.tableData.orderBy || 0)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const rowCount = useSelector(state => state.tableData.rowCount)
  const selectedCompany = useSelector(state => state.tableData.companyId)
  const selectedGroup = useSelector(state => state.tableData.groupId)
  const user = useSelector(state => state.userData.user)
  const textDisabledColumns = useSelector(state => state.userData.user?.user_options?.disabled_groups_columns || '[]')
  const disabledColumns = convertJSONToObject(textDisabledColumns, [])

  const {
    aTableSetDefaults,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
  })

  const [spinner, setSpinner] = useState(false)

  const defaultPagination = [
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_group_group-name')}`,
      filter_value: 'name',
      orderBy_value: 'name'
    },
    {
      id: 'company_name',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_group_company-name')}`,
      filter_value: 'company_name',
      orderBy_value: 'company_name'
    },
    {
      id: 'update_time',
      type: 'date',
      disablePadding: false,
      label: `${t('admin_group_update-time')}`,
      filter_value: 'update_time',
      orderBy_value: 'update_time'
    }
  ]

  const extraSettings = {
    language: archiveLanguage,
    fullSearchFilter: [ ['|name', 'lc=%{value}%']],
  }

  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, [], disabledColumns, defaultColumns, 'asc', 'id')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveLanguage])

  
  const runRequest = async (limit, requestLimits, requestFilters) => {
    // make a request
    try {
      const res = await tlService.getFilteredGroups(token,requestLimits, requestFilters)
      if (res?.data?.length) {
        const currentData = res.data.map(d => {
          return { ...d, frontend_options : !isObjectEmpty(d.user_group_options) ? d.user_group_options.enabled_frontend_sections : [], opened: false } })
        aTableSetData(currentData)
        aTableSetOtherData({ groups_fullcount: res.fullcount})
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.fullcount / limit))
        }
      }
      return true
    } catch (e) {
      console.log(e)
    }
    aTableSetData([])
    aTableSetOtherData({})
    aTableSetRowCount(0)
  }

  const prepareRequest = ( limit, order, orderBy, currentPage, filters) => {
    const requestLimits = {
      limit,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy ? orderBy : 'id',
      offset: limit * (currentPage - 1)
    }
    //filters
    let extendedFilter = []
    for (const fil of Object.values(filters)) {
      if (fil !== undefined && !isObjectEmpty(fil.filter)) {
        extendedFilter = [...extendedFilter, ...fil.filter]
      }
    }
    const requestFilters = {
      extended_filter: JSON.stringify(extendedFilter)
    }
    return { requestLimits, requestFilters }
  }


  const makeRequest = async ( limit, order, orderBy, currentPage, filters) => {
    const {
      requestLimits,
      requestFilters,
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    await runRequest(limit, requestLimits, requestFilters)
  }

  const getAllIds = async ( fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredGroups(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  
    return ids.filter(onlyUnique) // remove duplicates
  }

  function reloadAll( limit, order, orderBy, currentPage, filters, setSpinner) {
    setSpinner(true)
    makeRequest( limit, order, orderBy, currentPage, filters).then(() => setSpinner(false))
  }

  // filter if change
  useEffect(() => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, selectedCompany,selectedGroup])

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = rowCount || 0
    if (!selectAll) {
      const allIds = await getAllIds(fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  if (!checkUserRight(user, [206]))
    return <Redirect to={"/"} />

  return (
      <div className={`${style.mainCont}`}>
        <Paper className={`mb-5`}>
          {spinner && <Spinner />}
        <div style={{ float: 'right', marginBottom: '10px', marginRight: '8px' }}>
          {checkUserRight(user, [207]) &&
            <NavLink to={`/administration/group/update/add-new-group`} exact={true}>
              <Button variant="contained"
                style={{ fontSize: "13px", whiteSpace: "nowrap" }}
                color="primary"
                startIcon={<Add />}>
                {t('admin_user_add-new-group')}
              </Button>
            </NavLink>
          }
        </div>
          <UniversalTable 
            defaultColumns={defaultColumns}
            reloadAll={reloadAll}
            handleSelectAll={handleSelectAll}
            getAllIds={getAllIds}
            noDataText={t('admin_group_no-data')}
            selectAllText={t('admin_group_select-all-groups')}
            MassActionComponent={TableMassActionComponent}
            InnerComponent={TableInnerComponent}
            extraSettings={extraSettings}
            style={style}
            type='groups'
          />
        </Paper>
      </div>
  )
}