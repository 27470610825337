import React, { useMemo } from "react";
import style from "./style.module.scss"
import DashHeader from "../Header";
import SentenceConst from "./SentenceConst";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import config from "../../../config";
import { setSeries } from "../utils";
import { useTheme } from "@material-ui/core/styles";
import {
    convertLanguagesToShort,
    getScale, getValue,
    isObjectEmpty,
    chooseColor,
    roundWithPrecision,
    checkClixNotZero
} from "../../../utils";

export default function CLIXComponentCompare() {
    const { t, i18n: { language: i18nLanguage } } = useTranslation();
    const { theme: { colors } } = useTheme();
    const resultList1 = useSelector(state => state.dashboardData.resultList[0]?.results || []);
    const resultList2 = useSelector(state => state.dashboardData.resultList[1]?.results || []);
    const dataList1 = useSelector(state => state.dashboardData.resultList[0]?.data);
    const dataList2 = useSelector(state => state.dashboardData.resultList[1]?.data);
    const idList0 = useSelector(state => state.dashboardData.idList[0] || []);
    const idList1 = useSelector(state => state.dashboardData.idList[1] || []);
    const text1 = t('number of results {{num}}', { num: idList0?.length });
    const text2 = t('number of results {{num}}', { num: idList1?.length });
    const benchmarks = useSelector(state => state.userData.benchmarks);
    const benchmarkId1 = useSelector(state => state.dashboardData?.resultList[0]?.results?.benchmarkId);
    const benchmarkId2 = useSelector(state => state.dashboardData?.resultList[1]?.results?.benchmarkId);
    const categoryNames1 = useSelector(state => state.dashboardData.resultList[0]?.results?.categoryNames || []);
    const categoryNames2 = useSelector(state => state.dashboardData.resultList[1]?.results?.categoryNames || []);

    let currentBenchmarkSettings1;
    let currentBenchmarkSettings2;
    // First benchmark for comparison dashboard analysis:
    !!benchmarkId1 && Object.keys(benchmarkId1).map(el => {
        currentBenchmarkSettings1 = (benchmarks[el]);
    })
    // Second benchmark for comparison dashboard analysis:
    !!benchmarkId2 && Object.keys(benchmarkId2).map(el => {
        currentBenchmarkSettings2 = (benchmarks[el]);
    })
    /*** array2maxlist.moreWordsInLetters ***/
    let clixTableCells1 = [];
    if (!isObjectEmpty(resultList1[`array2maxlist.moreWordsInLetters`])) {
        clixTableCells1 = Object.entries(resultList1[`array2maxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length);
    }
    let clixTableCells2 = [];
    if (!isObjectEmpty(resultList2[`array2maxlist.moreWordsInLetters`])) {
        clixTableCells2 = Object.entries(resultList2[`array2maxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length);
    }

    const setResult = (resultList) => {
        const ret = [];
        // get the benchId
        const benchSortedPair = Object.entries(resultList["benchmarkId"]).sort((a, b) => b[1] - a[1]);
        let benchmarkObj;

        for (let bh of benchSortedPair) {
            if (!isObjectEmpty(benchmarks[parseInt(bh[0])])) {
                benchmarkObj = benchmarks[parseInt(bh[0])];
            }
        }
        for (const val of config.compareShowValuesCLIX?.length ? config.compareShowValuesCLIX : config.compareShowValues) {
            // if (!isObjectEmpty(resultList[`${val.prefix}.${val.name}`])) {
            // value exist
            const counterBase = config.fullCountBase[val.name];
            const countNumber = !!resultList[`${val.type}sum.${val.name}`] ? resultList[`${val.type}sum.${val.name}`] : 0
            const localTarget = benchmarkObj?.settings[val.name]?.values?.target || -100;
            const localTargetYellowGreen = benchmarkObj?.settings[val.name]?.values?.yellowGreen || -100;
            const localTargetYellowRed = benchmarkObj?.settings[val.name]?.values?.yellowRed || -100;
            const localPercent = countNumber / (resultList[`sum.${counterBase}`] / 100);
            const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
            // push to ret
            ret.push({
                value: roundWithPrecision(localPercent, 0),
                color: config.compareThemeColors[localColor],
                text: [resultList[`${val.prefix}text.${val.name}`]],
                title: t(val.title),
                subTitle: t(val.subTitle),
                lCount: countNumber,
                lCountMax: resultList[`sum.${counterBase}`] || 0,
                lText: [t(val.lText, { number: localTarget })],
                lTitle: t(val.lTitle),
                label: "",
                withLinear: false,
                textsComp: false
            })
            // }
        }
        return ret;
    }

    const seriesCLIX1 = useMemo(() => setSeries('formulaCLIX', dataList1, benchmarks, colors), [dataList1, benchmarks]);
    const seriesCLIX2 = useMemo(() => setSeries('formulaCLIX', dataList2, benchmarks, colors), [dataList2, benchmarks]);
    const sets = [{}, {}];
    if (!isObjectEmpty(resultList1)) {
        sets[0].benchmarkWarning = (Object.keys(resultList1["benchmarkId"]).length > 1);
        sets[0].counter = setResult(resultList1);
    }
    if (!isObjectEmpty(resultList2)) {
        sets[1].benchmarkWarning = (Object.keys(resultList2["benchmarkId"]).length > 1);
        sets[1].counter = setResult(resultList2);
    }

    if (isObjectEmpty(sets[0]) && isObjectEmpty(sets[1]))
        return <Redirect to={"/"} />;

    let clixList1;
    let clixList2;
    let heading1 = [];
    let heading2 = [];
    let corporateList = [];
    let termList = [];

    const clixDisplay = (currentBenchmarkSettings, resultList, categoryNames, heading) => {
        let clixListIs = Object.keys(currentBenchmarkSettings?.settings).reduce((acc, val, index) => {
            // if clix parameter is more than 0
            if (currentBenchmarkSettings?.settings[val].values?.clix !== 0 && !!currentBenchmarkSettings?.settings[val].values?.clix) {
                // define needed values
                let scalePercent = 0;
                let targetPercent = 0;
                let localPercent = 0;
                let name = '';
                let color = 'green';
                let localTarget = 0;
                let countNumber = 0;
                let section = "";
                const countWords = !!resultList["sum.countWords"] ? resultList["sum.countWords"] : 0;
                if (!val.includes("list")) {
                    // not a number - value from resultList
                    name = t(val);
                    const currentSettings = currentBenchmarkSettings?.settings[val]?.values;
                    countNumber = !!resultList[`sum.${val}`] ? resultList[`sum.${val}`] : !!resultList[`arraysizesum.${val}`] ? resultList[`arraysizesum.${val}`] : 0
                    localTarget = getValue(currentSettings, "target", 50);
                    const localScaleFrom = getValue(currentSettings, "scaleFrom", 0);
                    const localScaleTo = getValue(currentSettings, "scaleTo", 100);
                    const localTargetYellowGreen = getValue(currentSettings, "yellowGreen", 50);
                    const localTargetYellowRed = getValue(currentSettings, "yellowRed", 50);
                    if (config.fullCountBase[val] === "") {
                        localPercent = countNumber / (countWords / 100);
                    } else {
                        // if fullCountBase exist use value from it, or by default use countWords
                        const fullValueCount = config.fullCountBase[val] !== undefined ? config.fullCountBase[val] : "countWords";
                        localPercent = countNumber / ((!!resultList[`sum.${fullValueCount}`] ? resultList[`sum.${fullValueCount}`] : countWords) / 100);
                    }
                    // convert real % into  scale size percent
                    const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                    targetPercent = targetPercentTmp;
                    scalePercent = scalePercentTmp;
                    color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                    section = "";
                } else if (val === 'listTerms') {
                    // if it's a number - it's  Terms
                    const termsSettings = currentBenchmarkSettings?.settings['listTerms']?.values['_list'] || {};
                    const { valuesClix, dataCorporateLanguageClix, dataTermsClix: ids } = checkClixNotZero(currentBenchmarkSettings?.settings)
                    termList = ids?.length && ids.reduce((acc, currentValue, index) => {
                        // if it's a number - it's  Terms
                        countNumber = resultList[`categorysizesum.dataTerms.${currentValue}`]
                        const localSettings = termsSettings[currentValue];
                        localTarget = getValue(localSettings, "target", 50);
                        const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
                        const localScaleTo = getValue(localSettings, "scaleTo", 100);
                        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
                        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
                        localPercent = countNumber / (countWords / 100);
                        categoryNames.map(el => {
                            if (String(el.id) === String(currentValue)) {
                                name = el.name[convertLanguagesToShort(i18nLanguage)]
                                // Add avaible name in the config to display later:
                                config.clixDisplay.map(element => {
                                    if (element.title === "Terminology") {
                                        element.subCat.push(name)
                                    }
                                })
                            }
                        })
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                        targetPercent = targetPercentTmp;
                        scalePercent = scalePercentTmp;
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                        section = "Terminology";

                        return [...acc, {
                            id: index,
                            localTarget: roundWithPrecision(localTarget, 1),
                            targetPercent,
                            scalePercent,
                            localPercent: roundWithPrecision(localPercent, 1),
                            name,
                            val: currentValue,
                            color,
                            countNumber,
                            section,
                            sort: localSettings.sort,
                        }];
                    }, [])
                } else if (val === 'listCorporateLanguageTerms') {
                    // if it's a number - it's  Terms
                    const termsSettings = currentBenchmarkSettings?.settings['listCorporateLanguageTerms']?.values['_list'] || {};
                    const { valuesClix, dataCorporateLanguageClix: ids, dataTermsClix } = checkClixNotZero(currentBenchmarkSettings?.settings)
                    // if it's a number - it's  Terms
                    corporateList = ids?.length && ids.reduce((acc, currentValue, index) => {
                        countNumber = resultList[`categorysizesum.dataCorporateLanguage.${currentValue}`]
                        const localSettings = termsSettings[currentValue];
                        localTarget = getValue(localSettings, "target", 50);
                        const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
                        const localScaleTo = getValue(localSettings, "scaleTo", 100);
                        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
                        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
                        localPercent = countNumber / (countWords / 100);
                        section = "CorporateLanguage"
                        categoryNames.map(el => {
                            if (String(el.id) === String(currentValue)) {
                                name = el.name[convertLanguagesToShort(i18nLanguage)]
                                // Add avaible name in the config to display later:
                                config.clixDisplay.map(element => {
                                    if (element.title === "CorporateLanguage") {
                                        element.subCat.push(name)
                                    }
                                })
                            }
                        })
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                        targetPercent = targetPercentTmp;
                        scalePercent = scalePercentTmp;
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                        return [...acc, {
                            id: index,
                            localTarget: roundWithPrecision(localTarget, 1),
                            targetPercent,
                            scalePercent,
                            localPercent: roundWithPrecision(localPercent, 1),
                            name,
                            val: currentValue,
                            color,
                            countNumber,
                            section,
                            sort: localSettings.sort,
                        }];
                    }, [])
                }
                return [...acc, {
                    id: index,
                    localTarget: roundWithPrecision(localTarget, 1),
                    targetPercent,
                    scalePercent,
                    localPercent: roundWithPrecision(localPercent, 1),
                    name,
                    val,
                    color,
                    countNumber,
                    section
                }];
            }
            return acc;
        }, []);
        //  remove corporate list duplicate and take only object:
        const uniqueCorporateList = (!!corporateList?.length && Array.isArray(corporateList)) && corporateList.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
                accumulator.push(current);
            }
            accumulator = accumulator.filter(el => typeof el === "object")
            return accumulator;
        }, []);
        //  remove term list duplicate and take only object:
        const uniqueTermList = (!!termList?.length && Array.isArray(termList)) && termList.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
                accumulator.push(current);
            }
            accumulator = accumulator.filter(el => typeof el === "object")
            return accumulator;
        }, []);
        if (uniqueCorporateList?.length && Array.isArray(uniqueCorporateList)) {
            clixListIs.push(...uniqueCorporateList)
        }
        if (uniqueTermList?.length && Array.isArray(uniqueTermList)) {
            clixListIs.push(...uniqueTermList)
        }
        //  // To get the available heading:
        config.clixDisplay.map((el, i) => {
            clixListIs?.length && clixListIs.map(list => {
                if (el.subCat.some(element => t(element) === list.name) && typeof list.section === "string" && list.section.length === 0) {
                    heading.push({ "heading": el.title, "item": list.name })
                } else if (typeof list.section === "string" && list.section.length !== 0) {
                    heading.push({ "heading": list.section, "item": list.name })
                }
            })
        })
        return clixListIs;
    }

    // Clix Result 1:
    if (!isObjectEmpty(currentBenchmarkSettings1)) {
        clixList1 = clixDisplay(currentBenchmarkSettings1, resultList1, categoryNames1, heading1)
    }

    // Clix Result 2:
    if (!isObjectEmpty(currentBenchmarkSettings2)) {
        clixList2 = clixDisplay(currentBenchmarkSettings2, resultList2, categoryNames2, heading2)
    }

    const double = !isObjectEmpty(sets[0].counter) && !isObjectEmpty(sets[1].counter);

    if (double) {
        return (
            <div className={style.mainCont}>
                <DashHeader name={t('CLIX_dashboard_header')} textType={false} />
                <div style={{ display: "table", width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                    <div style={{ display: "table-cell", width: "50%", textAlign: "center", paddingRight: "10px" }}>
                        <SentenceConst clixList={clixList1} heading={heading1} clixTableCells={clixTableCells1} seriesCLIX={seriesCLIX1} headText={text1} type={1} benchmarkWarning={sets[0].benchmarkWarning} collapseFile={true} counters={sets[0].counter} />
                    </div>
                    <div style={{ display: "table-cell", width: "50%", textAlign: "center", paddingLeft: "10px" }}>
                        <SentenceConst clixList={clixList2} heading={heading2} clixTableCells={clixTableCells2} seriesCLIX={seriesCLIX2} headText={text2} type={2} benchmarkWarning={sets[1].benchmarkWarning} collapseFile={true} counters={sets[1].counter} />
                    </div>
                </div>
            </div>
        )
    }

    if (!isObjectEmpty(sets[0].counter) && (isObjectEmpty(sets[1].counter))) {
        return (
            <div className={style.mainCont}>
                <DashHeader name={t('CLIX_dashboard_header')} textType={false} />
                <SentenceConst clixList={clixList1} heading={heading1} clixTableCells={clixTableCells1} seriesCLIX={seriesCLIX1} headText={text1} type={1} benchmarkWarning={sets[0].benchmarkWarning} collapseFile={true} counters={sets[0].counter} />
            </div>
        );
    }

    return (
        <div className={style.mainCont}>
            <DashHeader name={t('CLIX_dashboard_header')} textType={false} />
            <SentenceConst clixList={clixList2} heading={heading2} clixTableCells={clixTableCells2} seriesCLIX={seriesCLIX2} headText={text2} type={2} benchmarkWarning={sets[1].benchmarkWarning} collapseFile={true} counters={sets[1].counter} />
        </div>
    )
}
