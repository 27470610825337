import React from 'react'
import style from './style.module.sass'
import arrow from './assets/arrow.svg'
import MainPageWrapper from '../../Main-page-wrapper'
import { NavLink } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LockIcon from '@material-ui/icons/Lock'
import PersonIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useSelector } from 'react-redux'

function ProfileWrapper ({ children }) {
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const userGroupName = useSelector(
    (state) => state.userData.user.user_group_name
  )
  const userCompanyName = useSelector(
    (state) => state.userData.user.company_name
  )
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
  const license = useSelector(state => state.userData.user?.user_options?.expired_time || '')

  return (
    <MainPageWrapper>
      <div className={style.mainCont}>
        <NavLink to="/" exact={true}>
          <button style={{ backgroundColor: bgColors.bgPrimary0, color: colors.colorPrimary500 }}
                  className={`d-flex align-items-center ${style.breadcrumb} pl-3`}>
            <img src={arrow} className={`mr-2`} alt=""/>
            {t('Start')}
          </button>
        </NavLink>
        <div className={`${style.wrapper} d-flex mt-5`}>
          <nav className={`mr-5`}>
            <ul style={{ borderColor: borderColors.borderPrimary300 }} className={`p-0`}>
              <li style={{ cursor: 'pointer', borderColor: borderColors.borderPrimary300 }} className={`p-2`}>
                <NavLink style={{ color: colors.colorPrimary200 }}
                         activeStyle={{ color: colors.colorPrimary1000 }}
                         to="/profile" exact={true}
                         className={`d-flex align-items-center ${style.link}`}
                         activeClassName={style.activeLink}>
                  <PersonIcon className={'ml-2'}/>
                  <p className={`m-0 ml-3`}>{t('Preferences')}</p>
                </NavLink>
              </li>
              {(enabledSections.indexOf('ga_2fa_enabled') > -1 || enabledSections.indexOf('ga_2fa_disabled') > -1)  &&
                <li style={{ cursor: 'pointer', borderColor: borderColors.borderPrimary300 }} className={`p-2`}>
                  <NavLink style={{ cursor: 'pointer', color: colors.colorPrimary200 }}
                           activeStyle={{ color: colors.colorPrimary1000 }}
                           to={`/profile/2fa/ga`} exact={true}
                           className={`d-flex align-items-center ${style.link}`}
                           activeClassName={style.activeLink}>
                    <LockIcon className={'ml-2'}/>
                    <p className={`m-0 ml-3`}>{t('2FA_GA')}</p>
                  </NavLink>
                </li>
              }
              <li style={{ cursor: 'pointer', borderColor: borderColors.borderPrimary300 }} className={`p-2`}>
                <NavLink style={{ color: colors.colorPrimary200 }}
                         activeStyle={{ color: colors.colorPrimary1000 }}
                         to="/logout" exact={true} className={`d-flex align-items-center ${style.link}`}
                         activeClassName={style.activeLink}>
                  <ExitToAppIcon className={'ml-2'}/>
                  <p className={`m-0 ml-3`}>{t('Logout')}</p>
                </NavLink></li>
            </ul>
          </nav>
          <div className={`${style.content}`}>
            <div
              style={{
                color: colors.colorPrimary500,
                borderColor: borderColors.borderPrimary1000,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className={`${style.block}`}
            >
          <span className={`${style.descText}`}>
              {t('your-group')} : {userGroupName}
          </span>
              <span
                className={`${style.descText}`}>
              {t('your-company')} : {userCompanyName}{' '}
          </span>
              {license && <span className={`${style.descText}`} style={{fontWeight: 'normal'}}>
                {t('user_license_expiration-date')} : {new Date(license).toLocaleDateString(i18nLanguage)}
              </span>}
            </div>
            {children}
          </div>
        </div>
      </div>
    </MainPageWrapper>
  )
}

export default ProfileWrapper
