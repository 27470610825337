import React from "react";
import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "../withBigRow.module.sass";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export default function EnhancedTableHead({order, orderBy, onRequestSort, headTitle, headText}) {
  const {theme: {bgColors, colors}} = useTheme();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.tableHead}`}>
        <TableRow>
          {headTitle && <TableCell
            align={'left'}
            padding={'none'}
            sortDirection={orderBy === "title" ? order : false}
            className={`p-3 pl-4 pr-4`}
          >
            <TableSortLabel
              active={orderBy === "title"}
              direction={orderBy === "title" ? order : 'asc'}
              style={{flexDirection: "row"}}
              onClick={createSortHandler("title")}
              IconComponent={(props) => (
                <ArrowDownwardIcon style={{order: "9", color: colors.colorPrimary0}} alt="" {...props}/>)}
            >
              <p style={{color: colors.colorPrimary1000, width: "150px"}} className={`m-0`}>{headTitle}</p>
            </TableSortLabel>
          </TableCell>}
          {headText && <TableCell
            align={'right'}
            padding={'none'}
            sortDirection={orderBy === "text" ? order : false}
          >
            <div className={`d-flex p-3`}>
              {headText && <TableSortLabel
                active={orderBy === "text"}
                direction={orderBy === "text" ? order : 'asc'}
                style={{flexDirection: "row"}}
                onClick={createSortHandler("text")}
                IconComponent={(props) => (
                  <ArrowDownwardIcon style={{order: "9", color: colors.colorPrimary0}} alt="" {...props}/>)}
              >
                <p style={{color: colors.colorPrimary1000}} className={`m-0`}>{headText}</p>
              </TableSortLabel>}
              <div style={{margin: "0 0 0 auto"}}/>
            </div>
          </TableCell>}
          <TableCell
            align={'right'}
            padding={'none'}
          >
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}
