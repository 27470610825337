import { useTheme } from "@material-ui/styles"
import { Fragment, useContext, useEffect, useState } from "react"
import TLServiceComponent from "../../../../../contexts/TLServiceComponent"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useActions } from "../../../../../utils/action-helper"
import {
  tableSetData,
  tableSetDialog,
  tableSetSelected,
} from "../../../../../actions"
import style from './style.module.sass'
import TableContainer from '@material-ui/core/TableContainer'
import { Delete, Settings } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import TableHead from '@material-ui/core/TableHead'
import CircularProgress from '@mui/material/CircularProgress'
import { convertFromErrorObject, convertLanguagesToShort, convertedValue, isObjectEmpty } from "../../../../../utils"
import { Box, Button, Checkbox, Collapse, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import styles from '../../../../common/ArchiveManager/style.module.sass'
import ComponentWithPop from "../../../../common/ComponentWithPop"


export default function TableInnerComponent({
  row,
  index,
  isBlueRow,
  handleChangeValue
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)

  const selected = useSelector(state => state.tableData.selected)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const data = useSelector(state => state.tableData.data)
  const columns = useSelector(state => state.tableData.columns)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const token = useSelector(state => state.userData.token)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
  const interfaceLanguage = useSelector(state => state.userData.user.language_id) || i18nLanguage



  const [extraData, setExtraData] = useState({})
  const [spinner, setSpinner] = useState(false)
  const [descLang, setDescLang] = useState('de')

  const {
    aTableSetSelected,
    aTableSetData,
    aTableSetDialog
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetSelected: tableSetSelected,
    aTableSetDialog: tableSetDialog,
  })

  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)
  const [editValue, setEditValue] = useState('')
  const [editMenu, setEditMenu] = useState('')

  useEffect(() => {
    async function fetchData() {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'deleteBenchmark':
            if (dialogResponse && !isObjectEmpty(extraData)) {
              const benchmark_id = extraData.id
              try {
                await tlService.deleteBenchmark(token, benchmark_id)
                const tData = [...data]
                tData.splice(index, 1)
                aTableSetData(tData)
              } catch (error) {
                console.log('deleteBenchmark', error)
                aTableSetDialog({
                  type: 'errorDeleteV',
                  open: true,
                  RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                  response: null,
                  buttons: {
                    yes: false,
                    no: false,
                    close: true,
                    save: false
                  }
                })
              }
            }
            break
          default:
            break
        }
        setExtraData({})
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const handleSelectItem = (id) => {
    const tSelected = [...selected]
    const idx = tSelected.indexOf(id)
    if (idx > -1) {
      tSelected.splice(idx, 1)
    } else {
      tSelected.push(id)
    }
    aTableSetSelected(tSelected.sort())
  }

  const handleOpenItem = (inx) => {
    const tData = [...data]
    tData[inx]['opened'] = !row?.opened
    aTableSetData(tData)
  }

  const handleDeleteBenchmark = (row) => {
    setExtraData(row)
    aTableSetDialog({
      type: 'deleteBenchmark',
      open: true,
      RequestComponent: () => <>{t('delete_text-type')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }

  const handleEditDescription = (row, lang) => {
    setEditMenu('description')
    setEditValue(row.description[lang])
    setDescLang(lang)
  }

  const handleEditName = (row) => {
    setEditMenu('name')
    setEditValue(row.name[shortArcLan])
  }

  const handleEditBlur = () => {
    setEditValue('')
    setEditMenu('')
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (editMenu === 'name') {
      try {
        const name = {
          ...row.name, [shortArcLan]: editValue
        }
        await tlService.updateBenchmark(token, row.id, JSON.stringify(name),
          JSON.stringify(row.description), JSON.stringify(row.calcelements_setting), JSON.stringify(row.return_values), JSON.stringify(row.settings))
        const tData = [...data]
        tData[index].name = {
          ...tData[index].name, [shortArcLan]: editValue
        }
        aTableSetData(tData)
      } catch (error) {
        console.log('editBenchmarkError', error)
        aTableSetDialog({
          type: 'errorUpdateVersion',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
      }

    } else if (editMenu === 'description') {
      try {

        const description = {
          ...row.description, [descLang]: editValue
        }

        await tlService.updateBenchmark(token, row.id, JSON.stringify(row.name),
          JSON.stringify(description), JSON.stringify(row.calcelements_setting), JSON.stringify(row.return_values), JSON.stringify(row.settings))
        const tData = [...data]
        tData[index].description = {
          ...tData[index].description, [descLang]: editValue
        }
        aTableSetData(tData)
      } catch (error) {
        console.log('editBenchmarkError', error)
        aTableSetDialog({
          type: 'errorUpdateVersion',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
      }
    }
    handleEditBlur()
  }

  const EmptyCell = () => {
    return <TableCell
      onClick={() => handleOpenItem(index)}
      className={style.emptyColumns}
      component="th"
      scope="row"
      padding="none" />
  }

  /* VIEW */
  const isItemSelected = selected.includes(row.id)
  const shortArcLan = convertLanguagesToShort(archiveLanguage)
  
  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><CircularProgress /></div>

  return (
    <>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={() => handleSelectItem(row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </TableCell>
        {columns.map((col, ind) =>
          <Fragment key={ind}>
            {disabledColumns.includes(col.id) ? <EmptyCell /> :
              <>
                {(col.id === 'name' && editMenu === 'name') ?
                  <EditForm
                  editValue={editValue}
                  setEditValue={setEditValue}
                  size={30}
                  handleEditSubmit={(e) => handleEditSubmit(e)}
                  handleEditBlur={() => handleEditBlur()}
                />
                  :
                  <TableCell
                    onClick={() => handleOpenItem(index)}
                    className={style.otherColumns}
                    component="th"
                    scope="row"
                    padding="none">
                    {
                      col.id !== 'name' ?
                        convertedValue(row[col.id], col.type, i18nLanguage, t, benchmarks) :
                        row[col.id][interfaceLanguage] || row[col.id]['de'] || row[col.id]['en']
                    }
                  </TableCell>
                }
              </>
            }
          </Fragment>)
        }
        <TableCell className={`${style.cell} d-flex`} align="right">
          <div className={style.buttonCell}>
            <ComponentWithPop text={`${t('admin_texttypes_delete-btn-tooltip')}`}>
            <Button variant="contained" color="primary" size="small"
              style={{
                padding: '4px',
                minWidth: '30px',
                backgroundColor: bgColors.bgProgress20,
                color: colors.colorPrimary0
              }}
              onClick={() => handleDeleteBenchmark(row)}>
              <Delete fontSize="small" />
            </Button>
            </ComponentWithPop>
          </div>
          <div className={style.buttonCell}>
          <ComponentWithPop text={`${t('admin_texttypes_settings-btn-tooltip')}`}>
            <Button variant="contained" color="primary" size="small"
              style={{
                padding: '4px',
                minWidth: '30px',
                backgroundColor: bgColors.bgBlue1000,
                color: colors.colorPrimary0
              }} onClick={() => handleEditName(row)}>
              <Settings fontSize="small" />
            </Button>
            </ComponentWithPop>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse className={'p-1'} in={row.opened} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
              className={`mb-4 d-flex justify-content-between pt-2`}
              margin={1}>
              <div className={'d-flex text-center w-100'}>
                <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
                  <TableContainer>
                    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={style.smallTableHeader}>{t('admin_texttypes_description-title')}</TableCell>
                          <TableCell className={style.smallTableHeader}>{t('admin_texttypes_create-time')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.opened &&
                          <Fragment key={row.id}>
                            <TableRow
                              key={row.id}>
                              <TableCell
                                className={style.smallTable}>
                                    <div className="d-flex p-2 pt-0 w-100">
                                      {
                                        Object.keys(backendLanguages).map((lang, idx, arr) => {
                                          const shortLang = convertLanguagesToShort(lang)
                                          const isLastElement = idx === arr.length - 1
                                          return <div key={idx} className="d-flex pl-4" style={{
                                            borderRight: !isLastElement ? "1px solid grey" : "none",
                                            paddingRight: !isLastElement ? "10px" : "0",
                                            width: '50%'
                                          }}>
                                            {
                                              editMenu === 'description' && descLang === shortLang ? 
                                              <div className={'w-100'}>
                                                <h5 className="text-center">{t(`admin_texttypes_description-${lang}`)}</h5>
                                                <EditForm
                                              editValue={editValue}
                                              setEditValue={setEditValue}
                                              size={100}
                                              handleEditSubmit={(e) => handleEditSubmit(e)}
                                              handleEditBlur={() => handleEditBlur()}
                                              textarea={true}
                                            />
                                              </div>
                                            :
                                            <>
                                            <div className={'w-100'}>
                                              <h5 className="text-center">{t(`admin_texttypes_description-${lang}`)}</h5>
                                              {row.description[shortLang]}
                                            </div>
                                            <div className="ml-2" style={{ alignSelf: 'center', cursor: 'pointer' }}>
                                          <ComponentWithPop text={`${t('admin_texttypes_edit-desc-btn-tooltip')}`}>
                                              <EditIcon
                                                onClick={() => handleEditDescription(row, shortLang)}
                                                className={style.icons}
                                                fontSize={'medium'} />
                                          </ComponentWithPop>
                                            </div>
                                            </>
                                            }
                                          </div>
                                        }
                                        )}
                                    </div>
                              </TableCell>
                              <TableCell
                                style={{ width: '130px' }}
                                className={style.smallTable}>{convertedValue(row.create_time, 'date', i18nLanguage, t)}</TableCell>

                            </TableRow>
                          </Fragment>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
const EditForm = ({
  editValue,
  setEditValue,
  editName,
  handleEditSubmit,
  handleEditBlur,
  size = 25,
  textarea
}) => {
  const Input = textarea ? 'textarea' : 'input'
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleEditSubmit(e);
    }
  };
  return (
    <div className={styles.name}>
      <form onSubmit={handleEditSubmit}>
        <Input
          autoFocus={true}
          type="text"
          style={textarea ? { height: "100px", width: "90%" } : {}}
          size={size}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onBlur={handleEditBlur}
          placeholder={editName}
          onKeyDown={handleKeyDown}
        />

      </form>
    </div>
  )
}

