import React from "react";
import style from "./style.module.scss"
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function DashHeader({ name, benchmarkName, archiveName, dashboardName = 'dashboard', textType = true, downloadPDf }) {
  const { pathname } = useLocation();
  const { params: { type } } = useRouteMatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <div className={`${style.mainCont}`}>
      <div className={`${style.firstRow}`}>
        <div className={`d-flex align-items-center`} style={{ gridGap: "10px" }}>
          <NavLink to={'/'}>
            <Button variant="contained" color="primary" className={`${style.breadcrumbs}`}>
              <ArrowLeftIcon />
              {t('start')}
            </Button>
          </NavLink>
          {pathname !== `/dashboard/${type}` &&
            <NavLink to={`/dashboard/${type}`}>
              <Button variant="contained" color="primary" className={`${style.breadcrumbs}`}>
                <ArrowLeftIcon />
                {t(dashboardName)}
              </Button>
            </NavLink>
          }
        </div>
      </div>
      <div className={`${style.secondRow} p-2`} >
        <div className={`d-flex justify-content-between col-12 col-lg-7`} style={{ gridGap: "15px" }}>
          <h1 className={`col-12 col-lg-5 mb-2 m-md-0`}>{t("Overview INDIVIDUAL ANALYSIS")} {!!name && ( "/ " + name) }</h1>
          <div style={{ fontSize: "18px", marginRight: "60px" }}>
            <b>{!!archiveName && archiveName}</b>
          </div>
          {textType && <p className={`m-0`}><b>{t("Text Type")}: </b> {!!benchmarkName && benchmarkName}</p>}
        </div>
        {url.includes("single") ? <NavLink to={'/dashboard/single/combine'} >
          <Button variant="contained"
            color="primary" className={`${style.breadcrumbs}`}>
            <p className={`m-0 ${style.text}`} >
              {t('combine-details')}
            </p>
          </Button>
        </NavLink> : <div />}
      </div>
    </div>
  )
}
