import React from "react";
import HomeComponent from "../../components/Analysis";

function Home() {
    return (
        <HomeComponent/>
    )
}

export default Home;
