import React from "react"
import Dropzone from 'react-dropzone'
import style from "./style.module.sass"

export const Dnd = ({ onReadDropFiles, text, disabled }) => {
    return (
        <Dropzone disabled={disabled} onDrop={onReadDropFiles}>
            {({ getRootProps, getInputProps }) => (
                <section className={style.dnd}>
                    <div {...getRootProps()} className="mx-auto my-auto row" >
                        <input {...getInputProps()} />
                        <div className={style.dndText}>{text}</div>
                    </div>
                </section>
            )}
        </Dropzone>
    )
}