export const Colors = {
  colors: {
    //dark primary color variables
    colorPrimary0: "#ff0000",//000000
    colorPrimary50: "#00ffab",//E2E2E2
    colorPrimary100: "#79ff00",//b7b7b7
    colorPrimary200: "#023d02",//b2b2b2
    colorPrimary300: "#177272",//9a9797
    colorPrimary400: "#2f395b",//808080
    colorPrimary500: "#35043a",//707070
    colorPrimary600: "#588111",//#585858
    colorPrimary700: "#f7e190",//363636
    colorPrimary800: "#d660c6",//2b2b2b
    colorPrimary1000: "#704fff",//000000

    //dark status color variables
    colorStatusError: "#b003d0",
    colorStatusTrue: "#9affd0",
    colorStatusInfo: "#e92f59",

    //dark progress color variables
    colorProgress20: "#7308dd",
    colorProgress40: "#178b6c",
    colorProgress60: "#421111",
    colorProgress80: "#e56514",
    colorProgress100: "#968313",
    colorProgress120: "#947c7c",


    //dark text color variables
    colorBlue100: "#00a2ff",
    colorBlue200: "#0040ac",
    colorBlue300: "#00225d",
    colorBlue1000: "rgb(0,9,29)",
  }
};
