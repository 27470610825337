import style from "./style.module.scss";
import Chart from "react-apexcharts";
import React from "react";
import ComparisonDashDoubledChard from "../UI/ComparisonDashDoubledChard";
import { useTranslation } from "react-i18next";

export const PreLoaderCont = ({
  id,
  title,
  subTitle,
  text,
  color,
  label,
  value,
  lTitle,
  lCount,
  lText,
  lCountMax,
  withLinear,
  textsComp,
  combine
}) => {
  const chartOptions = {
    plotOptions: {
      radialBar: {
        hollow: {
          size: "65%",
        },
        track: {
          background: "#e8e8e8",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#888",
            fontSize: "20px",
            fontWeight: 800
          },
          value: {
            show: false
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: [label],
    fill: {
      opacity: 1.5,
      type: 'gradient',
      colors: [color],
      gradient: {
        gradientToColors: [color],
        stops: [0, 50, 100],
        inverseColors: false
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      }
    }
  };

  const { t } = useTranslation();

  return (
    <div className={style.preloaderCont}>
      {combine ?
        <>
          <br />
          <h3>{title}</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={`d-flex align-items-start flex-lg-row flex-md-column`} style={{ gridGap: "30px" }}>
              <div className={`${style.mainDesc} p-0 pt-4 ${(withLinear || textsComp) && `${style.withLinearCont}`} w-100`}>
                <div className={style.loader}>
                  <span style={{ position: "relative" }}>
                    <p className={style.loaderValue1} style={{
                      color: color,
                      position: "absolute",
                      top: "80px",
                      left: "150px",
                      fontSize: "50px",
                      fontWeight: "bolder",
                      transform: "translateX(-50%)"
                    }}>{value}%</p>
                    <Chart type="radialBar" series={[value]} options={chartOptions} width={300} />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className={style.lDesc}>
                <p className={style.lCount}><b style={{ color: color }}>{lCount} </b>
                  {t('from')}
                  <b> {lCountMax}</b></p>
                <b>
                  {
                    combine ?
                      <h4>
                        {lTitle}
                      </h4> :
                      <>
                        {lTitle}
                      </>
                  }
                </b>
                {/* <h2 className={style.lTitle}></h2> */}
                {lText.map((t, i) => <div key={i} dangerouslySetInnerHTML={{ "__html": t }} />)}
              </div>
              {
                withLinear &&
                <div className={`w-100`}>
                  <ComparisonDashDoubledChard seriesNames={['preloader']} chartContent={{
                    withChartTypes: false,
                    withTimeInterval: true,
                    withTotalOver: false
                  }} chartId={`HIX_PAGEs-${id}`} />
                </div>
              }
            </div>
            <div style={{ width: "40%" }}>
              <div className={`${style.desc}`}>
                <h4>{subTitle}</h4>
                {text.map((t, i) => <div style={{ overflowWrap: "anywhere" }} key={i} dangerouslySetInnerHTML={{ "__html": t }} />)}
              </div>
            </div>
          </div>
        </>
        :
        <><h3>{title}</h3>
          <div className={`d-flex align-items-start flex-lg-row flex-md-column`} style={{ gridGap: "30px" }}>
            <div className={`${style.mainDesc} p-0 pt-4 ${(withLinear || textsComp) && `${style.withLinearCont}`} w-100`}>
              <div className={style.loader}>
                <span style={{ position: "relative" }}>
                  <Chart type="radialBar" series={[value]} options={chartOptions} width={300} />
                  <p className={style.loaderValue} style={{ color: color }}>{value}%</p>
                </span>

                <div className={style.lDesc}>
                  <p className={style.lCount}><b style={{ color: color }}>{lCount} </b>
                    {t('from')}
                    <b> {lCountMax}</b></p>
                  <h2 className={style.lTitle}>{lTitle}</h2>
                  {lText.map((t, i) => <div key={i} dangerouslySetInnerHTML={{ "__html": t }} />)}
                </div>
              </div>
            </div>
            {
              withLinear &&
              <div className={`w-100`}>
                <ComparisonDashDoubledChard seriesNames={['preloader']} chartContent={{
                  withChartTypes: false,
                  withTimeInterval: true,
                  withTotalOver: false
                }} chartId={`HIX_PAGEs-${id}`} />
              </div>
            }
          </div>
          <div className={`${style.desc}`}>
            <h4>{subTitle}</h4>
            {text.map((t, i) => <div style={{ overflowWrap: "anywhere" }} key={i} dangerouslySetInnerHTML={{ "__html": t }} />)}
          </div>
        </>
      }
    </div>
  );
}
