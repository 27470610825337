export const Colors = {
  //light primary text color variables
  colors: {
    colorPrimary0: "#FFFFFF",
    colorPrimary50: "#E2E2E2",
    colorPrimary100: "#b7b7b7",
    colorPrimary200: "#b2b2b2",
    colorPrimary300: "#9a9797",
    colorPrimary400: "#808080",
    colorPrimary500: "#707070",
    colorPrimary600: "#585858",
    colorPrimary700: "#363636",
    colorPrimary800: "#2b2b2b",
    colorPrimary1000: "#000000",

    //light status text color variables
    colorStatusError: "#BF1A1A",
    colorStatusTrue: "#3EAA1B",
    colorStatusInfo: "#415C98",

    //light progress text color variables
    colorProgress20: "#DD0D08",
    colorProgress40: "#E46D19",
    colorProgress60: "#EACD2A",
    colorProgress80: "#A1BE23",
    colorProgress100: "#43AB1B",
    colorProgress120: "#415C98",

    // light blue text color variables
    colorBlue100: "#F4F7FC",
    colorBlue200: "#CEE0FF",
    colorBlue300: "#A4C6FF",
    colorBlue1000: "#415c98",

  },
};
