import React, { useEffect } from 'react'
import style from './style.module.sass'
import MainPageWrapper from '../Main-page-wrapper'
import ArchiveDashboard from './Dashboard/Dashboard'
import ArchiveTable from './Table/Table'
import { useSelector } from 'react-redux'
import { isObjectEmpty } from '../../utils'
import { useActions } from '../../utils/action-helper'
import {
  userGetAllBenchmarks,
} from '../../actions'
import TLServiceComponent from '../../contexts/TLServiceComponent'


function ArchiveComponent () {
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const { actionUserGetAllBenchmarks } = useActions({ actionUserGetAllBenchmarks: userGetAllBenchmarks(tlService) })

 // on mount
  useEffect(() => {
    if (isObjectEmpty(benchmarks)) {
      actionUserGetAllBenchmarks(token)
    }
  }, [])

  return (
    <MainPageWrapper>
      <div className={`${style.mainCont}`}>
        <ArchiveDashboard />
        <ArchiveTable/>
      </div>
    </MainPageWrapper>
  )
}

export default ArchiveComponent
