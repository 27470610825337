import React from "react"
import style from "../../components/Analysis/Tabs/InitialTab/style.module.sass";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

export default function NewsMultilang() {
    const {theme: {bgColors, colors}} = useTheme();
    const {t} = useTranslation();
    return (
        <div className={`m-2 mt-0 ${style.textNews}`}>
            <div style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.info} mb-4`}>
                <div style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.info} mb-4`}
                     dangerouslySetInnerHTML={{__html: t('analyse-news-block', {color: colors.colorPrimary500, info_count_text: style.info_count_text})}} />
            </div>
        </div>
    );
}