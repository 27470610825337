import style from "./style.module.scss";
import {Button} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {generateLinearSeries, updateData} from "../utils";
import {useTheme} from "@material-ui/core/styles";
import ApexCharts from "apexcharts";
import {useTranslation} from "react-i18next";

function toggleLines(value, chartId) {
  ApexCharts.exec(`comparison-home-page-linear-chart-${chartId}`, 'toggleSeries', value)
}

export default function ComparisonMultiLinearChart({
                                                     chartId,
                                                     height,
                                                     title,
                                                     seriesNames,
                                                     customSeries,
                                                     chartContent: {withTotalOver, overTimeOppositeSide = 'Total'},
                                                     gridColor,
                                                     withLevels = true,
                                                     withoutDashboard,
                                                     colorByValue = true,
                                                     labelVariants = [],
                                                     legendShow = true
                                                   }) {
  const [timeInterval, setTimeInterval] = useState("all");
  const [totalOverTime, setTotalOverTime] = useState(false);
  const timeIntervals = [
    {label: "1M", value: "one_month"},
    {label: "6M", value: "six_months"},
    {label: "1J", value: "one_year"},
    {label: "2J", value: "ytd"},
    {label: "ALL", value: "all"}];

  const {theme: {colors, bgColors}} = useTheme();
  const [labels, setLabels] = useState(!!labelVariants.length ? () => {
    const obj = {};
    labelVariants.map(label => obj[label.name] = true)
    return obj
  } : [])
  const [series,] = useState(customSeries ? customSeries : seriesNames?.map(ser => generateLinearSeries(ser.title, 12, colorByValue, ser.color)));

  const { t } = useTranslation();

  useEffect(() => {
    updateData(timeInterval, 'comparison-home-page-linear-chart', series?.[0]?.data?.[0].x, series?.[0]?.data?.[series?.[0]?.data?.length - 1].x)
  }, [timeInterval, series])


  const [options,] = useState({
    chart: {
      id: `comparison-home-page-linear-chart-${chartId}`,
      height: 350,
      type: 'line',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
      },
      toolbar: {
        // show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: legendShow,
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
      },
      position: "top",
      horizontalAlign: "left",
    },
    markers: {
      size: 6,
      strokeColors: "#fff",
      strokeWidth: 1,
      hover: {
        size: 7,
      }
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)"
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session"
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val;
            }
          }
        }
      ]
    },
    stroke: {
      width: seriesNames?.map(() => 3) || 3,
      curve: 'straight',
    },
    grid: {
      borderColor: gridColor ? gridColor : '#f1f1f1',
    }
  })
  return (
    <div className={style.coloredMultiLinear}>
      {!withoutDashboard &&
      <>
        {title ? <h2 style={{color: colors.colorPrimary400}} className={`mb-2`}>{title}</h2> : ""}
        <div className={`${style.head} d-flex justify-content-between align-items-center`}>
          {withTotalOver && <div>
            <Button size='small' color={totalOverTime ? 'primary' : 'default'}
                    onClick={() => setTotalOverTime(true)}>{overTimeOppositeSide}</Button>
            <Button size='small' color={!totalOverTime ? 'primary' : 'default'} onClick={() => setTotalOverTime(false)}>
              {t('over time')}
            </Button>
          </div>}
          <div className={style.timeIntervals}>
            {
              timeIntervals.map((c) =>
                <Button
                  key={`${c.label}-ComparisonMultiLinearChartTimeIntervalButtons`} size='small'
                  color={c.value === timeInterval ? "primary" : "default"}
                  onClick={() => setTimeInterval(state => state === c.value ? "all" : c.value)}>
                  {c.label}
                </Button>
              )
            }
          </div>
        </div>
      </>
      }
      <div className={`${style.charts} d-flex flex-column pb-3`}>
        <div className={`w-100 d-flex`}>
          {withLevels &&
          <div className={`${style.colors}`}>
            <div style={{backgroundColor: "#DD0D08"}}/>
            <div style={{backgroundColor: "#E46D19"}}/>
            <div style={{backgroundColor: "#EACD2A"}}/>
            <div style={{backgroundColor: "#A1BE23"}}/>
            <div style={{backgroundColor: "#43AB1B"}}/>
          </div>
          }
          <div style={{width: '100%'}}>
            <ReactApexChart options={options} series={series} type="line" height={height}/>
          </div>
        </div>
        {
          !!(labels && Object.keys(labels).length) ?
            <div className={`d-flex`} style={{gridGap: 10}}>
              {
                labelVariants.map(label => {
                  return (
                    <Button key={label.name} size='small' variant='contained' className={`p-0 pl-1 pr-1`}
                            style={{
                              backgroundColor: labels[label.name] ? label.color : bgColors.bgPrimary400,
                              color: "#fff",
                              minWidth: 50
                            }}
                            color={'default'}
                            onClick={() => {
                              label.variants.map(variant => toggleLines(variant, chartId))
                              setLabels(s => ({...s, [label.name]: !s[label.name]}))
                            }}>{label.name}</Button>
                  )
                })
              }
            </div>
            : ""
        }
      </div>
    </div>
  )
}
