const standardOpenRequest = (name, tlServiceFunc, dispatch, rootData = false) => {
  dispatch({
    type: `FETCH_${name}_REQUEST`
  })
  tlServiceFunc()
    .then((data) => dispatch({
      type: `FETCH_${name}_SUCCESS`,
      payload: (rootData) ? data : (data.data !== undefined && data.data.length > 0) ? data.data : []
    }))
    .catch((err) => dispatch({
      type: `FETCH_${name}_FAILURE`,
      payload: err
    }))
}

export const getAllTextTypes = (tlService) => () => (dispatch) => {
  standardOpenRequest('TEXTTYPES', tlService.getAllTextTypes, dispatch)
}

export const ping = (tlService) => () => (dispatch) => {
  standardOpenRequest('PING', tlService.ping, dispatch, true)
}

export const getLocales = (tlService) => () => (dispatch) => {
  standardOpenRequest('LOCALES', tlService.getLocales, dispatch)
}

export const getReturnValues = (tlService) => () => (dispatch) => {
  standardOpenRequest('RETURN_VALUES', tlService.getAllReturnValues, dispatch)
}

export const getSettings = (tlService) => () => (dispatch) => {
  standardOpenRequest('SETTINGS', tlService.getAllSettings, dispatch)
}

export const getCountryLanguages = (tlService) => () => (dispatch) => {
  standardOpenRequest('COUNTRY_LANGUAGES', tlService.getCountryLanguages, dispatch)
}

export const getLanguages = (tlService) => () => (dispatch) => {
  standardOpenRequest('LANGUAGES', tlService.getLanguages, dispatch)
}

export const getCountries = (tlService) => () => (dispatch) => {
  standardOpenRequest('COUNTRIES', tlService.getCountries, dispatch)
}

export const getLicenses = (tlService) => () => (dispatch) => {
  standardOpenRequest('LICENSES', tlService.getLicenses, dispatch)
}

export const getUserTypes = (tlService) => () => (dispatch) => {
  standardOpenRequest('USERTYPES', tlService.getUserTypes, dispatch)

}