// Overriding MUI default styles
export function Overrides(bgColors, borderColors, colors) {
  return (
    {
      overrides: {
        MuiInputBase: {
          root: {
            fontFamily: 'Arial, sans-serif',
          },
        },
        MuiBox: {
          root: {
            padding: "0"
          },
        },
        MuiFilledInput: {
          input: {
            padding: "23px 12px 10px",
          },
          adornedEnd: {
            height: "56px"
          },
        },
        MuiOutlinedInput: {
          adornedEnd: {
            paddingRight: "0"
          },
        },
        MuiAlert: {
          icon: {
            display: "flex",
            alignItems: "center"
          },
          message: {
            display: "flex",
            alignItems: "center"
          },
        },
        PrivateTabIndicator: {
          root: {
            backgroundColor: "none"
          },
          'colorPrimary-4': {
            backgroundColor: "none"
          }
        },
        MuiTabs: {
          root: {
            color: colors.colorPrimary700
          },
          indicator: {
            backgroundColor: 'transparent',
            '& > span': {
              maxWidth: 40,
              width: '100%',
            },
          },
        },
        MuiTab: {
          root: {
            minWidth: "108px",
            fontWeight: "bold",
            textTransform: 'none',
            outline: "none",
            fontSize: "18px",
            marginRight: "8px",
            backgroundColor: "none",
            '&$selected': {
              backgroundColor: bgColors.bgPrimary0,
            },
            "@media (min-width: 600px)": {
              minWidth: "108px",
            }
          },
          textColorInherit: {
            color: colors.colorPrimary700
          }
        },
        MuiAccordion: {
          root: {
            boxShadow: 'none',
            backgroundColor: bgColors.bgPrimary0,
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&:before': {
              display: 'none',
            },
            '&$expanded': {
              margin: 'auto',
            },
          },
          expanded: {},
        },
        MuiAccordionSummary: {
          root: {
            marginBottom: -1,
            padding: "0",
            minHeight: 56,
            '&$expanded': {
              minHeight: 56,
            },
          },
          content: {
            '&$expanded': {
              margin: '12px 0',
            },
          },
          expanded: {},
        },
        MuiAccordionDetails: {
          root: {
            padding: "0",
          },
        },
        MuiTableCell: {
          root: {
            borderBottom: "none"
          },
        },
        MuiPaper: {
          elevation1: {
            boxShadow: "none"
          },
          root: {
            backgroundColor: bgColors.bgPrimary100
          }
        },
        MuiIconButton: {
          colorSecondary: {color: "white !important" }
        },
        MuiFormControl: {
          marginNormal: {margin: "0 !important" }
        },
        MuiButton: {
          root: {fontWeight: "bold" }
        }
      },
    }
  )
}



