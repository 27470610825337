import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import style from "./style.module.scss";
import {Tooltip} from "@material-ui/core";
import {Info} from "@material-ui/icons";
import {NavLink} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import { isObjectEmpty } from "../../../utils";

export default function ColumnChart({height, series, chartId, mainText, mean, max, min, withNavLink}) {
    const {theme: {bgColors, colors}} = useTheme();
    const {t} = useTranslation();
    const resultList2 = useSelector(state => state.dashboardData.resultList[1] || {});

    const [options,] = useState(
        {
            tooltip: {
                marker: {
                    show: false
                }
            },
            chart: {
                height,
                id: chartId,
                type: 'bar'
            },
            plotOptions: {
                bar: {
                    columnWidth: '100%'
                }
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
            },
            legend: {
                show: false
            },
            xaxis: {
                type: 'numeric',
                labels: {
                    show: false
                }
            },
            yaxis:{
                max, min
            },
            dataLabels: {
                enabled: false
            }
        }
    )

    return (
        <div className={`${style.RadialBar}`} style={{backgroundColor: bgColors.bgPrimary0}}>
            <div style={{marginBottom: "0%"}}>
                <div className={`d-flex justify-content-between pb-2 align-items-center ${style.withBorder}`}>
                    <h2 className={`m-0 ${style.withoutBorder} p-0`}
                        style={{color: colors.colorPrimary400}}>{chartId}</h2>
                    <Tooltip title={<h6 style={{fontSize: "15px"}}>{mainText}</h6>} className='ml-2 mr-auto'>
                        <Info style={{fill: bgColors.bgPrimary500}}/>
                    </Tooltip>
                    <div style={{color: colors.colorPrimary400}}>{t('mean value')} {mean}</div>
                </div>
                <div className={`w-100`}>
                    <ReactApexChart  width='100%' options={options} series={series} type="bar" height={350}/>
                </div>
            </div>
            {(withNavLink && !isObjectEmpty(resultList2)) && <div className={`${style.footer}`}>
                <NavLink to={withNavLink}>
                    <button>{t("Detail view")}</button>
                </NavLink>
            </div>}
        </div>
    );
}
