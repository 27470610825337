import {Modal} from "reactstrap";
import {Button, Collapse, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import TLServiceComponent from "../../../contexts/TLServiceComponent";
import {convertLanguagesToShort, convertFromErrorObject} from "../../../utils";
import {Alert} from "@material-ui/lab";
import config from "../../../config";
import {useRouteMatch} from "react-router-dom";

export default function RestoreModal({open, setOpen}) {
    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const {params: {code}} = useRouteMatch();
    const [resetPageDone, setResetPageDone] = useState(false);

    console.log('RestoreModal', code)
    const [openAlert, setOpenAlert] = useState('');

    // bind actions
    const tlService = React.useContext(TLServiceComponent);

    //state validation by YUP
    const validationSchema = yup.object({
        code: yup
            .string('Restore code')
            .min(config.minRestorePasswordCodeLength, t("Restore code minimum characters length", {length: config.minRestorePasswordCodeLength}))
            .required(t("Required field")),
        password: yup
            .string('Enter correct password')
            .min(config.minPasswordLength, t("Password minimum characters length", {length: config.minPasswordLength}))
            .required(t("Required field")),
        repeated_password: yup
            .string('Enter correct password')
            .oneOf([yup.ref('password'), null], t("Passwords must match"))
            .required(t("Required field"))
    });

    // effects
    // eslint-disable-next-line
    useEffect(() => {
        if (!open) setOpenAlert('');
    });

    const formik = useFormik({
        initialValues: {
            password: "",
            code: code || "",
            repeated_password: ""
        },
        validationSchema: validationSchema,
        onSubmit: async ({password, code}) => {
            setOpenAlert('');
            try {
                await tlService.resetPassword(convertLanguagesToShort(i18nLanguage), password, code);
                setResetPageDone(true);
            } catch (e) {
                console.log(e.message);
                setOpenAlert(convertFromErrorObject(t,e));
            }
        },
    });

    return (
        <Modal isOpen={open} className={`d-flex align-items-center justify-content-center`}
               toggle={() => setOpen(false)}>
            <div className={`pt-4 pb-4 pl-5 pr-5`}>
                <p>{resetPageDone ? t("Restore Page Text Done") : t("Restore Password Modal Text")}</p>
                {!resetPageDone &&
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        helperText={formik.touched.code && formik.errors.code}
                        defaultValue={formik.initialValues.code}
                        fullWidth
                        error={formik.touched.code && !!formik.errors.code}
                        onChange={formik.handleChange}
                        name={'code'}
                        variant="filled" label={t("Code from email")}/>
                    <TextField type='password'
                               helperText={formik.touched.password && formik.errors.password}
                               fullWidth
                               className={`mt-3`}
                               error={formik.touched.password && !!formik.errors.password}
                               onChange={formik.handleChange}
                               name={'password'}
                               variant="filled" label={t("New password")}/>
                    <TextField type='password'
                               helperText={formik.touched.repeated_password && formik.errors.repeated_password}
                               error={formik.touched.repeated_password && !!formik.errors.repeated_password}
                               onChange={formik.handleChange}
                               fullWidth
                               name={'repeated_password'}
                               className={`mt-3`}
                               variant="filled"
                               label={t("Repeat password")}/>

                    <Collapse in={!!openAlert}>
                        <Alert
                            className={`m-0`}
                            severity='error'>
                            <p className={`m-0`}>{openAlert}</p>
                        </Alert>
                    </Collapse>
                    <Button
                        type='submit'
                        className={`mt-3`}
                        fullWidth
                        color="primary"
                        variant='contained'>
                        {t("Request link")}
                    </Button>
                </form>
                }
            </div>
        </Modal>
    )
}
