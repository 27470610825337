import { useTheme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { useState, useEffect } from "react";
import deleteIcon from "../assets/deleteIcon.svg";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useTranslation } from "react-i18next";
import closeIcon from "../../../../assets/close-circle.svg";
import { Collapse, Modal, Box, Button } from "@material-ui/core";
import config from '../../../../config'
import { Alert } from "@material-ui/lab";

const headCells = [
  // {id: 'id', numeric: false, disablePadding: true, label: 'id', orderBy_value : 'id'},
  { id: 'words', numeric: false, disablePadding: false, label: 'words', orderBy_value: 'words' },
  { id: 'term_replacement', numeric: false, disablePadding: false, label: 'term_replacement', orderBy_value: 'term_replacement' },
  { id: 'description', numeric: false, disablePadding: false, label: 'description', orderBy_value: 'description' },
  { id: 'edit', numeric: false, disablePadding: false, label: 'editMissing' },
];

function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
  deleteAlertMsg,
  deleteCheckedTermModal,
  setDeleteCheckedTermModal,
  deleteChecked
}) {
  const { t } = useTranslation()
  const [showError, setShowError] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState();
  const { theme: { bgColors, colors } } = useTheme();

  const createSortHandler = (property, orderBy_value) => (event) => {
    if (property !== 'edit' && property !== 'term_replacement' && property !== 'description') {
      onRequestSort(event, property, orderBy_value);
    }
  };

  useEffect(() => {
    setDeleteMsg(deleteAlertMsg);
    setTimeout(() => {
      setShowError(false)
      setDeleteMsg('');
    }, config.timeOutDuration)
    return () => {
      setShowError(false)
      setDeleteMsg('');
    }
  }, [deleteAlertMsg, showError])

  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <>
      <Modal open={deleteCheckedTermModal} onClose={() => setDeleteCheckedTermModal(false)} style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
        <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
            style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
              {t('clear-terms')}
            </p>
            <button className={`border-0 bg-transparent`} onClick={() => setDeleteCheckedTermModal(false)}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
          <br />
          <div className={style.error} >
            <Collapse in={!!deleteMsg} >
              <Alert
                className={`m-0`}
                severity='error'>
                <h6 className={`m-0`}>
                  {t('delete-error-msg')}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div>
            <Button color='primary' variant='contained'
              onClick={(e) => {
                deleteChecked();
                setShowError(true)
              }
              }
              style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('yes')}
            </Button>
            <Button color='primary' variant='contained'
              onClick={() => { setDeleteCheckedTermModal(false); }}
              style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Modal>
      <TableHead style={{ backgroundColor: bgColors.bgBlue300 }} className={`${style.filterDashboard}`}>
        <TableRow>
          <TableCell colSpan={12} padding="checkbox">
            <Collapse in={numSelected ? true : false} timeout="auto" unmountOnExit>
              <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
                <p style={{ color: colors.colorPrimary1000 }} className={`m-0`}>{numSelected} {t('selected')}</p>
                <button className={`${style.delete} pr-0 pl-0`}
                  style={{ backgroundColor: bgColors.bgBlue1000 }}
                  onClick={e => setDeleteCheckedTermModal(true)}
                >
                  <img src={deleteIcon} alt="" />
                </button>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableHead style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.tableHead}`}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                style={{ flexDirection: "row" }}
                onClick={createSortHandler(headCell.id, headCell.orderBy_value)}
                IconComponent={(props) => (
                  <ArrowDownwardIcon style={{ order: "9", color: colors.colorPrimary0 }} alt="" {...props} />)}
              >
                <p style={{ color: colors.colorPrimary0 }} className={`m-0`}>{t(headCell.label)}</p>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default EnhancedTableHead;
