import React from "react";
import MainPageWrapper from "../../Main-page-wrapper";
import Form from "./Form/Form";

function UserUpdateComponent() {
  return (
    <MainPageWrapper>
      <Form/>
    </MainPageWrapper>
  )
}

export default UserUpdateComponent;