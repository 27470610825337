import React from "react";
import ProfileComponent from "../../components/Iframe/";

function Iframe() {
    return (
        <ProfileComponent/>
    )
}

export default Iframe;
