import React from "react";
import style from "./style.module.scss"
import {useTheme} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import VocabularyCircleChart from "../UI/VocabularyCircleChart";
import ComparisonMultiLinearChart from "../UI/ComparisonMultiLinearChart";
import SpeechClimateTextComparisonHorizontalChart from "../SpeechClimate/SpeechClimateTextComparisonHorizontalChart";
import {useTranslation} from "react-i18next";

export default function SpeechClimate({type}) {
    const {theme: {bgColors}} = useTheme();
    const {t} = useTranslation();

    return (
        <div className={`${style.speechClimate} ${style.cells}`} style={{backgroundColor: bgColors.bgPrimary0}}>
            {
                type === "comparison" ?
                    <ComparisonMultiLinearChart title={'SPRACHKLIMA'}
                                                chartContent={{withTotalOver: true, overTimeOppositeSide: "Total"}}
                                                seriesNames={
                                                    [
                                                        {title: "konservativ", color: "#A1BE23"},
                                                        {title: "rational", color: "#415C98"},
                                                        {title: "innovativ", color: "#E46D19"},
                                                        {title: "emotional", color: "#DD0D08"}
                                                    ]
                                                }
                                                height={350}
                    /> :
                    type === "texts" ?
                        <SpeechClimateTextComparisonHorizontalChart title={'SPRACHKLIMA'}/> :
                        <VocabularyCircleChart/>
            }
            <div className={`${style.footer} mt-2`}>
                <NavLink to={`/dashboard/${type}/speech-climate`}>
                    <button>{t("Detail view")}</button>
                </NavLink>
            </div>
        </div>
    )
}
