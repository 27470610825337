import 'date-fns';
import { useTheme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import { Box, Button, TableRow, IconButton, FormControlLabel } from "@material-ui/core";
import React from "react";
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import { deepCopy } from "../../../../utils";

export default function TableFilter({
  searchPlaces,
  setSearchPlaces,
  searchTerm,
  setTextValue,
  textValue,
  setDeleteRecall
}) {
  // const [filter, setFilter] = useState('');
  const { theme: { bgColors, borderColors, colors } } = useTheme();
  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);
  // Clearing up the input filter if those condition are meet!
  // useEffect(() => {
  //   // setTextValue('');
  //   setOpen(false);
  // }, [limitIs])

  const setSearchArea = (e) => {
    const { name } = e.target;
    const newSearchPlaces = deepCopy(searchPlaces);
    newSearchPlaces[name] = !newSearchPlaces[name];
    setSearchPlaces(newSearchPlaces);
  }

  const resetFilter = () => {
    if (textValue !== '') {
      setTextValue('');
      // setOpen(false);
      // Call recall after remove textValue :
      setDeleteRecall(Math.floor(Math.random() * 10000))
    }
    // callEndPointReCall();
  }

  return (
    <TableHead style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.tableFilter}`}>
      <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          {/* <Box style={{color: colors.colorPrimary500}}
             className={`d-flex justify-content-between align-items-end flex-column-reverse p-1 pl-3`} margin={1}>
          <div className={`d-flex align-items-center`} style={{marginRight: "auto"}}>
            <Autocomplete
              id="combo-box"
              options={selectVariants}
              getOptionLabel={(option) => option.title}
              className={`d-flex align-items-center mr-3 mb-3`}
              style={{width: 300, height: "52px"}}
              renderInput={(params) => <TextField {...params} style={{height: "52px"}} label="Filter"
                                                  variant="filled"/>}
            />
            {
              filter ?
                <Button onClick={() => setFilter('')} size='small' color="secondary" fullWidth variant='contained'>
                  {t('clear-filter')}
                </Button> : ""
            }
          </div>
        </Box> */}
          <Box style={{ color: colors.colorPrimary500 }}
            className={`d-flex align-items-center p-3`} margin={1}>
            {/* <Button className={`${style.filterBtn} mr-4`} onClick={() => console.log(!open)} variant="contained"
                    color="primary"
                    startIcon={<FilterListIcon/>}>
              {t('filter')}
            </Button> */}

            <Paper className={`${style.search} d-flex`}
              style={{ backgroundColor: bgColors.bgPrimary0, borderColor: borderColors.borderPrimary400 }}>
              <IconButton style={{ padding: "9px" }} type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                value={textValue}
                id='searchBox'
                placeholder={t('search')}
                onChange={(e) => setTextValue(e.target.value)}
                // style={{ marginTop: "5px" }}
                onKeyUp={(e) => {
                  if (textValue !== '' && e.key === 'Enter') {
                    searchTerm()
                  }
                }
                }
              />
            </Paper>
            <Button className={`${style.filterBtn} ml-2 mr-3`}
              onClick={() => {
                if (textValue !== '') {
                  searchTerm()
                }
              }
              }
              variant="contained"
              style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
            >
              {t("apply-filter")}
            </Button>
            <Button className={`${style.filterBtn} ml-2 mr-3`} onClick={() => resetFilter()} variant="contained"
              style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
            >
              {t("reset-filter")}
            </Button>
          </Box>
          <Box style={{ color: colors.colorPrimary500 }} className='pb-1 pl-3'  >
            <FormControlLabel control={<Checkbox name={'term'} onClick={setSearchArea} color="primary" checked={!!searchPlaces['term']} />} label={t("term")} />
            <FormControlLabel control={<Checkbox name={'replacement'} onClick={setSearchArea} color="primary" checked={!!searchPlaces['replacement']} />} label={t("replacement")} />
            <FormControlLabel control={<Checkbox name={'description'} onClick={setSearchArea} color="primary" checked={!!searchPlaces['description']} />} label={t("description")} />
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
};

// const selectVariants = [
//   {title: 'filter'},
//   {title: 'filter 2'},
//   {title: 'filter 3'},
//   {title: 'filter 4'},
//   {title: 'filter 5'},
//   {title: "filter 6"},
//   {title: 'filter 7'},
// ];

