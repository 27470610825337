import React, { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import style from './style.module.sass'
import { Box, Tooltip } from '@material-ui/core'
import {
  tableSetData,
  tableSetDialog, tableSetSelectAll, tableSetSelected
} from '../../../../../../actions'
import { useSelector } from 'react-redux'
import { Delete } from '@material-ui/icons'
import { Redirect } from 'react-router'
import TLServiceComponent from '../../../../../../contexts/TLServiceComponent'
import { useActions } from '../../../../../../utils/action-helper'
import { checkUserRight, convertFromErrorObject, isObjectEmpty, onlyUnique } from '../../../../../../utils'
import Spinner from '../../../../../Spinner'


export default function TableMassActionComponent({
  reloadAll
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)

  const {
    aTableSetSelectAll,
    aTableSetSelected,
    aTableSetDialog,
    aTableSetData
  } = useActions({
    aTableSetDialog: tableSetDialog,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelected: tableSetSelected,
    aTableSetData: tableSetData
  })

  const data = useSelector(state => state.tableData.data)
  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const limit = useSelector(state => state.tableData.limit)
  const otherData = useSelector(state => state.tableData.otherData)
  const selected = useSelector(state => state.tableData.selected)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const selectAllIds = useSelector(state => state.tableData.selectAllIds)
  const token = useSelector(state => state.userData.token)
  const filters = useSelector(state => state.tableData.filters)
  const orderBy = useSelector(state => state.tableData.orderBy || 'update_time')
  const order = useSelector(state => state.tableData.order)
  const user = useSelector(state => state.userData.user)

  const [redirect, setRedirect] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [massDelete, setMassDelete] = useState([])
  const [spinnerMessage, setSpinnerMessage] = useState('')


  const getGroupIdById = async (ids) => {
    if (ids?.length) {
      try {
        const res = await tlService.getFilteredGroups(token, {}, {
          extended_filter: JSON.stringify([["&id", `i=${JSON.stringify(ids)}`]]),
          return_values: '["id"]'
        })
        if (res?.data?.length) {
          const arrRet = []
          for (const el of res.data) {
            arrRet.push(el.id)
          }
          return arrRet.filter(onlyUnique)
        }
      } catch (err) {
        console.log(err)
      }
    }
    return []
  }

  useEffect(() => {
    const effectFunction = async () => {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'massDeleteGroups':
            if (dialogResponse && !isObjectEmpty(massDelete)) {
              setSpinner(true)
              const groupIds = await getGroupIdById(massDelete)
              const deletedIds = []
              for (const id of groupIds) {
                try {
                  setSpinnerMessage(t('deleting_groups') + id)
                  await tlService.deleteGroup(token, id)
                  deletedIds.push(id)
                } catch (error) {
                  aTableSetDialog({
                    type: 'errorMassDelete',
                    open: true,
                    RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                    response: null,
                    buttons: {
                      yes: false,
                      no: false,
                      close: true,
                      save: false
                    }
                  })
                  setMassDelete([])
                  setSpinner(false)
                  reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
                  aTableSetSelectAll(false)
                  aTableSetSelected([])
                  return false
                }
              }
              aTableSetSelectAll(false)
              aTableSetSelected([])
              setMassDelete([])
              setSpinner(false)
              const tData = [...data]
              for (const id of deletedIds) {
                const index = tData.findIndex(el => el.id === id)
                if (index !== -1) {
                  tData.splice(index, 1)
                }
              }
              aTableSetData(tData)
            }
            break
          default:
            break
        }
      }
    }
    effectFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const handleMassDelete = () => {
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }
    setMassDelete(allIds)
    aTableSetDialog({
      type: 'massDeleteGroups',
      open: true,
      RequestComponent: () => <>{t('are_you_sure_want_to_delete_groups')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }

  if (!selectAll && !selected?.length) {
    return null
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
      {spinner && <div>
        <Spinner />
        {spinnerMessage && <div
          style={{
            position: 'absolute',
            zIndex: 10001,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        ><strong>{spinnerMessage}</strong></div>}
      </div>}
      <div style={{ color: colors.colorPrimary1000, width: '100%' }}>
        {selectAll ? otherData?.groups_fullcount : selected?.length} {t('selected')}
      </div>
      <div>
      </div>
      {
        checkUserRight(user, [208]) &&
        <Tooltip title={<h6 style={{ fontSize: '15px' }}>
          {t('delete')}
        </h6>} placement="top-start">
          <Delete
            onClick={() => handleMassDelete()}
            className={`ml-2 ${style.export}`}
            color={'secondary'}
            style={{ backgroundColor: bgColors.bgBlue1000 }} />
        </Tooltip>
      }
    </Box>
  )
}