import React, { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import style from './style.module.sass'
import { Box, Tooltip } from '@material-ui/core'
import {
  tableSetData,
  tableSetDialog, tableSetSelectAll, tableSetSelected
} from '../../../../../actions'
import { useSelector } from 'react-redux'
import { Check, Delete } from '@material-ui/icons'
import { Redirect } from 'react-router'
import TLServiceComponent from '../../../../../contexts/TLServiceComponent'
import { useActions } from '../../../../../utils/action-helper'
import { convertFromErrorObject, getTCLocaleName, getTreeTaggerResult, isObjectEmpty } from '../../../../../utils'
import Spinner from '../../../../Spinner'


export default function TableMassActionComponent({
  reloadAll
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)

  const {
    aTableSetSelectAll,
    aTableSetSelected,
    aTableSetDialog,
    aTableSetData
  } = useActions({
    aTableSetDialog: tableSetDialog,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelected: tableSetSelected,
    aTableSetData: tableSetData
  })

  const data = useSelector(state => state.tableData.data)
  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const limit = useSelector(state => state.tableData.limit)
  const otherData = useSelector(state => state.tableData.otherData)
  const selected = useSelector(state => state.tableData.selected)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const selectAllIds = useSelector(state => state.tableData.selectAllIds)
  const token = useSelector(state => state.userData.token)
  const filters = useSelector(state => state.tableData.filters)
  const orderBy = useSelector(state => state.tableData.orderBy || 'update_time')
  const order = useSelector(state => state.tableData.order)

  const [redirect, setRedirect] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [massDelete, setMassDelete] = useState([])
  const [spinnerMessage, setSpinnerMessage] = useState('')

  const actionApprove = async (row) => {
    const categoryLocaleName = getTCLocaleName(tlService, token, row.term_category_id)
    if (!categoryLocaleName) {
      return false
    }

    let newTermId = 0
    try {
      const res = await tlService.addTerm(
        token,
        row.term_category_id,
        (row.words),
        (row.lemma),
        (row.tag),
        row?.lemma?.includes('<unknown>') ? 1 :
          row.check_words,
        {},
        row.description
      )
      newTermId = res?.data?.id || 0
    } catch (e) {
      let error
      try {
        error = JSON.parse(e.message)
      } catch (e) {
        error = {}
      }
      if (error?.number === 409) {
        return await update(row)
      } else {
        aTableSetDialog({
          type: 'errorMassApprove',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
        setMassDelete([])
        setSpinner(false)
        reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
        aTableSetSelectAll(false)
        aTableSetSelected([])
        return false
      }
    }
    // go through replacements
    const rowReplacements = row.replacements || []
    if (rowReplacements?.length && newTermId > 0) {
      for (const element of rowReplacements) {
        const tokenizeTerm = await getTreeTaggerResult(tlService, token, categoryLocaleName, element)
        if (!tokenizeTerm?.error) {
          try {
            await tlService.addTermReplacement(token, newTermId, row.term_category_id,
              tokenizeTerm.word, tokenizeTerm.lemma, tokenizeTerm.tag, row.description, 1)
          } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            return false
          }
        } else {
          console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
          return false
        }
      }
    }
    try {
      await tlService.calculateCategory(token, parseInt(row.term_category_id))
      await tlService.clearAllCache(token, 1, parseInt(row.term_category_id))
    } catch (e) {
      console.log(e)
    }

    return await update(row)
  }

  const update = async row => {
    try {
      await tlService.updateTextbin(
        token,
        row.id,
        1
      )
    } catch (e) {
      aTableSetDialog({
        type: 'errorMassApprove',
        open: true,
        RequestComponent: () => <>{convertFromErrorObject(t, e)}</>,
        response: null,
        buttons: {
          yes: false,
          no: false,
          close: true,
          save: false
        }
      })
      setMassDelete([])
      setSpinner(false)
      reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
      aTableSetSelectAll(false)
      aTableSetSelected([])
      return false
    }
    return true
  }

  useEffect(() => {
    const effectFunction = async () => {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'massDeleteTB':
            if (dialogResponse && !isObjectEmpty(massDelete)) {
              setSpinner(true)
              const deletedIds = []
              for (const id of massDelete) {
                try {
                  setSpinnerMessage(t('deleting_tb') + id)
                  await tlService.deleteTextbin(token, id)
                  deletedIds.push(id)
                } catch (error) {
                  aTableSetDialog({
                    type: 'errorMassDelete',
                    open: true,
                    RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                    response: null,
                    buttons: {
                      yes: false,
                      no: false,
                      close: true,
                      save: false
                    }
                  })
                  setMassDelete([])
                  setSpinner(false)
                  reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
                  aTableSetSelectAll(false)
                  aTableSetSelected([])
                  return false
                }
              }
              aTableSetSelectAll(false)
              aTableSetSelected([])
              setMassDelete([])
              setSpinner(false)
              const tData = [...data]
              for (const id of deletedIds) {
                const index = tData.findIndex(el => el.id === id)
                if (index !== -1) {
                  tData.splice(index, 1)
                }
              }
              aTableSetData(tData)
            }
            break
          case 'massApprove':
            if (dialogResponse && !isObjectEmpty(massDelete)) {
              setSpinner(true)
              const approvedIds = []
              for (const id of massDelete) {
                try {
                  setSpinnerMessage(t('approving_tb') + id)
                  const row = data.find(el => el.id === id)
                  if (row) {
                    await actionApprove(row)
                    approvedIds.push(id)
                  }
                } catch (error) {
                  aTableSetDialog({
                    type: 'errorMassApprove',
                    open: true,
                    RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                    response: null,
                    buttons: {
                      yes: false,
                      no: false,
                      close: true,
                      save: false
                    }
                  })
                  setMassDelete([])
                  setSpinner(false)
                  reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
                  aTableSetSelectAll(false)
                  aTableSetSelected([])
                  return false
                }
              }
              aTableSetSelectAll(false)
              aTableSetSelected([])
              setMassDelete([])
              setSpinner(false)
              const tData = [...data]
              for (const id of approvedIds) {
                const index = tData.findIndex(el => el.id === id)
                if (index !== -1) {
                  tData.splice(index, 1)
                }
              }
              aTableSetData(tData)
            }
            break

          default:
            break
        }
      }
    }
    effectFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const handleMass = (type = "delete") => {
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }
    setMassDelete(allIds)
    aTableSetDialog({
      type: type === 'delete' ? 'massDeleteTB' : 'massApprove',
      open: true,
      RequestComponent: () => <>{t(`are_you_sure_want_to_${type}_tb`)}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }

  if (!selectAll && !selected?.length) {
    return null
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
      {spinner && <div>
        <Spinner />
        {spinnerMessage && <div
          style={{
            position: 'absolute',
            zIndex: 10001,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        ><strong>{spinnerMessage}</strong></div>}
      </div>}
      <div style={{ color: colors.colorPrimary1000, width: '100%' }}>
        {selectAll ? otherData?.textBin_fullcount : selected?.length} {t('selected')}
      </div>
      <div>
      </div>
      <Tooltip title={<h6 style={{ fontSize: '15px' }}>
        {t('approve')}
      </h6>} placement="top-start">
        <Check
          onClick={() => handleMass("approve")}
          className={`ml-2 ${style.export}`}
          style={{ backgroundColor: bgColors.bgBlue1000, color: '#fff' }} />
      </Tooltip>
      <Tooltip title={<h6 style={{ fontSize: '15px' }}>
        {t('delete')}
      </h6>} placement="top-start">
        <Delete
          onClick={() => handleMass()}
          className={`ml-2 ${style.export}`}
          color={'secondary'}
          style={{ backgroundColor: bgColors.bgBlue1000 }} />
      </Tooltip>
    </Box>
  )
}