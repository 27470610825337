import {Block, Done, DoneAll} from "@material-ui/icons";
import {Button, Popover} from "@material-ui/core";
import React from "react";
import BLockAll from "./assets/BlockAll.svg";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

export default function ButtonWithPop({valueChanged, text, typeOfCount, children, onClick = () => true}) {
    const classes = useStyles();
    const {theme: {colors}} = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button
                onClick={onClick}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose} variant="contained" style={{minWidth: "60px", height: 30}}
                className={`p-0 m-0`}>
                {!children ?
                    typeOfCount !== 'all' ? valueChanged ? <Done style={{fill: colors.colorProgress100, height: 24}}/> :
                        <Block style={{fill: colors.colorPrimary400, height: 24}}/> : valueChanged ?
                        <DoneAll style={{fill: colors.colorProgress100, height: 24}}/> :
                        <img src={BLockAll} alt={''} style={{fill: colors.colorPrimary400, height: 20}}/> :
                    children
                }
            </Button>
            <Popover
                style={{zIndex: 10000}}
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <p className={`m-0`} style={{fontSize: "16px"}}>{text}</p>
            </Popover>
        </>
    )
}
