import React, { useState } from 'react';
import style from "./style.module.sass";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Rows from "./Rows";
import { useTheme } from '@material-ui/core/styles';
import EnhancedTableHead from "./TableHead";
import TableFilter from "./TableFilter";
import TableFooter from "./TableFooter";
import { Button } from "@material-ui/core";
import saveIcon from "../../Cleaning-rules-form/assets/saveIcon.svg";
// import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CRTable({ id }) {
  const { theme: { bgColors, colors } } = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { t } = useTranslation();

  let isEven = false
  const [afterAddField, setAfterAddField] = useState(false)
  const [rows, setRows] = useState([
    { id: 0, replacementTerm: 'EInkauf' },
    { id: 1, replacementTerm: 'EInkauf' },
    { id: 2, replacementTerm: 'EInkauf' },
    { id: 3, replacementTerm: 'EInkauf' },
    { id: 4, replacementTerm: 'EInkauf' },
    { id: 5, replacementTerm: 'EInkauf' },
    { id: 6, replacementTerm: 'EInkauf' },
    { id: 7, replacementTerm: 'EInkauf' },
    { id: 8, replacementTerm: 'EInkauf' },
    { id: 9, replacementTerm: 'EInkauf' },
    { id: 10, replacementTerm: 'EInkauf' },
    { id: 11, replacementTerm: 'EInkauf' },
    { id: 12, replacementTerm: 'EInkauf' },
    { id: 13, replacementTerm: 'EInkauf' },
    { id: 14, replacementTerm: 'EInkauf' },
    { id: 15, replacementTerm: 'EInkauf' },
    { id: 16, replacementTerm: 'EInkauf' },
    { id: 17, replacementTerm: 'EInkauf' },
    { id: 18, replacementTerm: 'EInkauf' },
    { id: 19, replacementTerm: 'EInkauf' },
    { id: 20, replacementTerm: 'EInkauf' },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div className={`${style.mainCont}`}>
      <Paper className={`pb-5`}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <h1 className={`mb-3 m-0 ${style.title}`}>{t('personal-names')}</h1>
          <Button type="submit" variant={"contained"} color={"primary"}
            style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
            className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt="" /> {t('save-changes')}
          </Button>
        </div>
        <p style={{ backgroundColor: bgColors.bgPrimary300 }}
          className={`m-0 ${style.subtitle} ${style.borderRadiusTop}`}>
          {t('name-replaced-by')} {id}
          <span style={{ color: colors.colorPrimary1000, margin: "0 0 0 auto" }}>
            {t("n entries", {
              full: 123
            })}
          </span>
        </p>
        <TableContainer>
          <TableFilter
            setRows={setRows}
            setAfterAddField={setAfterAddField}
            rows={rows}
          />
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <Table
            style={{ backgroundColor: bgColors.bgPrimary100, borderColor: "transparent" }}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {/* <TableRow> */}
            <TableBody style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "5px" }}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if (index % 2 === 0) isEven = !isEven;
                  return (
                    <Rows key={row.id}
                      checkBoxHandleClick={handleClick} isItemSelected={isItemSelected} labelId={labelId}
                      setRows={setRows}
                      row={row}
                      afterAddField={afterAddField}
                      rows={rows}
                      isEven={isEven}
                    />
                  );
                })}
            </TableBody>
            {/* </TableRow> */}
          </Table>
          <TableFooter
            rowsPerPageOptions={[10, 25, 40]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
        <div className={`${style.saveChangesBtn} mb-5 mt-4`}>
          <Button type="submit" variant={"contained"} color={"primary"}
            style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
            className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt="" /> {t('save-changes')}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
