import {TextField} from "@material-ui/core";
import style from "./style.module.scss"
import React from "react";
import {Autocomplete} from "@material-ui/lab";
import RenderSpeechCLTextComparisonHChartLines from "./RenderSpeechClTextComparisonHChartLines";
import {useTheme} from "@material-ui/core/styles";

const speechCLTextComparisonHChartLines = [
  {
    title: "KONSERVATIV",
    color: "#43AB1B",
    texts: [
      {name: 'Auswahl 1', wert: 50, ziel: 70},
      {name: 'Auswahl 2', wert: 63, ziel: 70},
    ]
  },
  {
    title: "RATIONAL",
    color: "#415C98",
    texts: [
      {name: 'Auswahl 1', wert: 64, ziel: 80},
      {name: 'Auswahl 2', wert: 50, ziel: 80},
    ]
  },
  {
    title: "INNOVATIV",
    color: "#EFA90B",
    texts: [
      {name: 'Auswahl 1', wert: 15, ziel: 20},
      {name: 'Auswahl 2', wert: 50, ziel: 20},
    ]
  },
  {
    title: "EMOTIONAL",
    color: "#DD0D08",
    texts: [
      {name: 'Auswahl 1', wert: 15, ziel: 10},
      {name: 'Auswahl 2', wert: 50, ziel: 10},
    ]
  },
]

export default function SpeechClimateTextComparisonHorizontalChart({title}) {
  const {theme: {colors}} = useTheme();

  return (
    <div className={`${style.mainCont} p-0`}>
      <div className={`d-flex align-items-center justify-content-end ${style.withBorder}`}>
        {title ? <h2 style={{color: colors.colorPrimary400, margin: "0 auto 0 0"}} className={`${style.withoutBorder}`}>{title}</h2> : ""}
        <Autocomplete
        id="Textsorte-124333123"
        options={selectVariants}
        defaultValue={selectVariants[0]}
        getOptionLabel={(option) => option.title}
        className={`d-flex align-items-center mr-3`}
        style={{width: 300, height: "52px"}}
        renderInput={(params) => <TextField {...params} style={{height: "52px"}} label="Textsorte"
                                            variant="filled"/>}
      />
      </div>

      <div className={`${style.rangeNumsCont} d-flex justify-content-between`}>
        {[0, 20, 40, 60, 80, 100].map(num => <p key={`speechRangerNums-${num}`}>{num}</p>)}
      </div>
      {
        speechCLTextComparisonHChartLines.map((item,i) => <RenderSpeechCLTextComparisonHChartLines key={i+'RenderSpeechCLTextComparisonHChartLines'} {...item}/>)
      }
    </div>
  )
}
const selectVariants = [
  {title: 'Brief formell'},
  {title: 'Brief formell 2'},
  {title: 'Brief formell 3'},
  {title: 'Brief formell 4'},
  {title: 'Brief formell 5'},
  {title: "Brief formell 6"},
  {title: 'Brief formell 7'},
];

