import React from "react";
import UserProfileWrapper from "../UserProfileWrapper/UserProfileWrapper";
import UserTable from "./Table/Table";

function UserComponent() {
  return (
    <UserProfileWrapper>
      <UserTable/>
    </UserProfileWrapper>
  )
}

export default UserComponent;
