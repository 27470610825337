import React from "react";
import style from "./style.module.scss"

export default function ColumnChart({charts}){
  return (
    <div className={`${style.column}`}>
      {
        charts.map(
          c => (
            <div key={c.id} className={style.cell}>
              <div className={style.cols}>
                <div style={{height: `${c.firstCol.value}%`, width: "20px", backgroundColor: c.firstCol.color}} className={style.col}/>
                <div style={{height: `${c.secondCol.value}%`,width: "20px", backgroundColor: c.secondCol.color}} className={style.col}/>
                {c?.thirdCol ? <div style={{height: `${c.thirdCol.value}%`, backgroundColor: c.thirdCol.color}}
                      className={style.col}/> : ""}
              </div>
              <p className={style.name}>{c.name}</p>
            </div>
          )
        )
      }
    </div>
  )
}
