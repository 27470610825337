const updateArchiveData = (state, action) => {
  const defaultState = {
    excel: [],
    folderID: 0,
    folderName: '',
    archiveType: 'personal',
    selectedFolder: [],
    folders: {
      predefinedFolder: undefined,
      forceReload: false,
      requests: {
        personalRootCount: false,
        groupRootCount: false,
        companyRootCount: false,
        personal: false,
        group: false,
        company: false
      },
      personalRootCount: undefined,
      groupRootCount: undefined,
      companyRootCount: undefined,
      personal: undefined,
      group: undefined,
      company: undefined
    },
  }

  if (state === undefined) {
    return defaultState
  }

  switch (action.type) {
    case 'ARCHIVE_SET_PREDEFINED':
      return {
        ...state.archiveData,
        folders: {
          ...state.archiveData.folders,
          predefinedFolder: action.payload
        }
      }
    case 'ARCHIVE_SET_FORCE_RELOAD':
      return {
        ...state.archiveData,
        folders: {
          ...state.archiveData.folders,
          forceReload: action.payload
        }
      }
    case 'ARCHIVE_SET_FOLDERS_REQUEST':
    case 'ARCHIVE_SET_ROOT_COUNT_REQUEST':
      return {
        ...state.archiveData,
        folders: {
          ...state.archiveData.folders,
          requests: {
            ...state.archiveData.folders.requests,
            [action.payload]: true
          }
        }
      }

    case 'ARCHIVE_SET_FOLDERS_RESPONSE':
    case 'ARCHIVE_SET_ROOT_COUNT_RESPONSE':
      return {
        ...state.archiveData,
        folders: {
          ...state.archiveData.folders,
          [action.payload.type]: action.payload.data,
          requests: {
            ...state.archiveData.folders.requests,
            [action.payload.type]: false
          }
        }
      }

    case 'ARCHIVE_SET_ARCHIVE_TYPE':
      return {
        ...state.archiveData,
        archiveType: action.payload,
      }

    case 'ARCHIVE_SET_FOLDER_NAME':
      return {
        ...state.archiveData,
        folderName: action.payload
      }
    case 'ARCHIVE_SET_FOLDER_ID':
      return {
        ...state.archiveData,
        folderID: action.payload
      }
    case 'EXCEL_DATA':
      return {
        ...state.archiveData,
        excel: action.payload
      }
    case 'ARCHIVE_SET_SELECTED_FOLDER':
      return {
        ...state.archiveData,
        selectedFolder: action.payload
      }
    default:
      return state.archiveData
  }

}

export default updateArchiveData