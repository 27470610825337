import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import logo from './assets/group372.svg'
import text from './assets/TEXTLAB-LOGIN.svg'
import submitIcon from './assets/anmeldenIcon.svg'
import { Collapse, TextField } from '@material-ui/core'
import Footer from '../Footer/Footer'
import { useTheme } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import { useActions } from '../../utils/action-helper'
import { clearUser, userSetUser } from '../../actions'
import { Redirect, useRouteMatch } from 'react-router-dom'
import Spinner from '../Spinner'
import { parseErrorObject } from '../../utils'

export default function Ga2FA () {
  const { t, i18n } = useTranslation()
  const { theme: { bgColors } } = useTheme()
  const { params: { code } } = useRouteMatch()

  // states
  const [submitted, setSubmitted] = useState(false)
  const [openAlert, setOpenAlert] = useState('')
  const [totpRedirect, setTotpRedirect] = useState('')
  const [secret, setSecret] = useState('')
  const [identifier, setIdentifier] = useState('')
  const [attempts, setAttempts] = useState(0)

  // get reducers
  const errorUser = useSelector(state => state.userData.error) || ''
  const token = useSelector(state => state.userData.token)

  // bind actions
  const tlService = React.useContext(TLServiceComponent)
  const { aClearUser, aUserSetUser } = useActions({
    aClearUser: clearUser,
    aUserSetUser: userSetUser(tlService, i18n)
  })

  const data2FA = parseErrorObject(errorUser)
  useEffect(() => {
    if (data2FA?.type_2fa !== 'ga') {
      redirectBack()
      return
    }
    setSecret(data2FA?.secret)
    setAttempts(data2FA?.attempts)
    setIdentifier(data2FA?.identifier)
  }, [])

  //state validation by YUP
  const validationSchema = yup.object({
    totp: yup.number()
      .required(t('Required field'))
  })

  const sendToTp = async (totp) => {
    try {
      const res = await tlService.loginGA(String(totp), secret, identifier)
      if (res?.token) {
        aUserSetUser({ token: res.token }, true)
      } else {
        redirectBack()
      }
    } catch (e) {
      const error = parseErrorObject(e)
      console.log('error', error)
      setOpenAlert(t(error.message))
      setSecret(error?.secret)
      setAttempts(error?.attempts)

    }

  }

  const formik = useFormik({
    initialValues: {
      totp: ''
    },
    validationSchema: validationSchema,
    onSubmit: ({ totp }) => {
      setSubmitted(true)
      setOpenAlert('')
      console.log('totp', totp, data2FA)
      if (data2FA?.type_2fa === 'ga') {
        sendToTp(totp).then(() => setSubmitted(false))
      }
      /*
attempts: 3
message: "Wait for TOTP proof"
number: 401
secret: "mfHTe9"
type_2fa: "ga"
*/
      //actionLoginUser(name, password, true)
      // add true to remember!!
    },
  })

  const redirectBack = () => {
    aClearUser()
    setTotpRedirect('/authorization')
  }

  if (totpRedirect) {
    return <Redirect to={totpRedirect}/>
  }

  if (token) {
    return <Redirect to="/"/>
  }

  return (
    <div style={{ backgroundColor: bgColors.bgPrimary100 }} className={style.mainCont}>
      {submitted && <Spinner/>}
      <form onSubmit={formik.handleSubmit} className={style.form}>
        <div style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.161)', backgroundColor: bgColors.bgPrimary0 }}>
          <div className={style.blueRec}>
            <img src={logo} className={style.logo} alt="logo"/>
            <img src={text} className={style.text} alt="text"/>
          </div>
          <div className={style.fields}>
            {attempts > 0 && <Alert
              className={`m-0`}
              severity="warning">
              <p className={`m-0`}>{t('2fa_ga_attempts_left {{attempts}}', { attempts })}</p>
            </Alert>}
            <TextField
              type={'number'}
              helperText={formik.touched.totp && formik.errors.totp}
              error={formik.touched.totp && !!formik.errors.totp}
              onChange={formik.handleChange}
              name={'totp'}
              variant="filled"
              label={t('ga_totp')}
              style={{ marginBottom: '30px' }}
            />
            <Collapse in={!!openAlert}>
              <Alert
                className={`m-0`}
                severity="error">
                <p className={`m-0`}>{openAlert}</p>
              </Alert>
            </Collapse>
            <div className={`${style.submitCont} mt-2 mb-3`}>
              {secret && <button type="submit" className={style.submit}>
                <img src={submitIcon} alt="" className='mr-2'/>
                {t('ga_totp_send')}
              </button>}
              <button onClick={redirectBack} type="button" className={style.submitBack}>
                {t('ga_totp_back')}
              </button>
            </div>
          </div>
        </div>
        <div className={`pt-1`}>
          <Footer/>
        </div>
      </form>
    </div>
  )
}


