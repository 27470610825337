import 'date-fns';
import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React, {useState} from "react";
import {Box} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {Autocomplete} from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {TextField} from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';


function TableFilter() {
  const {theme: {bgColors, colors}} = useTheme();
  const [selectedUser, setSelectedGroup] = useState(selectVariantsUser[0]);
  const [selectedDateUntil, setSelectedDateUntil] = useState(null);
  const [selectedDateAfter, setSelectedDateAfter] = useState(null);
  const handleDateUntilChange = (date) => {
    setSelectedDateUntil(date);
  };
  const handleDateAfterChange = (date) => {
    setSelectedDateAfter(date);
  };

  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.tableFilter}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box style={{color: colors.colorPrimary500}}
               className={`d-flex align-items-center p-3`} margin={1}>
            <Autocomplete
              value={selectedUser}
              onChange={(e, newValue) => setSelectedGroup(newValue)}
              id="combo-box-demo"
              className={`mr-3`}
              options={selectVariantsUser}
              style={{width: 300}}
              renderInput={(params) => <TextField {...params} size="small" label="User"
                                                  variant="outlined"/>}
            />
            <Autocomplete
              value={selectedUser}
              onChange={(e, newValue) => setSelectedGroup(newValue)}
              id="combo-box-demo"
              className={`mr-3`}
              options={selectVariantsUser}
              style={{width: 300}}
              renderInput={(params) => <TextField {...params} size="small" label="User group"
                                                  variant="outlined"/>}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className={`mr-3`}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Time range from"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  size="small"
                  value={selectedDateUntil}
                  className={`m-0`}
                  open={false}
                  onChange={handleDateUntilChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Time range to"
                format="MM/dd/yyyy"
                inputVariant="outlined"
                size="small"
                value={selectedDateAfter}
                open={false}
                className={`m-0`}
                onChange={handleDateAfterChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>

          </Box>
        </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

const selectVariantsUser = [
  '',
  'Admin',
  'Admin',
  'Admin',
  'Admin',
];

export default TableFilter
