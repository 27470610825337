import React, {useState} from "react";
import {Switch} from '@material-ui/core';
import {Stack, Typography} from "@mui/material";

export default function LabeledSwitch({
                                          labelEnd = false,
                                          labelBegin = false,
                                          labelVertical = false,
                                          labelPlacementBegin = true,
                                          labelPlacementEnd = true,
                                          handleChange,
                                          defaultChecked = false,
                                          labelBeginClass = '',
                                          labelEndClass = '',
                                          switchClass = '',
                                          color = 'primary'
                                      }) {

    const [checked, setChecked] = useState(defaultChecked);
    const handleClickSwitch = () => {
        handleChange(!checked);
        setChecked(!checked);
    }
    const direction = labelVertical ? "column" : "row";
    return (
        <Stack direction={direction} alignItems="center">
            {labelPlacementBegin &&
                <Typography className={labelBeginClass} style={{cursor: "pointer"}}
                            onClick={handleClickSwitch}>{labelBegin}</Typography>}
            <Switch

                onClick={handleClickSwitch}
                className={switchClass}
                checked={checked}
                color={color}
            />
            {labelPlacementEnd &&
                <Typography className={labelEndClass} style={{cursor: "pointer"}}
                            onClick={handleClickSwitch}>{labelEnd}</Typography>}
        </Stack>
    );
}


