import TableHead from "@material-ui/core/TableHead"
import { Box, Button, TableRow, LinearProgress } from "@material-ui/core"
import React, { useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { downloadUsers } from "./functions"
import { NavLink } from "react-router-dom"

export default function AddNew({ setUploadModal, enableDownloadButton }) {
  const { theme: { colors } } = useTheme()
  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const selected = useSelector(state => state.tableData.selected)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const selectAllIds = useSelector(state => state.tableData.selectAllIds)


  //handleDownload
  const [downloadBarNow, setDownloadBarNow] = useState(0)
  const [downloadBarVisible, setDownloadBarVisible] = useState(false)

  const handleDownload = (downloadFormat) => {
    let selectedUsers = []
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (allIds?.length > 0) {
      selectedUsers = allIds
    }
    downloadUsers(tlService, token, setDownloadBarVisible, setDownloadBarNow, downloadFormat, selectedUsers)
  }

  return (
    <>
      <TableHead style={{ display: 'flex', alignItems: 'center', fontSize: '16px', marginLeft: 'auto' }}>
        <TableRow>
            <Box style={{ color: colors.colorPrimary500 }}
              className={`d-flex justify-content-between align-items-center p-1 pt-2 pr-3`} margin={1}>
              <Button onClick={() => setUploadModal(true)} color='secondary' size="small" variant='contained' style={{ marginLeft: "auto", }}>
                {t('upload')}
              </Button>
              {enableDownloadButton && <>
                <Button disabled={downloadBarVisible} onClick={() => handleDownload("SimpleXLSX")} className={`ml-2`} size="small" variant='contained'>
                  {t('download as xlsx')}
                </Button>
                <Button disabled={downloadBarVisible} onClick={() => handleDownload("SimpleCSV")} className={`ml-2`} size="small" variant='contained'>
                  {t('download as csv')}
                </Button>
              </>}
              <NavLink to={`/administration/user/update/add-new-user`} exact={true}>

            <Button className={`ml-2`} size="small" variant="contained"
              color="primary">
              {t('admin_user_add-new-user')}
            </Button>
                </NavLink>
          </Box>
            {downloadBarVisible && <div style={{ width: "100%", justifyContent: "flex-end", display: "flex", alignItems: "end" }}>
              <div style={{ marginRight: "103px", height: "5px", width: "300px" }}><LinearProgress color="primary" variant="determinate" value={downloadBarNow} /></div>
            </div>}
        </TableRow>
      </TableHead>
    </>
  )
}
