import React, { useEffect, useState } from "react"
import DayJsUtils from "@date-io/dayjs"
import Dayjs from "dayjs"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { useTranslation } from "react-i18next"
import siteLanguages from "../../config/sitelanguages.json"
import { convertUTCDateToLocalDate } from "../../utils"

function DatePicker({ formik, name, label, ...rest }) {
  const { i18n: { language: i18nLanguage } } = useTranslation()
  const [localeLoaded, setLocaleLoaded] = useState(false)
  
  const date = formik.values[name] ? formik.values[name] : ""
  const dateWithoutUTC = convertUTCDateToLocalDate(date)
  const validDate = Dayjs(dateWithoutUTC).isValid() ? Dayjs(dateWithoutUTC) : null

  useEffect(() => {
    const loadLocale = async () => {
      try {
        const localeName = siteLanguages[i18nLanguage]?.extName || "en-gb"
        await import(`dayjs/locale/${localeName}`)
        Dayjs.locale(localeName)
        setLocaleLoaded(true)
      } catch (error) {
        console.error("Error loading locale:", error)
      }
    }

    loadLocale()
  }, [i18nLanguage])

  const handleDateChange = (date) => {
    if (date && !isNaN(date.get('year'))) {
      const newDate = new Date(date)
      const dateToUTCString = Dayjs(newDate).format("YYYY-MM-DD HH:mm:ss")
      formik.setFieldValue(name, dateToUTCString)
    }
  }

  if (!localeLoaded) {
    return <div>Loading...</div>
  }

  return (
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      <KeyboardDatePicker
        id="date-picker-dialog"
        label={label}
        format="YYYY-MM-DD"
        inputVariant="outlined"
        fullWidth
        {...rest}
        value={validDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker