import React, { useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import TypeOfField from "../../../../../FormikFields/TypeOfField"
import style from "../style.module.sass"
import { Collapse } from "@material-ui/core"
import config from "../../../../../../config"
import RenderSection from "../../../Groups-table/Sections"
import { useSelector } from "react-redux"
import { checkUserRight } from "../../../../../../utils"


function Options({ formik, id }) {
    const { t, i18n: { language: i18nLanguage } } = useTranslation()
    const user = useSelector(state => state.userData.user)
    const [bg, setBg] = useState(false)
    const [collapse, setCollapse] = useState(true)
    const {theme: {bgColors, colors}} = useTheme()
    const sections = config.sortedFrontendSections
    const userRight = checkUserRight(user, [104])

  const archiveOptionsList = [
    { id: "1", control: "switch", name: "edit_archives", labelPlacementEnd: true, label: t("admin_group_edit-archives"), archive: true, disabled: !userRight },
    { id: "2", control: "switch", name: "view_archives", labelPlacementEnd: true, label: t("admin_group_view-archives"), archive: true, disabled: !userRight },
    { id: "3", control: "switch", name: "enable_textbin", labelPlacementEnd: true, label: t("admin_group_enable-textbin"), disabled: !userRight }
  ]

    return <>
      <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
           onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}
           className={`${style.benchmarks}`}>
        <div style={{backgroundColor: bgColors.bgPrimary500}} className={`${style.header} pr-4`}>
          <h4 style={{color: colors.colorPrimary0}}
              className={style.title}>{t('admin_group_user-group-options')}</h4>
          <div>
            <button className={`ml-2 p-0`} onClick={(e) => {
              e.preventDefault()
              setCollapse(!collapse)
            }}>
              {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
                <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
            </button>
          </div>
        </div>
        <Collapse in={collapse}>
          {
            !!id && <ul className={`mt-3 pb-4 d-flex`} >
              {sections.map((sec, idx) => (
                <div key={idx}>
                  <RenderSection sections={sec} row={formik.values} i18nLanguage={i18nLanguage} t={t} form={true} />
                </div>
              ))}
            </ul>
          }
          <ul className={`mt-3 pb-4 d-flex`}>
            {archiveOptionsList
              .map((f, i) => (
                <li key={i} className="d-flex align-items-center" style={{ width: '100%', marginBottom: '5px' }}>
                      <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
                    <TypeOfField control={f.control} formik={formik} name={f.name} label={f.label} {...f} />
                  </div>
                </li>
              )
              )
            }
          </ul>
        </Collapse>
      </div>
    </>
}

export default Options
