import React from "react";
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TonalityTexts from "./TonalityTexts";
import TonalitySingle from "./TonalitySingle";
import ComparisonMultiLinearChart from "../UI/ComparisonMultiLinearChart";
import { NavLink, useRouteMatch } from "react-router-dom";

export default function Tonality({ type, wide, center }) {
    const { theme: { bgColors } } = useTheme();
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
        <div className={`${style.tonality} ${wide ? style.cell_two_grid : style.cells}`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
            {type === "comparison" ?
                <ComparisonMultiLinearChart chartId={'Home-page-Tonality-chart-1234524313213'} title={t("Tonality")}
                    chartContent={{ withTotalOver: true, overTimeOppositeSide: "ComparisonTable" }}
                    seriesNames={
                        [
                            { title: "BUROKRATISCH", color: "#415C98" },
                            { title: "NEUTRAL", color: "#768fc6" },
                            { title: "ABSTRAKT", color: "#b5c0d5" },
                            { title: "KNAPP", color: "#d7d7d7" }
                        ]
                    } height={350} /> :
                type === "texts" ?
                    <TonalityTexts /> :
                    <TonalitySingle wide={wide} center={center} />
            }
            {
                !url.includes("combine") &&
                <div className={`${style.footer}`}>
                    <NavLink to={`/dashboard/${type}/tonality`}>
                        <button>{t("Detail view")}</button>
                    </NavLink>
                </div>}
        </div>
    )
    /*
    return (
        <div className={`${style.tonality} ${style.cells}`} style={{backgroundColor: bgColors.bgPrimary0}}>
            <h2 className={`m-0`}>{t("Tonality")}</h2>
            <div>
                <h3>{t("Dashboard Your text works")}</h3>
                <div className={style.tonRanger}>
                    {cells.map((cell, i) => (
                        <div key={i} className={style.tonRangerRow}>
                            <p style={{color: colors.colorBlue1000}}>{cell.leftText}</p>
                            <RangerBlocks ranger={cell.ranger} activeCell={cell.rangePosition}/>
                            <p style={{color: colors.colorBlue1000}} className={style.text2}>{cell.rightText}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${style.footer}`}>
                <button onClick={() => setDialogOpen(true)}>{t("Detail view")}</button>
            </div>
        </div>
    )

     */
}
