import style from "./style.module.scss";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {Button} from "@material-ui/core";
import React, {useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

export default function HIXTable({tableCells}) {
    const [activePagination, setActivePagination] = useState(0);
    const [wordsOnPage, setWordsOnPage] = useState(10);
    const {theme: {bgColors}} = useTheme();
    const {t} = useTranslation();

    const allPages = Math.ceil(tableCells.length / wordsOnPage);
    const tFoot = [...Array(allPages).keys()].map((value,index, arr) => {
        if (index === 0)
            return value;
        if (index === arr.length - 1)
            return value;
        if (index === 1 && activePagination>3)
            return '...';
        if (index === arr.length - 2 && activePagination < arr.length - 3)
            return '...';
        if (index < activePagination - 2)
            return null;
        if (index > activePagination + 2)
            return null;
        return value;

    })
    const to = activePagination * wordsOnPage + wordsOnPage >= tableCells.length ? tableCells.length  : activePagination * wordsOnPage + wordsOnPage

    return (
        <div className={style.main}>
            <div className={style.mainHead}>
                <TextField
                    select size={"small"}
                    value={wordsOnPage}
                    onChange={e => setWordsOnPage(parseInt(e.target.value))}
                    variant={"outlined"}>
                    <MenuItem value={5}>
                        5
                    </MenuItem>
                    <MenuItem value={10}>
                        10
                    </MenuItem>
                    <MenuItem value={15}>
                        15
                    </MenuItem>
                </TextField>
                {t("Show entries")}
            </div>
            <div className={style.table}>
                <div className={style.thead}>
                    <p>{t("word")}</p>
                    <p>{t("word length")}</p>
                    <p>{t("word frequency")}</p>
                </div>
                <div>
                    {tableCells.slice(activePagination * wordsOnPage, to).map((c, index) => (
                        <div style={{backgroundColor: c.id % 2 === 0 ? bgColors.bgPrimary0 : bgColors.bgPrimary200}}
                             className={style.tcell} key={index}>
                            <p title={c.word} style={{overflowWrap:"anywhere"}}>{c.word.length > 100 ? c.word.substring(0,50)+' ...' :  c.word }</p>
                            <p>{c.length}</p>
                            <p>{c.freq}</p>
                        </div>
                    ))}
                </div>
                <div className={style.tFoot}>
                    <p>
                        {t("n to n entries out of n entries", {
                            from: activePagination * wordsOnPage + 1,
                            to: to,
                            full: tableCells.length
                        })}
                    </p>
                    <div style={{margin: "0 0 0 5%"}}>
                        {tFoot.map((f, i) => {
                            if (f !== null)
                                return (
                                    <Button color="default" style={{minWidth: "38px"}}
                                            variant={activePagination === f ? "contained" : 'text'}
                                            onClick={() => f !== '...' && setActivePagination(parseInt(f)) }
                                            key={i}>{f !== '...' ? f + 1 : f}</Button>
                                )
                            return null;
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
