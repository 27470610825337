import style from "./style.module.sass"
import closeIcon from "../Orthography/SpellCheck/assets/close-circle.svg";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {Modal} from "@material-ui/core";

export default function ErrorModal({state, setState}) {
  const {theme: {bgColors}} = useTheme();

  return state ? (
    <Modal open={!!!state.text && !!state.type} onClose={() => setState(null)}>
      <div className={`${style.mainCont} ${state && style.open} pb-3`}
           style={{backgroundColor: bgColors.bgPrimary0}}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}>
          <p className={`m-0 ${style.title}`}>Something went wrong!</p>
          <button className={`border-0 bg-transparent`} onClick={() => setState(null)}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <p className={`${style.descText} mt-2 m-0 pr-3 pl-3`}>
          {state.type === "add" ?
            "Please select the text you want to add to your allowed list" :
            "Please select the text you want to ban from your corporate language"
          }
        </p>
      </div>
    </Modal>
  ) : null
}
