import {Button, Checkbox, Modal, TextField} from "@material-ui/core";
import style from "./style.module.sass";
import nextId from "react-id-generator";
import {Check, Delete, Edit} from "@material-ui/icons";
import {useFormik} from "formik";
import Select from "../../../../components/Fields/Select";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";

const tableRows = [
  {
    id: "1",
    replacement: "total",
    description: "Only use this replacement in medical context",
    meaning: "medical context",
    editing: false,
  }
];

const checkReqs = values => Object.keys(values).filter(v => !values[v]);

export default function EditTermModal({open, setOpen}) {
  const formik = useFormik({
    initialValues: tableRows,
    onSubmit: () => submit()
  });
  const [filter, setFilter] = useState('');
  const handleFilter = e => setFilter(e.target.value);
  const { t } = useTranslation();

  const submit = () => {
    formik.setFieldValue(`${[formik.values.length]}`, {
      id: nextId(),
      replacement: "",
      description: "",
      meaning: "",
      editing: true
    })
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)} className={`d-flex align-items-center justify-content-center`} style={{height: "100%"}}>
        <form onSubmit={formik.handleSubmit}>
          <div className={`${style.termModal}`}>
            <div className={`d-flex align-items-center justify-content-between`}>
              <h6 className={`m-0`}>{t('add-term')}</h6>
              <Button color='primary' variant={'contained'} size='small' type='submit'>
                {t('add-new-term-replacement')}
              </Button>
            </div>
            <div className={`d-flex flex-column align-items-end mt-3`}>
              <div style={{width: '100%', gridGap: 10}} className={`d-flex flex-column align-items-start`}>
                <table cellPadding={10} border={1} bordercolor={'#dee2e6'} style={{width: "100%"}}>
                  <tbody>
                  <tr>
                    <th/>
                    <th>id</th>
                    <th>{t('replacement')}</th>
                    <th>{t('description')}</th>
                    <th>{t('meaning')}</th>
                    <th>{t('edit')}</th>
                  </tr>
                  {
                    formik.values.map(
                      (td, i) =>
                        <tr key={td.id}>
                          <td><Checkbox color='primary'/></td>
                          <td>{td.id}</td>
                          <td>{
                            !td.editing ? td.replacement :
                              <TextField required label='replacement' name={`${[i]}.replacement`}
                                         onChange={formik.handleChange} value={formik.values[i].replacement}
                                         size='small'
                                         variant='outlined'/>
                          }</td>
                          <td>{
                            !td.editing ? td.description :
                              <TextField required multiline rows={3} label='description' name={`${[i]}.description`}
                                         onChange={formik.handleChange} value={formik.values[i].description}
                                         size='small'
                                         variant='outlined'/>
                          }</td>
                          <td>{
                            !td.editing ? td.meaning :
                              <TextField required label='meaning' name={`${[i]}.meaning`} onChange={formik.handleChange}
                                         value={formik.values[i].meaning}
                                         size='small'
                                         variant='outlined'/>
                          }</td>
                          <td>
                            <div className={`d-flex align-items-center`}>
                              {
                                td.editing ?
                                  <Button color='primary' size={'small'} className={`p-0`}
                                          onClick={() => !checkReqs(td).length ? formik.setFieldValue(`${[i]}.editing`, false) : alert(`${checkReqs(td)[0]} must be filled!`)}>
                                    <Check/>
                                  </Button> :
                                  <Button color='primary' size={'small'} className={`p-0`}
                                          onClick={() => formik.setFieldValue(`${[i]}.editing`, true)}>
                                    <Edit/>
                                  </Button>
                              }
                              <Button color='primary' size={'small'} className={`p-0`}><Delete/></Button>
                            </div>
                          </td>
                        </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
              <div className={`d-flex mt-3 w-100`}>
                <Select variant={'filled'} style={{backgroundColor: "#fff", width: 250}} value={filter} handleChange={handleFilter} label={t('Choose actions')}
                        options={[{id: 1, name: t("Delete Selected Items")}, {id: 2, name: t("Delete All Items")}]}/>
                <Button style={{marginLeft: "auto"}} color='default' onClick={() => setOpen(false)}>
                  {t('close')}
                </Button>
                <Button variant="contained" color='primary' className={`ml-2`} onClick={() => setOpen(false)}>
                  {t('save')}
                </Button>
              </div>
            </div>
          </div>
        </form>
    </Modal>
  )
}
