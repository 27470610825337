import style from "./style.module.sass"
import closeIcon from "../../../../assets/close-circle.svg";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

export default function ErrorModal({ error, setShowLoadText, setError }) {
  const { theme: { bgColors } } = useTheme();

  return error ? (
    <Modal open={!!error} onClose={() => {
      setError(null);
      // setShowLoadText(false);
    }}>
      <div className={`${style.mainCont} ${!!error && style.open} pb-3`}
        style={{ backgroundColor: bgColors.bgPrimary0 }}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}>
          <p className={`m-0 ${style.title}`}>{error.header}</p>
          <button className={`border-0 bg-transparent`} onClick={() => {
            setError(null);
            // setShowLoadText(false);
          }}>
            <img src={closeIcon} alt="" />
          </button>
        </div>
        <p className={`${style.descText} mt-2 m-0 pr-3 pl-3`}>
          {error.text}
        </p>
      </div>
    </Modal>
  ) : null
}
