import React, { useMemo, useState } from "react"
import style from "./style.module.sass"
import arrowDown from "../../../AnalyseFooter/assets/arrow-down.svg"
import { Collapse } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { chooseColor, convertJSONToObject, convertLanguagesToFull, convertLanguagesToShort, getValue, isObjectEmpty } from "../../../../../utils"
import { useSelector } from "react-redux"
import config from "../../../../../config"

export default function AiFooter({
    charactersCount,
    wordsCount,
    handleOnLanguageChange,
    handleOnTextTypeChange,
}) {


    const { t, i18n: { language: i18nLanguage } } = useTranslation()
    const [filter, setFilter] = useState("")
    const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
    const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
    const textLanguage = useSelector(state => state.analyseData.textLanguage)
    const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
    const autoTextLanguage = useSelector(state => state.analyseData.autoTextLanguage)
    const userTypeId = useSelector(state => state.userData.user.user_type_id)
    const [openDrop, setOpenDrop] = useState("")
    const shortLanguage = convertLanguagesToShort(i18nLanguage)
    const storageLanguageTextType = convertJSONToObject(
        useSelector(
            (state) => state.userData?.user?.user_options?.analyse_language_texttype
        ),
        config.defaultAnalyseLanguageTextType
    )
    if (typeof storageLanguageTextType.textType !== 'object') {
        storageLanguageTextType.textType = {}
    }

    const isAutoLang = storageLanguageTextType.language === "auto"
    const fullLanguage = convertLanguagesToFull(i18nLanguage)
    const currentLanguage = backendLanguages[textLanguage] || backendLanguages[fullLanguage]
    const currentTextType = enabledBenchmarks[useBenchmark] || enabledBenchmarks[Object.keys(enabledBenchmarks)[0]]

    const realEnabledBenchmarks = useMemo(() => {
        if (filter.length > 0)
            return Object.entries(enabledBenchmarks).filter(v => v[1]?.name[shortLanguage].indexOf(filter) > -1)
        else
            return Object.entries(enabledBenchmarks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabledBenchmarks, filter])

    return (
        <div className={style.mainCont}>
            <div className={`${style.selects}`} style={{  marginLeft: "10px", maxWidth: "100%" }}>
                <div className={`${style.dropLanguage}`} >
                    <div onClick={() => setOpenDrop(s => s !== "lang" ? "lang" : "")}
                        className={`d-flex justify-content-between align-items-center`}>
                        <p className={`m-0 pr-1 font-weight-bold`}>{t("Language")}:</p>
                        <div className={`${style.selectField}`} style={{ marginRight: "100px", minWidth: "150px" }}>
                            <p className={`m-0 pr-1`}><b>{currentLanguage?.nativeName} {isAutoLang && " (auto)"}</b></p>
                            <img src={arrowDown}
                                className={`${style.icon} ${openDrop === "lang" && style.iconReversed}`} alt="" />
                        </div>
                    </div>
                    <Collapse className={`position-absolute d-flex flex-column-reverse`}
                        style={{ top: "-75px", right: "29px" }} in={openDrop === "lang"}>
                        <ul className={`${style.select}`}>
                            {
                                Object.entries(backendLanguages).map(([key, value]) =>
                                    <li key={key} onClick={() => {
                                        setOpenDrop("")
                                        handleOnLanguageChange(key)
                                    }}>
                                        {value['nativeName']} {autoTextLanguage === key && ' (auto)'}
                                    </li>
                                )
                            }
                        </ul>
                    </Collapse>
                </div>
                {!isObjectEmpty(enabledBenchmarks) &&
                    <div className={`${style.dropTextType}`}>
                        {userTypeId === 1 && <input type="text" value={filter} onChange={e => setFilter(e.target.value)} />}
                        <div onClick={() => setOpenDrop(s => s !== "text" ? "text" : "")}
                            className={`d-flex justify-content-between align-items-center`} style={{ marginLeft: "-80px" }}>
                            <p className={` m-0 pr-0 font-weight-bold`} style={{ minWidth: "80px" }}>{t("Text Type")}:</p>
                            <div className={`${style.selectField}`} style={{ minWidth: "150px" }}>
                                <p className={`m-0 pr-1`}><b>{currentTextType?.name[shortLanguage] || currentTextType?.id}</b></p>
                                <img src={arrowDown}
                                    className={`${style.icon} ${openDrop === "text" && style.iconReversed}`} alt="" />
                            </div>
                        </div>
                        <Collapse className={`position-absolute d-flex flex-column-reverse`}
                            style={{ top: "-75px", right: "-10px" }} in={openDrop === "text"}>
                            <ul className={`${style.select}`}>
                                {
                                    realEnabledBenchmarks.map(([key, val]) =>
                                        <li key={key} onClick={() => {
                                            setOpenDrop("")
                                            handleOnTextTypeChange(parseInt(key.toString()))
                                        }}>{val['name'][shortLanguage] || key}
                                        </li>
                                    )
                                }
                            </ul>
                        </Collapse>
                    </div>
                }
            </div>
        </div>
    )
}