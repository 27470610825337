import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "../withBigRow.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {Box} from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';


export default function Subrow({title, text}) {
  const {theme: {bgColors, colors}} = useTheme();

  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary300}} className={`${style.borderTop}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box className={`d-flex align-items-center p-3`} margin={1}>
            <p style={{color: colors.colorPrimary1000}} className={`m-0 mr-4`}>{title}</p>
          </Box>
        </TableCell>
        </TableRow>
      </TableHead>
      {text && <TableHead style={{backgroundColor: bgColors.bgPrimary100}} className={`${style.borderTop}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box className={`d-flex align-items-center p-2 pr-3 pl-3`} margin={1}>
            <p style={{color: colors.colorPrimary1000, fontWeight: "400"}} className={`m-0 mr-4`}>{text}</p>
          </Box>
        </TableCell>
        </TableRow>
      </TableHead>}
    </>
  );
}
