const updateBatchData = (state, action) => {

  const defaultState = {
    running: false,
    paused: false,
    done: false,
    finishedAnalyses: 0,
    errorAnalyses: 0,
    totalAnalyses: 0,
    folderID: 0,
    ApiOption: 'personal',
    language: 'de_DE',
    hasError: false,
    error: null,
    errorDocument: null,
    percent: 0,
    progressByDocument: []
  }

  if (state === undefined) {
    return defaultState
  }
  switch (action.type) {
    case 'BATCH_PROCESSING_START':
      return {
        ...state.batchData,
        ApiOption: action.payload.ApiOption,
        running: true,
        totalAnalyses: action.payload.totalAnalyses,
        finishedAnalyses: 0,
        errorAnalyses: 0,
        percent: 0,
        hasError: false,
        done: false,
        language: action.payload.language,
        progressByDocument: action.payload.progressByDocument
      }
    case 'BATCH_PROCESSING_PROGRESS': {
      return {
        ...state.batchData,
        finishedAnalyses: action.payload.finishedAnalyses,
        percent: action.payload.percent,
        progressByDocument: action.payload.progressByDocument
      }
    }
    case 'BATCH_PROCESSING_PERCENT': {
      return {
        ...state.batchData,
        percent: action.payload,
      }
    }
    case 'BATCH_PROCESSING_UPDATE_ERROR': {
      return {
        ...state.batchData,
        errorAnalyses: action.payload.errorAnalyses
      }
    }
    case 'BATCH_PROCESSING_DONE': {
      return {
        ...state.batchData,
        folderID: action.payload.folderID,
        running: false,
        done: true
      }
    }
    case 'BATCH_PROCESSING_CLEAR': {
      return defaultState
    }
    case 'BATCH_PROCESSING_ERROR': {
      return {
        ...state.batchData,
        hasError: true,
      }
    }
    case 'BATCH_PROCESSING_SET_PAUSED': {
      return {
        ...state.batchData,
        paused: action.payload.paused
      }
    }
    default:
      return state.batchData
  }
}

export default updateBatchData