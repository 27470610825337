import React from "react"
import { Switch as SwitchField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function Switch({ formik, label, name, labelPlacementEnd, status, value = null, archive = false, onChange, defaultChecked, color = 'primary', setAutoFillState, rights, ...rest }) {

  const handleChange = (event) => {
    if (archive) {
      const isChecked = event.target.checked
      const newValue = isChecked ? 1 : 0
      formik.setFieldValue(name, newValue)
    } else {
      formik.setFieldValue(name, event.target.checked)
    }
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <FormControlLabel
      name={name && name}
      // onClick={() => setAutoFillState && setAutoFillState(null)}
      {...rest}
      color={color}
      labelPlacement={labelPlacementEnd ? "end" : "top"}
      control={<SwitchField
        defaultChecked={defaultChecked}
        onChange={handleChange}
        // checked={status && status === "company" ? true : status && status === "group" ? false : value ? value : formik?.values[name]}
        checked={rights ? defaultChecked : value ? value : formik?.values[name]}
        color={rest?.disabled ? "default" : color} />}
      disabled={rest?.disabled}
      label={label}
    />
  )
}

export default Switch
