import React from "react";
import style from "./style.module.sass";
import {NavLink} from "react-router-dom";
import arrow from "../../../../Profile/ProfileWrapper/assets/arrow.svg";
import {Button} from "@material-ui/core";
import { useTranslation } from "react-i18next";

function Dashboard() {

  const t = useTranslation();

  return (
    <div className={style.mainCont}>
      <NavLink to="/administration/cleaning-rules" exact={true}>
        <Button color="primary" variant="contained"
                className={`d-flex align-items-center ${style.breadcrumb} pl-3`}>
          <img src={arrow} className={`mr-3`} alt=""/>
          {t('cleaning-rules')}
        </Button>
      </NavLink>
    </div>
  )
}

export default Dashboard;
