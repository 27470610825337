import React, { useEffect, useMemo, useState } from "react";
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles";
import "./tag-cloud.css"
import { Slider, Button, Select, MenuItem } from "@material-ui/core";
import WordsTable from "./words-table/WordsTable";
import HomeWordsCloud from "../../Home/HomeWordsCloud";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import { isObjectEmpty } from "../../../../utils";
import TagCloud from "react-tag-cloud";
import { dashboardSettermWordCount } from "../../../../actions";
import { useActions } from "../../../../utils/action-helper";
import config from '../../../../config'

export default function VocabularyComponent({ combine }) {
    const [dictionary, setDictionary] = useState(false)
    const [funcWord, setFuncWord] = useState(false)
    const [terms, setTerms] = useState(20)
    const [maxTerms, setMaxTerms] = useState(100);
    const { theme: { bgColors, colors } } = useTheme();
    const [sort, setSort] = useState("");
    const [wordBase, setWordBase] = useState(1)
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const termWordCount = useSelector(state => state.dashboardData.termWordCount);
    const analyseLanguage = useSelector(state => state.analyseData.result.benchmark.locale_name);
    const wordsCloudConfig = config.wordsCloudTemplate[analyseLanguage];

    // console.log('check1', analyseLanguage, wordsCloudConfig)
    const { actionDashboardSettermWordCount } = useActions({
        actionDashboardSettermWordCount: dashboardSettermWordCount
    })

    const handleWordLemmaBase = (e) => {
        setWordBase(e.target.value);
    }

    const addWordItem = (position, item) => {
        const jArrayToken = resultData['dataTokenizer'][position];
        if (wordsCloudConfig.ignoreTags.includes(jArrayToken.tag)) {
            return;
        }

        if (wordsCloudConfig.ignoreTypes.includes(jArrayToken.type)) {
            return;
        }

        const wordItem = {
            position,
            color: item.color,
            type: item.label,
            rotate: Math.random() > 0.8 ? 90 : 0
        };

        if (wordBase > 0) {
            if (wordsCloudConfig.ignoreWords.includes(jArrayToken.word)) {
                return;
            }
            wordItem.length = jArrayToken.word.length;
            wordItem.num = resultData['dataTokenizer'].filter(val => val.word === jArrayToken.word).length;
            wordItem.text = jArrayToken.word;
        } else {
            if (wordsCloudConfig.ignoreLemmas.includes(jArrayToken.lemma)) {
                return;
            }
            wordItem.length = jArrayToken.lemma.length;
            wordItem.num = resultData['dataTokenizer'].filter(val => val.lemma === jArrayToken.lemma).length;
            wordItem.text = jArrayToken.lemma === '@card@' ? '123...': jArrayToken.lemma;
        }
        return wordItem;
    };

    function calculateWordsCloud(resultData, sort, terms, funcWord) {

        let wordsWithFilters = [];
        wordsCloudConfig.catType.map((item) => {

            if ((sort === "" || sort === item.label ) && resultData?.[item.countData]?.length) {
                resultData[item.countData].map(position => {
                    const newItem = addWordItem(position, item);
                    if (newItem)
                        wordsWithFilters.push(newItem);
                });
            } else {
                console.log(`resultData does not have key: ${item.label} or it's empty`);
            }
        });

        // filter for function words
        if(sort === "") {
            const allFunctionWords = [];
            // if (funcWord) {
                // Add respective item of below array when if it is available:
            wordsCloudConfig.funcWordData.map((func) => {
                if (resultData[func]?.length) {
                    allFunctionWords.push(
                      ...resultData[func]
                    )
                }
            })
            // }

            const item = {label: 'All', color: "#172535"};
            for(let i = 0; i < resultData['dataTokenizer'].length; i++ ) {
                if (allFunctionWords.includes(i)) {
                    if(funcWord) {
                        continue;
                    } else {
                        item.label = 'Function';
                        item.color = "#656565";
                    }
                } else {
                    item.label = 'All';
                    item.color = "#172535";
                }
                // const newItem = addWordItem(i, { type:  itemType, color: itemType !== 'Function'? "#172535" : "#351a17" });
                const newItem = addWordItem(i, item);
                if (newItem)
                    wordsWithFilters.push(newItem);
            }

        }

        //  uniqueficalion
        wordsWithFilters = wordsWithFilters.filter((value, index, self) => {
            return self.findIndex(val => val.text === value.text) === index
        })

        // sort
        wordsWithFilters.sort((a, b) => a.num > b.num ? -1 : 1)

        // and set limit
        setMaxTerms(wordsWithFilters.length);
        wordsWithFilters = wordsWithFilters.slice(0, terms);

        // calculate word sizes
        const maxMinSize = wordsWithFilters.reduce((acc, val) => [acc[0] < val.num ? val.num : acc[0], acc[1] > val.num ? val.num : acc[1]], [0, 10000]);
        wordsWithFilters = wordsWithFilters.map(v => {
            return { ...v, fontSize: Math.ceil(v.num / (maxMinSize[0] / 50)) }
        })
        return wordsWithFilters;
    }

    // memorisation result
    const wordsWithFilters = useMemo(() => {
        if (!isObjectEmpty(resultData)) {
            return calculateWordsCloud(resultData, sort, terms, funcWord)
        }
        return []
    }, [resultData, sort, terms, funcWord, wordBase])

    function handleOnSetTerms(event, newValue) {
        setTerms(newValue);
        actionDashboardSettermWordCount(newValue)
    }

    return (
        <div className={`${style.vocabulary}`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
            {!combine ? <>  <div className={`${style.head}`}>
                <h2 style={{ color: colors.colorPrimary400 }}>{t("Vocabulary")}</h2>
                <div style={{ gridGap: "10px" }} className={`d-flex align-items-center`}>
                    <Select
                        value={wordBase}
                        onChange={handleWordLemmaBase}
                    >
                        <MenuItem value={1}>{t('dashboard_wordcloud_wordbase')}</MenuItem>
                        <MenuItem value={0}>{t('dashboard_wordcloud_lemmabase')}</MenuItem>
                    </Select>

                    <Button color={!dictionary ? "primary" : "default"} onClick={() => setDictionary(s => !s)}
                        variant="contained"
                        className={`${style.wordsCloud}`}>{t("Word cloud")}</Button>
                    <Button color={dictionary ? "primary" : "default"} onClick={() => setDictionary(s => !s)}
                        variant="contained"
                        className={`${style.wordsCloud}`}>{t("List view")}</Button>
                </div>
            </div>
                <div className={style.vocMain}>
                    {dictionary ?
                        <div className={`d-flex`} style={{ gridGap: 80 }}>
                            <WordsTable wordsWithFilters={wordsWithFilters} />
                        </div> :
                        <HomeWordsCloud wordsWithFilters={wordsWithFilters} />
                    }
                    <div className={style.legends}>
                        <div className={`mb-4 d-flex flex-column`}>
                            <h4 className={`mb-2`}>{t("Parts of speech")}</h4>
                            {wordsCloudConfig.catType && wordsCloudConfig.catType.map((item, index) => (
                              <Button
                                key={'button_tag_' + index}
                                variant={sort !== item.label ? 'text' : 'contained'}
                                color={sort !== item.label ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== item.label ? item.color : colors.colorPrimary0 }}
                                onClick={() => setSort(item.label)}
                              >{t(item.label)}</Button>
                            ))}
                            <Button variant={sort !== "" ? "text" : "contained"}
                                color={"primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                onClick={() => setSort("")}>{t("Show all")}</Button>
                        </div>
                        <div>
                            <h4 className={`mb-2`}>{t('filter')}</h4>
                            <Button style={{ fontSize: 10 }} className={`mb-3 d-flex p-1`}
                                color={funcWord ? "primary" : "default"} variant={"contained"}
                                onClick={() => setFuncWord(s => !s)}
                            >{t("without function words")}</Button>
                            <div>
                                <p className={`d-flex align-items-center`}
                                    style={{ color: colors.colorPrimary400 }}>{t("Terms")}
                                    <input className={`ml-2`} max={maxTerms}
                                        style={{ width: "35px", outline: "none" }}
                                        type="number" value={terms}
                                        onChange={e => setTerms(e.target.value)} /></p>
                                <Slider min={1} max={maxTerms} value={terms} onChange={handleOnSetTerms} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
                :
                wordsWithFilters?.length && <div className={style.vocMain} style={{ height: "400px" }}>
                    <div className={"mainTagCloud"}>
                        <div className={"app-outer"}>
                            <div className={"app-inner"}>
                                <TagCloud className='tag-cloud' style={{ padding: 6, fontFamily: 'Roboto', width: "800px", marginLeft: "-120px" }}>
                                    {wordsWithFilters.map(w => (
                                        <div key={w.text} rotate={w.rotate} style={{
                                            fontSize: w.fontSize,
                                            fontWeight: "bolder",
                                            color: w.color,
                                        }}>{w.text}</div>
                                    ))}
                                </TagCloud>
                            </div>
                        </div>
                    </div>
                    <div className={style.legends}>
                        <div className={`mb-4 d-flex flex-column`} style={{ paddingBottom: "170px" }}>
                            <h4 className={`mb-2`}>{t("Parts of speech")}</h4>
                            <Button variant={sort !== "Adjectives" ? "text" : "contained"}
                                color={sort !== "Adjectives" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Adjectives" ? "#3fbdff" : colors.colorPrimary0 }}
                                onClick={() => setSort("Adjectives")}>{t("Adjectives")}</Button>
                            <Button variant={sort !== "Nouns" ? "text" : "contained"}
                                color={sort !== "Nouns" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Nouns" ? "#415c98" : colors.colorPrimary0 }}
                                onClick={() => setSort("Nouns")}>{t("Nouns")}</Button>
                            <Button variant={sort !== "Verbs" ? "text" : "contained"}
                                color={sort !== "Verbs" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Verbs" ? "#5e47d9" : colors.colorPrimary0 }}
                                onClick={() => setSort("Verbs")}>{t("Verbs")}</Button>
                        </div>
                        <h4>
                            {t("numberOfWords_dashboard_pdf")}  : </h4> <b> {termWordCount}
                        </b>
                    </div>
                </div>
            }
        </div>
    )
}
