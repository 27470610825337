import 'date-fns';
import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {Box, Button, IconButton} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import {InputBase} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { useTranslation } from "react-i18next";

function TableFilter({setRows, rows, setAfterAddField}) {
  const {theme: {bgColors, borderColors, colors}} = useTheme();
  const hasEmpty = rows.find(r => (r.replacementTerm === ""))
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.tableFilter}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box style={{color: colors.colorPrimary500}}
               className={`d-flex align-items-center p-3`} margin={1}>
            <Paper className={`${style.search} d-flex`}
                   style={{backgroundColor: bgColors.bgPrimary0, borderColor: borderColors.borderPrimary400}}>
              <IconButton style={{padding: "9px"}} type="submit" aria-label="search">
                <SearchIcon/>
              </IconButton>
              <InputBase
                placeholder="Search..."
              />
            </Paper>
            <Button className={`${style.addBtn} ${style.autoMargin}`} variant="contained" color="primary">
              {t('import-svc-file')}
            </Button>
            <Button
              onClick={() => {
              setAfterAddField(true)
                !hasEmpty ?
              setRows(s => [{id: s.length, replacementTerm: ""}, ...s]) :
                alert("You cannot add a new cell without changing the previously added cell!")
            }}
              className={`${style.addBtn} ml-3`} variant="contained" color="primary"
                    startIcon={<AddIcon/>}>
              {t('add-new-entry')}
            </Button>
          </Box>
        </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}

export default TableFilter
