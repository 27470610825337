import React from "react";
import style from "./style.module.scss"
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {PreLoaderCont} from "./PreLoaderCont";
import CollapseFile from "./CollapseFile";
import {Alert} from "@material-ui/lab";
import {isObjectEmpty} from "../../../utils";
import ColumnChart from "../UI/ColumnChart";


export default function SentenceConst({seriesHIX, type = 0, headText = "", counters, benchmarkWarning, collapseFile = true, hixTableCells}) {
    const {theme: {bgColors}} = useTheme();

    const {t} = useTranslation();
    const color = type === 1 ? {backgroundColor: "#cee0ff", color: "#00091d"} : {
        backgroundColor: "#fae9c8",
        color: "#efa90b"
    };

    if (!counters?.length) {
        return null;
    }

    return (
        <>
            {type > 0 && <div style={{backgroundColor: bgColors.bgPrimary0, minHeight: "147px"}} className={`${style.cells} ${style.sentenceConst}`}>
                 <div className={style.nameRow}>
                    <div className={style.textWrap} style={color}>
                        <p className={`m-0 ${style.text}`}>{headText}</p>
                    </div>
                </div>
                {benchmarkWarning && <Alert className={`mb-1`} severity="error">
                    {t('text types is different')}
                </Alert>}
            </div>}

            {(!isObjectEmpty(seriesHIX) && !isObjectEmpty(seriesHIX[0])) &&
                <div style={{gridGap: "30px", margin: "30px 30px 30px 0"}}>
                    <ColumnChart mainText={t("dash_hix_main_text")} series={seriesHIX[0]} mean={seriesHIX[1]} height={350}
                                 chartId={t("HIX_dashboard_chartID")} min={0} max={20}/>
                </div>
            }
            <div style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.cells} ${style.sentenceConst}`}>
            {counters.map((value,index) =>
                <div key={index} className={`d-flex flex-lg-nowrap flex-md-wrap`} style={{gridGap: "10px"}}>
                    <div className={`w-100`} style={index > 0 ? {borderTop: `4px solid #e2e1e1`} : {}}>
                        <PreLoaderCont
                            id={'diagram-'+index}
                            withLinear={value.withLinear}
                            value={value.value}
                            label={value.label}
                            color={value.color}
                            textsComp={value.textsComp}
                            text={value.text}
                            title={value.title}
                            subTitle={value.subTitle}
                            lCount={value.lCount}
                            lCountMax={value.lCountMax}
                            lText={value.lText}
                            lTitle={value.lTitle}
                        />
                    </div>
                </div>
            )}
                {(collapseFile && hixTableCells?.length) ? <CollapseFile hixTableCells={hixTableCells} /> : null}
            </div>
        </>
    )
}
