import React, {useState} from 'react';
import style from "./style.module.sass";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Rows from "./Rows";
import {useTheme} from '@material-ui/core/styles';
import EnhancedTableHead from "./TableHead";
import TableFilter from "./TableFilter";
import TableFooter from "./TableFooter";

function createData(id, userId, userName, logType, data, date) {
  return {id, userId, userName, logType, data, date}
}

const rows = [
  createData(992, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(312, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(322, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(332, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(542, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(552, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(562, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(572, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(582, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(592, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(402, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(431, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(132, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(433, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(134, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
  createData(135, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(136, 2133, "Hannah", "Login", [<p className={`m-0`}><b>Session Data:</b> <br/>Type of User: Super-Admin User group: ComLab</p>], `02-09-2021 07:44:58`),
  createData(137, 2133, "hoffman-natalie", "Analyse-durchfuhren", [<p className={`m-0`}>Analysis id: 2a7cad87-36ee-4a9f-9391-fea1f65c8510</p>], `02-09-2021 07:44:58`),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Logs() {
  const {theme: {bgColors}} = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div className={`${style.mainCont}`}>
      <Paper className={`mb-5`}>
        <TableContainer>
          <Table
            style={{backgroundColor: bgColors.bgPrimary100, borderColor: "transparent"}}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableFilter
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <Rows key={row.id} handleClick={handleClick} isItemSelected={isItemSelected} labelId={labelId}
                          row={row}
                          isEven={index % 2 === 0}/>
                  );
                })}
            </TableBody>
            <TableFooter
              rowsPerPageOptions={[10, 25, 40]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
