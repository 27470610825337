import { readDNDFiles, startAnalyse } from '../analysisFunctions'
import {
  batchProcessingUpdateError,
} from '../../../actions'
import { clearHtml, returnValuesFilter } from '../../../utils'

export default class BatchProcessingService {
  _internalState = {
    running: false,
    paused: false,
    finishedAnalyses: 0,
    errorAnalyses: 0,
    errorMessages: [],
    totalAnalyses: 0,
    percent: 0,
    data: {},
    callback: () => { },
    token: '',
    tlService: null,
    translate: null,
    userId: null,
    userGroupId: null,
    companyId: null,
    progressByDocument: [],
    analysisInProgress: false,
    lastFilename: null
  }

  _beforeUnloadListener = null

  startBatchProcessing = async (token, dispatch, userName, benchmarks, ApiOption, userId, userGroupId, companyId,
    tlService, translate, batchProcessingData, returnValues, stateChangeCallback, maxRequestSize, maxAnalizeTextSize) => {
    this._resetInternalState()
    this._internalState.maxRequestSize = maxRequestSize
    this._internalState.returnValues = returnValues
    this._internalState.maxAnalizeTextSize = maxAnalizeTextSize
    this._internalState.dispatch = dispatch
    this._internalState.userName = userName
    this._internalState.benchmarks = benchmarks
    this._internalState.ApiOption = ApiOption
    this._internalState.running = true
    this._internalState.errorAnalyses = 0
    this._internalState.data = batchProcessingData
    this._internalState.callback = stateChangeCallback
    this._internalState.token = token
    this._internalState.userId = userId
    this._internalState.userGroupId = userGroupId
    this._internalState.companyId = companyId
    this._internalState.tlService = tlService
    this._internalState.translate = translate
    this._internalState.totalAnalyses = this._internalState.data.files.length
    this._createBeforeUnloadListener()
    this._prepareProgressByDocument()
    this._internalState.callback('BATCH_PROCESSING_START', {
      language: batchProcessingData.language,
      ApiOption: this._internalState.ApiOption,
      totalAnalyses: this._internalState.totalAnalyses, progressByDocument: this._internalState.progressByDocument
    })
    return await this._nextAnalyisBatchProcessing()
  }

  pauseBatchProcessing = () => {
    if (!this._internalState.paused) {
      this._internalState.paused = true
      this._internalState.callback('BATCH_PROCESSING_SET_PAUSED', { paused: true })
    }
  }

  resumeBatchProcessing = async () => {
    if (this._internalState.paused) {
      this._internalState.paused = false
      this._internalState.callback('BATCH_PROCESSING_SET_PAUSED', { paused: false })
      this._internalState.progressByDocument[this._internalState.finishedAnalyses].state = 'running'
      this._publishProgress()

      if (!this._internalState.analysisInProgress) {
        return await this._nextAnalyisBatchProcessing()
      }
    }
  }

  cancelBatchProcessing = () => {
    this._internalState.running = false
    this._internalState.callback('BATCH_PROCESSING_CLEAR')
  }

  _nextAnalyisBatchProcessing = async () => {
    if (this._internalState.data.files.length === 0) {
      this._removeBeforeUnloadListener()
      this._internalState.callback('BATCH_PROCESSING_DONE', { folderID: this._internalState.data.folderID })
      return
    }

    if (!this._internalState.running || this._internalState.paused) {
      if (this._internalState.paused) {
        this._internalState.progressByDocument[this._internalState.finishedAnalyses].state = 'paused'
        this._publishProgress()
      }
      return
    }

    let file = this._internalState.data.files.shift()

    this._internalState.analysisInProgress = true

    let resultText = await this._extractText(file)
    let text = !resultText.text ? null : resultText.text
    let filename = file.name

    this._internalState.lastFilename = filename

    let error = ''

    if (!!resultText.error) {
      this._onError()
      error = resultText.error.text
      // return
    }

    // replace all &nbsp; to utf-8 (160)
    text = clearHtml(text)

    let resultAnalysis = await this._analyzeText(text)

    resultAnalysis.languageTool = []
    resultAnalysis.benchmark = this._internalState.benchmarks[resultAnalysis.resultTemplate]

    if (!!resultAnalysis.error) {
      this._onError()
      error = resultAnalysis.error.text
      //   return
    } else {
      delete resultAnalysis.error

      try {
        await this._saveAnalysis(filename, text, resultAnalysis)
      } catch (e) {
        //let errorMessage = e.message ? e.message : this._internalState.translate("errorMessage")
        this._onError()
        error = e.message ? e.message : this._internalState.translate('errorMessage')
        //  return
      }
    }

    if (error.length === 0) {
      if (!!this._internalState.progressByDocument[this._internalState.finishedAnalyses]) {
        this._internalState.progressByDocument[this._internalState.finishedAnalyses].state = 'done'
      }
    } else {

      this._internalState.dispatch(batchProcessingUpdateError({ errorAnalyses: ++this._internalState.errorAnalyses }))
      if (!!this._internalState.progressByDocument[this._internalState.finishedAnalyses]) {
        this._internalState.progressByDocument[this._internalState.finishedAnalyses].state = 'error'
        this._internalState.progressByDocument[this._internalState.finishedAnalyses].error = error
      }
    }

    this._internalState.finishedAnalyses++

    if (!!this._internalState.progressByDocument[this._internalState.finishedAnalyses]) {
      this._internalState.progressByDocument[this._internalState.finishedAnalyses].state = 'running'
    }

    this._internalState.percent = this._internalState.totalAnalyses === 0 ? 0 : (this._internalState.finishedAnalyses * 100) / this._internalState.totalAnalyses
    this._publishProgress()
    this._internalState.analysisInProgress = false
    await this._nextAnalyisBatchProcessing()
  }
  //function decodeHtml(html) {
  //    const txt = document.createElement("textarea");
  //    txt.innerHTML = html;
  //    return txt.value;
  //}

  _analyzeText = async (text) => {
    return await startAnalyse(
      // this._internalState.data.folderID,
      this._internalState.data.textType,
      this._internalState.data.language,
      text,
      this._internalState.tlService,
      this._internalState.token,
      this._internalState.translate)
  }

  _saveAnalysis = async (filename, text, resultAnalysis) => {
    let token = this._internalState.token
    let name = filename
    let version = 1
    let textType = this._internalState.data.textType
    let analyseText = text
    let analyseResult = resultAnalysis
    let hix = analyseResult.resultData.formulaHix
    let clix = analyseResult.resultData.formulaCLIX
    let folderId = this._internalState.data.folderID
    let textCleaning = resultAnalysis?.resultData?.textTextCleaning
      ? resultAnalysis.resultData.textTextCleaning
      : resultAnalysis.resultData.convertedText
    let tags = this._internalState.data.keywords
    let userId = this._internalState.userId
    let userGroupId = this._internalState.userGroupId
    let companyId = this._internalState.companyId
    //let dispatch = this._internalState.dispatch;
    let ApiOption = this._internalState.ApiOption
    let benchmarks = this._internalState.benchmarks

    const benchmark = benchmarks[textType] ? benchmarks[textType] : undefined

    if (!!benchmark) {
      const intBenchmarkId = parseInt(textType)
      let settingIs = {}
      settingIs['user_name'] = this._internalState.userName
      settingIs['benchmark_name'] = benchmark.name
      const languageIs = benchmark.locale_name

      let resultIs = returnValuesFilter(analyseResult.resultData, this._internalState.returnValues)

      switch (ApiOption) {
        case 'personal':
          await this._internalState.tlService.addUserArchives(token, name, String(version), intBenchmarkId,
            !!textCleaning ? textCleaning : analyseText, JSON.stringify(resultIs), settingIs, hix, clix, folderId, languageIs, tags, userId)
          break
        case 'group':
          await this._internalState.tlService.addGroupArchives(token, name, String(version), intBenchmarkId,
            !!textCleaning ? textCleaning : analyseText, JSON.stringify(resultIs), settingIs, hix, clix, folderId, languageIs, tags, userGroupId)
          break
        case 'company':
          await this._internalState.tlService.addCompanyArchives(token, name, String(version), intBenchmarkId,
            !!textCleaning ? textCleaning : analyseText, JSON.stringify(resultIs), settingIs, hix, clix, folderId, languageIs, tags, companyId)
          break
        default:
          await this._internalState.tlService.addUserArchives(token, name, String(version), intBenchmarkId,
            !!textCleaning ? textCleaning : analyseText, JSON.stringify(resultIs), settingIs, hix, clix, folderId, languageIs, tags, userId)
          break
      }
    }
  }

  _extractText = async (file) => {
    return await readDNDFiles(this._internalState.token,
      this._internalState.tlService,
      [file],
      this._internalState.translate,
      this._internalState.maxRequestSize,
      this._internalState.maxAnalizeTextSize
    )
  }

  _resetInternalState = () => {
    this._internalState.running = false
    this._internalState.paused = false
    this._internalState.finishedAnalyses = 0
    this._internalState.totalAnalyses = 0
    this._internalState.percent = 0
    this._internalState.data = {}
    this._internalState.callback = () => { }
    this._internalState.token = ''
    this._internalState.userId = null
    this._internalState.userGroupId = null
    this._internalState.companyId = null
    this._internalState.tlService = null
    this._internalState.translate = null
    this._internalState.progressByDocument = []
    this._internalState.lastFilename = null
    this._internalState.analysisInProgress = false
  }

  _onError = () => {
    this._internalState.callback('BATCH_PROCESSING_ERROR')
  }

  _createBeforeUnloadListener = () => {
    this._beforeUnloadListener = (ev) => {
      ev.preventDefault()
      let message = this._internalState.translate('');
      (window.event || ev).returnValue = message
      return message
    }

    window.addEventListener('beforeunload', this._beforeUnloadListener)
  }

  _removeBeforeUnloadListener = () => {
    if (!!this._beforeUnloadListener) {
      window.removeEventListener('beforeunload', this._beforeUnloadListener)
      this._beforeUnloadListener = null
    }
  }

  _prepareProgressByDocument = () => {
    for (var i = 0, length = this._internalState.data.files.length; i < length; ++i) {
      this._internalState.progressByDocument.push({
        filename: this._internalState.data.files[i].name,
        state: i === 0 ? 'running' : 'open'
      })
    }
  }

  _publishProgress = () => {
    this._internalState.callback('BATCH_PROCESSING_PROGRESS', {
      finishedAnalyses: this._internalState.finishedAnalyses,
      percent: this._internalState.percent,
      progressByDocument: this._internalState.progressByDocument
    })
  }
}