import React, { useContext, useEffect, useState } from 'react'
import style from '../style.module.sass'
import MainPageWrapper from '../../Main-page-wrapper'
import Footer from '../../Footer/Footer'
import comBotBlue from '../../../assets/combot-blue.svg'
import TextTypeCard from './components/TextTypeCard'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import GeneratorResult from './components/GeneratorResult'
import { useSelector } from 'react-redux'
import { isObjectEmpty } from '../../../utils'
import comBotWhite from '../../../assets/combot-white.svg'
import { useActions } from '../../../utils/action-helper'
import {
 userGetAllBenchmarks
} from '../../../actions'
import TLServiceComponent from '../../../contexts/TLServiceComponent'

const TextGenerator = () => {
  const { theme: { colors } } = useTheme()
  const { t } = useTranslation()
  const tlService = useContext(TLServiceComponent)

  const cardData = useSelector(state => state.userData?.user?.ai_settings?.text_generator) || []
  const token = useSelector(state => state.userData.token)
  const benchmarks = useSelector(state => state.userData.benchmarks)

  const {
    actionUserGetAllBenchmarks,
  } = useActions({
    actionUserGetAllBenchmarks: userGetAllBenchmarks(tlService)
  })

  const [message, setMessage] = useState('')
  const [selectedCardData, setSelectedCardData] = useState(cardData[0])
  const [selectedBenchmark, setSelectedBenchmark] = useState(null)
  const [selectedCardId, setSelectedCardId] = useState('')
  const [resutMode, setResultMode] = useState(false)
  const [enabledSubmit, setEnabledSubmit] = useState(false)
  const [missingTextType, setMissingTextType] = useState(false)

  useEffect(() => {
    if (isObjectEmpty(benchmarks)) {
      actionUserGetAllBenchmarks(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchmarks])

  useEffect(() => {
    if (!message.length > 0 || !selectedCardId) {
      setEnabledSubmit(false)
    } else {
      setEnabledSubmit(true)
      setMissingTextType(false)
    }
  }, [message, selectedCardId])

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }

  const handleSwitchToResult = () => {
    if (enabledSubmit) {
      setResultMode(true)
    } else {
      setMissingTextType(true)
    }
  }

  const handleCardSelection = (benchmark, id) => {
    setSelectedBenchmark(benchmark)
    setSelectedCardId(id)
    setSelectedCardData(cardData.find(card => card.id === id))
    setMissingTextType(false)
  }

  // to calculate the height of the textarea based on the content
  const calculateHeight = (element) => {
    element.style.height = 'auto'
    element.style.height = element.scrollHeight + 'px'
  }

  if (isObjectEmpty(cardData)) {
    return (
      <MainPageWrapper>
        <h1 style={{ color: colors.colorStatusError, position: 'absolute', top: '50%', left: '50%' }}>
          {t('ai_gen_no-data')}
        </h1>
      </MainPageWrapper>
    )
  }
  return (
    <MainPageWrapper>
      <section className={`${style.mainCont} mt-5`}
               style={{ height: '100vh', display: 'flex', flexDirection: 'column', overflowY: 'hidden'  }}>
        {/* Main section */}
        {
          resutMode ? (
            <GeneratorResult
              message={message}
              handleMessageChange={handleMessageChange}
              analyseButtonEnable={enabledSubmit}
              setAnalyseButtonEnable={setEnabledSubmit}
              selectedCardId={selectedCardId}
              selectedBenchmark={selectedBenchmark}
              handleCardSelection={handleCardSelection}
              card={selectedCardData}/>
          ) : (
            <>
              <div className="d-flex flex-column align-items-center" style={{ flex: '1 0 auto' }}>
                <div className="mb-4">
                  <img
                    src={comBotBlue}
                    alt="ComLab AI Bot"
                    width="120px"
                    height="80px"
                  />
                </div>
                <h4>{t('ai_gen_title')}</h4>
                <div className="d-flex flex-wrap mt-1 justify-content-center" style={{
                  width: '60%',
                }}>
                  {cardData.map(card => (
                    <TextTypeCard key={card.id} title={card.id} img={card.icon} texttype={card.benchmark} selectedCardId={selectedCardId} card={card} handleCardSelection={handleCardSelection} selectedBenchmark={selectedBenchmark}/>
                  ))}
                </div>
                {/* Additional Content */}
                <div className="mt-5 d-flex flex-column align-items-center" style={{ width: '100%' }}>
                  <div className="d-flex flex-column align-items-center">
                    <h2>{t('ai_gen_header')}</h2>
                    <p><i>{t('ai_gen_text')}</i></p>
                  </div>
                  {
                    missingTextType && (
                      <div className="d-flex flex-column align-items-center mt-1">
                        <p style={{ color: colors.colorStatusError }}>{t('ai_gen_missing_text_type')}</p>
                      </div>
                    )
                  }

                  {/* Chat input area */}
                  <div className="d-flex align-items-center mt-2 chat-input-container"
                       style={{ width: '100%', maxWidth: '800px' }}>
                    <div className="d-flex align-items-center" style={{ position: 'relative', width: '100%' }}>
                      <textarea
                        placeholder={t('ai_gen_type-your-message')}
                        rows="3"
                        value={message}
                        onChange={handleMessageChange}
                        onInput={(e) => calculateHeight(e.target)}
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '8px',
                          padding: '12px',
                          fontSize: '16px',
                          resize: 'none',
                          overflowY: 'hidden',
                          width: '100%',
                          maxWidth: '800px',
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className={'align-items-center mt-2'}>
                    <button
                      title={t('ai_gen_start_button')}
                      onClick={handleSwitchToResult}
                      className={`${style.loader}  ${style.active}`}>
                      <div><img style={{ marginBottom: '3px' }} src={comBotWhite} alt={''} width="35px"
                                height="35px"/> {t('ai_gen_initial_gen_button')}</div>
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ flex: '1' }}>
                <Footer/>
              </div>
            </>
          )
        }
      </section>
    </MainPageWrapper>
  )
}

export default TextGenerator
