import React, { useState } from 'react'
import style from './style.module.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Collapse, Modal } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import closeIcon from '../../Analysis/Overview/Orthography/SpellCheck/assets/close-circle.svg'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { checkArchiveRights } from '../../../utils/archives'
import ArchiveManager from '../../common/ArchiveManager'

export default function MoveToFolderModal ({
  handleRunMoveFolder,
  moveFolderModal,
  archiveName,
  setMoveFolderModal
}) {
  const { t } = useTranslation()
  const { theme: { bgColors } } = useTheme()

  const userArchives = useSelector(state => state.userData.user.archives)
  const userType = useSelector(state => state.userData.user.user_type_id)

  const [archiveType, setArchiveType] = useState(archiveName || 'personal')
  const [formData, setFormData] = useState({
    personal: {
      pathName: [t('root_folder')],
      folder: 0
    },
    group: {
      pathName: [t('root_folder')],
      folder: 0
    },
    company: {
      pathName: [t('root_folder')],
      folder: 0
    }
  })

  const ArchiveTab = ({ type, formData, setFormData}) => {
    const { t } = useTranslation()
    const [openFolders, setOpenFolders] = useState(false)
    const [folderPath, setFolderPath] = useState(formData[type].pathName)

    const localFormData = formData[type]
    if (!localFormData)
      return null

    const handleFolderClick = (id, tFolders, names) => {
      setOpenFolders(false)
      setFormData(d => {
        return { ...d, [type]: { ...d[type], pathName: names, folder: id } }
      })
      setFolderPath(names)
    }

    return (
      <div className={`d-flex flex-column pt-3`} style={{ gridGap: 10 }}>
        <div className="d-flex align-items-center pb-3">
          <div onClick={() => setOpenFolders(s => !s)}>{t('Folder name')} <strong>{folderPath.reverse().join(' / ')}</strong>
            {openFolders ? <ExpandLess/> : <ExpandMore/>}
          </div>
        </div>
        <Collapse className={style.file} in={openFolders} timeout="auto">
          <ArchiveManager
            type={type}
            defaultFolderId={0}
            showCheckbox={false}
            showRootCheckbox={false}
            showAllFiles={false}
            showAddIcon={true}
            showEditIcon={false}
            showDeleteIcon={false}
            showArchivesCount={false}
            showMassFunctions={false}
            externalFolderClick={handleFolderClick}
          />
        </Collapse>
      </div>
    )
  }


  return (
    <Modal open={moveFolderModal} onClose={() => setMoveFolderModal(false)}
           className={`d-flex align-items-center justify-content-center`} >
      <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
             style={{ backgroundColor: bgColors.bgPrimary400 }}>
          <p className={`m-0 ${style.title}`}>{t('where_move_the_archives')}</p>
          <button className={`border-0 bg-transparent`} onClick={() => setMoveFolderModal(false)}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          <Nav tabs fill justify="true" style={{ cursor: 'pointer' }}>
            <NavItem>
              <NavLink
                className={classnames({ active: archiveType === 'personal' })}
                onClick={() => {
                  setArchiveType('personal')
                }}
              >
                {t('Personal archive')}
              </NavLink>
            </NavItem>
            {checkArchiveRights('group', userType, userArchives) ?
              <NavItem>
                <NavLink
                  className={classnames({ active: archiveType === 'group' })}
                  onClick={() => {
                    setArchiveType('group')
                  }}
                >
                  {t('Group archive')}
                </NavLink>
              </NavItem> : null}
            {checkArchiveRights('company', userType, userArchives) ?
              <NavItem>
                <NavLink
                  className={classnames({ active: archiveType === 'company' })}
                  onClick={() => {
                    setArchiveType('company')
                  }}
                >
                  {t('Company archive')}
                </NavLink>
              </NavItem> : null}
          </Nav>
          <TabContent className={'p-3'} activeTab={archiveType}>
            <TabPane tabId="personal">
              <ArchiveTab
                formData={formData}
                setFormData={setFormData}
                type="personal"/>
            </TabPane>
            <TabPane tabId="group">
              <ArchiveTab
                formData={formData}
                setFormData={setFormData}
                type="group"/>
            </TabPane>
            <TabPane tabId="company">
              <ArchiveTab
                formData={formData}
                setFormData={setFormData}
                type="company"/>
            </TabPane>
          </TabContent>
        </div>
        <div className={`d-flex justify-content-center pt-3`}>
          <Button type="submit" className="col-8" color="primary" variant="contained" size="small"
                  onClick={()=>handleRunMoveFolder(formData[archiveType], archiveType)}>
            {t('start_move_folders')}
          </Button>
        </div>
      </div>

    </Modal>

  )

}