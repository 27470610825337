import React, { useState } from "react"
import style from "../style.module.sass"
import { useTheme } from "@material-ui/core/styles"
import TypeOfField from "../../../../../components/FormikFields/TypeOfField"
import AutoFill from "../../../../../components/FormikFields/AutoFill"
import { useTranslation } from "react-i18next"
import { Switch as SwitchField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import config from '../../../../../config'

function UserRights({ formik, groupList, userRights }) {
  const { t } = useTranslation()
  const [bg, setBg] = useState(false)
  const { theme: { bgColors, colors } } = useTheme()
  const [autoFillStatus, setAutoFillStatus] = useState(null)
  const [autoFillStatusForMouse, setAutoFillStatusForMouse] = useState(null)
  const [mode, setMode] = useState('group')

  const groupFields = { id: 2, title: t("singleGroupLevel"), options: config.singleGroupLevel }
  const companyFields = { id: 3, title: t("companyLevel"), options: config.companyLevel }

  //AutoFill functions to change state
  const handleCompany = () => {
    setAutoFillStatus('company')
    formik.setFieldValue("mayAdminBasicVocabulary", true)
    formik.setFieldValue("mayAdminGeneralTerminology", true)
    formik.setFieldValue("mayAdminControlList", true)
    formik.setFieldValue("mayAdminUserRights", true)
    formik.setFieldValue("mayAddUserToGroup", true)
    formik.setFieldValue("mayCRUDUserGroup", true)
    formik.setFieldValue("mayChangeUsersName", true)
    formik.setFieldValue("mayBlockUsers", true)
    formik.setFieldValue("mayEditTextType", true)
    formik.setFieldValue("mayViewLogs", true)
    formik.setFieldValue("mayUpdateAResGroupMem", true)
    formik.setFieldValue("mayAdminTerms", true)
    formik.setFieldValue("mustAcceptTerms", true)
    formik.setFieldValue("mustChangePassLogin", true)
    formik.setFieldValue("mayUpdateAResGroup", true)
  }
  const handleGroup = () => {
    setAutoFillStatus('group')
    formik.setFieldValue("MayViewListOfUsersInAGroup", false)
    formik.setFieldValue("MayCreateUsersInAGroup", false)
    formik.setFieldValue("MayChangeDeleteUsersInAGroup", false)
    formik.setFieldValue("mayAdminUserRights", false)
    formik.setFieldValue("mayAddUserToGroup", false)
    formik.setFieldValue("mayCRUDUserGroup", false)
    formik.setFieldValue("mayChangeUsersName", false)
    formik.setFieldValue("mayBlockUsers", false)
    formik.setFieldValue("mayEditTextType", false)
    formik.setFieldValue("mayViewLogs", false)
    formik.setFieldValue("mayUpdateAResGroupMem", false)
    formik.setFieldValue("mayAdminTerms", false)
    formik.setFieldValue("mustAcceptTerms", false)
    formik.setFieldValue("mustChangePassLogin", false)
    formik.setFieldValue("mayUpdateAResGroup", false)
  }

  const field = mode === 'group' ? groupFields : companyFields

  const handleSwitch = () => {
    setMode(prev => prev === 'group' ? 'company' : 'group')
  }

  const getSelectedGroup = (rightId) => {
    const right = userRights.find(r => r.id === rightId)
    return right ? right.user_group_id : formik.values.userGroup
  }

  const handleOnChangeGroup = (rightId, groupId) => {
    formik.setFieldValue(`group_select_${rightId}`, groupId)
  }

  return (
    <div className={`pb-5 position-relative`} style={{ backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
      onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <AutoFill title={t("UserRights")} state={autoFillStatus} setAutoFillState={setAutoFillStatus}
        stateForMouse={autoFillStatusForMouse}
        setStateForMouse={setAutoFillStatusForMouse} handleCompany={handleCompany} handleGroup={handleGroup} />
      <div className="d-flex align-items-center mt-3" style={{
        fontSize: "20px",
        color: colors.colorPrimary1000,
        marginLeft: "30px",
      }}>
        <p className="mt-2 mr-2">{t('admin_user_admin-level')}:</p>
        <p className="mt-2 mr-3">{t('admin_user_group')}</p>
        <FormControlLabel
          control={<SwitchField checked={mode === 'company'} onChange={handleSwitch} color="primary" />}
        />
        <p className="mt-2" style={{
          marginLeft: "-10px",
        }}>{t('admin_user_company')}</p>
      </div>
      <ul className={`${style.fieldsUser} mb-0 pt-3`} >
        <li className={`p-0 ${style.userRightsList}`} key={field.id}>
          <div className="d-flex flex-column">
            <p className={`${style.userRightsTitle} mt-2 mb-4`}>{t('admin_user_rights')}: </p>
            <ul className={`p-0`} style={{
              display: "flex", flexWrap: "wrap", 
            }}>
              {
                field.options.map(
                  (o, index) => (
                    <li key={index} className="d-flex align-items-center" style={{ width: '100%', marginBottom: '2px' }}>
                      <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
                        <TypeOfField
                          disabled={formik.values.company === ''}
                          setAutoFillState={setAutoFillStatus}
                          control={o.control}
                          formik={formik}
                          defaultChecked={formik.values[`rights_label_${o.id}`] ? true : false}
                          rights
                          name={`rights_label_${o.id}`}
                          label={t("rights_label_" + o.id)}
                          style={{ width: mode === 'group' && '80%'}}
                          {...o}
                        />
                        {mode === 'group' && formik.values[`rights_label_${o.id}`] && (
                          <div style={{ width: '100px'}}>

                          <FormControl variant="outlined" margin="normal">
                            <InputLabel>{t('group')}</InputLabel>
                            <Select
                              value={formik.values[`group_select_${o.id}`] || getSelectedGroup(o.id)}
                              onChange={(e) => handleOnChangeGroup(o.id, e.target.value)}
                              label={t('select_group')}
                              style={{ height: '25px', fontSize: '12px', width: '100px' }}
                              >
                              {groupList.map(group => (
                                <MenuItem key={group.id} value={group.id}>
                                  {group.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                              </div>
                        )}
                      </div>
                    </li>
                  )
                )
              }
            </ul>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default UserRights
