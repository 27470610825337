import { MenuItem, Select } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import i18n from "i18next";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  analyseSetAutoTextLanguage,
  analyseSetTextLanguage,
  userGetAllBenchmarks,
  userSaveOption,
  userSetOption,
  userUpdateUser
} from "../../actions";
import config from "../../config";
import siteLanguages from "../../config/sitelanguages.json";
import TLServiceComponent from "../../contexts/TLServiceComponent";
import {
  convertJSONToObject,
  convertLanguagesToFull,
  convertLanguagesToShort,
  isObjectEmpty
} from "../../utils";
import { filterBenchmark } from "../Analysis/analysisFunctions";

import { useActions } from "../../utils/action-helper";
import Sliders from "../Analysis/Tabs/InitialTab/Sliders";
import ProfileWrapper from "./ProfileWrapper";
import style from "./style.module.sass";

function ProfileComponent() {
  const {
    theme: { bgColors, borderColors, colors },
  } = useTheme();
  const {
    t,
    i18n: { language: i18nLanguage },
  } = useTranslation();

  const token = useSelector((state) => state.userData.token);
  const userId = useSelector((state) => state.userData.user.id);
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages) || {}
  const analyseLanguages = {...backendLanguages, ...config.autoAnalyseLanguage}
  const storageAnalyseTonality = convertJSONToObject(
    useSelector(
      (state) => state.userData?.user?.user_options?.analyse_tonality
    ),
    config.defaultAnalyseTonality
  )

  const benchmarks = useSelector((state) => state.userData.benchmarks)

  const shortLanguage = convertLanguagesToShort(i18nLanguage)
  const storageLanguageTextType = convertJSONToObject(
    useSelector(
      (state) => state.userData?.user?.user_options?.analyse_language_texttype
    ),
    config.defaultAnalyseLanguageTextType
  )

  if (typeof storageLanguageTextType.textType !== 'object') {
    storageLanguageTextType.textType = {}
  }

  const allFilteredBenchmarks = useMemo(() => Object.keys(backendLanguages).reduce((acc, curr) => {
    acc[curr] = filterBenchmark(benchmarks, curr, "locale_name")
    return acc
  }, {}), [benchmarks, backendLanguages])

  /**BIND ACTIONS**/
  const tlService = React.useContext(TLServiceComponent);
  const {
    actionUserUpdateUser,
    actionUserSetOption,
    actionUserSaveOption,
    actionAnalyseSetTextLanguage,
    actionAnalyseSetAutoTextLanguage,
    actionUserGetAllBenchmarks
  } = useActions({
    actionUserSetOption: userSetOption,
    actionAnalyseSetTextLanguage: analyseSetTextLanguage,
    actionAnalyseSetAutoTextLanguage: analyseSetAutoTextLanguage,
    actionUserGetAllBenchmarks: userGetAllBenchmarks(tlService),
    actionUserSaveOption: userSaveOption(tlService),
    actionUserUpdateUser: userUpdateUser(tlService),
  })

  // on mount
  useEffect(() => {
    if (isObjectEmpty(benchmarks)) {
        actionUserGetAllBenchmarks(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  function handleOnChangeLanguage(e) {
    const userLanguage = e.target.value;
    if (userLanguage) {
      i18n.changeLanguage(userLanguage);
      actionUserUpdateUser(token, userId, { language_id: userLanguage });
    }
  }

  function handleSaveAnalyseTonality(name, value) {
    const newTonality = { ...storageAnalyseTonality, [name]: value };
    actionUserSetOption("analyse_tonality", JSON.stringify(newTonality));
    actionUserSaveOption(
      token,
      userId,
      "analyse_tonality",
      JSON.stringify(newTonality)
    );
  }

  function handleChangeAnalyseTextType(name, lang, value) {
    let newValue
    if (name === "language") {
      newValue = value
    } else {
      newValue = { ...storageLanguageTextType[name], [lang]: value }
    }
    const newTextType = { ...storageLanguageTextType, [name]: newValue }

    actionUserSetOption(
      "analyse_language_texttype",
      JSON.stringify(newTextType)
    )
    actionUserSaveOption(
      token,
      userId,
      "analyse_language_texttype",
      JSON.stringify(newTextType)
    )
    if (name === "language" && value === "auto") {
      actionAnalyseSetTextLanguage(convertLanguagesToFull(i18nLanguage))
      actionAnalyseSetAutoTextLanguage(convertLanguagesToFull(i18nLanguage))
    }
  }

  return (
    <ProfileWrapper>
        <div
          style={{
            color: colors.colorPrimary500,
            borderColor: borderColors.borderPrimary1000,
          }}
          className={style.blockInternal}
        >
          <ul className={`mt-4 p-0 list-unstyled`}>
            <li
              style={{ backgroundColor: bgColors.bgPrimary0, height:"90px", borderRadius: 10, fontSize: "18px" }}
              className={`d-flex align-items-center justify-content-between pr-3 pl-3 mb-4`}
            >
              <p className={`m-0 ${style.title}`}>
             <b>   {t("Interface Language")}</b>
                <i
                  dangerouslySetInnerHTML={{
                    __html: t("select-language-text"),
                  }}
                  style={{
                    marginLeft: "15px",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                />
              </p>

              <Select
                onChange={handleOnChangeLanguage}
                labelId="language-select-label"
                id="language-select"
                value={i18nLanguage}
                style={{ borderRadius: 18 }}
                variant="outlined"
                className={`p-0`}
              >
                {Object.keys(siteLanguages).map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {siteLanguages[lang]["nativeName"]}
                  </MenuItem>
                ))}
              </Select>
            </li>
            <li
              style={{ backgroundColor: bgColors.bgPrimary0, height:"90px", borderRadius: 10, fontSize: "18px" }}
              className={`d-flex align-items-center justify-content-between pr-3 pl-3 mb-4`}
            >
              <p className={`m-0 ${style.title}`}>
             <b>   {t("analysis-language")}</b>
                <i
                  dangerouslySetInnerHTML={{
                    __html: t("select-analysis-text"),
                  }}
                  style={{
                    marginLeft: "15px",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                />
              </p>

              <Select
                      onChange={event => handleChangeAnalyseTextType('language',"", event.target.value)}
                      variant="outlined"
                      defaultValue={storageLanguageTextType.language}
                      value={storageLanguageTextType.language}
                      className={`p-0`}            
                      style={{ borderRadius: 18 }}
                      >
                        {
                            Object.keys(analyseLanguages).map(lang =>
                                <MenuItem key={lang} value={lang}>{analyseLanguages[lang]['nativeName']}</MenuItem>
                            )
                        }
               </Select>
            </li>
    
            {!isObjectEmpty(benchmarks) && (
              <li
                style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: 10, fontSize: "18px" }}
              className={`d-flex align-items-center justify-content-between pr-3 pl-3 mb-4`}
                >
                  <p className={`m-0`}>
                <b>    {t("Default Text Type")}</b>
                    <i
                      dangerouslySetInnerHTML={{
                        __html: t("select-text-type-text"),
                      }}
                      style={{
                        marginLeft: "15px",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    />
                  </p>
                  <span />

                <div className="d-flex flex-column mb-3 mt-3" style={{ gap: 16 }}>
                  {
                    Object.entries(allFilteredBenchmarks).map(([lang, val]) => (
                      <div className="d-flex justify-content-between" style={{ gap: 50 }} key={lang}>
                        <p style={{ alignSelf: "flex-end" }} className="font-weight-bold">{t("text-type-" + lang)}:</p>
                        <Select
                          style={{ borderRadius: 18 }}
                          className={`p-0`}
                          variant="outlined"
                          onChange={event => handleChangeAnalyseTextType('textType', lang, parseInt(event.target.value.toString()))}
                          defaultValue={storageLanguageTextType.textType[lang]}
                          value={storageLanguageTextType.textType[lang]}
                        >
                          {Object.entries(val).map(([key, val]) => (
                            <MenuItem key={key} value={key}>
                              {val["name"][shortLanguage] || key}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    ))
                  }
                </div>

              </li>
            )}
            <li
              style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: 10, fontSize: "18px" }}
              className={` ${style.row}  pl-3 mb-4 pr-3 d-flex align-items-center justify-content-between`}
            >
              <p className={`m-0 font-weight-bold`}>
                {t("Set the tonality")}
                <i
                      dangerouslySetInnerHTML={{
                        __html: t("tonality-text"),
                      }}
                      style={{
                        marginLeft: "15px",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    />
              </p>
             <Sliders handleSaveAnalyseTonality={handleSaveAnalyseTonality} />
            </li>
          </ul>
        </div>

    </ProfileWrapper>
  )
}

export default ProfileComponent
