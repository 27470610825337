import mammoth from "./mammoth";

export function readFileAsync(file, format) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader);
        };
        reader.onerror = reject;
        if (format === "text")
            reader.readAsText(file);
        else if (format === "binary")
            reader.readAsBinaryString(file);
        else
            reader.readAsArrayBuffer(file);
    })
}

export function mammothAsync(text, options = {}) {
    return new Promise((resolve, reject) => {
        mammoth.convertToHtml({arrayBuffer: text}, options)
            .then(result => {
                console.log("result.messages",result.messages);
                resolve(result.value);
            })
            .catch(reject)
    })
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}