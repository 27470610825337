import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import config from "../../../../config";
import { convertJSONToObject } from "../../../../utils";
import sliderThump from "./assets/slider-thump.svg";

const AirbnbSlider = withStyles({
  root: {
    height: 5,
    padding: '13px 0',

    "& span:nth-child(4), & span:nth-child(8)": {
      display: "none",
    }
  },
  thumb: {
    height: 14,
    width: 28,
    border: '2px solid #fff',
    marginTop: -5,
    marginLeft: -13,
    borderRadius: "4px",
    boxShadow: '#999999 0 0 2px',
        '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 2px 1px',
    },
    '& .bar': {
      height: 7,
      width: "100%",
    },
  },
  active: {},
  track: {
    height: 5,
  },
  mark: {
    height: "5px",
    backgroundColor: "#999999",
    width: "1px"
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 5,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <img src={sliderThump} className="bar" alt=""/>
    </span>
  );
}

export default function Sliders({ handleSaveAnalyseTonality}) {

  const {t} = useTranslation();
  const storageAnalyseTonality = convertJSONToObject(
    useSelector(state => state.userData?.user?.user_options?.analyse_tonality),
    config.defaultAnalyseTonality);

  return (
    <div className={`d-flex flex-column mt-2 pt-2`} style={{ width: "50%"}}>
      {Object.entries(config.tonalityParameters).map(([key, val]) => (
        <div
          key={`div-${key}`}
          style={{
            display: "grid",
            gridTemplateColumns: "13% 72% 15%",
            justifyItems: "end",
          }}
        >
          <p className="mr-3">{t(val.scaleFrom[1])}</p>
          <AirbnbSlider
            onChangeCommitted={(_event, value) =>
            handleSaveAnalyseTonality(key, value)}
            key={key}
            name={key}
            track={false}
            value={storageAnalyseTonality[key]}
            valueLabelDisplay="off"
            marks
            min={val.scaleFrom[0]}
            max={val.scaleTo[0]}
            ThumbComponent={AirbnbThumbComponent}
            step={Math.round((val.scaleTo[0] - val.scaleFrom[0]) / 4)}
          />
          <p>{t(val.scaleTo[1])}</p>
        </div>
      ))}
      <br />
    </div>
  );
}