import React, { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import TableFilter from './TableFilter'
import TableHeader from './TableHeader'
import { useSelector } from 'react-redux'
import { useActions } from '../../../utils/action-helper'
import {
  tableClearAll, tableSetCompanyId, tableSetCurrentPage,
  tableSetDialog, tableSetFilters, tableSetGroupId
} from '../../../actions'
import { Redirect } from 'react-router-dom'
import { deepCopy, isObjectEmpty, isObjectsEqual } from '../../../utils'
import TableFooter from './TableFooter'

export default function UniversalTable (
  {
    defaultColumns,
    reloadAll,
    handleSelectAll,
    getAllIds,
    style,
    extraSettings,
    MassActionComponent,
    selectAllText,
    enableExtraFilters = false,
    InnerComponent,
    setTerminologyModal,
    setCurrentTerm,
    callEndPoint,
    noDataText = '',
    type= 'archive'
  }
) {

  const { theme: { bgColors } } = useTheme()
  const { t } = useTranslation()
  const data = useSelector(state => state.tableData.data)
  const redirect = useSelector(state => state.tableData.redirect)

  const {
    aTableSetDialog,
    aTableClearAll, aTableSetCurrentPage,
    aTableSetCompanyId,
    aTableSetGroupId,
    aTableSetFilters
  } = useActions({
    aTableSetDialog: tableSetDialog,
    aTableClearAll: tableClearAll,
    aTableSetFilters: tableSetFilters,
    aTableSetCompanyId: tableSetCompanyId,
    aTableSetGroupId: tableSetGroupId,
    aTableSetCurrentPage: tableSetCurrentPage
  })

  const dialog = useSelector(state => state.tableData.dialog)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const DialogRequestComponent = useSelector(state => state.tableData.dialog.RequestComponent)
  const dialogButtons = useSelector(state => state.tableData.dialog.buttons)
  const filters = useSelector(state => state.tableData.filters)
  const extraFilters = useSelector(state => state.tableData.extraFilters)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const pages = useSelector(state => state.tableData.pages)
  const userType = useSelector(state => state.userData.user.user_type_id)
  const companies = useSelector(state => state.adminData.companies)
  const columns = useSelector(state => state.tableData.columns)

  const [localFilter, setLocalFilter] = useState({})

  const handleChangePage = (num) => {
    if ((num >= 1) && (num <= pages)) {
      aTableSetCurrentPage(num)
      return true
    }
    return false
  }

  const handleNextPage = (direction) => {
    handleChangePage(currentPage + direction)
  }

  const handleSearchButtonClick = (newLocal) => {
    if (!isObjectEmpty(newLocal)) {
      let newFilter = deepCopy(filters)
      for (const f of extraFilters) {
        if (!newLocal[f.id]) {
          newFilter = { ...newFilter, [f.id]: undefined }
        } else {
          newFilter = { ...newFilter, [f.id]: newLocal[f.id] }
        }
      }
      newFilter = { ...newFilter, full_text_search_filter: newLocal['full_text_search_filter'] || undefined }
      aTableSetFilters(newFilter)
    }
  }
  const handleChangeValue = (data, value, convertedValue, click = false) => {
    if (value !== undefined) {
      const newFilter = {
        ...localFilter,
        [data.id]: {
          value,
          filter: [[`&${data.searchField}`, data.searchTemplate.replace('{value}', convertedValue)]]
        }
      }
      setLocalFilter(newFilter)
      if (click) {
        handleSearchButtonClick(newFilter)
      }
    } else {
      const newFilterUndef = { ...localFilter, [data.id]: undefined }
      setLocalFilter(newFilterUndef)
      if (click) {
        handleSearchButtonClick(newFilterUndef)
      }
    }

  }

  const handleChangeFullSearchString = (e) => {
    const str = e.target.value.trim()
    if (str?.length) {
      const arrSearch =
        extraSettings.fullSearchFilter.map(v => [v[0], v[1].replace('{value}', str)])

      // remove id if it is not a number (id search for texttypes)
      const indexToDelete = arrSearch.findIndex(pair => pair[0] === '|id' && !/^\d+$/.test(pair[1].replace('=', '')))
      if (indexToDelete !== -1) {
        arrSearch.splice(indexToDelete, 1)
      }
      setLocalFilter(v => ({ ...v, full_text_search_filter: { value: str, filter: arrSearch } }))
    } else {
      setLocalFilter(v => ({ ...v, full_text_search_filter: undefined }))
    }
  }
  const handleResetSearchClick = () => {
    let newFilter = deepCopy(filters)
    for (const f of extraFilters) {
      newFilter = { ...newFilter, [f.id]: undefined }
    }
    newFilter = { ...newFilter, full_text_search_filter: undefined }
    setLocalFilter({})
    aTableSetFilters(newFilter)
    const defaultCompanyId = isObjectEmpty(companies) ? 0 : Object.keys(companies)[0]
    aTableSetCompanyId(userType === 1 ? 0 : defaultCompanyId)
    aTableSetGroupId(0)
  }

  // on unmount
  useEffect(() => () => aTableClearAll(), [])

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  if(!isObjectsEqual(defaultColumns, columns)){
    return null
  }

  return (
    <Fragment>
      <Dialog
        open={!!dialogOpen}
        onClose={() => aTableSetDialog({ ...dialog, response: null })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: '300px' }}>
          <DialogContentText id="alert-dialog-description">
            <DialogRequestComponent/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogButtons.yes &&
            <Button onClick={
              () => {
                aTableSetDialog({ ...dialog, response: true, open: false })
              }}>
              {t('yes')}
            </Button>}
          {dialogButtons.no &&
            <Button onClick={
              () => {
                aTableSetDialog({ ...dialog, response: false, open: false })
              }}>
              {t('no')}
            </Button>}
            {dialogButtons.edit &&
            <Button onClick={
              () => aTableSetDialog({
                type: type === 'users' ? 'deleteUser' : type === 'groups' ? 'deleteGroup' : 'deleteCompany',
                open: true,
                RequestComponent: () => <>{type === 'users' ? t('admin_user_delete-user') : type === 'groups' ? t('admin_group_delete-group') : t('admin_company_delete-company')}</>,
                response: null,
                buttons: {
                  yes: true,
                  no: true,
                  close: false,
                  save: false
                }
              })} >
              {t(`admin_${type}_delete-anyway`)}
            </Button>}
          {dialogButtons.close &&
            <Button onClick={
              () => {
                aTableSetDialog({ ...dialog, response: null, open: false })
              }}>
              {t('Close')}
            </Button>}
          {dialogButtons.save &&
            <Button onClick={
              () => aTableSetDialog({ ...dialog, open: false })}>
              {t('Save')}
            </Button>}
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table
          style={{
            backgroundColor: bgColors.bgPrimary100,
            borderColor: 'transparent'
          }}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableFilter
            handleNextPage={handleNextPage}
            handleChangePage={handleChangePage}
            localFilter={localFilter}
            handleSearchButtonClick={handleSearchButtonClick}
            handleChangeValue={handleChangeValue}
            handleChangeFullSearchString={handleChangeFullSearchString}
            handleResetSearchClick={handleResetSearchClick}
            style={style}
            extraSettings={extraSettings}
            enableExtraFilters={enableExtraFilters}
            type={type}
          />
          <TableHeader
            reloadAll={reloadAll}
            handleSelectAll={handleSelectAll}
            selectAllText={selectAllText}
            getAllIds={getAllIds}
            MassActionComponent={MassActionComponent}
            style={style}
            extraSettings={extraSettings}
            type={type}
          />
          <TableBody>
            {data?.length ?
              <>{data.map((row, index) => <InnerComponent
                localFilter={localFilter}
                handleChangeValue={handleChangeValue}
                isBlueRow={index % 2 === 0}
                row={row}
                setTerminologyModal={setTerminologyModal}
                setCurrentTerm={setCurrentTerm}
                callEndPoint={callEndPoint}
                index={index}
                key={index}/>)}</>
              :
              <TableRow>
                <TableCell colSpan="10" style={{ height: '400px', textAlign: 'center', marginTop: '140px' }}>
                  <h2>{noDataText}</h2>
                </TableCell>
              </TableRow>
            }
          </TableBody>
          <TableFooter
            style={style}
            handleNextPage={handleNextPage}
            handleChangePage={handleChangePage}
          />

        </Table>


      </TableContainer>
    </Fragment>

  )
}