import React, { useMemo, useState, useEffect } from "react";
import TagCloud from "react-tag-cloud";
import { Slider } from "@material-ui/core";
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertArrayToText, onlyUnique } from "../../../utils";
import { useTranslation } from "react-i18next";

export default function VocabularyChart({ title, color, termCategory }) {
  const { theme: { bgColors, colors } } = useTheme();
  const [terms, setTerms] = useState(1000000);
  const { params: { type } } = useRouteMatch();
  const { t } = useTranslation();
  const [maxTerms, setMaxTerms] = useState()
  const resultData = useSelector(state => state.analyseData.result.resultData);
  const textLanguage = useSelector(state => state.analyseData.textLanguage) || "de_DE";
  // const maxTerms = resultData[termCategory]

  function calculateWordsCloud(resultData, termCategory, terms) {
    return resultData['dataColorWords'][termCategory].result.map(value => {
      const words = convertArrayToText(value.term.words, textLanguage);
      return {
        length: value.term.words.length,
        num: value.position.length,
        text: words,
        rotate: Math.random() > 0.8 ? 90 : 0
      };
    }).slice(0, terms);
  }

  // memorisation result
  let wordsWithFilters = useMemo(() => calculateWordsCloud(resultData, termCategory, terms), [resultData, termCategory, terms]);
  // const maxMinSize = wordsWithFilters.reduce((acc, val) => [acc[0] < val.num ? val.num : acc[0], acc[1] > val.num ? val.num : acc[1]], [0, 10000]);

  let numberOfUniqueWordsCount;
  let largestCount;
  let counts = [];
  for (let v of wordsWithFilters) {
    counts.push(v.num)
  }

  // set Max. Word Limit according the max. words:
  useEffect(() => {
    setTerms(wordsWithFilters?.length)
  }, [wordsWithFilters])

  // Set Slider Max. limit:
  useEffect(() => {
    setMaxTerms(wordsWithFilters?.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uniqueCount = counts.filter(onlyUnique)
  largestCount = Math.max(...uniqueCount);
  numberOfUniqueWordsCount = uniqueCount.length;

  function normalize(v) {
    let minFontSize = 12;
    let maxFontSize = 22;
    let defaultStep = 1.5;
    let step;
    if (numberOfUniqueWordsCount < (maxFontSize - minFontSize)) {
      step = defaultStep;
    } else {
      step = (maxFontSize - minFontSize) / numberOfUniqueWordsCount;
    }
    if (v.num === largestCount) {
      return maxFontSize;
    } else if (v.num < largestCount / 5) {
      return minFontSize
    } else if (v.num < (largestCount / 5 * 2) && v.num > largestCount / 5) {
      return maxFontSize - (step * 4)
    } else if (v.num < (largestCount / 5 * 3) && v.num > (largestCount / 5 * 2)) {
      return maxFontSize - (step * 3)
    } else if (v.num < (largestCount / 5 * 4) && v.num > (largestCount / 5 * 3)) {
      return maxFontSize - (step * 2)
    } else if (v.num < (largestCount / 5 * 5) && v.num !== largestCount && v.num > (largestCount / 5 * 4)) {
      return maxFontSize - step
    }
  }

  wordsWithFilters = wordsWithFilters.map(v => {
    return { ...v, fontSize: normalize(v) }
  })

  function handleOnSetTerms(event, newValue) {
    setTerms(newValue);
  }
  return (
    <div style={{ backgroundColor: bgColors.bgPrimary0 }} className={style.cells}>
      <div className={`${style.head}`}>
        <h2 className={`m-0 p-0`} style={{ color: color, border: "none" }}>{t(title.toLowerCase()).toUpperCase()}</h2>
        <div style={{ width: "30%" }}>
          <div className={`d-flex align-items-center justify-content-between`}>
            <p className={`d-flex align-items-center m-0`} style={{ color: colors.colorPrimary400 }}>{t('terms')}
              <input className={`ml-2`} style={{ width: "100px", outline: "none" }} type="text" value={terms}
                onChange={e => setTerms(e.target.value)} /></p>
            <Search style={{ fill: colors.colorPrimary400 }} />
          </div>
          <Slider min={1}
            max={maxTerms}
            value={terms}
            onChange={handleOnSetTerms} />
        </div>
      </div>
      <div className={style.mainTagCloud}>
        {type === "texts" && <div className={`d-flex justify-content-start align-items-center ${style.textTitles}`}>
          <p className={`m-0`} style={{ color: color }}>{t('optional')} 1</p>
          <p className={`m-0`} style={{ color: color, opacity: .5 }}>{t('optional')} 2</p>
        </div>}
        <div className={style.appOuter}>
          <div className={style.appInner}>
            <TagCloud className={style.tagCloud} style={{ padding: 6, fontFamily: 'Roboto' }}>
              {wordsWithFilters?.length && wordsWithFilters.sort((a, b) => b.num - a.num).filter((w, i) => (i + 1) <= terms).map((w, i) => (
                <div key={i} rotate={w.rotate} style={{
                  fontSize: w.fontSize,
                  color: color,
                }}>{w.text}</div>
              ))}
            </TagCloud>
          </div>
        </div>
      </div>
    </div>
  )
}
