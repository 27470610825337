import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  archiveSetArchiveType,
  archiveSetPredefined,
  batchProcessingClear,
  tableSetFilters, userSetArchiveLanguage
} from '../../../actions'
import { useActions } from '../../../utils/action-helper'
import style from './batch.module.sass'
import ErrorModal from '../ModalWindows/ErrorModal'
import BatchProcessingDetails from './BatchProcessingDetails'
import { Redirect } from 'react-router-dom'
import { getFolderFilter } from '../../../utils/archives'

export default function BatchProcessingProgressbar () {

  const { t } = useTranslation()
  const batchProcessingState = useSelector(state => state.batchData)
  const errorAnalyses = useSelector(state => state.batchData.errorAnalyses)
  const filters = useSelector(state => state.tableData.filters)

  const [error, setError] = useState(null)
  const [redirect, setRedirect] = useState('')
  const [detailsOpen, setDetailsOpen] = useState(false)

  const [countdown, setCountdown] = useState(100)

  const { aArchiveSetArchiveType, aArchiveSetPredefined, aTableSetFilters, aUserSetArchiveLanguage } = useActions({
    aArchiveSetArchiveType: archiveSetArchiveType,
    aTableSetFilters: tableSetFilters,
    aArchiveSetPredefined: archiveSetPredefined,
    aUserSetArchiveLanguage: userSetArchiveLanguage
  })

  const { actionBatchProcessingClear } = useActions({
    actionBatchProcessingClear: batchProcessingClear
  })

  useEffect(() => {
    if (batchProcessingState.done) {
      if (countdown <= 0) {
        actionBatchProcessingClear()
      } else if (!detailsOpen) {
        setTimeout(() => setCountdown(countdown - 5), 1000)
      }
    }
  }, [batchProcessingState.done, countdown])

  const handleProgressbarClick = () => {

    if (batchProcessingState.hasError) {
      setDetailsOpen(true)
    } else if (batchProcessingState.done) {
      aArchiveSetPredefined(batchProcessingState.folderID)
      aArchiveSetArchiveType(batchProcessingState.ApiOption)
      aUserSetArchiveLanguage(batchProcessingState.language)
      aTableSetFilters(getFolderFilter(batchProcessingState.folderID, filters))
      setTimeout(() => setRedirect(`/archive`), 100)
      setTimeout(() => actionBatchProcessingClear(), 200)

    } else if (batchProcessingState.running) {
      setDetailsOpen(true)
    }
  }

  let barStyle = style.front
  if (countdown < 100) {
    barStyle = style.countdownFront
  } else if (batchProcessingState.hasError) {
    barStyle = style.errorFront
  }

  if (redirect)
    return <Redirect to={redirect}/>

  return (
    <div>
      <div className={`${style.batchProcessingProgressbar}`}
           style={{ visibility: (!batchProcessingState.running && !batchProcessingState.done && !batchProcessingState.hasError) ? 'hidden' : 'visible' }}
           onClick={handleProgressbarClick}>
                <span className={errorAnalyses > 0 ? `${style.errorTitle}` : `${style.title}`} style={{marginTop: "-15px"}}>
                    {batchProcessingState.done  ?
                      <span className={style.title} style={{marginBottom: "-3px"}}> {t('batchProcessingReady')} </span>
                      :
                      <span className={style.title} style={{marginBottom: "-3px"}}>{t('batchProcessingTitle')} ({batchProcessingState.finishedAnalyses} / {batchProcessingState.totalAnalyses})</span>
                      }
                  {errorAnalyses > 0 ? ` ${t('danger')}: (${errorAnalyses}) ${t('batchProcessingErrorAnalyses')} ` : ''}
                </span>
        <div className={`${style.bar}`}>
          <div className={`${style.back}`}/>
          <div className={barStyle}
               style={{ width: countdown < 100 ? countdown + '%' : batchProcessingState.percent + '%' }}/>
        </div>
      </div>
      <ErrorModal error={error} setError={setError}/>
      <BatchProcessingDetails open={detailsOpen} setOpen={setDetailsOpen}/>
    </div>
  )
}