import style from "../Home/style.module.scss";
import React from "react";
import TonalityRangerBlocks from "../Home/TonalityRangerBlocks";
import {useTheme} from "@material-ui/core/styles";
import {useRouteMatch} from "react-router-dom";

export default function TonalityCollapsableChart({text1, text2, activeBlock, activeBlock2, ...props}) {
  const {theme: {colors}} = useTheme();
  const {params: {type}} = useRouteMatch();

  return (
    <div className={`${style.tonRanger} ${type === "texts" ? style.withoutBorder : ""}`} {...props}>
      <div className={style.tonRangerRow}>
        <p style={{color: colors.colorBlue1000}}>{text1}</p>
        <TonalityRangerBlocks activeCell={activeBlock} activeCell2={activeBlock2}/>
        <p style={{color: colors.colorBlue1000}} className={style.text2}>{text2}</p>
      </div>
    </div>
  )
}
