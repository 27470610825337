import React from "react"
import { Button, TextField } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import sitelanguages from "../../../../config/sitelanguages.json"

export default function TermsModalTabs({
  setDescription, description,
  name, setName, lang, setLang }) {

  const { t } = useTranslation()
  const langugesAsArray = Object.keys(sitelanguages)
  let nextLang = langugesAsArray.indexOf(lang)
  nextLang = langugesAsArray.length - 1 === nextLang ? langugesAsArray[0] : langugesAsArray[nextLang + 1]

  return (
    <div className={`d-flex flex-column`}>
      <div className={`d-flex align-items-center justify-content-between mb-3`}>
        <TextField
          value={!!name[lang] ? name[lang] : ""}
          onChange={e => {
            setName({ ...name, [lang]: e.target.value })
          }} size='small' className={`col-9`} label={t('title')} variant='outlined' color='primary' />
        <div className={`col-3 d-flex justify-content-center`} style={{ gridGap: 5 }}>
          <Button className="p-1" color='primary' variant='contained' onClick={() => setLang(nextLang)}
            style={{ minWidth: 50 }} >{sitelanguages[nextLang].nativeName}</Button>
        </div>
      </div>
      <TextField
        value={!!description[lang] ? description[lang] : ""}
        onChange={e => {
          setDescription({ ...description, [lang]: e.target.value })
        }} label={t('enter-description')} multiline rows={9} style={{ height: "100%" }} variant='outlined' color='primary' />
    </div>
  )
}
