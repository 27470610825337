import React, { useCallback, useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import closeIcon from '../../../../assets/close-circle.svg'
import { Rnd } from 'react-rnd'
import { useTranslation } from 'react-i18next'
import {
  convertJSONToObject,
  convertPipeToNewLine,
  isObjectEmpty,
  setAutoPosition,
  stripHtml,
} from '../../../../utils'
import config from '../../../../config'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import ButtonWithPop from '../../Overview/ButtonWithPop'
import nextId from 'react-id-generator'
import { Done, DoneAll } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import ComBotBlue from '../../../../assets/combot-blue.svg'
import ComBotGray from '../../../../assets/combot-grey.svg'
import Beta from '../../../../assets/beta.png'
import { useActions } from '../../../../utils/action-helper'
import {
  analyseSetNNSuggestion,
  analyseUpdateNNSuggestion,
} from '../../../../actions'

export default function SuggestionModal ({
  closePopupWindow,
  cumulativeEditor,
  cumulativeElement,
  popupText,
  handleChangeInText,
  innerKey,
  handleAddToIgnoreList,
}) {
  const { t } = useTranslation()
  const token = useSelector(state => state.userData.token)
  const tlService = React.useContext(TLServiceComponent)
  const enabledSections = useSelector(
    state => state.userData.user?.enabled_frontend_sections || [])
  const nnPaths = useSelector(state => state.userData.nnPaths || {})
  const popups = useSelector(state => state.analyseData.popover.popups)
  const currentPopup = useSelector(
    state => state.analyseData.popover.currentPopup)
  const nnSuggestions = useSelector(
    state => (state.analyseData.popover.nnSuggestions &&
      state.analyseData.popover.nnSuggestions[popupText])
      ? state.analyseData.popover.nnSuggestions[popupText] : {})

  const popupData = popups[currentPopup].data
  const realPosition = popupData.currentPosition
  const fullNNPath = `${popupData.sectionName}_${popupData.language}`
  console.log('fullNNPath', fullNNPath)

  const modalRef = React.createRef()
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  let left = cumulativeElement.left + 60
  let top = cumulativeElement.top + cumulativeEditor.top
  const [positionX, setPositionX] = useState(left)
  const [positionY, setPositionY] = useState(top)

  const [newText, setNewText] = useState('')
  const [valueChanged, setValueChanged] = useState(false)

  const { aAnalyseSetNNSuggestion, aAnalyseUpdateNNSuggestion } = useActions({
    aAnalyseSetNNSuggestion: analyseSetNNSuggestion(tlService),
    aAnalyseUpdateNNSuggestion: analyseUpdateNNSuggestion,
  })

  useEffect(() => {
      setNewText(popupText)
      setValueChanged(false)
    }, [popupText],
  )

  const getSuggestions = async (text, num_return = 1) => {
    if (!nnSuggestions?.loading && isObjectEmpty(nnSuggestions['data'])) {
      aAnalyseSetNNSuggestion(token, text, fullNNPath, num_return)
    }
  }

  useEffect(() => {
    if (enabledSections.indexOf(fullNNPath) > -1) {
      getSuggestions(stripHtml(popupText), popupData.numSuggestions, 3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupText])

  const suggestionColors = useCallback((probability) => {
    if (!isObjectEmpty(nnPaths) &&
      !isObjectEmpty(nnPaths[fullNNPath]) &&
      nnPaths[fullNNPath]?.color_scores) {
      for (let i of nnPaths[fullNNPath].color_scores) {
        if (probability > i['probability']) {
          return i['color']
        }
      }
    }
    return 'rgba(255,255,255,0.60)'
  }, [nnPaths])

  useEffect(() => {
      const [newX, newY] = setAutoPosition(
        modalRef.current?.clientHeight,
        modalRef.current?.clientWidth,
        window.innerHeight,
        window.innerWidth,
        top,
        left)
      setPositionY(newY)
      setPositionX(newX)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [left, top, modalRef.current, window.innerHeight, window.innerWidth],
  )

  const noTranslate = popupData?.noTranslate
  const descriptionText = popupData?.text ? (noTranslate ? popupData.text : t(
    popupData.text)) : ''

  return (
    <Rnd
      onDragStop={(e, d) => {
        setPositionX(d.x)
        setPositionY(d.y)
      }}
      position={{ x: positionX, y: positionY }}
      default={{
        width: 640,
      }} style={{ cursor: 'default', zIndex: 1000 }}
      key={innerKey}>
      <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
        <div
          style={{ backgroundColor: bgColors.bgPrimary300 }}
          className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
          <p style={{ backgroundColor: bgColors.bgPrimary300 }}
             className={`m-0 ${style.title}`}>
            {noTranslate ? popupData?.header : t(popupData?.header)}
          </p>
          <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
            closePopupWindow()
          }} onMouseDown={e => e.stopPropagation()}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          {(enabledSections.indexOf(fullNNPath) > -1) ? <>
            <div
              className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>
              <div>
                <b>
                  {popupText}
                </b>
              </div>
              <div className={`d-flex`} style={{ gridGap: '10px' }}>
                {(!popupData?.category?.ignoreDisabled || valueChanged) && <>
                  <ButtonWithPop
                    text={valueChanged ? t('replace here') : t('ignore here')}
                    typeOfCount={''}
                    valueChanged={valueChanged}
                    onClick={() => {
                      if (!valueChanged) {
                        handleAddToIgnoreList(currentPopup, newText, '', 'terminology', convertJSONToObject(popupData.words), realPosition)
                      } else {
                        handleChangeInText(currentPopup, newText, '', 'terminology')
                      }
                      closePopupWindow()

                    }}
                  />
                  <ButtonWithPop
                    text={valueChanged ? t('replace everywhere in the document') : t('ignore everywhere in the document')}
                    typeOfCount={'all'}
                    valueChanged={valueChanged}
                    onClick={() => {
                      if (!valueChanged) {
                        handleAddToIgnoreList(
                          currentPopup, newText,
                          popupText, 'terminology',
                          convertJSONToObject(
                            popupData.words), -1)
                      } else {
                        handleChangeInText(currentPopup,
                          newText, popupText,
                          'terminology')
                      }
                      closePopupWindow()
                    }}
                  />
                </>}
              </div>
            </div>
            {(!nnSuggestions?.loading && nnSuggestions['data'] &&
              !isObjectEmpty(nnSuggestions['data']['sequences'])) ?
              <div className={`${style.rows} ml-3 mr-3 p-1 m-1`}
                   style={{ borderColor: borderColors.borderPrimary100 }}>
                {nnSuggestions['data']['sequences'].map(
                  (val, index) => {

                    const probability = nnSuggestions['data']['sequences_scores'][index] ||
                      0
                    let color = suggestionColors(probability)

                    return (<div key={index}
                                 className={`${style.row} p-1`}
                                 style={{ borderColor: borderColors.borderPrimary100 }}>
                      <div className={`d-flex align-items-center`}>
                        <div className={style.fieldCont}>
                          <div className={style.imgdiv}>
                            <div className={style.image}><img alt=""
                                                              src={ComBotBlue}
                                                              width={30}/>
                            </div>
                            <div className={style.image}><img alt=""
                                                              src={Beta}
                                                              width={30}/>
                            </div>
                          </div>
                          <div>
                                                <textarea type="text"
                                                          className={style.textArea}
                                                          style={{
                                                            background: `linear-gradient(0deg, ${color} 3%, rgba(255,255,255,1) 7%)`,
                                                          }}
                                                          value={val.replaceAll('<br>', '\n')}
                                                          onMouseDown={e => e.stopPropagation()}
                                                          onChange={e => {
                                                            setValueChanged(
                                                              true)
                                                            aAnalyseUpdateNNSuggestion(
                                                              popupText, index,
                                                              e.target.value)
                                                          }}/>
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex align-items-center`}
                           style={{
                             gridGap: '5px',
                             marginBottom: '30px'
                           }}>
                        <ButtonWithPop
                          text={t('replace here')}
                          typeOfCount={''}
                          onClick={() => {
                            handleChangeInText(currentPopup, val, '', 'net')
                            closePopupWindow()
                          }}
                        >
                          <Done
                            key={nextId()}
                            style={{ fill: colors.colorProgress100, height: 24 }}/>
                        </ButtonWithPop>
                        <ButtonWithPop
                          text={t('replace everywhere in the document')}
                          typeOfCount={''}
                          onClick={() => {
                            handleChangeInText(currentPopup, val, popupText, 'net')
                            closePopupWindow()
                          }}
                        >
                          <DoneAll
                            key={nextId()}
                            style={{ fill: colors.colorProgress100, height: 24 }}/>
                        </ButtonWithPop>
                      </div>
                    </div>)
                  })}
              </div> : <>
                {nnSuggestions?.loading ?
                  <div className={`mx-auto my-auto`}
                       style={{ borderColor: borderColors.borderPrimary100 }}>
                    <div className={`d-flex justify-content-center align-items-center`}>
                      <div style={{ marginTop: '40px' }}><img
                        alt={t('loading the robot suggestions...')} src={ComBotGray} width={30}
                        className={style.android}/></div>
                      <div
                        style={{ marginLeft: '20px' }}>{t('loading the robot suggestions...')}</div>
                    </div>
                  </div>
                  : <></>}
              </>
            }
            <p className={`m-1 mr-3 ml-3 mt-3 ${style.errorText}`}
               dangerouslySetInnerHTML={{ __html: stripHtml(t(`num ${descriptionText}`, { num: popupText.trim().split(/\s+/)?.length }), config.dangerousTags) }}/>
          </> : <>
            <p className={`m-1 mr-3 ml-3 mt-3 ${style.errorText}`}
               dangerouslySetInnerHTML={{ __html: stripHtml(descriptionText, config.dangerousTags) }}/>
            {(popupData.bestPractice && t(popupData.bestPractice)) &&
              <>
                <p className={`m-1 mt-3 mr-3 ml-3 ${style.errorDescTitle}`}>{t('best-practices')}:</p>
                <div style={{
                  backgroundColor: 'rgba(206, 224, 255, .20)',
                  borderColor: borderColors.borderPrimary200
                }}
                     className={`${style.desc} ml-3 mt-3 mr-3 p-1`}
                     dangerouslySetInnerHTML={{ __html: stripHtml(convertPipeToNewLine(noTranslate ? popupData.bestPractice : t(popupData.bestPractice), true), config.dangerousTags) }}/>
              </>
            }
          </>}
        </div>
      </div>
    </Rnd>
  )
}