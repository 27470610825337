import React, { useState } from "react";
import { Dialog, DialogContent, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import style from "./style.module.sass"
import submitIcon from "../../assets/anmeldenIcon.svg"
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import config from "../../config";
import TLServiceComponent from "../../contexts/TLServiceComponent";
import { useActions } from "../../utils/action-helper";
import { clearUser } from "../../actions";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import { convertFromErrorObject } from "../../utils";

export default function ModalMustChangePassword({ alerts }) {
    const { t } = useTranslation();
    const { theme: { bgColors, borderColors, colors } } = useTheme();
    const token = useSelector(state => state.userData.token);
    const userId = useSelector(state => state.userData.user.id);
    const [submitted, setSubmitted] = useState(false);
    const tlService = React.useContext(TLServiceComponent);
    const { actionClearUser } = useActions({
        actionClearUser: clearUser(tlService),
    })

    const validationSchema = yup.object({
        old_password: yup
            .string('Enter correct password')
            .min(config.minPasswordLength, t("Password minimum characters length", { length: config.minPasswordLength }))
            .required(t("Required field")),
        password: yup
            .string('Enter correct password')
            .min(config.minPasswordLength, t("Password minimum characters length", { length: config.minPasswordLength }))
            .required(t("Required field")),
        repeated_password: yup
            .string('Enter correct password')
            .oneOf([yup.ref('password'), null], t("Passwords must match"))
            .required(t("Required field"))
    });

    const formik = useFormik({
        initialValues: {
            password: "",
            old_password: "",
            repeated_password: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values, options) => {
            setSubmitted(true);
            if (!await handleSubmit(values)) {
                options.setFieldError('old_password', t("Wrong old password"))
            }
            setSubmitted(false);
        },
    });

    function logout() {
        actionClearUser();
        localStorage.removeItem(config.localStorageTokenName);
        return <Redirect to="/" />;
    }

    async function handleSubmit(values) {
        try {
            await tlService.updateUser(token, userId, {
                password: values.password,
                old_password: values.old_password
            });
        } catch (e) {
            console.log(convertFromErrorObject(t, e));
            return false;
        }
        try {
            await tlService.setMessageRead(token, alerts.id);
        } catch (e) {
            console.log(convertFromErrorObject(t, e));
        }
        logout();
    }

    return (
        <>
            {submitted && <Spinner />}
            <Dialog
                open={true}
                onClose={() => true}
                aria-labelledby="dialog-title"
            >
                <DialogContent className={style.description} id="alert-dialog-description" style={{
                    backgroundColor: bgColors.bgPrimary0
                }}>
                    <form onSubmit={formik.handleSubmit} className={`${style.form} col-12 col-md-10 col-lg-8`}>
                        <div className={style.fields}>
                            <TextField type='password'
                                helperText={formik.touched.old_password && formik.errors.old_password}
                                size='small'
                                error={formik.touched.old_password && !!formik.errors.old_password}
                                onChange={formik.handleChange}
                                name={'old_password'}
                                variant="filled" label={t("Old password")} />
                            <TextField type='password'
                                helperText={formik.touched.password && formik.errors.password}
                                size='small' className={`mt-3`}
                                error={formik.touched.password && !!formik.errors.password}
                                onChange={formik.handleChange}
                                name={'password'}
                                variant="filled" label={t("New password")} />
                            <TextField type='password'
                                helperText={formik.touched.repeated_password && formik.errors.repeated_password}
                                error={formik.touched.repeated_password && !!formik.errors.repeated_password}
                                onChange={formik.handleChange} size='small'
                                name={'repeated_password'} className={`mt-3`} variant="filled"
                                label={t("Repeat password")} />

                            <div className={`${style.submitCont} mt-2`}>
                                <button type="submit" className={`${style.submit} mr-4 d-flex align-items-center`}>
                                    <img src={submitIcon} alt="submit svg" />
                                    <p className={`m-0 ${style.submitText}`}>{t("Change password")}</p>
                                </button>
                                <button onClick={logout} type="button" className={`${style.submitLogout} d-flex align-items-center`}>
                                    <img src={submitIcon} alt="submit svg" />
                                    <p className={`m-0 ${style.submitText}`}>{t("Logout")}</p>
                                </button>
                            </div>
                            <div className={`${style.fieldsActions} mb-3`}>
                                <div style={{ borderColor: borderColors.borderPrimary300 }}
                                    className={style.news}>
                                    <div style={{ color: colors.colorPrimary300 }}
                                        dangerouslySetInnerHTML={{ __html: t("Modal change password bottom text") }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}