import React, {useState} from "react";
import DashHeader from "../Header";
import RadialBar from "../UI/RadialBar";
import style from "./style.module.scss"
import SpeechClimate from "./SpeechClimate";
import Tonality from "./Tonality";
import VocabularyComponent from "../UI/tags-cloud/VocabularyComponent";
import {useTheme} from "@material-ui/core/styles";
import Distribution from "./Distribution";
import {NavLink, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import {useTranslation} from "react-i18next";
import {convertLanguagesToShort, isObjectEmpty, roundWithPrecision} from "../../../utils";
import ModalDialog from "../../ModalWindows/ModalDialog";
import config from "../../../config";

export default function DashHomeSingle() {
    const {theme: {bgColors}} = useTheme();
    const {params: {type}} = useRouteMatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const archiveName = useSelector(state => state.analyseData.archives?.name);
    const benchmark = useSelector(state => state.analyseData.result.benchmark);
    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const targetHix = currentBenchmarkSettings?.settings?.formulaHix?.values?.target || 0;
    const targetCLIX = currentBenchmarkSettings?.settings?.formulaCLIX?.values?.target || 0;
    const targetFlesch = currentBenchmarkSettings?.settings?.formulaFlesch?.values?.target || 0;

    const tonalityRows = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {
        if (!isObjectEmpty(resultData[key])) {
            const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1;
            const blocks = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep);
            const exactlyActual = resultData[key];
            const actualInRange = blocks.findIndex(val => exactlyActual <= val);

            return [
                ...acc,
                {
                    blocks,
                    leftText: t(val.scaleFrom[1]),
                    rightText: t(val.scaleTo[1]),
                    rangePosition: blocks[actualInRange],
                }
            ]

        }
        return acc;
    }, []);

    if (resultTemplate < 1)
        return <Redirect to={"/"}/>;

    // hix text
    let dash_hix_text;
    if (resultData["formulaHix"] > 16.6)
        dash_hix_text = t("dash_hix_text_16_6")
    else if  (resultData["formulaHix"] > 13.3)
        dash_hix_text = t("dash_hix_text_13_3")
    else if  (resultData["formulaHix"] > 10)
        dash_hix_text = t("dash_hix_text_10")
    else if  (resultData["formulaHix"] > 6.6)
        dash_hix_text = t("dash_hix_text_6_6")
    else if  (resultData["formulaHix"] > 3.3)
        dash_hix_text = t("dash_hix_text_3_3")
    else
        dash_hix_text = t("dash_hix_text_0")

    // clix text
    let dash_clix_text;
    if (resultData["formulaCLIX"] > 85)
        dash_clix_text = t("dash_clix_text_85")
    else if  (resultData["formulaCLIX"] > 70)
        dash_clix_text = t("dash_clix_text_70")
    else if  (resultData["formulaCLIX"] > 55)
        dash_clix_text = t("dash_clix_text_55")
    else if  (resultData["formulaCLIX"] > 33)
        dash_clix_text = t("dash_clix_text_33")
    else if  (resultData["formulaCLIX"] > 16)
        dash_clix_text = t("dash_clix_text_16")
    else
        dash_clix_text = t("dash_clix_text_0")

    // flesch text
    let dash_flesch_text;
    if (resultData["formulaFlesch"] > 90)
        dash_flesch_text = t("dash_flesch_text_90")
    else if (resultData["formulaFlesch"] > 80)
        dash_flesch_text = t("dash_flesch_text_80")
    else if (resultData["formulaFlesch"] > 70)
        dash_flesch_text = t("dash_flesch_text_70")
    else if (resultData["formulaFlesch"] > 60)
        dash_flesch_text = t("dash_flesch_text_60")
    else if (resultData["formulaFlesch"] > 50)
        dash_flesch_text = t("dash_flesch_text_50")
    else if (resultData["formulaFlesch"] > 30)
        dash_flesch_text = t("dash_flesch_text_30")
    else
        dash_flesch_text = t("dash_flesch_text_less_30")

    const colorHIX = resultData["formulaHix"]  < targetHix/2 ? "#dd0d08" : resultData["formulaHix"] < targetHix ? "#da7d27" : "#a8c721";
    const colorCLIX = resultData["formulaCLIX"] < targetCLIX/2 ? "#dd0d08" : resultData["formulaCLIX"] < targetCLIX ? "#da7d27" : "#a8c721";
    const colorFLESCH = resultData["formulaFlesch"] < targetFlesch/2 ? "#dd0d08" : resultData["formulaFlesch"] < targetFlesch ? "#da7d27" : "#a8c721";

    return (
        <div className={style.mainCont}>
            <DashHeader archiveName={archiveName} benchmarkName={benchmark['name'][convertLanguagesToShort(i18nLanguage)]}/>
            <div className={`${style.HIXCLIX}`} style={{gridGap: "30px", margin: "0 0 30px"}}>
                {resultData["formulaHix"] !== undefined ? <RadialBar
                    setDialogOpen={setDialogOpen}
                    value={roundWithPrecision(resultData["formulaHix"], 1)}
                    max={20}
                    color={bgColors.bgPrimary100}
                    text={dash_hix_text}
                    bg={colorHIX}
                    name={t("HIX_dashboard_minidash")}
                    targetVal={targetHix}
                    withNavLink={`/dashboard/${type}/HIX`}
                    mainText={t("dash_hix_main_text")}/> :
                    resultData["formulaFlesch"] !== undefined && <RadialBar
                    setDialogOpen={setDialogOpen}
                    value={roundWithPrecision(resultData["formulaFlesch"], 1)}
                    max={100}
                    color={bgColors.bgPrimary100}
                    text={dash_flesch_text}
                    bg={colorFLESCH}
                    name={t("FLESCH_dashboard_minidash")}
                    targetVal={targetFlesch}
                    mainText={t("dash_flesch_main_text")}/>}

                {resultData["formulaCLIX"] !== undefined && <RadialBar
                    setDialogOpen={setDialogOpen}
                    color={bgColors.bgPrimary100}
                    value={roundWithPrecision(resultData["formulaCLIX"], 1)}
                    text={dash_clix_text}
                    bg={colorCLIX}
                    name={t("CLIX_dashboard_minidash")}
                    max={100}
                    targetVal={targetCLIX}
                    withNavLink={`/dashboard/${type}/CLIX`}
                    mainText={t("dash_clix_main_text")}
                />}

            </div>
            <div className={`${style.SpeechTonality}`}>
                {resultData?.dataColorWords && <SpeechClimate type={"single"} setDialogOpen={setDialogOpen}/>}
                {/* since two div were one only in webtexte, had to make two tonality compoent with diff. center value! */}
                {tonalityRows?.length ? resultData?.dataColorWords && <Tonality center="10"  wide={!resultData?.dataColorWords} setDialogOpen={setDialogOpen} type={"single"}/> : <div/>}
                {tonalityRows?.length && !resultData?.dataColorWords ? <Tonality center="0" wide={!resultData?.dataColorWords} setDialogOpen={setDialogOpen} type={"single"}/> : <div/>}
            </div>
            <div className={`${style.words} ${style.cells}`} style={{backgroundColor: bgColors.bgPrimary0}}>
                <div className={`${style.words_main}`}>
                    <div className={style.vocabulary}>
                        <VocabularyComponent setDialogOpen={setDialogOpen}/>
                    </div>
                    <Distribution setDialogOpen={setDialogOpen}/>
                </div>
                <NavLink to={`/dashboard/${type}/vocabulary`}>
                    <div className={`${style.footer}`}>
                        <button>{t("Detail view")}</button>
                    </div>
                </NavLink>

            </div>
            <ModalDialog
                handleClose={()=>setDialogOpen(false)}
                open={dialogOpen}
                title={t('sneak preview modal title')}
                text={t('sneak preview modal text')}
                yesText={'Ok'}
                noText={false}
            />
        </div>
    )
}
