import MainPageWrapper from "../../components/Main-page-wrapper";
import React from "react";
import SpeechClimateComp from "../../components/Dashboard/SpeechClimate";

export default function SpeechClimate(){

  return (
    <MainPageWrapper>
      <SpeechClimateComp/>
    </MainPageWrapper>
  )
}