import React, { Fragment, useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { Done, DoneAll, Add } from '@material-ui/icons'
import closeIcon from '../../../../assets/close-circle.svg'
import { Rnd } from 'react-rnd'
import ButtonWithPop from '../../Overview/ButtonWithPop'
import { useTranslation } from 'react-i18next'
import {
    prepareReplacement,
    convertArrayToText,
    convertJSONToObject,
    convertPipeToNewLine,
    setAutoPosition,
    stripHtml,
    convertLanguagesToShort, applyReplacements
} from '../../../../utils'
import config from '../../../../config'
import nextId from 'react-id-generator'
import {
    allowlistText,
    // popUpData
} from '../../../../actions'
import { useSelector, useDispatch } from 'react-redux'

export default function TerminologyModal({
    handleChangeInText,
    handleAddToIgnoreList,
    popupText,
    closePopupWindow,
    cumulativeEditor,
    cumulativeElement,
    innerKey
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // get popover
    const popups = useSelector(state => state.analyseData.popover.popups)
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup)

    const popupData = popups[currentPopup].data

    console.log('popupData',popupData)

    const description = popupData?.term?.description || popupData?.category?.description
    const suggestions = popupData.replacement || []
    const realPosition = popupData.currentPosition
    const innerReplacements = popupData.inner_replacements[realPosition] || []
    const arrText = popupData.arrText || [];
    const preparedReplacement = prepareReplacement(suggestions)
    const modalRef = React.createRef()
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
    const textLanguage = useSelector(state => state.analyseData.textLanguage) || "de_DE";

    const { theme: { bgColors, borderColors, colors } } = useTheme()
    const [valueChanged, setValueChanged] = useState(false)
    const [newText, setNewText] = useState('')


    const wordColorToBgColor = {
        colorBlue: bgColors.bgProgress120,
        colorRed: bgColors.bgProgress20,
        colorGreen: bgColors.bgProgress100,
        colorYellow: bgColors.bgProgress60,
    }


    let left = cumulativeElement.left + 60
    let top = cumulativeElement.top + cumulativeEditor.top

    const [positionX, setPositionX] = useState(left)
    const [positionY, setPositionY] = useState(top)

    useEffect(() => {
        const [newX, newY] = setAutoPosition(
            modalRef.current?.clientHeight,
            modalRef.current?.clientWidth,
            window.innerHeight,
            window.innerWidth,
            top,
            left)
        setPositionY(newY)
        setPositionX(newX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [left, top, modalRef.current, window.innerHeight, window.innerWidth]
    )

    useEffect(() => {
        setNewText(popupText)
        setValueChanged(false)
    }, [popupText]
    )

    useEffect(() => {
        if (newText === popupText)
            setValueChanged(false)
    }, [newText, popupText]
    )
    return (
        <Rnd
            onDragStop={(e, d) => {
                setPositionX(d.x)
                setPositionY(d.y)
            }}
            position={{ x: positionX, y: positionY }}
            default={{
                width: 640
            }} style={{ cursor: 'default', zIndex: 1000 }}
            key={innerKey}>
            <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
                <div
                    style={{ backgroundColor: bgColors.bgPrimary300 }}
                    className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
                    <p style={{ backgroundColor: bgColors.bgPrimary300 }}
                        className={`m-0 ${style.title}`}>{popupData?.category?.name}</p>
                    <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
                        closePopupWindow()
                    }} onMouseDown={e => e.stopPropagation()}>
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div
                    className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>
                    <div className={style.fieldCont} style={{
                        backgroundColor: popupData?.category?.category_type
                            ? wordColorToBgColor[popupData?.category?.category_type]
                            : bgColors.bgProgress40

                    }}>
                        <textarea type="text"
                            value={newText}

                            onMouseDown={e => e.stopPropagation()}
                            onChange={e => {
                                setValueChanged(true)
                                setNewText(e.target.value)
                            }} />
                    </div>
                    <div className={`d-flex`} style={{ gridGap: '10px' }}>
                        {(!popupData?.category?.ignoreDisabled || valueChanged) && <>
                            <ButtonWithPop
                                text={valueChanged ? t('replace here') : t('ignore here')}
                                typeOfCount={''}
                                valueChanged={valueChanged}
                                onClick={() => {
                                    if (!valueChanged) {
                                        handleAddToIgnoreList(currentPopup, newText, '', 'terminology', convertJSONToObject(popupData.words), realPosition)
                                    } else {
                                        handleChangeInText(currentPopup, newText, '', 'terminology')
                                    }
                                    closePopupWindow()

                                }}
                            />
                            <ButtonWithPop
                                text={valueChanged ? t('replace everywhere in the document') : t('ignore everywhere in the document')}
                                typeOfCount={'all'}
                                valueChanged={valueChanged}
                                onClick={() => {
                                    if (!valueChanged) {
                                        handleAddToIgnoreList(currentPopup, newText, popupText, 'terminology', convertJSONToObject(popupData.words), -1)
                                    } else {
                                        handleChangeInText(currentPopup, newText, popupText, 'terminology')
                                    }
                                    closePopupWindow()
                                }}
                            />
                        </>}
                        {(!popupData?.category?.allowListDisabled && enabledSections.indexOf('allowedlist') > -1) &&
                            <ButtonWithPop text={t('add entry to my allowed list')}>
                                <Add style={{ height: '20px' }} />
                                <span style={{ padding: '0 5px 0 0' }}
                                    onClick={() => {
                                        dispatch(allowlistText(newText))
                                        closePopupWindow()
                                    }
                                    }
                                >{t('allowed')}</span>
                            </ButtonWithPop>}

                    </div>
                </div>
                <div className={`${style.rows} ml-3 mr-3 m-1`} style={{ borderColor: borderColors.borderPrimary100 }}>
                    {Object.values(preparedReplacement).map((meaningVal, index) => {
                        if (meaningVal.replacements.length > 0) {
                            return (
                                <Fragment key={index}>
                                    {meaningVal.meaning &&
                                        <div className={`d-flex align-items-start`}>
                                            <p className={`m-0`}>{t('Meaning')}: {meaningVal.meaning[0]}</p>
                                        </div>
                                    }
                                    {
                                        meaningVal.replacements.map(valRep => {
                                            let text = convertArrayToText(applyReplacements(innerReplacements, valRep['words'], arrText),convertLanguagesToShort(textLanguage))

                                            if (!text)
                                                return null

                                            return (
                                                <div key={valRep.index} className={`${style.row} p-1`}
                                                    style={{ borderColor: borderColors.borderPrimary100 }}>
                                                    <div className={`d-flex align-items-center`}>
                                                        <p className={`m-0 ml-2`}>{text}</p>
                                                    </div>
                                                    <div className={`d-flex align-items-center`}
                                                        style={{ gridGap: '5px' }}>
                                                        <ButtonWithPop
                                                            text={t('replace here')}
                                                            typeOfCount={''}
                                                            valueChanged={valueChanged}
                                                            onClick={() => {
                                                                handleChangeInText(currentPopup, text, '', 'terminology')
                                                                closePopupWindow()
                                                            }}
                                                        >
                                                            <Done
                                                                key={nextId()}
                                                                style={{ fill: colors.colorProgress100, height: 24 }} />
                                                        </ButtonWithPop>
                                                        <ButtonWithPop
                                                            text={t('replace everywhere in the document')}
                                                            typeOfCount={''}
                                                            valueChanged={valueChanged}
                                                            onClick={() => {
                                                                handleChangeInText(currentPopup, text, popupText, 'terminology')
                                                                closePopupWindow()
                                                            }}
                                                        >
                                                            <DoneAll
                                                                key={nextId()}
                                                                style={{ fill: colors.colorProgress100, height: 24 }} />
                                                        </ButtonWithPop>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        }
                        return null
                    })
                    }
                </div>
                {description &&
                    <div style={{
                        backgroundColor: 'rgba(206, 224, 255, .20)',
                        borderColor: borderColors.borderPrimary200
                    }}
                        className={`${style.desc} ml-3 mt-3 mr-3 p-1`}
                        dangerouslySetInnerHTML={{ __html: stripHtml(convertPipeToNewLine(description, true), config.dangerousTags) }} />
                }
            </div>
        </Rnd>
    )
}
