import React from "react";
import ProfileComponent from "../../components/Profile/";

function Profile() {
    return (
        <ProfileComponent/>
    )
}

export default Profile;
