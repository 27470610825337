import React from 'react';
import Field from './Field';
import PasswordField from "./PasswordField";
import Switch from "./Switch";
import DatePicker from "./DatePicker";
import Select from "./Select";
import Autocomplete from "./AutoComplete";
import SelectWithTextField from "./SelectWithTextField";
import Textarea from "./Textarea";

function TypeOfField({ control, formik, defaultChecked, rights = false, ...rest }){
  switch (control) {
    case 'text':
      return <Field formik={formik} {...rest} />
    case 'password':
      return <PasswordField formik={formik} {...rest}/>
    case 'switch':
      return <Switch formik={formik} defaultChecked={defaultChecked} rights={rights} {...rest}/>
    case 'date':
      return <DatePicker formik={formik} {...rest}/>
    case 'select':
      return <Select formik={formik} {...rest}/>
    case 'autocomplete':
      return <Autocomplete formik={formik} {...rest}/>
    case 'select-with-text-field':
      return <SelectWithTextField formik={formik} {...rest}/>
    case 'textarea':
      return <Textarea formik={formik} {...rest}/>
    default:
      return null
  }
}

export default TypeOfField;

