import style from "./style.module.scss";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import TonalityRangerBlocks from "./TonalityRangerBlocks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import config from "../../../config";
import { isObjectEmpty, roundWithPrecision } from "../../../utils";
import { NavLink, useRouteMatch } from "react-router-dom";

export default function TonalitySingle({ wide, center }) {
  const { theme: { colors } } = useTheme();
  const { t } = useTranslation();
  const resultData = useSelector(state => state.analyseData.result.resultData);
  const { url } = useRouteMatch();

  const tonalityRows = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {
    if (!isObjectEmpty(resultData[key])) {
      const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1;
      const blocks = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep);
      const exactlyActual = resultData[key];
      const actualInRange = blocks.findIndex(val => exactlyActual <= val);

      return [
        ...acc,
        {
          blocks,
          leftText: t(val.scaleFrom[1]),
          rightText: t(val.scaleTo[1]),
          rangePosition: blocks[actualInRange],
        }
      ]

    }
    return acc;
  }, []);

  const comparionTonalityRows = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {
    if (!isObjectEmpty(resultData[key])) {
      const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1;
      const blocks = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep);
      const exactlyActual = resultData[key];
      const actualInRange = blocks.findIndex(val => exactlyActual <= val);

      return [
        ...acc,
        {
          blocks,
          leftText: t(val.scaleFrom[1]),
          rightText: t(val.scaleTo[1]),
          rangePosition: blocks[actualInRange],
        }
      ]

    }
    return acc;
  }, []);

  return (
    <div>
      <h1 style={{ fontSize: "23px", color: "gray" }}>{t("Dashboard Your text works")}</h1>
      <div className={style.tonRanger} >
        <span style={{ marginTop: `${center}rem` }}>
          {url.includes("combine") ? comparionTonalityRows.map((cell, i) => (
            <div key={i} className={style.tonRangerRow} >
              <p style={{ color: colors.colorBlue1000 }}>{cell.leftText}</p>
              <TonalityRangerBlocks activeCell={cell.rangePosition} blocks={cell.blocks} />
              <p style={{ color: colors.colorBlue1000 }} className={style.text2}>{cell.rightText}</p>
            </div>
          )) :
            tonalityRows.map((cell, i) => (
              <div key={i} className={style.tonRangerRow} >
                <p style={{ color: colors.colorBlue1000 }}>{cell.leftText}</p>
                <TonalityRangerBlocks activeCell={cell.rangePosition} blocks={cell.blocks} />
                <p style={{ color: colors.colorBlue1000 }} className={style.text2}>{cell.rightText}</p>
              </div>
            ))}
        </span>
      </div>
    </div>
  )
}
