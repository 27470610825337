import React from "react"
import AuthForm from "../../components/Authorization";

function Authorization() {
    return (
        <section>
            <AuthForm/>
        </section>
    )
}

export default Authorization
