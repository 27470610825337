import { useTheme } from "@material-ui/styles"
import { Fragment, useContext, useEffect, useState } from "react"
import TLServiceComponent from "../../../../../contexts/TLServiceComponent"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useActions } from "../../../../../utils/action-helper"
import {
  tableSetData,
  tableSetDialog,
  tableSetSelected,
} from "../../../../../actions"
import style from './style.module.sass'
import TableContainer from '@material-ui/core/TableContainer'
import { Check, Delete, Settings } from '@material-ui/icons'
import TableHead from '@material-ui/core/TableHead'
import { convertFromErrorObject, convertJSONToObject, convertedValue, isObjectEmpty, showStateAsText, checkUserRight, getTCLocaleName, getTreeTaggerResult } from "../../../../../utils"
import { Box, Button, Checkbox, Collapse, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import Spinner from "../../../../Spinner"
import closeIcon from "../../../../../assets/close-circle.svg"
import { Alert } from "@mui/material"
import config from "../../../../../config"


export default function TableInnerComponent({
  row,
  index,
  isBlueRow,
  setTerminologyModal,
  setCurrentTerm,
  callEndPoint
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)

  const selected = useSelector(state => state.tableData.selected)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const data = useSelector(state => state.tableData.data)
  const columns = useSelector(state => state.tableData.columns)
  const token = useSelector(state => state.userData.token)


  const [extraData, setExtraData] = useState({})
  const [spinner, setSpinner] = useState(false)
  const [termModal, setTermModal] = useState(false)
  const [deleteRecall, setDeleteRecall] = useState()
  const [errorMsg, setErrorMsg] = useState('')
  const [approveModal, setApproveModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [deleteMsg, setDeleteMsg] = useState()

  const modalSetting = convertJSONToObject(
    useSelector(state => state.userData?.user?.user_options.modal_settings))

const user = useSelector(state => state.userData.user)
const rightToUser = checkUserRight(user, [101, 102, 103, 104, 105, 201, 202, 203, 204, 205])

  const {
    aTableSetSelected,
    aTableSetData,
    aTableSetDialog,
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetSelected: tableSetSelected,
    aTableSetDialog: tableSetDialog,
  })

  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)


  useEffect(() => {
    async function fetchData() {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'deleteAL':
            if (dialogResponse && !isObjectEmpty(extraData)) {
              const al_id = extraData.id
              try {
                await tlService.deleteAllowedList(token, al_id)
                setTermModal(false)
                setDeleteRecall(al_id)
                const tData = [...data]
                tData.splice(index, 1)
                aTableSetData(tData)
              } catch (error) {
                console.log('deleterAL', error)
                aTableSetDialog({
                  type: 'errorDeleteAL',
                  open: true,
                  RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                  response: null,
                  buttons: {
                    yes: false,
                    no: false,
                    close: true,
                    save: false
                  }
                })
              }
            }
            break
          default:
            break
        }
        setExtraData({})
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const handleSelectItem = (id) => {
    const tSelected = [...selected]
    const idx = tSelected.indexOf(id)
    if (idx > -1) {
      tSelected.splice(idx, 1)
    } else {
      tSelected.push(id)
    }
    aTableSetSelected(tSelected.sort())
  }

  const handleOpenItem = (inx) => {
    const tData = [...data]
    tData[inx]['opened'] = !row?.opened
    aTableSetData(tData)
  }

  const handleDeleteAllowedList = async (row) => {
    setExtraData(row)
    aTableSetDialog({
      type: 'deleteAL',
      open: true,
      RequestComponent: () => <>{t('admin_al_delete-al')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })

  }
  const update = async row => {
    try {
        await tlService.updateWhitelist(
            token,
            row.id,
            1
        )
      setApproveModal(false)
    } catch (e) {
        console.log('error ' + convertFromErrorObject(t, e))
        setErrorModal(true)
        setErrorMsg(convertFromErrorObject(t, e))
        setTimeout(() => {
            setErrorMsg('')
        }, config.timeOutDuration)
        return false
    }
    setApproveModal(false)
    // Recall Endpoint :
    callEndPoint()
    return true
}
  const actionApprove = async () => {
    const categoryLocaleName = await getTCLocaleName(tlService, token, row.term_category_id)
    if (!categoryLocaleName) {
        return false
    }

    let newTermId = 0
    try {
        const res = await tlService.addTerm(
            token,
            row.term_category_id,
            (row.words),
            (row.lemma),
            (row.tag),
            row?.lemma?.includes('<unknown>') ? 1 :
                row.check_words,
            {},
            row.description
        )
        newTermId = res?.data?.id || 0
    } catch (e) {
        let error
        try {
            error = JSON.parse(e.message)
        } catch (e) {
            error = {}
        }
        if (error?.number === 409) {
            return await update(row)
        } else {
            setErrorModal(true)
            setDeleteMsg(convertFromErrorObject(t, e))
            console.log('error ' + convertFromErrorObject(t, e))
            setErrorMsg(convertFromErrorObject(t, e))
            setTimeout(() => {
                setErrorMsg('')
            }, config.timeOutDuration)
        }
        return false
    }
    // go through replacements
    const rowReplacements = row.replacements || []
    if (rowReplacements?.length && newTermId > 0) {
        for (const element of rowReplacements) {
            const tokenizeTerm = await getTreeTaggerResult(tlService, token, categoryLocaleName, element)
            if (!tokenizeTerm?.error) {
                try {
                    await tlService.addTermReplacement(token, newTermId, row.term_category_id,
                        tokenizeTerm.word, tokenizeTerm.lemma, tokenizeTerm.tag, row.description, 1)
                } catch (e) {
                    console.log('error ' + convertFromErrorObject(t, e))
                    return false
                }
            } else {
                console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                return false
            }
        }
    }
    try {
        await tlService.calculateCategory(token, parseInt(row.term_category_id))
        await tlService.clearAllCache(token, 1, parseInt(row.term_category_id))
    } catch (e) {
        console.log(e)
    }

    return await update(row)
}

  const EmptyCell = () => {
    return <TableCell
      onClick={() => handleOpenItem(index)}
      className={style.emptyColumns}
      component="th"
      scope="row"
      padding="none" />
  }

  /* VIEW */
  const isItemSelected = selected.includes(row.id)

  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><Spinner /></div>

  return (
    <>
      <Modal
        open={approveModal}
        onClose={() => setApproveModal(false)}
        style={{
          width: '30%',
          margin: '0 auto',
          marginTop: '20%',
          height: '300px',
        }}
      >
        <div
          className={`pb-3`}
          style={{ backgroundColor: bgColors.bgPrimary0 }}
        >
          <div
            className={`d-flex justify-content-between p-1 pl-3`}
            style={{ backgroundColor: bgColors.bgPrimary400 }}
          >
            <p
              className={`m-0 ${style.title}`}
              style={{ color: 'white', fontSize: '25px' }}
            >  {t('approve-allowed-list')}</p>
            <button
              className={`border-0 bg-transparent`}
              onClick={() => setApproveModal(false)}
            >
              <img src={closeIcon} alt="" />
            </button>
          </div>

          <br />
          <div>
            <Collapse in={!!errorMsg} style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Alert className={`m-0`} severity="error">
                <h6 className={`m-0`}>
                  {errorMsg}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div
            style={{ textAlign: 'center', marginTop: '5%' }}>

            <Button
              color="primary"
              variant="contained"
              onClick={() => actionApprove()}
            >
              {t('approve-allowedList')}
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setApproveModal(false)
              }}
              style={{ marginLeft: '4%' }}
            >
              {t('close')}
            </Button>
            <br />
            <br />
          </div>
        </div>
      </Modal>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={() => handleSelectItem(row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </TableCell>
        {columns.map((col, ind) =>
          <Fragment key={ind}>
            {disabledColumns.includes(col.id) ? <EmptyCell /> :
              <>
                <TableCell
                  onClick={() => handleOpenItem(index)}
                  className={style.otherColumns}
                  component="th"
                  scope="row"
                  padding="none">
                  {
                    col.id === 'term_category_name' ? convertedValue(row[col.id][i18nLanguage] ? row[col.id][i18nLanguage] : ""  , col.type, i18nLanguage, t, {}, {}) :
                    col.id === 'check_words' ? 
                    t(row[col.id] === 1 ? 'Wortform' : 'Grundform') :
                    col.id === 'state' ? convertedValue(showStateAsText(row[col.id], t) , col.type, i18nLanguage, t, {}, row) :
                     convertedValue(row[col.id], col.type, i18nLanguage, t, {}, row, rightToUser)
                  }
                </TableCell>
              </>
            }
          </Fragment>)
        }
        <TableCell className={`${style.cell} d-flex`} align="right">
          <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
              style={{
                padding: '4px',
                minWidth: '30px',
                backgroundColor: bgColors.bgProgress20,
                color: colors.colorPrimary0
              }}
              onClick={() => handleDeleteAllowedList(row)}>
              <Delete fontSize="small" />
            </Button>
          </div>
          <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
              style={{
                padding: '4px',
                minWidth: '30px',
                backgroundColor: bgColors.bgBlue1000,
                color: colors.colorPrimary0
              }}
              onClick={() => {
                setTerminologyModal(true)
                setCurrentTerm(row)
            }}
            >
              <Settings fontSize="small" />
            </Button>
          </div>
         { !row.state > 0 && <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
              style={{
                padding: '4px',
                minWidth: '30px',
                backgroundColor: bgColors.bgBlue1000,
                color: colors.colorPrimary0
              }}
              onClick={async () => {
                if (!modalSetting?.allowed_list_checked_term?.not_show) {
                    setApproveModal(true)
                } else {
                    await actionApprove()
                }
            }}
              >
              <Check fontSize="small" />
            </Button>
          </div>}
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse className={'p-1'} in={row.opened} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
              className={`mb-4 d-flex justify-content-between pt-2`}
              margin={1}>
              <div className={'d-flex text-center w-100'}>
                <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
                  <TableContainer>
                    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={style.smallTableHeader} style={{ width: '15%' }}>{t('id')}</TableCell>
                          <TableCell className={style.smallTableHeader} style={{ width: '25%' }}>{t('admin_al_desc')}</TableCell>
                          <TableCell className={style.smallTableHeader} style={{ width: '30%' }}>{t('admin_al_update-time')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.opened &&
                          <Fragment key={row.id}>
                            <TableRow
                              key={row.id}>
                              <TableCell
                                className={style.smallTable}>
                                {convertedValue(row.id, 'numeric', i18nLanguage, t)}</TableCell>
                              <TableCell
                                className={style.smallTable}>{convertedValue(row.term_category_description[i18nLanguage] ? row.term_category_description[i18nLanguage] : "", 'string', i18nLanguage, t)}</TableCell>
                              <TableCell
                                className={style.smallTable}>{convertedValue(row.update_time, 'date', i18nLanguage, t)}</TableCell>
                            </TableRow>
                          </Fragment>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}