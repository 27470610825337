import * as hash from 'object-hash';

class HashedData {
    constructor(data = [], keyName = []) {
        this.data = {};
        if (data && data.length > 0 && keyName && keyName.length > 0) {
            for (let tData of data) {
                const hashObj = keyName.reduce((accumulator, currentValue) => {
                    accumulator[currentValue] = tData[currentValue];
                    return accumulator
                }, {});
                this.data[hash(hashObj)] = tData;
            }
        }
    }

    get length() { return Object.keys(this.data).length; }

    getByHash(obj) {
        return this.data[hash(obj)]
    }

    getTextByHash(obj) {
        if (this.data[hash(obj)]) {
            return this.data[hash(obj)].text;
        }
        return false;
    }

    getAsArray () {
        let ret = [];
        for (let [, dataValue] of Object.entries(this.data)) {
            ret.push(dataValue)
        }
        return ret;
    }

    getByKey(value, key) {
        let ret = [];
        for (let [, dataValue] of Object.entries(this.data)) {
            if (dataValue[value] === key) {
                ret.push(dataValue)
            }
        }
        return ret;
    }

    getByRegexp(value, key) {
        let ret = [];
        for (let [, dataValue] of Object.entries(this.data)) {
            if (dataValue[value].match(key)) {
                ret.push(dataValue)
            }
        }
        return ret;
    }

}

export default HashedData
