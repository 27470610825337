import style from './style.module.sass'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import closeIcon from '../../../assets/close-circle.svg'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { Button } from '@material-ui/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import CreateTabContent from './CreateTabContent'
import { Collapse } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  analyseSetProgressBar
} from '../../../actions'
import { convertFromErrorObject, onlyUnique, removeTagMark, returnValuesFilter } from '../../../utils'
import config from '../../../config'
import { useActions } from '../../../utils/action-helper'

const WordSaveInArchive = ({ setOpen, modalState, setModalState }) => {
  const { theme: { bgColors } } = useTheme()
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('user')
  const [ApiOption, setApiOption] = useState('personal')
  const [errorState, setErrorState] = useState()
  const [name, setName] = useState()
  const [version, setVersion] = useState(1)
  const [tags, setTags] = useState([])
  const [formData, setFormData] = useState({
    personal: {
      pathName: [t('root_folder')],
      folder: 0
    },
    group: {
      pathName: [t('root_folder')],
      folder: 0
    },
    company: {
      pathName: [t('root_folder')],
      folder: 0
    }
  })

  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const userId = useSelector(state => state.userData.user.id)
  const saveArchive = useSelector(state => state.analyseData.saveArchive)
  const userType = useSelector(state => state.userData.user.user_type_id)
  const userGroupId = useSelector(state => state.userData.user.user_group_id)
  const companyId = useSelector(state => state.userData.user.company_id)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const benchmarksId = useSelector(state => state.analyseData.useBenchmark)
  const analyseText = useSelector(state => state.analyseData.text)
  const ResultData = useSelector(state => state.analyseData.result.resultData)
  const fileName = useSelector(state => state.analyseData.fileName)
  const userArchives = useSelector(state => state.userData.user.archives)
  const returnValues = useSelector(state => state.openData.return_values)
  const clix = useSelector(state => state.analyseData.result.resultData.formulaCLIX)
  const hix = useSelector(state => state.analyseData.result.resultData.formulaHix)
  const userName = useSelector(state => state.userData.user.user_name)

  const {
    aAnalyseSetProgressBar
  } = useActions({
    aAnalyseSetProgressBar: analyseSetProgressBar
  })

  // Set predefined archive name:
  useEffect(() => {
    if (!!fileName) {
      // when user upload new DOC:
      setName(fileName)
    } else if (!!saveArchive.name) {
      // when user enter through edit from archiev page
      setName(saveArchive.name)
    } else {
      // or User type himself then fill user name and date:
      let dateIs = new Date().toLocaleDateString()
      setName(userName + '_' + dateIs)
    }
  }, [fileName, saveArchive.name, userName])

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const postArchive = async (id) => {
    const splittedTags = tags.split(',')
      .map(v => v.trim()).filter(v => v?.length).filter(onlyUnique).sort()
    let benchmark
    try {
      Object.values(benchmarks).map(element => {
        if (Number(element.id) === Number(benchmarksId)) {
          benchmark = element
        }
      })
    } catch (e) {
      console.log(e)
    }
    if (!!benchmark) {
      let settingIs = {}
      settingIs['user_name'] = userName
      settingIs['benchmark_name'] = benchmark.name
      let languageIs = benchmark.locale_name
      try {
        let res
        let resultIs = returnValuesFilter(ResultData, returnValues)
        switch (ApiOption) {
          case 'personal':
            res = await tlService.addUserArchives(token, name, String(version), parseInt(benchmarksId),
              removeTagMark(analyseText), JSON.stringify(resultIs), settingIs, hix, clix, id, languageIs,
              splittedTags, userId)

            break
          case 'group':
            res = await tlService.addGroupArchives(token, name, String(version), parseInt(benchmarksId),
              removeTagMark(analyseText), JSON.stringify(resultIs), settingIs, hix, clix, id, languageIs,
              splittedTags, userGroupId)
            break
          case 'company':
            res = await tlService.addCompanyArchives(token, name, String(version), parseInt(benchmarksId),
              removeTagMark(analyseText), JSON.stringify(resultIs), settingIs, hix, clix, id, languageIs,
              splittedTags, companyId)
            break
          default:
            res = await tlService.addUserArchives(token, name, String(version), parseInt(benchmarksId),
              removeTagMark(analyseText), JSON.stringify(resultIs), settingIs, hix, clix, id, languageIs,
              splittedTags, userId)
            break
        }
        aAnalyseSetProgressBar({ type: ApiOption, folder: id })
        setModalState(false)
        setVersion(1)
      } catch (e) {
        if (e.message) {
          setErrorState(convertFromErrorObject(t, e))
        } else {
          setErrorState(t('errorMessage'))
        }
      }
    } else {
      setErrorState(t('errorMessage'))
    }
  }

  const addAnalysis = async () => {
    const selectedFolder = formData[ApiOption]?.folder ? formData[ApiOption]?.folder : 0
    await postArchive(selectedFolder)
  }

  return (
    <Modal open={modalState} onClose={() => {
      setOpen('')
    }}>
      <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
             style={{ backgroundColor: bgColors.bgPrimary400 }}>
          <p className={`m-0 ${style.title}`}>{t('Save As New Analysis')}</p>
          <button className={`border-0 bg-transparent`} onClick={() => {
            setOpen('')
          }}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          <Nav tabs fill justify="true">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'user' })}
                onClick={() => {
                  setApiOption('personal')
                  toggle('user')
                }}
              >{t('Personal archive')}
              </NavLink>
            </NavItem>
            {userType === 1 ?
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'user_group' })}
                    onClick={() => {
                      setApiOption('group')
                      toggle('user_group')
                    }}
                  >
                    {t('Group archive')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'company' })}
                    onClick={() => {
                      setApiOption('company')
                      toggle('company')
                    }}
                  >
                    {t('Company archive')}
                  </NavLink>
                </NavItem>
              </> :
              <>
                {userArchives.group_edit_archives === 1 &&
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'user_group' })}
                      onClick={() => {
                        setApiOption('group')
                        toggle('user_group')
                      }}
                    >
                      {t('Group archive')}
                    </NavLink>
                  </NavItem>
                }
                {userArchives.company_edit_archives === 1 &&
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'company' })}
                      onClick={() => {
                        setApiOption('company')
                        toggle('company')
                      }}
                    >
                      {t('Company archive')}
                    </NavLink>
                  </NavItem>
                }
              </>
            }
          </Nav>
          <TabContent activeTab={activeTab}>
            {
              config?.archiveTypes?.map((tab, index) => {
                return (
                  <TabPane tabId={tab} key={index}>
                    <Row>
                      <CreateTabContent
                        formData={formData}
                        setFormData={setFormData}
                        ApiOption={ApiOption}
                        name={name}
                        setTags={setTags} setVersion={setVersion} setName={setName}
                        version={version}/>
                    </Row>
                  </TabPane>
                )
              })
            }
          </TabContent>
          <br/>
          {((activeTab === 'user_group' && userArchives.group_edit_archives === 1) || (userType === 1) || (activeTab === 'company' && userArchives.company_edit_archives === 1) || (activeTab === 'user'))
            && <div className={`d-flex justify-content-center`}>
              <Button color="primary" variant="contained" onClick={addAnalysis}>
                {t('Export archive button')}
              </Button>
            </div>}
          <br/>
          <div className={style.error}>
            <Collapse in={!!errorState}>
              <Alert
                className={`m-0`}
                severity="error">
                <p className={`m-0`}>{errorState}</p>
              </Alert>
            </Collapse>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WordSaveInArchive