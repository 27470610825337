import React from "react";
import {Dialog, DialogContentText} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import style from "./style.module.sass"
import submitIcon from "../../assets/anmeldenIcon.svg"
import {useTranslation} from "react-i18next";
import TLServiceComponent from "../../contexts/TLServiceComponent";
import {useSelector} from "react-redux";


export default function ModalTermsAndConditions({alerts}) {
    const {t} = useTranslation();
    const {theme: {bgColors}} = useTheme();
    const token = useSelector(state => state.userData.token);


    const tlService = React.useContext(TLServiceComponent);


    async function handleSubmit() {
        try {
            await tlService.setMessageRead(token, alerts.id);
        } catch (e) {
            console.log(e);
        }
        window.location.reload();
    }


    return (
        <>
            <Dialog
                open={true}
                onClose={() => true}
                aria-labelledby="dialog-title"
            >
                <DialogContentText className={style.termstext} id="alert-dialog-description" style={{
                    backgroundColor: bgColors.bgPrimary0
                }}>
                    <p className={style.terms} dangerouslySetInnerHTML={{__html: t("Modal Terms And Condition")}} />
                    <p className={`${style.submitCont} mt-2`}>
                        <button onClick={handleSubmit} type="submit" className={`${style.submit} d-flex align-items-center`}>
                            <img src={submitIcon} alt="submit svg"/>
                            <p className='m-0'>{t("Agree")}</p>
                        </button>
                    </p>

                </DialogContentText>
            </Dialog>
        </>
    )
}