import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import {Box, Button} from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {useTranslation} from "react-i18next";

const headCells = [
  {id: 'id', numeric: false, disablePadding: true, label: 'ID'},
  {id: 'userId', numeric: true, disablePadding: false, label: 'User ID'},
  {id: 'userName', numeric: false, disablePadding: false, label: 'User Name'},
  {id: 'logType', numeric: false, disablePadding: false, label: 'Log Type'},
  {id: 'data', numeric: false, disablePadding: false, label: 'Data'},
  {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
  // {id: 'arrow', numeric: true, disablePadding: false, label: ''},
];

function EnhancedTableHead({onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort}) {
  const {theme: {bgColors, colors}} = useTheme();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const {t} = useTranslation();


  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgBlue300}} className={`${style.filterDashboard}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Collapse in={numSelected} timeout="auto" unmountOnExit>
            <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
              <p style={{color: colors.colorPrimary1000}} className={`m-0`}>{numSelected} t{('selected')}</p>
              <Button className={``} color="primary" variant="contained"
                      style={{backgroundColor: bgColors.bgBlue1000, margin: "0 0 0 auto"}}>
                {t('export')}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
        </TableRow>
      </TableHead>
      <TableHead style={{backgroundColor: bgColors.bgPrimary500}} className={`${style.tableHead}`}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all desserts'}}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                style={{flexDirection: "row"}}
                onClick={createSortHandler(headCell.id)}
                IconComponent={(props) => (
                  <ArrowDownwardIcon style={{order: "9", color: colors.colorPrimary0}} alt="" {...props}/>)}
              >
                <p style={{color: colors.colorPrimary0}} className={`m-0`}>{headCell.label}</p>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default EnhancedTableHead
