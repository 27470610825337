import React from 'react'
import Gear from '../../assets/gear.png'
import GearBlue from '../../assets/gear-blue.png'
import GearGray from '../../assets/gear-gray.png'
import CombotBLue from '../../assets/combot-blue.svg'
import './spinner.css'

const RoboSpinner = ({ text }) => {
  return (
    <div className="modal-spinner">
      <div className="lds-css">
        <div className={'gear-black'}><img src={Gear} width={'30px'} alt=""/></div>
        <div className={'gear-blue'}><img src={GearBlue} width={'30px'} alt=""/></div>
        <div className={'gear-gray'}><img src={GearGray} width={'30px'} alt=""/></div>
        <div className={'bot'}><img src={CombotBLue} width={'100px'} alt=""/></div>
        <div className={'text'}>{text}</div>
      </div>
    </div>
  )
}

export default RoboSpinner
