import React, {useState} from 'react';
import style from "../withBigRow.module.sass";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Rows from "./Rows";
import {useTheme} from '@material-ui/core/styles';
import EnhancedTableHead from "./TableHead";
import TableFilter from "./TableFilter";
import TableFooter from "./TableFooter";
import Subrow from "./Subrow";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = !!array && array.map((el, index) => [el, index]);
  !!stabilizedThis && stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return !!stabilizedThis && stabilizedThis.map((el) => el[0]);
}

export default function TableWithThreeCols({title, text, rows, headText, headTitle, formik, name, infoBanner, withFooter}) {
  const {theme: {bgColors}} = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div className={`${style.mainCont}`}>
      <Paper className={`mb-5`}>
        <TableContainer
          style={{backgroundColor: bgColors.bgPrimary500}}
          className={`${!withFooter && "pb-5"}`}>
          <Table
            style={{backgroundColor: bgColors.bgPrimary100, borderColor: "transparent"}}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableFilter
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />

            <Subrow title={title} text={text}/>

            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headText={headText}
              headTitle={headTitle}
            />
            <TableBody>
              {!!stableSort(rows, getComparator(order, orderBy)) && stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <Rows formik={formik} key={row.id} handleClick={handleClick}
                          isItemSelected={isItemSelected} labelId={labelId}
                          row={row}
                          index={index}
                          name={name}
                          isEven={index % 2 !== 0}/>
                  );
                })}
            </TableBody>

            { infoBanner &&
              <>
                <Subrow title={"Info banner"} text={text}/>
                <TableBody>
                  {stableSort(infoBanner, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <Rows formik={formik} key={row.id} handleClick={handleClick}
                              isItemSelected={isItemSelected} labelId={labelId}
                              row={row}
                              index={index}
                              name={name}
                              isEven={index % 2 !== 0}/>
                      );
                    })}
                </TableBody>
              </>
            }

            {withFooter && <TableFooter
              rowsPerPageOptions={[10, 25, 40]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
