import React, { useState } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Collapse } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

function PasswordField ({ formik, name, label, withAlert, ...rest }) {
  const [isFocused, setIsFocused] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  return (
    <div style={{ width: '100%' }}>
      <FormControl className={`mb-1`} fullWidth variant="outlined"
                   error={formik.touched[name] && Boolean(formik.errors[name])}>
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          name={name}
          onInput={() => setIsFocused(true)}
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={formik.values[name]}
          onChange={formik.handleChange}
          {...rest}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={e => e.preventDefault()}
                edge="end"
                style={{ marginRight: '1px' }}
              >
                {showPassword ? <Visibility/> : <VisibilityOff/>}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={label.length * 9}
        />
        <FormHelperText id="outlined-adornment-password">{formik.touched[name] && formik.errors[name]}</FormHelperText>
      </FormControl>
      <Collapse in={isFocused}>

        {(withAlert && formik.errors.password) ?
          <Alert className={`mb-1`} severity="error">
            <p dangerouslySetInnerHTML={{ __html: t(formik.errors.password) }}/>
          </Alert>
          : (!withAlert && formik.errors.repeatedPassword) &&
          <Alert className={`mb-1`} severity="error">
            {t(formik.errors.repeatedPassword)}
          </Alert>}
      </Collapse>
    </div>
  )
}

export default PasswordField
