import React, { useEffect } from "react";
import style from "./style.module.scss"
import DashHeader from "../Header";
import RadialBar from "../UI/RadialBar";
import VColoredLinearChart from "../UI/VColoredLinearChart";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import {
    convertLanguagesToShort,
    countTermsWithPositions,
    getScale, getValue,
    isObjectEmpty,
    chooseColor,
    roundWithPrecision,
    checkClixNotZero
} from "../../../utils";
import config from "../../../config";

export default function CLIXComponent() {
    const { theme: { bgColors } } = useTheme();
    const { t, i18n: { language: i18nLanguage } } = useTranslation();
    const archiveName = useSelector(state => state.analyseData.archives?.name);
    const benchmark = useSelector(state => state.analyseData.result.benchmark);
    const benchmarks = useSelector(state => state.userData.benchmarks);
    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const targetCLIX = currentBenchmarkSettings?.settings?.formulaCLIX?.values?.target || 0;
    const corporateCategoryList = useSelector(state => state.analyseData.result.resultData.dataCorporateLanguage);
    const dataTermsList = useSelector(state => state.analyseData.result.resultData.dataTerms);


    if (resultTemplate < 1)
        return <Redirect to={"/"} />;

    let clixList;
    let corporateList = [];
    let termList = [];
    let heading = [];

    if (!isObjectEmpty(currentBenchmarkSettings)) {
        clixList = Object.keys(currentBenchmarkSettings?.settings).reduce((acc, val, index) => {
            // if clix parameter is more than 0
            if (currentBenchmarkSettings?.settings[val].values?.clix !== 0 && !!currentBenchmarkSettings?.settings[val].values?.clix) {
                // define needed values
                let scalePercent = 0;
                let targetPercent = 0;
                let localPercent = 0;
                let name = '';
                let color = 'green';
                let localTarget = 0;
                let countNumber = 0;
                let section = "";

                if (!val.includes("list")) {
                    // not a number - value from resultData
                    name = t(val);
                    const currentSettings = currentBenchmarkSettings?.settings[val]?.values;
                    localTarget = getValue(currentSettings, "target", 50);
                    const localScaleFrom = getValue(currentSettings, "scaleFrom", 0);
                    const localScaleTo = getValue(currentSettings, "scaleTo", 100);
                    const localTargetYellowGreen = getValue(currentSettings, "yellowGreen", 50);
                    const localTargetYellowRed = getValue(currentSettings, "yellowRed", 50);
                    // check if value exist in result object
                    if (resultData[val] !== undefined) {
                        countNumber = Array.isArray(resultData[val]) ? resultData[val].length : resultData[val];
                        if (config.fullCountBase[val] === "") {
                            // ih fullCountBase == "" means use value directly
                            localPercent = resultData[val];
                        } else {
                            // if fullCountBase exist use value from it, or by default use countWords
                            const fullValueCount = config.fullCountBase[val] !== undefined ? config.fullCountBase[val] : "countWords";
                            localPercent = countNumber / (resultData[fullValueCount] / 100);
                        }
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                        targetPercent = targetPercentTmp;
                        scalePercent = scalePercentTmp;
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                    } else if (['colorBlue', 'colorGreen', 'colorRed', 'colorYellow'].indexOf(val) > -1) {
                        // value is in dataColor object
                        localPercent = resultData[val] / (resultData?.countWords / 100);
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                        targetPercent = targetPercentTmp;
                        scalePercent = scalePercentTmp;
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                    }
                    section = "";
                } else if (val === 'listTerms') {
                    // if it's a number - it's  Terms
                    // countNumber = countTermsWithPositions(currentBenchmarkSettings?.settings.listTerms[val]);
                    const termsSettings = currentBenchmarkSettings?.settings['listTerms']?.values['_list'] || {};
                    const { valuesClix, dataCorporateLanguageClix, dataTermsClix: ids } = checkClixNotZero(currentBenchmarkSettings?.settings)
                    termList = ids?.length && ids.reduce((acc, currentValue, index) => {
                        // if it's a number - it's  Terms
                        countNumber = countTermsWithPositions(resultData.dataTerms[currentValue]?.result);
                        const localSettings = termsSettings[currentValue];
                        localTarget = getValue(localSettings, "target", 50);
                        const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
                        const localScaleTo = getValue(localSettings, "scaleTo", 100);
                        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
                        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
                        localPercent = countNumber / (resultData.countWords / 100);
                        name = resultData.dataTerms[currentValue]?.category?.name ? resultData.dataTerms[currentValue].category.name[convertLanguagesToShort(i18nLanguage)] : '';
                        // Add avaible name in the config to display later:
                        config.clixDisplay.map(element => {
                            if (element.title === "Terminology") {
                                element.subCat.push(name)
                            }
                        })
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                        targetPercent = targetPercentTmp;
                        scalePercent = scalePercentTmp;
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                        section = "Terminology";

                        return [...acc, {
                            id: index,
                            localTarget: roundWithPrecision(localTarget, 1),
                            targetPercent,
                            scalePercent,
                            localPercent: roundWithPrecision(localPercent, 1),
                            name,
                            val: currentValue,
                            color,
                            countNumber,
                            sort: localSettings.sort,
                        }];
                    }, [])
                } else if (val === 'listCorporateLanguageTerms') {
                    // if it's a number - it's  Terms
                    const termsSettings = currentBenchmarkSettings?.settings['listCorporateLanguageTerms']?.values['_list'] || {};
                    const { valuesClix, dataCorporateLanguageClix: ids, dataTermsClix } = checkClixNotZero(currentBenchmarkSettings?.settings)
                    // if it's a number - it's  Terms
                    corporateList = ids?.length && ids.reduce((acc, currentValue, index) => {
                        countNumber = countTermsWithPositions(resultData.dataCorporateLanguage[currentValue]?.result);
                        const localSettings = termsSettings[currentValue];
                        localTarget = getValue(localSettings, "target", 50);
                        const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
                        const localScaleTo = getValue(localSettings, "scaleTo", 100);
                        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
                        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
                        localPercent = countNumber / (resultData.countWords / 100);
                        name = resultData.dataCorporateLanguage[currentValue]?.category?.name ? resultData.dataCorporateLanguage[currentValue].category.name[convertLanguagesToShort(i18nLanguage)] : ''
                        // Add avaible name in the config to display later:
                        config.clixDisplay.map(element => {
                            if (element.title === "CorporateLanguage") {
                                element.subCat.push(name)
                            }
                        })
                        // convert real % into  scale size percent
                        const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
                        targetPercent = targetPercentTmp;
                        scalePercent = scalePercentTmp;
                        color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                        section = "CorporateLanguage"

                        return [...acc, {
                            id: index,
                            localTarget: roundWithPrecision(localTarget, 1),
                            targetPercent,
                            scalePercent,
                            localPercent: roundWithPrecision(localPercent, 1),
                            name,
                            val: currentValue,
                            color,
                            countNumber,
                            section,
                            sort: localSettings.sort,
                        }];
                    }, [])
                }
                return [...acc, {
                    id: index,
                    localTarget: roundWithPrecision(localTarget, 1),
                    targetPercent,
                    scalePercent,
                    localPercent: roundWithPrecision(localPercent, 1),
                    name,
                    val,
                    color,
                    countNumber,
                    section
                }];
            }
            return acc;
        }, []);

        //  remove corporate list duplicate and take only object:
        const uniqueCorporateList = (!!corporateList?.length && Array.isArray(corporateList)) && corporateList.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
                accumulator.push(current);
            }
            accumulator = accumulator.filter(el => typeof el === "object")
            return accumulator;
        }, []);

        //  remove term list duplicate and take only object:
        const uniqueTermList = (!!termList?.length && Array.isArray(termList)) && termList.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
                accumulator.push(current);
            }
            accumulator = accumulator.filter(el => typeof el === "object")
            return accumulator;
        }, []);

        if (uniqueCorporateList?.length && Array.isArray(uniqueCorporateList)) {
            clixList.push(...uniqueCorporateList)
        }
        if (uniqueTermList?.length && Array.isArray(uniqueTermList)) {
            clixList.push(...uniqueTermList)
        }
    }
    //  // To get the available heading:
    config.clixDisplay.map((el, i) => {
        clixList?.length && clixList.map(list => {
            if (el.subCat.some(element => t(element) === list.name) && typeof list.section === "string" && list.section.length === 0) {
                heading.push({ "heading": el.title, "item": list.name })
            } else if (typeof list.section === "string" && list.section.length !== 0) {
                heading.push({ "heading": list.section, "item": list.name })
            }
        })
    })

    // clix text
    let dash_clix_text;
    if (resultData["formulaCLIX"] > 85)
        dash_clix_text = t("dash_clix_text_85")
    else if (resultData["formulaCLIX"] > 70)
        dash_clix_text = t("dash_clix_text_70")
    else if (resultData["formulaCLIX"] > 55)
        dash_clix_text = t("dash_clix_text_55")
    else if (resultData["formulaCLIX"] > 33)
        dash_clix_text = t("dash_clix_text_33")
    else if (resultData["formulaCLIX"] > 16)
        dash_clix_text = t("dash_clix_text_16")
    else
        dash_clix_text = t("dash_clix_text_0")
    const colorCLIX = resultData["formulaCLIX"] < targetCLIX / 2 ? "#dd0d08" : resultData["formulaCLIX"] < targetCLIX ? "#da7d27" : "#a8c721";

    return (
        <div className={style.mainCont}>
            <DashHeader archiveName={archiveName} benchmarkName={benchmark['name'][convertLanguagesToShort(i18nLanguage)]}
            name={t('CLIX_dashboard_header')}  />
            <RadialBar
                color={bgColors.bgPrimary100}
                value={roundWithPrecision(resultData["formulaCLIX"], 1)}
                text={dash_clix_text}
                bg={colorCLIX}
                name={t("CLIX_dashboard_header")}
                max={100}
                targetVal={targetCLIX}
                mainText={t("dash_clix_main_text")}
            />
            {!isObjectEmpty(clixList) ?
                <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: "10px", margin: "30px 0 0 0" }} className={style.cells}>
                    {/* <h2 style={{color: colors.colorPrimary400}}>
                          {t('grammar-style')}
                      </h2> */}
                    <div className={`mb-5`}>
                        <VColoredLinearChart cells={clixList} heading={heading} />
                    </div>
                    {/*<LineChart height={"350"}/>*/}
                </div>
                : <div />}
        </div>
    )
}
