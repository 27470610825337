import { Button, Modal, LinearProgress, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import style from "./exim.module.sass"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert } from "@material-ui/lab"
import config from "../../../../config"
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { clrModal, runUploadUsers } from "./functions"
import { readUploadedFileAsBinary } from "../../../../utils"
import * as XLSX from 'xlsx'
import * as Papa from "papaparse"
import { Dnd } from "../../Terminology/TermsByCategory/Dnd"

export default function UserUploadModal({ open, setOpen, reloadPage }) {
    const { t } = useTranslation()
    const tlService = React.useContext(TLServiceComponent)
    const token = useSelector(state => state.userData.token)
    const running = useRef(false)
    const [success, setSuccess] = useState(false)
    const [uploadBarVisible, setUploadBarVisible] = useState(false)
    const [uploadBarNow, setUploadBarNow] = useState(0)
    const [fullCounterText, setFullCounterText] = useState('')
    const [importedUserCount, setImportedUserCount] = useState({})
    const [alert, setAlert] = useState('')
    const [disableUpload, setDisableUpload] = useState(false)
    const [disableButton, setDisableButton] = useState(true)
    const [uploadText, setUploadText] = useState(t("Data Upload"))
    const [uploadFile, setUploadFile] = useState(null)

    const updateAlert = (text, user_name) => {
        if(user_name) {
            setAlert(` ${user_name}: ${t(text)}`)
        } else {
            setAlert(t(text))
        }
        setTimeout(() => {
            setAlert('')
            setImportedUserCount({})
        }, config.timeOutDuration)
    }

    const updateSuccessTrue = () => {
        setSuccess(true)
        setTimeout(() => {
            setSuccess(false)
            setImportedUserCount({})
        }, config.timeOutDuration)
    }

    const handleStop = () => {
        running.current = false
    }

    const handleDND = (acceptedFiles) => {
        setDisableUpload(true)
        if (acceptedFiles?.length !== 1) {
            updateAlert('wrong number of upload files')
            setUploadText(t("Data Upload"))
            setDisableUpload(false)
            setDisableButton(true)
            setUploadFile(null)
            setDisableUpload(false)
        }
        const file = acceptedFiles[0]
        if (
            file['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file['type'] === "application/vnd.ms-excel" ||
            file['type'] === "text/csv"
        ) {
            setUploadFile(file)
            setUploadText(file?.name)
            setDisableButton(false)
        } else {
            setUploadFile(null)
            updateAlert('wrong type of file')
            setDisableUpload(false)
            setDisableButton(true)
            setUploadText(t("Data Upload"))
        }
        setDisableUpload(false)
    }

    const closeModal = () => {
        handleStop()
        reloadPage()
        setOpen(false)
        setUploadText(t("Data Upload"))
        setDisableUpload(false)
    }

    const uploadUsers = async () => {
        setDisableButton(true)
        setDisableUpload(true)
        setFullCounterText('')

        let data = []
        if (
            uploadFile['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            uploadFile['type'] === "application/vnd.ms-excel"
        ) {
            const fileContents = await readUploadedFileAsBinary(uploadFile, false)
            const workbook = XLSX.read(fileContents, { type: 'binary' })
            if (workbook && workbook.SheetNames[0] && workbook.Sheets[workbook.SheetNames[0]]) {
                data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 0 })
            } else {
                updateAlert('Wrong data')
                clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t)
                return false
            }
        } else if (uploadFile['type'] === "text/csv") {
            const fileContents = await readUploadedFileAsBinary(uploadFile, true)

            const res = Papa.parse(fileContents, { delimiter: "", header: true, skipEmptyLines: true  })

            const cleanedData = res.data.map((item) => {
                const cleanedItem = {}
                for (let key in item) {
                    let value = item[key].trim()  // Remove any surrounding whitespace
                    // Remove surrounding quotes if present
                    if (value.startsWith('"') && value.endsWith('"')) {
                        value = value.slice(1, -1)
                    }
                    // Convert to number if the value is numeric
                    if (!isNaN(value) && value !== "") {
                        value = parseFloat(value)
                    }
                    cleanedItem[key] = value
                }
                return cleanedItem
            })
            data = cleanedData
        } else {
            updateAlert('Wrong data')
            clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t)
            return false
        }
        if (data && data.length > 0) {
            setUploadBarVisible(true)
            setUploadBarNow(0)
            if (data.some(d => !d.user_name || !d.email)) {
                updateAlert(t("username_and_email_are_required"))
                clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t)
                return false
            }

            let fullCounter = 0
            for (let tmpData of data) {
                if (!await runUploadUsers(tlService, token, tmpData, t, updateAlert)) {
                    clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t)
                    return false
                }
                fullCounter++
                setUploadBarNow(Math.round(fullCounter / (data.length / 100)))
                setFullCounterText(`${fullCounter}/${data.length}`)
                setImportedUserCount({ imported: fullCounter, fullLength: data.length })
            }
        } else {
            updateAlert('Data is empty')
            clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t)
            return false
        }
      
        if (data?.length) {
            clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t)
            setUploadText(t("Data Upload"))
            updateSuccessTrue()
            return true
        }
    }

    const handleUpload = () => {
        setSuccess(false)
        running.current = true
        uploadUsers()
    }

    return (
        <Modal open={open} onClose={() => closeModal()}>
            <div className={`${style.termModal} `} style={{ margin: "0 auto", marginTop: "10vh" }}>
                <IconButton className={`p-0 m-0`} style={{ float: "right" }} onClick={() => { closeModal() }}><Close /></IconButton>
                <h6 className={`mb-4`} dangerouslySetInnerHTML={{ __html: t('user-upload-modal') }} style={{ textAlign: "center", fontSize: "20px" }} />
                {!!alert && <Alert className={`mb-1`} severity="error">{alert}</Alert>}
                {(!!alert && !!importedUserCount.imported) && <Alert className={`mb-1`} severity="success">{t('admin_user_import_{{num}}', { num: `${importedUserCount.imported}/${importedUserCount.fullLength}` })}</Alert>}
                {!!success && <Alert className={`mb-1`} severity="success">{t('admin_user_import_{{num}}', { num: `${importedUserCount.imported}/${importedUserCount.fullLength}` })}</Alert>}
                <div className={`d-flex flex-row p-3`} style={{ marginBottom: "30px", justifyContent: "center" }}>
                    <span className={`d-flex flex-row p-2 ${style.uploadBtn}`}>
                    <Dnd onReadDropFiles={handleDND} text={uploadText} disabled={disableUpload}
                        />
                    </span>
                    <span className={`d-flex flex-row p-2 ${style.rightBox}`}>
                        <i dangerouslySetInnerHTML={{ __html: t('user_upload_info_message') }} style={{padding: 10}}>
                        </i>
                    </span>
                </div>
                {uploadBarVisible &&
                    <div className={`d-flex flex-row justify-content-center w-100 p-1`}>
                        <div className={'flex-column w-50 my-auto'}><LinearProgress color="primary"
                            variant="determinate"
                            value={uploadBarNow} /></div>
                        <div className={'pl-1 flex-column my-auto'}>{fullCounterText}</div>
                        <div className={'pl-1 flex-column my-auto'}>
                            <Button color='default' onClick={handleStop}>
                                {t('stop upload')}
                            </Button></div>
                    </div>}
                <div className={`d-flex align-items-start w-100 ${style.alignItem}`}>
                    <div className={`d-flex flex-column`}>
                        <Button style={{ color: "#3f51b5" }} disabled={disableButton} onClick={handleUpload}>
                            {t('upload')}
                        </Button>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
