import React from "react"
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles"
import { NavLink, useRouteMatch } from "react-router-dom"
import SemiCircleProgressBar from "react-progressbar-semicircle"
import ComparisonDashDoubledChard from "./ComparisonDashDoubledChard"
import { Tooltip } from "@material-ui/core"
import { Info } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { convertNumberToStringLocally } from "../../../utils"

export default function RadialBar({ name, mainText, fullName, text, max, value, color, bg, targetVal, withNavLink, combine }) {
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  const {
    t,
    i18n: { language: i18nLanguage },
  } = useTranslation()
  const { params: { type }, url } = useRouteMatch()
  const valueAfterSolution = (value * 100) / max

  return (
    <div className={`${style.RadialBar}`} style={{ backgroundColor: combine ? "lightgray" : bgColors.bgPrimary0 }}>
      <div style={{ marginBottom: "-10%" }}>
        {!combine && <div className={`d-flex justify-content-between pb-2 align-items-center ${style.withBorder}`}>
          <h2 className={`m-0 ${style.withoutBorder} p-0`} style={{ color: colors.colorPrimary400 }}>{name}</h2>
          <Tooltip title={<h6 style={{ fontSize: "15px" }}>{mainText}</h6>} className='ml-2 mr-auto'>
            <Info style={{ fill: bgColors.bgPrimary500 }} />
          </Tooltip>
          {type === "texts" && <div className={`d-flex justify-content-between align-items-center ${style.textTitles}`}>
            <p className={`m-0`} style={{ color: colors.colorBlue1000 }}>{t('optional')} 1</p>
            <p className={`m-0`} style={{ color: colors.colorProgress60 }}>{t('optional')} 2</p>
          </div>}
        </div>}
        {(type === "single" || combine)
          ? <div className={`${style.main}`}>
            <div className={`${style.chartCont}`}>
              <div className={`${style.chart}`}>
                <div className={style.bars}>
                  {/* Just for PDF, the direction is left when download so below is the work aournd solution */}
                  {combine ? <SemiCircleProgressBar diameter={254} percentage={100 - valueAfterSolution} background={bg} stroke={color}
                    direction="left"
                    strokeWidth={20} /> : <SemiCircleProgressBar diameter={254} percentage={valueAfterSolution} background={color} stroke={bg}
                      strokeWidth={20} />}
                  <div className={style.progressDesc}>
                    <p style={{ color: colors.colorPrimary500 }} className={`m-0 ${style.title}`}>{name}</p>
                    <p className={`m-0 ${style.percentage}`} style={{ color: colors.colorPrimary1000 }}>
                      {
                        // percentIs(value)
                        convertNumberToStringLocally(value, i18nLanguage)
                      }
                    </p>
                    <div style={{ borderColor: borderColors.borderPrimary1000 }}
                      className={`${style.maxSize} d-flex justify-content-center`}>
                      <p style={{ color: colors.colorPrimary500 }} className={`m-0 ${style.zielwert}`}>
                        {t('target-value')} :</p>
                      <p className={`m-0 ${style.zielwertNum} ml-1`}>{targetVal}</p>
                    </div>
                  </div>
                  {combine && <span className={style.fullName}>
                    {fullName}
                  </span>}
                </div>
              </div>
            </div>
            <div className={`${style.desc} mt-5`} style={{ marginBottom: "40px" }}>
              {
                combine ?
                  <>
                    <p>
                      {text.length < 50 ?
                        <b style={{ marginRight: "600px" }}>
                          {text}
                        </b>
                        :
                        <b>
                          {text}
                        </b>}
                      <p style={{ marginRight: "-130px", marginTop: "-65px" }}>
                        {(name === t('HIX_dashboard_chartID')) && <span className={style.sideTextHIX}>  HIX
                        </span>}
                        {(name === t('CLIX_dashboard_chartID')) &&
                          <span className={style.sideTextCLIX} style={{ marginTop: "5px", marginRight: "-20px" }}>  CLIX
                          </span>}
                        {(name === t('FLESCH_dashboard_chartID')) &&
                          <span className={style.sideTextFLESCH} style={{ marginRight: "-20px", marginTop: "25px", position: "" }}>  FLESCH
                          </span>}
                      </p>
                    </p>
                  </> :
                  <p><b>
                    {text}
                  </b></p>}
            </div>
          </div> :
          <ComparisonDashDoubledChard seriesNames={["HIX"]} chartContent={{
            withChartTypes: true,
            withTimeInterval: true,
            withTotalOver: true
          }} chartId={name} />}
      </div>
      {withNavLink && <div className={`${style.footer}`}>
        <NavLink to={withNavLink}>
          <button>{t("Detail view")}</button>
        </NavLink>
      </div>}
    </div>
  )
}
