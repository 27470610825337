import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent } from '@material-ui/core'
import style from './style.module.sass'
import { useTranslation } from 'react-i18next'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { parseErrorObject } from '../../utils'
import QRCode from 'qrcode'
import { Alert } from '@material-ui/lab'
import InputBase from '@material-ui/core/InputBase'
import { useActions } from '../../utils/action-helper'
import { meUser } from '../../actions'

export default function ModalSet2FAGA ({ alerts }) {
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector((state) => state.userData.token)
  const enabled2FA = useSelector((state) => state.userData.user['2fa']) || {}
  const { t } = useTranslation()

  const [qrCode, setQrCode] = useState('')
  const [secret, setSecret] = useState('')
  const [qrNumber, setQrNumber] = useState('')
  const [alert, setAlert] = useState('')
  const [totp, setTotp] = useState('')
  const [attempts, setAttempts] = useState(0)

  const { aMeUser } = useActions({
    aMeUser: meUser(tlService),
  })

  useEffect(() => {
    aMeUser(token);
  }, [])

  const setQR = async token => {
    try {
      const qr = await tlService.getGAQA(token)
      if (qr?.qr?.length) {
        setSecret(qr.secret)
        const textQr = await QRCode.toDataURL(qr.qr)
        setQrCode(textQr)
        setQrNumber(new URL(qr.qr)?.searchParams.get('secret'))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const setProofNumber = (e) => {
    const input = e.target.value
    setTotp(input.replaceAll(/[^0-9]/g, ''))
  }

  const setProofRequest = async (token, totp, secret, type) => {
    try {
      await tlService.proofGAQA(token, totp, secret, type)
      // redirect
      handleEnabled()
    } catch (e) {
      const message = parseErrorObject(e)
      if (message?.number !== 200) {
        if (message?.secret?.length) {
          const messageAttempts = parseInt(message?.attempts) || 0
          setAttempts(messageAttempts)
          setAlert(t(message.message))
          setSecret(message.secret)
        } else {
          setAttempts(0)
          setAlert(t('message_2fa_too_many_attempts_reload'))
          setSecret('')
        }

      } else {
        console.log(e)
      }
    }
    setTotp('')
  }

  const enableGA = async () => {
    await setProofRequest(token, totp, secret, 'enable')
  }

  const handleOnEnter = (event, func) => {
    if (event.keyCode === 13) {
      func()
    }
  }

  const handleEnabled = async () => {
    try {
      await tlService.setMessageRead(token, alerts.id)
    } catch (e) {
      console.log(e)
    }
    window.location.reload()
  }

  const handleReload = async () => {
    window.location.reload()
  }

  useEffect(() => {
    if (!enabled2FA?.ga) {
      setQR(token)
    }
  }, [enabled2FA?.ga])
  return (
    <>
      <Dialog
        open={true}
        onClose={() => true}
        aria-labelledby="dialog-title"
      >
        <DialogContent id="alert-dialog-description">
          {!!alert && <Alert
            className={`mb-1`}
            severity="error"
          >{t(alert)} {attempts > 0 && t('2fa_ga_attempts_left {{attempts}}', { attempts })}
          </Alert>}
          {(!enabled2FA?.ga) ?
            <>
              {(qrCode && secret) ?
                <div className={'d-flex align-items-center w-100'}>
                  <div className={'p-2'}>
                    <div className={'text-center'}><img src={qrCode} alt={''}/></div>
                    <div className={'text-center'}>{qrNumber}</div>
                    <div className={'text-center'}>
                      <InputBase
                        onKeyUp={e => handleOnEnter(e, enableGA)}
                        autoFocus={true}
                        className={`${style.input2FA}`}
                        value={totp}
                        type="text"
                        onChange={setProofNumber}
                      /></div>
                    <div className={'text-center'}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={enableGA}
                        disabled={!totp.length}
                      >
                        {t('2fa_ga_enable_button')}
                      </Button>
                    </div>
                  </div>
                  <div className={'p-2'}
                       dangerouslySetInnerHTML={{ __html: t('2fa_ga_enable_description_forced') }}/>
                </div> :
                <div className={'align-items-center w-100 text-center'}>
                  <div className={'text-center'}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleReload}>
                      {t('2fa_ga_reload_button')}
                    </Button>
                  </div>
                </div>
              }
            </>
            :
            <div className={'d-flex align-items-center w-100'}>
              <div className={'p-2'}>
                <div className={'p-1'} dangerouslySetInnerHTML={{__html: t('message_2fa_already_enabled')}} />
                <div className={'p-1'}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleEnabled}
                  >{t('message_2fa_close_button')}</Button>
                </div>
              </div>
            </div>
          }
        </DialogContent>
      </Dialog>
    </>
  )
}