import style from './batch.module.sass'
import closeIcon from '../../../assets/close-circle.svg'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Modal, Button, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BatchProcessingServiceComponent from './BatchProcessingServiceComponent'
import { CheckCircle, Loop, Timer, Pause, Error } from '@material-ui/icons'
import {
  archiveSetArchiveType, archiveSetPredefined, batchProcessingClear, tableSetFilters, userSetArchiveLanguage
} from '../../../actions'
import { useActions } from '../../../utils/action-helper'
import { Redirect } from 'react-router-dom'
import { getFolderFilter } from '../../../utils/archives'

export default function BatchProcessingDetails ({ open, setOpen }) {

  const { theme: { bgColors } } = useTheme()
  const { t } = useTranslation()
  const batchProcessingState = useSelector(state => state.batchData)
  const filters = useSelector(state => state.tableData.filters)

  const batchProcessingService = React.useContext(BatchProcessingServiceComponent)

  const { aArchiveSetArchiveType, aArchiveSetPredefined, aTableSetFilters, aUserSetArchiveLanguage } = useActions({
    aArchiveSetArchiveType: archiveSetArchiveType,
    aTableSetFilters: tableSetFilters,
    aArchiveSetPredefined: archiveSetPredefined,
    aUserSetArchiveLanguage: userSetArchiveLanguage,
  })

  const {
    actionBatchProcessingClear
  } = useActions({
    actionBatchProcessingClear: batchProcessingClear
  })

  const [redirect, setRedirect] = useState('')

  const handleCancelClick = () => {
    setOpen(false)
    batchProcessingService.cancelBatchProcessing()
  }

  const handlePauseClick = () => {
    if (batchProcessingState.paused) {
      batchProcessingService.resumeBatchProcessing()
    } else {
      batchProcessingService.pauseBatchProcessing()
    }
  }

  useEffect(() => () => actionBatchProcessingClear(), [])

  const handleArchiveLinkClick = () => {
    //setRedirect(`/archive?type=${batchProcessingState.ApiOption}&folder=${batchProcessingState.folderID}`)
    aArchiveSetPredefined(batchProcessingState.folderID)
    aArchiveSetArchiveType(batchProcessingState.ApiOption)
    aUserSetArchiveLanguage(batchProcessingState.language)
    aTableSetFilters(getFolderFilter(batchProcessingState.folderID, filters))
    setTimeout(() => setRedirect(`/archive`), 200)
  }

  if (redirect)
    return <Redirect to={redirect}/>

  return open ? (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={`${style.mainCont} ${open && style.open} pb-3`}
           style={{ backgroundColor: bgColors.bgPrimary0 }}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}>
          <p className={`m-0 ${style.title}`}>{t('batchProcessingTitle')}</p>
          <button className={`border-0 bg-transparent`} onClick={() => setOpen(false)}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div className={`${style.descText} mt-2 m-0 pr-3 pl-3`}>
          <div className={`${style.documentList}`}>
            {
              <Table>
                <TableBody>
                  {
                    batchProcessingState.progressByDocument.map((doc, idx) => {
                      return (
                        <TableRow key={idx}>
                          <TableCell className={`${style.noPadding}`}>
                            <div className={`${style.cellName}`}>
                              {doc.filename}
                              {doc?.error &&
                                <span className={`${style.errorCall}`}>
                                                    &nbsp; {doc.error}
                                                </span>
                              }
                            </div>
                          </TableCell>
                          <TableCell className={`${style.noPadding}`}>
                            <div className={`${style.cellIcon}`}>
                              {
                                doc.state === 'running' ? <Loop className={`${style.iconRunning}`}/>
                                  : doc.state === 'paused' ? <Pause className={`${style.iconPaused}`}/>
                                    : doc.state === 'error' ? <Error className={`${style.iconPaused}`}/>
                                      : doc.state === 'done' ? <CheckCircle className={`${style.iconDone}`}/>
                                        : <Timer className={`${style.iconOpen}`}/>
                              }
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            }
          </div>
          <Button hidden={!batchProcessingState.done} className={`${style.detailsButton} ml-2 mr-3`}
                  onClick={() => handleArchiveLinkClick()} variant="contained"
                  style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary">
            {t('batchProcessingToArchive')}
          </Button>
          <Button hidden={!batchProcessingState.done} className={`${style.detailsButton} ml-2 mr-3`}
                  onClick={() => handleCancelClick()} variant="contained"
                  style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary">
            {t('batchProcessingCancel')}
          </Button>
          <Button hidden={batchProcessingState.done} className={`${style.detailsButton} ml-2 mr-3`}
                  onClick={() => handleCancelClick()} variant="contained"
                  style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary">
            {t('batchProcessingCancel')}
          </Button>
          <Button hidden={batchProcessingState.done} className={`${style.detailsButton} ml-2 mr-3`}
                  onClick={() => handlePauseClick()} variant="contained"
                  style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary">
            {batchProcessingState.paused ? t('batchProcessingResume') : t('batchProcessingPause')}
          </Button>
        </div>
      </div>
    </Modal>
  ) : null
}
