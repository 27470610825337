export const BgColors = {
  bgColors: {
    //dark background color variables
    bgPrimary0: "#782222", //ffffff
    bgPrimary100: "#0e7952", //F0F0F0
    bgPrimary200: "#7d0449",
    bgPrimary300: "#609f19",
    bgPrimary400: "#14409c",
    bgPrimary500: "#91167e",
    bgPrimary600: "#9f0202",
    bgPrimary900: "#df7d00",
    bgPrimary1000: "#ffffff",

    //dark status background color variables
    bgStatusError: "#1f05c1",
    bgStatusTrue: "#363d00",
    bgStatusInfo: "#ad00c4",

    //dark progress background color variables
    bgProgress20: "#7308dd",
    bgProgress40: "#178b6c",
    bgProgress60: "#421111",
    bgProgress80: "#e56514",
    bgProgress100: "#968313",
    bgProgress120: "#00a2ff",

    // dark blue background color variables
    bgBlue50: "#ff00e5",
    bgBlue100: "#00a2ff",
    bgBlue150: "#e7effc",
    bgBlue200: "#0040ac",
    bgBlue300: "#00225d",
    bgBlue1000: "rgb(0,9,29)",


    // light color variables for analysis color buttons
    bgLightGreen: "#90EE90",
    bgLightYellow : "#FCF787",
    bgLightPink : "#FADADD"



  },
};
