export const tableSetCurrentPage = (page) => {
  return {
    type: 'TABLE_SET_CURRENT_PAGE',
    payload: page
  }
}
export const tableSetSelectAllIds = (value) => {
  return {
    type: 'TABLE_SET_SELECT_ALL_IDS',
    payload: value
  }
}
export const tableSetDefaults = (pagination, extraFilters, disabledColumns, columns, order, orderBy) => {
  return {
    type: 'TABLE_SET_DEFAULTS',
    payload: { pagination, extraFilters, disabledColumns, columns, order, orderBy }
  }
}
export const tableSetPageAndLimit = (currentPage, limit) => {
  return {
    type: 'TABLE_SET_CURRENT_PAGE_AND_LIMIT',
    payload: { currentPage, limit }
  }
}
export const tableSetPagination = (page) => {
  return {
    type: 'TABLE_SET_PAGINATION',
    payload: page
  }
}
export const tableSetPages = (page) => {
  return {
    type: 'TABLE_SET_PAGES',
    payload: page
  }
}
export const tableSetRedirect = (value) => {
  return {
    type: 'TABLE_SET_REDIRECT',
    payload: value
  }
}
export const tableSetData = (value) => {
  return {
    type: 'TABLE_SET_DATA',
    payload: value
  }
}
export const tableSetOtherData = (value) => {
  return {
    type: 'TABLE_SET_OTHER_DATA',
    payload: value
  }
}
export const tableSetLimit = (value) => {
  return {
    type: 'TABLE_SET_LIMIT',
    payload: value
  }
}

export const tableSetOrder = (value) => {
  return {
    type: 'TABLE_SET_ORDER',
    payload: value
  }
}

export const tableSetOrderBy = (value) => {
  return {
    type: 'TABLE_SET_ORDER_BY',
    payload: value
  }
}
export const tableDialogReset = () => {
  return {
    type: 'TABLE_DIALOG_RESET'
  }
}
export const tableSetDialog = (value) => {
  return {
    type: 'TABLE_SET_DIALOG',
    payload: value
  }
}
export const tableSetRowCount = (value) => {
  return {
    type: 'TABLE_SET_ROW_COUNT',
    payload: value
  }
}
export const tableSetSelected = (value) => {
  return {
    type: 'TABLE_SET_SELECTED',
    payload: value
  }
}
export const tableSetDisabledColumns = (value) => {
  return {
    type: 'TABLE_SET_DISABLED_COLUMNS',
    payload: value
  }
}

export const tableSetColumns = (value) => {
  return {
    type: 'TABLE_SET_COLUMNS',
    payload: value
  }
}

export const tableSetFilters = (filter) => {
  return {
    type: 'TABLE_SET_FILTERS',
    payload: filter
  }
}
export const tableSetSelectAll = (value) => {
  return {
    type: 'TABLE_SET_SELECT_ALL',
    payload: !!value
  }
}

export const tableSetInscription = (value) => {
  return {
    type: 'TABLE_SET_INSCRIPTION',
    payload: value
  }
}
export const tableSetExtraFilters = (filter) => {
  return {
    type: 'TABLE_SET_EXTRA_FILTERS',
    payload: filter
  }
}

export const tableSetCompanyId = (value) => {
  return {
    type: 'TABLE_SET_COMPANY_ID',
    payload: value
  }
}
export const tableSetGroupId = (value) => {
  return {
    type: 'TABLE_SET_GROUP_ID',
    payload: value
  }
}
export const tableClearAll = () => {
  return {
    type: 'TABLE_CLEAR_ALL'
  }
}