import style from "./style.module.sass";
import Button from "@material-ui/core/Button";
import React  from "react";
import {useTranslation} from "react-i18next";

export default function AnalyseButton({
                                          handleOnAnalyseClick,
                                          analyseButtonEnable,
                                          setAnalyseButtonEnable
                                      }) {
    const {t} = useTranslation();
    return (
        <div className={`${style.selectCont}`}>
            <Button onClick={() => {
                if (analyseButtonEnable) {
                    setAnalyseButtonEnable(false);
                    handleOnAnalyseClick()
                }
            }}
                    disabled={!analyseButtonEnable}
                    className={`${style.loader} ${analyseButtonEnable && style.active}`}>
                <div className={style.figure}>
                    <div className={`${style.circle} ${analyseButtonEnable && style.active}`}/>
                    <div className={style.top}/>
                    <div className={style.center}>
                        <div className={`${style.center_circle} ${analyseButtonEnable && style.active}`}/>
                    </div>
                </div>
                <h4 className={`${analyseButtonEnable && style.activeText}`}>{t("Start analysis")}</h4>
            </Button>
        </div>
    )
}