import React from "react";
import TextField from '@material-ui/core/TextField';
import {Autocomplete as AutocompleteField} from '@material-ui/lab';

function Autocomplete({formik, name, label, options, freesolo, ...rest}) {
  return (
    <AutocompleteField
      fullWidth
      freesolo={freesolo}
      name={name}
      inputValue={formik.values[name]}
      onInputChange={(event, newInputValue) => {
        formik.setFieldValue(name, newInputValue);
      }}
      options={options.map((option) => option.title)}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined"/>
      )}
      {...rest}
    />
  )
}

export default Autocomplete;
