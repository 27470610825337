const updateDashboardData = (state, action) => {
  const defaultState = {
    language: 'de_DE',
    folderSelected: [[], []],
    folderType: ['personal', 'personal'],
    folderList: [[], []],
    archivesList: [[], []],
    openedFolders: [[], []],
    idList: [[], []],
    oldIdList: [[], []],
    resultList: [{}, {}],
    allId: [false, false],
    filter: [{}, {}],
    limit: [50, 50],
    fullcount: [0, 0],
    offset: [0, 0],
    sort: ['archive_name', 'archive_name'],
    type: 'single',
    loading: false,
    archiveResult: [],
    termWordCount: 20
  }
  if (state === undefined) {
    return defaultState
  }
  const newState = { ...state.dashboardData }
  switch (action.type) {
    case 'DASHBOARD_INIT_ONE':
      //{filter, idList, type, language}
      newState.filter[action.payload.id] = action.payload.data.filter
      newState.idList[action.payload.id] = action.payload.data.idList
      newState.folderType[action.payload.id] = action.payload.data.folderType
      newState.language = action.payload.data.language
      newState.type = action.payload.data.type
      return newState
    case 'DASHBOARD_CLEAR_ALL':
      newState.folderSelected[action.payload.id] = defaultState.folderSelected[action.payload.id]
      newState.folderSelected[action.payload.id] = defaultState.folderList[action.payload.id]
      newState.archivesList[action.payload.id] = defaultState.archivesList[action.payload.id]
      newState.openedFolders[action.payload.id] = defaultState.openedFolders[action.payload.id]
      newState.idList[action.payload.id] = defaultState.idList[action.payload.id]
      newState.oldIdList[action.payload.id] = defaultState.oldIdList[action.payload.id]
      newState.allId[action.payload.id] = defaultState.allId[action.payload.id]
      newState.filter[action.payload.id] = defaultState.filter[action.payload.id]
      newState.limit[action.payload.id] = defaultState.limit[action.payload.id]
      newState.fullcount[action.payload.id] = defaultState.fullcount[action.payload.id]
      newState.offset[action.payload.id] = defaultState.offset[action.payload.id]
      newState.sort[action.payload.id] = defaultState.sort[action.payload.id]
      return newState
    case 'DASHBOARD_SET_TYPE':
      return {
        ...state.dashboardData,
        type: action.payload
      }
    case 'DASHBOARD_SET_LOADING':
      return {
        ...state.dashboardData,
        loading: true
      }
    case 'DASHBOARD_STOP_LOADING':
      return {
        ...state.dashboardData,
        loading: false
      }
    case 'DASHBOARD_SET_LANGUAGE':
      return {
        ...state.dashboardData,
        language: action.payload
      }
    case 'FETCH_FOLDER_LIST_FAILURE':
      newState.folderList[action.payload.id] = []
      return newState
    case 'FETCH_FOLDER_LIST_SUCCESS':
      newState.loading = false
      newState.folderList[action.payload.id] = action.payload.data
      return newState
    case 'FETCH_FOLDER_LIST_ERROR':
      newState.loading = false
      newState.folderList[action.payload] = []
      return newState
    case 'FETCH_ARCHIVE_LIST_SUCCESS':
      newState.loading = false
      newState.archivesList[action.payload.id] = action.payload.data
      newState.fullcount[action.payload.id] = action.payload.fullcount
      return newState
    case 'FETCH_ARCHIVE_LIST_ERROR':
      newState.loading = false
      newState.archivesList[action.payload] = []
      newState.fullcount[action.payload] = 0
      return newState
    case 'DASHBOARD_SET_ARCHIVE_LIST':
      newState.archivesList[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_FOLDER_SELECTED':
      newState.folderSelected[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_OPENED_FOLDERS':
      newState.openedFolders[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_FOLDER_LIST':
      newState.folderList[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_ID_LIST':
      newState.idList[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_OLD_ID_LIST':
      newState.oldIdList[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_RESULT_LIST':
      newState.resultList[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_FILTER':
      newState.filter[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_LIMIT':
      newState.limit[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_OFFSET':
      newState.offset[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_SORT':
      newState.sort[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_ALL_ID':
      newState.allId[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_FOLDER_TYPE':
      newState.folderType[action.payload.id] = action.payload.data
      return newState
    case 'DASHBOARD_SET_TERM_WORD_COUNT':
      return {
        ...state.dashboardData,
        termWordCount: action.payload
      }
    case 'DASHBOARD_SET_ARCHIVE_RESULT':
      return {
        ...state.dashboardData,
        archiveResult: action.payload
      }
    default:
      return state.dashboardData
  }
}

export default updateDashboardData