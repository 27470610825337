import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux'
import TLService from './services/tl-service';
import {userSetToken} from "./actions";
import {HelmetProvider} from "react-helmet-async";
import store from './store';
import TLServiceComponent from "../src/contexts/TLServiceComponent"
import BatchProcessingServiceComponent from './components/Analysis/BatchProcessing/BatchProcessingServiceComponent';
import './index.sass';
import App from './App';
import Spinner from "./components/Spinner";
import config from "./config";
import BatchProcessingService from './components/Analysis/BatchProcessing/batch-processing-service';
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {convertLanguagesToFull} from "./utils";


require('dotenv').config();

// get storageToken (if saved)
const storageToken = localStorage.getItem(config.localStorageTokenName);
if (storageToken && storageToken !== 'null') {
    store.dispatch(userSetToken(storageToken));
}

// connect to TLService
const tlService = new TLService();

// i18n config
i18n.use(Backend).use(LanguageDetector).use(initReactI18next);

// first load the translations
TLService.localGetAPIBase().then(api => {

    tlService._apiBase = api;

    // then init the i18n
    i18n.init({
        fallbackLng: 'de',
        debug: true,
        cleanCode: true,
        lowerCaseLng: true,
        load: 'languageOnly',
        backend: {
            customHeaders: () => {
                const storageToken = localStorage.getItem('token');
                if (storageToken && storageToken !== 'null') {
                    return {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Access-Control-Max-Age': 600,
                        'Authorization': `Bearer ${storageToken}`
                    };
                }
                return {
                    'Content-Type': 'application/json',
                    'Access-Control-Max-Age': 600,
                    'Accept': 'application/json'
                };
            },
            loadPath: lng => {
                return api + `/translations/view/${convertLanguagesToFull(lng)}`;
            }
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

    // now run the application
    const batchProcessingService = new BatchProcessingService();
    ReactDOM.render(
        <Suspense fallback={<Spinner/>}>
            <Provider store={store}>
                <TLServiceComponent.Provider value={tlService}>
                    <BatchProcessingServiceComponent.Provider value={batchProcessingService}>
                        <HelmetProvider>
                            <App/>
                        </HelmetProvider>
                    </BatchProcessingServiceComponent.Provider>
                </TLServiceComponent.Provider>
            </Provider>
        </Suspense>,
        document.getElementById('root')
    );

});
