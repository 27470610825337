import React, {useState} from "react";
import style from "./style.module.scss"
import {useTheme} from "@material-ui/core/styles";
import {Button, Collapse} from "@material-ui/core";
import {Add, Remove} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import HIXTable from "./HIXTable";


export default function CollapseFile({hixTableCells}) {
    const {theme: {bgColors, colors}} = useTheme();
    const [openLongWords, setOpenLWords] = useState(true)
    const {t} = useTranslation();

  return (
        <div className={style.moreLongerWords}>
            <div className={style.header}>
                <p>{t("More longer words ...")}</p>
                <Button variant="contained"
                        onClick={() => setOpenLWords(!openLongWords)}
                        style={{backgroundColor: bgColors.bgPrimary400, padding: "6px", minWidth: "38px"}}>
                    {openLongWords ?
                        <Remove style={{fill: colors.colorPrimary0}}/> :
                        <Add style={{fill: colors.colorPrimary0}}/>}
                </Button>
            </div>
            <Collapse in={openLongWords}>
                <div className={`d-flex flex-lg-nowrap flex-md-wrap mb-5`} style={{gridGap: "10px"}}>
                    <div className={`w-100`}
                         style={{borderTop: `4px solid #e2e1e1`}}>
                        <HIXTable tableCells={hixTableCells}/>
                    </div>
                </div>
            </Collapse>
        </div>
  )
}
