import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React, {useState} from "react";
import {FormControl, IconButton, MenuItem, Select, TextField} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import TableRow from '@material-ui/core/TableRow';
import {useTranslation} from "react-i18next";


function TableFooter() {
  const {theme: {bgColors, colors}} = useTheme();
  const [rows, setRows] = useState(10);
  const {t} = useTranslation();


  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary500}} className={`${style.tableFilter}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <div className={style.pagination}>
            <p className={`mr-2 m-0`} style={{color: colors.colorPrimary0}}>{t('entries-per-page')}</p>
            <div style={{backgroundColor: bgColors.bgBlue100}}
                 className={`pl-2 mr-2 ${style.perPage} d-flex align-items-center`}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rows}
                  onChange={e => setRows(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </div>

            <TextField inputProps={{maxLength: 3}}
                       className={`pl-2`}
                       style={{backgroundColor: bgColors.bgBlue100, borderRadius: "4px"}}/>
            <span style={{color: colors.colorPrimary0}} className={`mr-2 ml-2`}>{t('of')}</span>
            <p style={{color: colors.colorPrimary0}} className={`mr-2 m-0`}>5</p>
            <IconButton style={{color: colors.colorPrimary0}}>
              <KeyboardArrowLeftIcon/>
            </IconButton>
            <IconButton style={{color: colors.colorPrimary0}}>
              <KeyboardArrowRightIcon/>
            </IconButton>
          </div>
        </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

export default TableFooter;

