import style from './style.module.sass'
import { Button, Checkbox } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useActions } from '../../../utils/action-helper'
import { dashboardSetFolderSelected, dashboardSetOpenedFolders } from '../../../actions'
import { ExpandMore, ChevronRight, } from '@material-ui/icons'
import { deepCopy } from '../../../utils'

export default function FolderSelectionTable ({ panel }) {
  const { t } = useTranslation()
  const [selectAll, setSelectAll] = useState(false)

  const folderList = useSelector(state => state.dashboardData.folderList[panel])
  const folderSelected = useSelector(state => state.dashboardData.folderSelected[panel])
  const openedFolders = useSelector(state => state.dashboardData.openedFolders[panel])

  const recursiveRenderRows = (rows, deep) => {
    let res = []
    for (const row of rows) {
      res.push({ ...row, deep })
      if (openedFolders.indexOf(row.id) > -1 && row.child?.length) {
        res = [...res, ...recursiveRenderRows(row.child, deep + 1)]
      }
    }
    return res
  }

  const folderRows = useMemo(() => recursiveRenderRows(folderList, 0), [folderList, openedFolders])

  const { aDashboardSetFolderSelected, aDashboardSetOpenedFolders } = useActions({
    aDashboardSetOpenedFolders: dashboardSetOpenedFolders,
    aDashboardSetFolderSelected: dashboardSetFolderSelected
  })

  function onFolderChange (id) {
    const idPosition = folderSelected.indexOf(id)
    if (idPosition === -1) {
      aDashboardSetFolderSelected(panel, [...folderSelected, id])
    } else {
      aDashboardSetFolderSelected(panel, folderSelected.filter(v => v !== id))
    }
  }

  //useEffect(() => {
  //    setSelectAll(folderRows.every(v => folderList.indexOf(v.id) > -1));
  //}, [folderList, folderRows])

  function onOpenFolder (id) {
    const tmpOpenedFolders = deepCopy(openedFolders)
    const index = tmpOpenedFolders.indexOf(id)
    if (index > -1) {
      delete tmpOpenedFolders[index]
    } else {
      tmpOpenedFolders.push(id)
    }
    aDashboardSetOpenedFolders(panel, tmpOpenedFolders)
  }

  const onSelectAll = () => {
    let newList = []

    if (!selectAll) {
      for (let addId of folderRows) {
        newList.push(addId.id)
      }
      newList.push(0)
    } else {
      newList = []
    }

    aDashboardSetFolderSelected(panel, newList)
    setSelectAll(!selectAll)
  }

  const DrawRow = ({ folderId, folderName, shift, checked, opened, addButton }) => {
    return (
      <tr>
        <td className={'p-0 m-0'} width={50} style={{ backgroundColor: '#e7e7e7' }}>
          <div className="d-flex align-items-center justify-content-center">
            <Checkbox checked={checked} onChange={() => onFolderChange(folderId)} color="primary"/>
          </div>
        </td>
        <td>
          <div className="m-0 mr-auto" style={{ paddingLeft: `${shift}px` }}>
            {addButton ?
              <Button className={'pointer-event p-0 m-0'} onClick={() => onOpenFolder(folderId)}>
                {opened ? <ExpandMore/> : <ChevronRight/>}
              </Button>
              :
              <span style={{ marginLeft: '58px' }}>&nbsp;</span>
            }
            <span onClick={() => onOpenFolder(folderId)}>{folderName}</span>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div className={'p-0 m-0'}
         style={{ backgroundColor: '#ffffff', maxHeight: '300px', minHeight: '300px', overflowY: 'scroll' }}>
      <table border={'1px solid #000'} className={`${style.table}`}>
        <thead>
        <tr style={{ backgroundColor: '#d8d8d8' }}>
          <th>
            <div>
              {folderRows?.length ?
                <Checkbox checked={selectAll} onChange={onSelectAll} color="primary" id="name-selects"/> :
                <div/>}
            </div>
          </th>
          <th>
            <h6 className={`m-0 mr-auto`}>{t('Folders')}</h6>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className={'p-0 m-0'} width={50} style={{ backgroundColor: '#e7e7e7' }}>
            <div className="d-flex align-items-center justify-content-center">
              <Checkbox checked={folderSelected.indexOf(0) > -1} onChange={() => onFolderChange(0)} color="primary"/>
            </div>
          </td>
          <td>
            <div className="m-0 mr-auto" style={{ paddingLeft: `0px` }}>
              <span style={{ marginLeft: '20px' }}>&nbsp;</span>
              <span onClick={() => onOpenFolder(0)}>{t('root_folder')}</span>
            </div>
          </td>
        </tr>
        {folderRows.map(v =>
          <DrawRow
            addButton={v.child?.length}
            key={v.id}
            folderId={v.id}
            folderName={v.folder_name}
            shift={v.deep * 20}
            checked={folderSelected.indexOf(v.id) > -1}
            opened={openedFolders.indexOf(v.id) > -1}
          />
        )}
        </tbody>
      </table>
    </div>
  )
}