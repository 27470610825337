import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@material-ui/lab';
import { useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";


function Td({ data, display, setId, Id, fullData }) {
  // const [select, setSelect] = useState('');
  // const handleChange = e => setSelect(e.target.value);
  const { t } = useTranslation();

  return (
    <div className={`d-flex flex-column`} style={{ gridGap: 15 }}>
      <Autocomplete
        onChange={(e, newValue) => {
          setId([...Id, newValue?.id]);
          fullData.current = newValue;
          display();
        }}
        // inputValue={!!fullData.current?.title ? fullData.current?.title : ""}
        id="combo-box-demo"
        options={!!data ? data : []}
        // options={data}
        noOptionsText={t('no-option')}
        getOptionLabel={(option) => option.title}
        className={`d-flex align-items-center mr-3`}
        style={{ width: 300, height: "52px" }}
        renderInput={(params) => <TextField {...params} style={{ height: "52px" }} label={t('start-to-type-here')}
          variant="filled"
        />}
      />
    </div>
  )
}

export default function LowerTermsUserRights({
  setUserId, setFirmaId, setGroupId, user, group, company,
  //  setUserType,
  //  setGroupType,
  //  setCompanyType,
  userId, groupId, firmaId, mainBox, setMainBox,
  setDeleteRight, deleteRight
}) {
  const { t } = useTranslation();
  const { theme: { colors } } = useTheme();
  const userIs = useRef('');
  const groupIs = useRef('');
  const companyIs = useRef('');
  // const [alertMsg, setAlertMsg] = useState();

  const display = () => {
    if (!!userIs.current || !!groupIs.current || !!companyIs.current) {
      // let resultBox = mainBox;
      if (!!userIs.current) {
        setMainBox([...mainBox, [userIs.current, "", ""]])
      } else if (!!groupIs.current) {
        setMainBox([...mainBox, ["", groupIs.current, ""]])
      } else if (!!companyIs.current) {
        setMainBox([...mainBox, ["", "", companyIs.current]])
      }
      userIs.current = '';
      groupIs.current = '';
      companyIs.current = '';
      // }
    }
  }

  const deleteUserRight = async (el) => {
    setDeleteRight([...deleteRight, el])
    let newArry = mainBox.filter(list => list !== el)
    setMainBox(newArry)
  }

  return (
    <div className={'pt-1'}>
      <table cellPadding={10} border={1} bordercolor={'#dee2e6'} style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th>{t('user')}</th>
            <th>{t('group')}</th>
            <th>{t('company')}</th>
            <th>{t('edit')}</th>
          </tr>
          {mainBox?.length ? mainBox.map((el, key) => {
            return <tr key={key}>
              <td>
                {el[0]?.title}
              </td>
              <td>
                {el[1]?.title}
              </td>
              <td>
                {el[2]?.title}
              </td>
              <td>
                <span onClick={() => deleteUserRight(el)}>
                  <svg className={`ml-2`} xmlns="http://www.w3.org/2000/svg" width="15.991" height="18.275"
                    style={{ marginLeft: "0px", marginTop: "10px" }}
                    viewBox="0 0 15.991 18.275">
                    <path id="Icon_awesome-trash-alt-2" data-name="Icon awesome-trash-alt"
                      d="M1.142,16.562a1.713,1.713,0,0,0,1.713,1.713h10.28a1.713,1.713,0,0,0,1.713-1.713V4.569H1.142Zm9.709-9.137a.571.571,0,0,1,1.142,0v8a.571.571,0,1,1-1.142,0Zm-3.427,0a.571.571,0,1,1,1.142,0v8a.571.571,0,1,1-1.142,0ZM4,7.424a.571.571,0,1,1,1.142,0v8a.571.571,0,1,1-1.142,0ZM15.419,1.142H11.136L10.8.475A.857.857,0,0,0,10.033,0H5.954A.847.847,0,0,0,5.19.475l-.336.667H.571A.571.571,0,0,0,0,1.713V2.855a.571.571,0,0,0,.571.571H15.419a.571.571,0,0,0,.571-.571V1.713A.571.571,0,0,0,15.419,1.142Z"
                      fill={colors.colorStatusInfo}
                    />
                  </svg>
                </span>
              </td>
            </tr>
          }) :
            <tr />
          }
          <tr>
            <td>
              <Td fullData={userIs} display={display} data={user} setId={setUserId} Id={userId} />
            </td>
            <td>
              <Td fullData={groupIs} display={display} data={group} setId={setGroupId} Id={groupId} />
            </td>
            <td>
              <Td fullData={companyIs} display={display} data={company} setId={setFirmaId} Id={firmaId} />
              {/* setData={setCompanyIs} */}
            </td>
            {/* <td>
              <Button color='primary' variant='contained' onClick={() => display()}>
                <Add />
              </Button>
            </td> */}
          </tr>
        </tbody>
      </table>
      {/* <div className={style.error}>
            <Collapse in={!!alertMsg}>
              <Alert
                className={`m-0`}
                severity='error'>
                <h6 className={`m-0`}>
                  {alertMsg}
                </h6>
              </Alert>
            </Collapse>
          </div> */}
    </div>
  )
}
