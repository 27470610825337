import React from "react";
import style from './style.module.sass'

import ComponentWithPop from '../../common/ComponentWithPop'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import { Close } from "@material-ui/icons"

export default function AiHead({ handleDiscardText }) {
  const { t } = useTranslation();
  const { theme: { bgColors, colors } } = useTheme()

  return (
    <div className={`${style.aiHeader}`}>

      <div style={{ color: colors.colorPrimary900 }} className={`d-flex justify-content-end`} >
        {/* <div>
          <p style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: t('ai_head_text') }} />
        </div>
        <div className={'pl-2 ml-3 d-flex  '} >
          <div className="mr-3">
            <ComponentWithPop text={`${t('start-send-text-back')}`}>
              <button disabled={!enableButtonBack} onClick={handleTakeSuggestion} style={{ backgroundColor: enableButtonBack ? "#71a141" : bgColors.bgPrimary400 }} className={`d-flex ${style.textback}`}>
                <p className={`m-0 pl-1`} style={{ cursor: enableButtonBack ? "pointer" : "inherit" }} >
                  {t('ai_accept_text')}
                </p>
                <Check style={{ cursor: enableButtonBack ? "pointer" : "inherit", marginLeft: 8 }} />

              </button>
            </ComponentWithPop>
          </div> */}
          <ComponentWithPop text={`${t('ai_reject_text_tt')}`} >
            <button onClick={handleDiscardText} style={{ backgroundColor: "#bf6a86" }} className={`d-flex ${style.textback}`}>
              <p className={`m-0 pl-1`} style={{ cursor: "pointer" }} >
                {t('ai_reject_text')}
              </p>
              <Close style={{ cursor: "pointer", marginLeft: 8 }} />

            </button>
          </ComponentWithPop>
        {/* </div> */}
      </div>

    </div>
  )

}