import { useTheme } from "@material-ui/styles"
import { Fragment, useContext, useEffect, useState } from "react"
import TLServiceComponent from "../../../../../../contexts/TLServiceComponent"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useActions } from "../../../../../../utils/action-helper"
import {
  tableSetData,
  tableSetDialog,
  tableSetSelected,
} from "../../../../../../actions"
import style from './style.module.sass'
import TableContainer from '@material-ui/core/TableContainer'
import { Delete, Settings } from '@material-ui/icons'
import { NavLink } from "react-router-dom"
import TableHead from '@material-ui/core/TableHead'
import { checkSharedData, checkUserRight, convertFromErrorObject, convertedValue, isObjectEmpty } from "../../../../../../utils"
import { Box, Button, Checkbox, Collapse, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import config from "../../../../../../config"
import RenderSection from "../../Sections"
import RequestComponent from "../../../../User/Table/TableInnerComponent/ReqComponent"
import Spinner from "../../../../../Spinner"



export default function TableInnerComponent({
  row,
  index,
  isBlueRow,
  handleChangeValue
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)

  const selected = useSelector(state => state.tableData.selected)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const data = useSelector(state => state.tableData.data)
  const columns = useSelector(state => state.tableData.columns)
  const token = useSelector(state => state.userData.token)
  const user = useSelector(state => state.userData.user)


  const [extraData, setExtraData] = useState({})
  const [spinner, setSpinner] = useState(false)

  const {
    aTableSetSelected,
    aTableSetData,
    aTableSetDialog
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetSelected: tableSetSelected,
    aTableSetDialog: tableSetDialog,
  })

  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)

  useEffect(() => {
    async function fetchData() {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'deleteCompany':
            if (dialogResponse && !isObjectEmpty(extraData)) {
              const company_id = extraData.id
              try {
                await tlService.deleteCompany(token, company_id)
                const tData = [...data]
                tData.splice(index, 1)
                aTableSetData(tData)
              } catch (error) {
                console.log('deleteCompany', error)
                aTableSetDialog({
                  type: 'errorDeleteCompany',
                  open: true,
                  RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                  response: null,
                  buttons: {
                    yes: false,
                    no: false,
                    close: true,
                    save: false
                  }
                })
              }
            }
            break
          default:
            break
        }
        setExtraData({})
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const handleSelectItem = (id) => {
    const tSelected = [...selected]
    const idx = tSelected.indexOf(id)
    if (idx > -1) {
      tSelected.splice(idx, 1)
    } else {
      tSelected.push(id)
    }
    aTableSetSelected(tSelected.sort())
  }

  const handleOpenItem = (inx) => {
    const tData = [...data]
    tData[inx]['opened'] = !row?.opened
    aTableSetData(tData)
  }

  const handleDeleteCompany = async (row) => {
    setExtraData(row)
    setSpinner(true)

    let {termCategories, textBins, allowedLists, sharedArchivesInCompany, archiveFoundInFolderCompany, benchmarksInGroup, groupsInCompany } = await checkSharedData(tlService, token, row, t, "company")
    
    setSpinner(false)
  
    if (termCategories?.fullcount > 0 || textBins?.fullcount > 0 || allowedLists?.fullcount > 0 || sharedArchivesInCompany?.archives_fullcount > 0 || benchmarksInGroup?.fullcount > 0 || groupsInCompany?.fullcount > 0) {
      aTableSetDialog({
        type: 'deleteCompanyWarning',
        open: true,
        RequestComponent: () => (
          <RequestComponent
            t={t}
            archiveFoundInFolderCompany={archiveFoundInFolderCompany}
            sharedArchivesInCompany={sharedArchivesInCompany}
            benchmarksInGroup={benchmarksInGroup}
            textBins={textBins}
            allowedLists={allowedLists}
            termCategories={termCategories}
            groupsInCompany= {groupsInCompany}
            type='company'
          />
        ),
        response: null,
        buttons: {
          yes: false,
          no: false,
          close: true,
          save: false,
          edit: true,
        }
      })

    } else {

    aTableSetDialog({
      type: 'deleteCompany',
      open: true,
      RequestComponent: () => <>{t('admin_company_delete-company')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }
  }

  const EmptyCell = () => {
    return <TableCell
      onClick={() => handleOpenItem(index)}
      className={style.emptyColumns}
      component="th"
      scope="row"
      padding="none" />
  }

  /* VIEW */
  const isItemSelected = selected.includes(row.id)

  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><Spinner /></div>

  return (
    <>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={() => handleSelectItem(row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </TableCell>
        {columns.map((col, ind) =>
          <Fragment key={ind}>
            {disabledColumns.includes(col.id) ? <EmptyCell /> :
              <>
                <TableCell
                  onClick={() => handleOpenItem(index)}
                  className={style.otherColumns}
                  component="th"
                  scope="row"
                  padding="none">
                  {
                    convertedValue(row[col.id], col.type, i18nLanguage, t)
                  }
                </TableCell>
              </>
            }
          </Fragment>)
        }
        <TableCell className={`${style.cell} d-flex`} align="right">
          {checkUserRight(user, [208]) && <>
            <div className={style.buttonCell}>
              <Button variant="contained" color="primary" size="small"
                style={{
                  padding: '4px',
                  minWidth: '30px',
                  backgroundColor: bgColors.bgProgress20,
                  color: colors.colorPrimary0
                }}
                onClick={() => handleDeleteCompany(row)}>
                <Delete fontSize="small" />
              </Button>
            </div>
            <div className={style.buttonCell}>
              <NavLink to={`/administration/company/update/${row.id}`} exact={true}>
                <Button variant="contained" color="primary" size="small"
                  style={{
                    padding: '4px',
                    minWidth: '30px',
                    backgroundColor: bgColors.bgBlue1000,
                    color: colors.colorPrimary0
                  }}>
                  <Settings fontSize="small" />
                </Button>
              </NavLink>
            </div>
          </>
          }
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse className={'p-1'} in={row.opened} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
              className={`mb-4 d-flex justify-content-between pt-2`}
              margin={1}>
              <div className={'d-flex text-center w-100'}>
                <div style={{ width: '100%', maxHeight: '260px', overflowY: 'scroll' }}>
                  <TableContainer>
                    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={style.smallTableHeader} style={{ width: '10%' }}>{t('admin_company_company-id')}</TableCell>
                          <TableCell className={style.smallTableHeader} style={{ width: '90%' }}>{t('admin_company_frontend-options')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.opened &&
                          <Fragment key={row.id}>
                            <TableRow
                              key={row.id}>
                              <TableCell
                                className={style.smallTable}>{convertedValue(row.id, 'numeric', i18nLanguage, t)}</TableCell>
                              <TableCell className={style.smallTable} style={{ display: 'flex' }}>
                                {
                                  config.companyFESections.map((section, idx) => (
                                    <div>
                                      <RenderSection key={idx} sections={section} row={row} i18nLanguage={i18nLanguage} t={t} />
                                    </div>
                                  ))
                                }
                              </TableCell>
                            </TableRow>
                          </Fragment>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}