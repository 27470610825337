import React, { Fragment, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Switch } from "@material-ui/core";
import classNames from "classnames";
import LowerTermsUserRights from "./LowerTermsUserRights";
import { useTranslation } from "react-i18next";
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import style from "./style.module.sass";
import config from "../../../../config";

export default function LowerTermsModalFields({
  setUserId, setFirmaId, setGroupId,
  allowListDisabled, setAllowListDisabled,
  ignoreDisabled, setIgnoreDisabled,
  setColor, color,
  invisible, setInvisible,
  userId, groupId, firmaId,
  mainBox, setMainBox,
  setDeleteRight, deleteRight,
  categoryType
}) {
  const [activeTab, setActiveTab] = useState('1');
  const [user, setUser] = useState();
  const [group, setGroup] = useState();
  const [company, setCompany] = useState();
  // const [userType, setUserType] = useState();
  // const [groupType, setGroupType] = useState();
  // const [companyType, setCompanyType] = useState();
  // const [color, setColor] = useState('');
  const { t } = useTranslation();
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const { theme: { bgColors } } = useTheme();

  const getDatas = async () => {
    // Get all User:
    try {
      let user = await tlService.getUser(token, {}, {});
      let userIs = [];
      user.data.map(el => {
        let obj = {};
        obj.title = el.user_name;
        obj.id = el.id;
        userIs.push(obj)
      })
      setUser(userIs)
    } catch (err) {
      console.log('error', err)
    }
    // Get all Group:
    try {
      let group = await tlService.getFilteredGroups(token, {}, {});
      let groupIs = [];
      group.data.map(el => {
        let obj = {};
        obj.title = el.name;
        obj.id = el.id;
        groupIs.push(obj)
      })
      setGroup(groupIs)
    } catch (err) {
      console.log('error', err)
    }
    // Get all Company:
    try {
      let company = await tlService.getFilteredCompanies(token, {}, {});
      let companyIs = [];
      company.data.map(el => {
        let obj = {};
        obj.title = el.name;
        obj.id = el.id;
        companyIs.push(obj)
      })
      setCompany(companyIs)
    } catch (err) {
      console.log('error', err)
    }
    // Set Color according to TermList Type, if negative set color to red, if positive, to Green:
    if (color === "") {
      if (categoryType === "negative") {
        setColor("colorRed")
      } else if (categoryType === "positive") {
        setColor("colorGreen")
      }
    }
  }

  useEffect(() => {
    getDatas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink style={{ cursor: "pointer", color: "#707070" }}
            className={classNames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            {t('user-rights')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{ cursor: "pointer", color: "#707070" }}
            className={classNames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            {t('settings')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div style={{ height: "400px", overflow: "scroll" }}>
            {/* , display: "flex", flexDirection: "column-reverse" */}
            <LowerTermsUserRights
              deleteRight={deleteRight}
              setDeleteRight={setDeleteRight}
              mainBox={mainBox} setMainBox={setMainBox}
              // setUserType={setUserType}
              // setGroupType={setGroupType}
              // setCompanyType={setCompanyType}
              user={user} group={group} company={company} setUserId={setUserId}
              setFirmaId={setFirmaId} setGroupId={setGroupId}
              userId={userId} groupId={groupId} firmaId={firmaId} />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <table cellPadding={10}>
            <tbody>
              <tr>
                <th>
                  {t('settings')}
                </th>
                <th>{t('value')}</th>
              </tr>
              <tr>
                <td width={200}>
                  <b>
                    {t("AllowList Disabled")} :
                  </b>
                </td>
                <td>
                  <Switch
                    checked={!!allowListDisabled}
                    onChange={() => setAllowListDisabled(!allowListDisabled)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </td>
              </tr>
              <tr>
                <td width={200}>
                  <b>
                    {t("Ignore Disabled")} :
                  </b>
                </td>
                <td>
                  <Switch
                    checked={!!ignoreDisabled}
                    onChange={() => setIgnoreDisabled(!ignoreDisabled)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </td>
              </tr>
              {categoryType !== "allowedlist" ? <tr>
                <td width={200}>
                  <b>
                    {t("Color")} :
                  </b>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    {
                      config.userSettingColor.map((list, key) => {
                        return (
                          //  <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                          //     {`${t(list["name"])}`}
                          // </h6>} className='ml-2 mr-auto' key={key}>
                          <span key={key} onClick={() => {
                            if (color === list["name"]) {
                              setColor("")
                            } else {
                              setColor(list["name"])
                            }
                          }} style={{
                            borderColor: list["color"],
                            backgroundColor: (color === list["name"]) ? list["color"] : bgColors.bgPrimary0
                          }} className={`mr-2 ${style.border}`}>
                          </span>
                          // </Tooltip>
                        )
                      })
                    }
                  </div>
                </td>
              </tr> :
                <Fragment />}
              {categoryType !== "negative" ? <tr>
                <td width={200}>
                  <b>
                    {t("Invisible")} :
                  </b>
                </td>
                <td>
                  <Switch
                    checked={!!invisible}
                    onChange={() => setInvisible(!invisible)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </td>
              </tr> :
                <Fragment />}
            </tbody>
          </table>
          {/* <Button className={`ml-2`} color="primary" variant='contained'>
            <Add /> {t('add-settings')}
          </Button> */}
        </TabPane>
      </TabContent>
    </div>
  )
}
