import style from "./style.module.scss";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import SingleDistribution from "./SingleDistribution";
import CompareSingleDistribution from "./CompareSingleDistribution";
import { useTranslation } from "react-i18next";
import { NavLink, useRouteMatch } from "react-router-dom";

export default function Distribution({ currentBenchmarkSettings }) {
  const { theme: { bgColors, colors } } = useTheme();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <div className={`${style.distribution}`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
      <div className={`d-flex align-content-center justify-content-between ${style.withBorder}`}>
        <h2 style={{ color: colors.colorPrimary400 }}
          className={`mb-0 pb-0 ${style.withoutBorder}`}>{t("WORD DISTRIBUTION")}</h2>
      </div>
      {url.includes("combine") ?
        <CompareSingleDistribution currentBenchmarkSettings={currentBenchmarkSettings} /> :
        <SingleDistribution />
      }
    </div>
  )
}
