import React from "react";
import style from "./style.module.sass"
import { useTheme } from "@material-ui/core/styles";
// import { useTranslation } from "react-i18next";

function AutoFill({ title, state, stateForMouse, handleCompany, handleGroup, setStateForMouse }) {
  const { theme: { bgColors, colors } } = useTheme();
  // const { t } = useTranslation();

  return (
    <div style={{ color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500 }}
      className={`${style.autoFill} d-flex align-items-center justify-content-between`}>
      <h4 style={{ color: colors.colorPrimary0 }}
        className={`${style.title}`}>{title}</h4>
      {/* <div>
        <Button onClick={handleCompany} onMouseLeave={()=>setStateForMouse(null)} onMouseEnter={()=>setStateForMouse('company')}
                className={`mr-3`} color={stateForMouse === "company" ? "primary" : state === "company" ? "primary" : "default"} variant={"contained"}>
            {t('company-default')}      
                  </Button>
        <Button onClick={handleGroup} onMouseLeave={()=>setStateForMouse(null)} onMouseEnter={()=>setStateForMouse('group')}
                color={stateForMouse === "group" ? "primary" : state === "group" ? "primary" : "default"} variant={"contained"}>
            {t('group-default')}
                </Button>
      </div> */}
    </div>
  )
}

export default AutoFill;
