import React, { useEffect, useState, useRef } from 'react'
import style from './style.module.scss'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isObjectEmpty, deprecatedBenchmarkCheck } from '../../../utils'
import DashHeaderCompare from '../Header/compare'
import Spinner from '../../Spinner'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useActions } from '../../../utils/action-helper'
import {
    dashboardSetIdList,
    userGetAllBenchmarks,
    dashboardSetResultList,
    userSetArchiveLanguage,
    dashboardSetLanguage
} from '../../../actions'
import ComparePanel from './ComparePanel'
import { NavLink } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import AveragePanel from './AveragePanel'
import { Alert } from '@material-ui/lab'
import { MenuItem, Select } from '@material-ui/core'


export default function DashHomeCompare() {
    const tlService = React.useContext(TLServiceComponent)
    const { t } = useTranslation()
    const benchmarkExist1 = useRef(true)
    const benchmarkExist2 = useRef(true)
    const [submitted, setSubmitted] = useState(false)
    const [defaultSet, setDefaultSet] = useState(false)
    // const [trigger, setTrigger] = useState(false)
    const idList0 = useSelector(state => state.dashboardData.idList[0])
    const idList1 = useSelector(state => state.dashboardData.idList[1])
    const language = useSelector(state => state.dashboardData.language)
    const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
    const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})

    const token = useSelector(state => state.userData.token)
    const benchmarks = useSelector(state => state.userData.benchmarks)
    const userId = useSelector(state => state.userData.user.id)
    const groupId = useSelector(state => state.userData.user.user_group_id)
    const companyId = useSelector(state => state.userData.user.company_id)
    // const archiveResult = useSelector(state => state.dashboardData.archiveResult);

    const {
        actionUserGetAllBenchmarks, actionDashboardSetIdList, actionDashboardSetResultList,
        actionUserSetArchiveLanguage, aDashboardSetLanguage
    } = useActions({
        actionDashboardSetIdList: dashboardSetIdList,
        actionDashboardSetResultList: dashboardSetResultList,
        actionUserGetAllBenchmarks: userGetAllBenchmarks(tlService),
        actionUserSetArchiveLanguage: userSetArchiveLanguage,
        aDashboardSetLanguage: dashboardSetLanguage
    })

    function handleOnChangeLanguage(e) {
        actionUserSetArchiveLanguage(e.target.value)
    }

    const getArchivesDefault = async (language) => {
        setSubmitted(true)
        const archiveSequence = [`[["&user_id","=${userId}"]]`, `[["&user_group_id","=${groupId}"]]`, `[["&company_id","=${companyId}"]]`]
        let res
        for (const archFilters of archiveSequence) {
            try {
                res = await tlService.getFilteredArchive(token, {}, {
                    order_by: 'create_time',
                    order: 1,
                    by_last: 0,
                    exact_result: 2,
                    limit: 15,
                    locale_name: language,
                    return_values: '["id"]',
                    extended_filter: archFilters
                })
            } catch (err) {
                console.log(err)
            }
            if (res?.data?.length) {
                break
            }
        }
        setSubmitted(false)
        if (res?.data?.length) {
            const idListTemp = res.data.map(v => v.id)
            actionDashboardSetIdList(0, idListTemp)
            return
        }
        actionDashboardSetIdList(0, [])
        actionDashboardSetResultList(0, {})
    }

    // on mount
    useEffect(() => {
        // Set benchmark exist when land on page as default:
        benchmarkExist1.current = true
        benchmarkExist2.current = true

        if (isObjectEmpty(benchmarks)) {
            actionUserGetAllBenchmarks(token)
        }
        if (!idList1?.length && !idList0?.length) {
            console.log('setDefaultSet true')
            setDefaultSet(true)
            getArchivesDefault(language)
        } else {
            console.log('setDefaultSet false')
            setDefaultSet(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Call the getFilterarchive everytime language is change:
    useEffect(() => {
        if (language !== archiveLanguage) {
            aDashboardSetLanguage(archiveLanguage)
            getArchivesDefault(archiveLanguage)
        }
    }, [archiveLanguage])


    function deleteList(id) {
        if (idList1?.length && idList0?.length) {
            if (id === 0) {
                actionDashboardSetIdList(0, [...idList1])
                actionDashboardSetIdList(1, [])
                actionDashboardSetResultList(1, {})
            } else {
                actionDashboardSetIdList(1, [])
                actionDashboardSetResultList(1, {})
            }
        } else {
            actionDashboardSetIdList(id, [])
            actionDashboardSetResultList(id, {})
        }
    }

    if (submitted) {
        return <Spinner/>
    }

    const BenchmarkExistFunction1 = () => {
        if (benchmarkExist1.current === false) {
            return <Alert
                className={`m-0`}
                style={{ position: 'relative' }}
                severity="error">
                {t('text-types-does-not-exist')}
            </Alert>
        } else {
            return null
        }
    }

    const BenchmarkExistFunction2 = () => {
        if (benchmarkExist2.current === false) {
            return <Alert
                className={`m-0`}
                style={{ position: 'relative' }}
                severity="error">
                {t('text-types-does-not-exist')}
            </Alert>
        } else {
            return null
        }
    }

    const WrappedPanel = ({ id, width, BenchmarkExistFunction }) => {
        const resultList = useSelector(state => state.dashboardData.resultList[id])
        // Check whethere current benchmark exist or not?:
        if (id === 0) {
            benchmarkExist1.current = deprecatedBenchmarkCheck(benchmarks, resultList)
        }
        if (id === 1) {
            benchmarkExist2.current = deprecatedBenchmarkCheck(benchmarks, resultList)
        }
        const color = id === 0 ? { backgroundColor: '#cee0ff', color: '#00091d' } : {
            backgroundColor: '#fae9c8',
            color: '#efa90b'
        }
        const trashColor = id === 0 ? '#00091d' : '#efa90b'
        const text = t('number of results {{num}}', { num: id === 0 ? idList0.length : idList1.length })
        const link = id === 0 ? '/dashboard/compare/filters/single' : '/dashboard/compare/filters/double'
        return (
            <div style={{ display: 'table-cell', width: width, paddingRight: '30px' }}>
                <div className={style.filterRow}>
                    <div className={style.nameRow}>
                        <div className={style.textWrap} style={color}>
                            <NavLink to={link} style={{ textDecoration: 'none' }}>
                                <p className={`m-0 ${style.text}`}>{text}
                                </p>
                            </NavLink>
                        </div>
                    </div>
                    <div className={style.delRow}>
                        <div onClick={() => deleteList(id)} className={style.textWrap} style={color}>
                            <DeleteIcon style={{ fill: trashColor, width: 22 }}/>
                        </div>
                    </div>
                </div>
                <BenchmarkExistFunction/>
                &nbsp;
                &nbsp;
                &nbsp;
                <ComparePanel panel={id}/>
            </div>
        )
    }

    const LanguageSelector = () => {
        return (
            <div style={{ float: 'right', marginBottom: '10px', marginRight: '30px' }}>
                <Select
                    onChange={handleOnChangeLanguage}
                    labelId="language-select-label"
                    id="language-select"
                    defaultValue={archiveLanguage}
                    style={{ borderRadius: 18, width: '220px' }}
                    variant="outlined"
                    className={`p-0`}
                >
                    {
                        Object.keys(backendLanguages).map(lang =>
                            <MenuItem key={lang} value={lang}>{backendLanguages[lang]['nativeName']}</MenuItem>
                        )
                    }
                </Select>
            </div>
        )
    }

    if (idList1?.length && idList0?.length) {
        return (
            <div className={style.mainCont}>
                <LanguageSelector/>
                <DashHeaderCompare defaultSet={defaultSet}/>
                <AveragePanel
                    benchmarkExist1={benchmarkExist1}
                    benchmarkExist2={benchmarkExist2}
                />
                <div style={{ display: 'table', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <WrappedPanel BenchmarkExistFunction={BenchmarkExistFunction1} id={0} width={'50%'}/>
                    <WrappedPanel BenchmarkExistFunction={BenchmarkExistFunction2} id={1} width={'50%'}/>
                </div>
            </div>
        )
    }

    if (idList0?.length) {
        return (
            <div className={style.mainCont}>
                <LanguageSelector/>
                <DashHeaderCompare defaultSet={defaultSet}/>
                <AveragePanel
                    benchmarkExist1={benchmarkExist1}
                    benchmarkExist2={benchmarkExist2}
                />
                <div style={{ display: 'table', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <WrappedPanel BenchmarkExistFunction={BenchmarkExistFunction1} id={0} width={'100%'}/>
                </div>
            </div>
        )
    }

    return (
        <div className={style.mainCont}>
            <LanguageSelector/>
            <DashHeaderCompare defaultSet={defaultSet}/>
            <div style={{ display: 'table', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={{ display: 'table-cell', textAlign: 'center' }}>
                    {t('empty compare result')}
                </div>
            </div>
        </div>
    )
}
