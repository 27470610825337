import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Paper } from '@material-ui/core'
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { checkUserRight, convertJSONToObject, isObjectEmpty, onlyUnique } from "../../../../utils"
import { useActions } from "../../../../utils/action-helper"
import {
  tableSetData, tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount, tableSetSelectAll, tableSetSelectAllIds
} from "../../../../actions"
import Spinner from "../../../Spinner"
import UniversalTable from "../../../common/UniversalTable"
import TableMassActionComponent from "./TableMassActionComponent/TableMassActionComponent"
import TableInnerComponent from "./TableInnerComponent/TableInnerComponent"
import style from './style.module.sass'
import { Redirect } from "react-router-dom"
import AddNew from "./AddNew"
import UserUploadModal from "./UserUploadModal"

export default function Table() {

  const { t } = useTranslation()
  const tlService = useContext(TLServiceComponent)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const token = useSelector(state => state.userData.token)

  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order || 'asc')
  const orderBy = useSelector(state => state.tableData.orderBy || 0)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const rowCount = useSelector(state => state.tableData.rowCount)
  const selectedCompany = useSelector(state => state.tableData.companyId)
  const selectedGroup = useSelector(state => state.tableData.groupId)
  const user = useSelector(state => state.userData.user)
  const textDisabledColumns = useSelector(state => state.userData.user?.user_options?.disabled_users_columns || '[]')
  const disabledColumns = convertJSONToObject(textDisabledColumns, [])

  const {
    aTableSetDefaults,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
  })

  const [spinner, setSpinner] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)


  const defaultPagination = [
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'user_name',
      type: 'string',
      disablePadding: false,
      label: `${t('Username')}`,
      filter_value: 'user_name',
      orderBy_value: 'user_name'
    },
    {
      id: 'active',
      type: 'boolean',
      disablePadding: false,
      label: `${t('Active')}`,
      filter_value: 'enabled',
      orderBy_value: 'enabled'
    },
    {
      id: 'last_name',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_user_last-name')}`,
      filter_value: 'last_name',
      orderBy_value: 'last_name'
    },
    {
      id: 'company_name',
      type: 'string',
      disablePadding: false,
      label: `${t('Company')}`,
      filter_value: 'company_id',
      orderBy_value: 'company_id'
    },
    {
      id: 'user_group_name',
      type: 'string',
      disablePadding: false,
      label: `${t('Group')}`,
      filter_value: 'user_group_id',
      orderBy_value: 'user_group_id'
    },
    {
      id: 'create_time',
      type: 'date',
      disablePadding: false,
      label: `${t('admin_user_create-time')}`,
      filter_value: 'create_time',
      orderBy_value: 'create_time'
    },
    {
      id: 'expired_time',
      type: 'date',
      disablePadding: false,
      label: `${t('admin_user_expired-time')}`,
      filter_value: 'expired_time',
      orderBy_value: 'options.expired_time'
    }
  ]

  const extraSettings = {
    language: archiveLanguage,
    fullSearchFilter: [['|id', '={value}'], ['|user_name', 'lc=%{value}%'], ['|first_name', 'lc=%{value}%'], ['|last_name', 'lc=%{value}%'], ['|email', 'lc=%{value}%']],
  }

  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, [], disabledColumns, defaultColumns, 'asc', 'id')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveLanguage])

  
  const runRequest = async (limit, requestLimits, requestFilters) => {
    // make a request
    try {
      const res = await tlService.getFilteredUsers(token,requestLimits, requestFilters)
      if (res?.data?.length) {
        const currentData = res.data.map(d => {
          return { ...d, opened: false, active: !!d.enabled , expired_time: d.user_options?.expired_time ? d.user_options.expired_time : ""}
        })
        aTableSetData(currentData)
        aTableSetOtherData({ users_fullcount: res.fullcount})
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.fullcount / limit))
        }
      }
      return true
    } catch (e) {
      console.log(e)
    }
    aTableSetData([])
    aTableSetOtherData({})
    aTableSetRowCount(0)
  }

  const prepareRequest = ( limit, order, orderBy, currentPage, filters) => {
    const requestLimits = {
      limit,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy ? orderBy : 'id',
      offset: limit * (currentPage - 1)
    }
    //filters
    let extendedFilter = []
    for (const fil of Object.values(filters)) {
      if (fil !== undefined && !isObjectEmpty(fil.filter)) {
        extendedFilter = [...extendedFilter, ...fil.filter]
      }
    }
    const filtersToKeep = extendedFilter.filter(f => f[0] === '|user_name' || f[0] === '|last_name' || f[0] === '|id' || f[0] === '|first_name' || f[0] === '|email')
    const requestFilters = {
      extended_filter: JSON.stringify(filtersToKeep)
    }
    return { requestLimits, requestFilters }
  }


  const makeRequest = async ( limit, order, orderBy, currentPage, filters) => {
    const {
      requestLimits,
      requestFilters,
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    await runRequest(limit, requestLimits, requestFilters)
  }

  const getAllIds = async ( fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredUsers(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  
    return ids.filter(onlyUnique) // remove duplicates
  }

  function reloadAll( limit, order, orderBy, currentPage, filters, setSpinner) {
    setSpinner(true)
    makeRequest( limit, order, orderBy, currentPage, filters).then(() => setSpinner(false))
  }

  // filter if change
  useEffect(() => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, selectedCompany,selectedGroup])

  const reloadPage = () => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
  }

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = rowCount || 0
    if (!selectAll) {
      const allIds = await getAllIds(fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  if (!checkUserRight(user, [101, 102, 103, 104, 105, 201, 202, 203, 204, 205]))
    return <Redirect to={"/"} />

  return (
      <div className={`${style.mainCont}`}>
        <Paper className={`mb-5`}>
          {spinner && <Spinner />}
          <UserUploadModal reloadPage={reloadPage} open={uploadModal} setOpen={setUploadModal}/>
        <div style={{ float: 'right', marginBottom: '10px', marginRight: '8px' }}>
          {checkUserRight(user, [102, 202]) &&
            <AddNew enableDownloadButton setUploadModal={setUploadModal} />
          }
        </div>
          <UniversalTable 
            defaultColumns={defaultColumns}
            reloadAll={reloadAll}
            handleSelectAll={handleSelectAll}
            getAllIds={getAllIds}
            noDataText={t('admin_user_no-data')}
            selectAllText={t('admin_user_select-all-users')}
            MassActionComponent={TableMassActionComponent}
            InnerComponent={TableInnerComponent}
            extraSettings={extraSettings}
            style={style}
            type='users'
          />
        </Paper>
      </div>
  )
}