import mhtDocumentTemplate from './templates/mht_document'
import mhtPartTemplate from './templates/mht_part'

export function wrapTpl (text, obj) {
  for (let key of Object.keys(obj)) {
    if (toString.call(obj[key]) === '[object Object]') {
      for (let keyInternal of Object.keys(obj[key])) {
        const re = new RegExp(`<%= ${key}\\.${keyInternal} %>`, 'g')
        text = text.replaceAll(re, obj[key][keyInternal])
      }
    } else {
      const re = new RegExp(`<%= ${key} %>`, 'g')
      text = text.replaceAll(re, obj[key])
    }
  }
  return text
}

export function getMHTdocument (htmlSource) {
  var imageContentParts;
  // take care of images
  ({ htmlSource, imageContentParts } = _prepareImageParts(htmlSource))
  // for proper MHT parsing all '=' signs in html need to be replaced with '=3D'
  htmlSource = htmlSource.replace(/=/g, '=3D')
  return wrapTpl(mhtDocumentTemplate, {
    htmlSource,
    contentParts: imageContentParts.join('\n'),
  })
}

export function _prepareImageParts (htmlSource) {
  var imageContentParts, inlinedReplacer, inlinedSrcPattern
  imageContentParts = []
  inlinedSrcPattern = /"data:(\w+\/\w+);(\w+),(\S+)"/g
  // replacer function for images sources via DATA URI
  inlinedReplacer = function (
    match, contentType, contentEncoding, encodedContent) {
    var contentLocation, extension, index
    index = imageContentParts.length
    extension = contentType.split('/')[1]
    contentLocation = `file:///C:/fake/image${index}.${extension}`
    imageContentParts.push(wrapTpl(mhtPartTemplate,
      { contentType, contentEncoding, contentLocation, encodedContent }))
    return `\"${contentLocation}\"`
  }
  if (typeof htmlSource === 'string') {
    if (!/<img/g.test(htmlSource)) {
      return { htmlSource, imageContentParts }
    }
    htmlSource = htmlSource.replace(inlinedSrcPattern, inlinedReplacer)
    return { htmlSource, imageContentParts }
  } else {
    throw new Error('Not a valid source provided!')
  }
}
