import React, {useState} from "react";
import style from "./style.module.sass";
import arrow from "./assets/arrow.svg";
import {NavLink} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";
import saveIcon from "./assets/saveIcon.svg";
import {useFormik} from 'formik';
import * as yup from 'yup';
import TableWithThreeCols from "./TableWithThreeCols";
import Logs from "./Logs";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";

//state validation by YUP
const validationSchema = yup.object();

export default function InfoComponent({page}) {
  const {theme: {bgColors, colors}} = useTheme()
  const {t} = useTranslation();

  const formik = useFormik({
    initialValues: {
      bestPrRows: [
        {
          id: 112333432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>,
            <p className={`mt-3 m-0`}>
              Better: <br/>
              For a nested sentence, you need to add a lot of information. You should also present it in a complex way.
              This
              will make it quite hard for your reader to follow you.”
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>,
            <p className={`mt-3 m-0`}>
              Лучше: <br/>
              Для вложенного предложения нужно добавить много информации. Вы также должны представить это комплексно.
              Вашему читателю будет довольно сложно следить за вами.”
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>,
            <p className={`mt-3 m-0`}>
              Besser: <br/>
              Für einen verschachtelten Satz müssen Sie viele Informationen hinzufügen. Sie sollten es auch auf komplexe
              Weise präsentieren. Dies wird es Ihrem Leser ziemlich schwer machen, Ihnen zu folgen. ”
            </p>
          ],
          rowNameEng: ">2 clauses per sentence",
          rowNameRus: "2 предложения в сложносочиненном предложении",
          rowNameDeu: "2 Satzteile pro Satz", langType: "Eng"
        },
        {
          id: 134543432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>,
            <p className={`mt-3 m-0`}>
              Better: <br/>
              For a nested sentence, you need to add a lot of information. You should also present it in a complex way.
              This
              will make it quite hard for your reader to follow you.”
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>,
            <p className={`mt-3 m-0`}>
              Лучше: <br/>
              Для вложенного предложения нужно добавить много информации. Вы также должны представить это комплексно.
              Вашему читателю будет довольно сложно следить за вами.”
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>,
            <p className={`mt-3 m-0`}>
              Besser: <br/>
              Für einen verschachtelten Satz müssen Sie viele Informationen hinzufügen. Sie sollten es auch auf komplexe
              Weise präsentieren. Dies wird es Ihrem Leser ziemlich schwer machen, Ihnen zu folgen. ”
            </p>
          ],
          rowNameEng: ">2 clauses per sentence",
          rowNameRus: "2 предложения в сложносочиненном предложении",
          rowNameDeu: "2 Satzteile pro Satz", langType: "Eng"
        },
        {
          id: 115673432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>,
            <p className={`mt-3 m-0`}>
              Better: <br/>
              For a nested sentence, you need to add a lot of information. You should also present it in a complex way.
              This
              will make it quite hard for your reader to follow you.”
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>,
            <p className={`mt-3 m-0`}>
              Лучше: <br/>
              Для вложенного предложения нужно добавить много информации. Вы также должны представить это комплексно.
              Вашему читателю будет довольно сложно следить за вами.”
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>,
            <p className={`mt-3 m-0`}>
              Besser: <br/>
              Für einen verschachtelten Satz müssen Sie viele Informationen hinzufügen. Sie sollten es auch auf komplexe
              Weise präsentieren. Dies wird es Ihrem Leser ziemlich schwer machen, Ihnen zu folgen. ”
            </p>
          ],
          rowNameEng: ">2 clauses per sentence",
          rowNameRus: "2 предложения в сложносочиненном предложении",
          rowNameDeu: "2 Satzteile pro Satz", langType: "Eng"
        },
        {
          id: 117685632,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>,
            <p className={`mt-3 m-0`}>
              Better: <br/>
              For a nested sentence, you need to add a lot of information. You should also present it in a complex way.
              This
              will make it quite hard for your reader to follow you.”
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>,
            <p className={`mt-3 m-0`}>
              Лучше: <br/>
              Для вложенного предложения нужно добавить много информации. Вы также должны представить это комплексно.
              Вашему читателю будет довольно сложно следить за вами.”
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>,
            <p className={`mt-3 m-0`}>
              Besser: <br/>
              Für einen verschachtelten Satz müssen Sie viele Informationen hinzufügen. Sie sollten es auch auf komplexe
              Weise präsentieren. Dies wird es Ihrem Leser ziemlich schwer machen, Ihnen zu folgen. ”
            </p>
          ],
          rowNameEng: ">2 clauses per sentence",
          rowNameRus: "2 предложения в сложносочиненном предложении",
          rowNameDeu: "2 Satzteile pro Satz", langType: "Eng"
        },
        {
          id: 111209432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>,
            <p className={`mt-3 m-0`}>
              Better: <br/>
              For a nested sentence, you need to add a lot of information. You should also present it in a complex way.
              This
              will make it quite hard for your reader to follow you.”
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>,
            <p className={`mt-3 m-0`}>
              Лучше: <br/>
              Для вложенного предложения нужно добавить много информации. Вы также должны представить это комплексно.
              Вашему читателю будет довольно сложно следить за вами.”
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>,
            <p className={`mt-3 m-0`}>
              Besser: <br/>
              Für einen verschachtelten Satz müssen Sie viele Informationen hinzufügen. Sie sollten es auch auf komplexe
              Weise präsentieren. Dies wird es Ihrem Leser ziemlich schwer machen, Ihnen zu folgen. ”
            </p>
          ],
          rowNameEng: ">2 clauses per sentence",
          rowNameRus: "2 предложения в сложносочиненном предложении",
          rowNameDeu: "2 Satzteile pro Satz", langType: "Eng"
        },
      ],

      editorMessage: [
        {
          id: 112333432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>,
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>
          ],
          rowNameEng: "Header message",
          rowNameRus: "Заголовок сообщения",
          rowNameDeu: "Header-Nachricht",
          langType: "Eng"
        },
        {
          id: 112312323432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>
          ],
          rowNameEng: "Footer message",
          rowNameRus: "Сообщение нижнего колонтитула",
          rowNameDeu: "Fußzeilennachricht", langType: "Eng"
        },
      ],

      infoBanner: [
        {
          id: 112333432,
          tagEng: [
            <p className={`m-0`}>
              Instead of: <br/>
              To build a nested sentence, you need to make sure to always add a lot of information, presented in a
              complex
              way, to make it hard for your reader to follow your thoughts.
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Вместо: <br/>
              Чтобы построить вложенное предложение, вам нужно всегда добавлять много информации, представленной в
              сложной форме, чтобы вашему читателю было трудно следовать вашим мыслям.
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Statt: <br/>
              Um einen verschachtelten Satz zu erstellen, müssen Sie sicherstellen, dass Sie immer viele Informationen
              hinzufügen, die auf komplexe Weise dargestellt werden, damit Ihr Leser Ihren Gedanken nur schwer folgen
              kann.
            </p>
          ],
          rowNameEng: "Info banner",
          rowNameRus: "информационный баннер",
          rowNameDeu: "Infobanner",
          langType: "Eng"
        },
      ],

      infoMessage: [
        {
          id: 112333432,
          tagEng: [
            <p className={`m-0`}>
              Abbreviations make it harder to read and understand your text.
            </p>,
            <p className={`m-0 mt-3`}>
              Tip: Try to use abbreviations sparingly. If you need to use lesser known abbreviations, explain them to
              your
              readers.
            </p>
          ],
          tagRus: [
            <p className={`m-0`}>
              Сокращения затрудняют чтение и понимание вашего текста.
            </p>,
            <p className={`m-0 mt-3`}>
              Совет: старайтесь экономно использовать сокращения. Если вам нужно использовать менее известные
              сокращения, объясните их
              ваш
              читатели.
            </p>
          ],
          tagDeu: [
            <p className={`m-0`}>
              Abkürzungen erschweren das Lesen und Verstehen Ihres Textes.
            </p>,
            <p className={`mt-3 m-0`}>
              Tipp: Verwenden Sie Abkürzungen sparsam. Wenn Sie weniger bekannte Abkürzungen verwenden müssen, erklären
              Sie diese
              Ihre
              Leser.
            </p>
          ],
          rowNameEng: "Abbreviations",
          rowNameRus: "Сокращения",
          rowNameDeu: "Abkürzungen", langType: "Eng"
        }
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const selectVariants = [
    '',
    'GLOBAL',
    'Mega Company',
    'BestInsurence',
    'OptiBank: Marketing',
    'OptiBank: Management',
    'PowerDevs',
  ];

  const [selectedGroup, setSelectedGroup] = useState(selectVariants[0]);
  return (
    <form className={`pb-5 ${style.mainCont}`} onSubmit={formik.handleSubmit}>
      <NavLink className={`${style.breadcrumb}`} to="/administration" exact={true}>
        <Button color="primary" variant="contained">
          <img src={arrow} className={`mr-3`} alt=""/>
          {t('admin-overview')}
        </Button>
      </NavLink>
      <div className={`mt-5`}>
        <div className={`d-flex align-items-center justify-content-between mb-3`}>
          <h3 style={{color: colors.colorPrimary1000}} className={`${style.title} d-flex align-items-center`}>
            {t('info')}
          </h3>
          <div className={`d-flex align-items-center`}>
            {
              <Autocomplete
                value={selectedGroup}
                onChange={(e, newValue) => setSelectedGroup(newValue)}
                id="combo-box-demo"
                options={selectVariants}
                className={`d-flex align-items-center mr-3`}
                style={{width: 300, backgroundColor: "white"}}
                size="small"
                renderInput={(params) => <TextField {...params} label="user specific view"
                                                    variant="outlined"/>}
              />
            }
            <Button type="submit" variant={"contained"} color={"primary"}
                    style={{height: "39px"}}
                    className={`mr-2`}>
              <img src={saveIcon} className={`mr-2`} alt=""/>  {t('save-changes')}
            </Button>
          </div>
        </div>
        <div className={`pl-0 mb-4 pb-3 pr-0 col-12 ${style.borderTop}`}>
          <div className={`d-flex align-items-center ${style.tabs}`}>
            <NavLink to={`/administration/info/best-practice`} activeStyle={{color: colors.colorPrimary1000}}
                     style={{color: colors.colorPrimary400}} activeClassName={`${style.activeTab}`}
                     className={`${style.tab}`}>{t('best-practices')}</NavLink>
            <NavLink to={`/administration/info/editor-messages`} activeStyle={{color: colors.colorPrimary1000}}
                     style={{color: colors.colorPrimary400}} activeClassName={`${style.activeTab}`}
                     className={`${style.tab}`}>{t('editor-messages')}</NavLink>
            <NavLink to={`/administration/info/info-messages`} activeStyle={{color: colors.colorPrimary1000}}
                     style={{color: colors.colorPrimary400}} activeClassName={`${style.activeTab}`}
                     className={`${style.tab}`}>{t('info-messages')}</NavLink>
            <NavLink to={`/administration/info/logs`} activeStyle={{color: colors.colorPrimary1000}}
                     style={{color: colors.colorPrimary400}} activeClassName={`${style.activeTab}`}
                     className={`${style.tab}`}>{t('logs')}</NavLink>
          </div>
          <div className={`mt-4`}>
            {page === "best-practice" &&
            <TableWithThreeCols title={"Best practice tips"}
                                text={null}
                                rows={formik.values.bestPrRows}
                                name={"bestPrRows"}
                                formik={formik}
                                headText={"BEST PRACTICE TIP"}
                                withFooter={true}
                                headTitle={"PARAMETER"}/>}
            {page === "editor-messages" &&
            <TableWithThreeCols title={"Header & Footer"}
                                text={"You can enter a message here at top or bottom of the editor pane. It will be displayed every time your users use the text type letters"}
                                rows={formik.values.editorMessage}
                                name={"editorMessage"}
                                infoBanner={formik.values.infoBanner}
                                formik={formik}
                                withFooter={false}
                                headTitle={""}/>}
            {page === "info-messages" &&
            <TableWithThreeCols title={"Info Messages"}
                                text={"Leave some general writing tips for your group here. "}
                                rows={formik.values.infoMessage}
                                name={"infoMessage"}
                                formik={formik}
                                withFooter={false}
                                headTitle={"PARAMETER"} headText={"DESCRIPTION"}/>}
            {page === "logs" &&
            <Logs/>}
          </div>
        </div>
        <div className={`${style.saveChangesBtn} mb-5`}>
          <Button type="submit" variant={"contained"} color={"primary"}
                  style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
                  className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
          </Button>
        </div>
      </div>
    </form>
  )
}