export const batchProcessingStart = (data) => {
  return {
    type: 'BATCH_PROCESSING_START',
    payload: data
  }
}
export const batchProcessingPercent = (data) => {
  return {
    type: 'BATCH_PROCESSING_PERCENT',
    payload: data
  }
}
export const batchProcessingProgress = (data) => {
  return {
    type: 'BATCH_PROCESSING_PROGRESS',
    payload: data
  }
}

export const batchProcessingUpdateError = (data) => {
  return {
    type: 'BATCH_PROCESSING_UPDATE_ERROR',
    payload: data
  }
}

export const batchProcessingDone = (data) => {
  return {
    type: 'BATCH_PROCESSING_DONE',
    payload: data
  }
}

export const batchProcessingClear = () => {
  return {
    type: 'BATCH_PROCESSING_CLEAR'
  }
}

export const batchProcessingError = (data) => {
  return {
    type: 'BATCH_PROCESSING_ERROR',
    payload: data
  }
}

export const batchProcessingSetPaused = (data) => {
  return {
    type: 'BATCH_PROCESSING_SET_PAUSED',
    payload: data
  }
}