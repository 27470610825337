import React, {useState} from "react";
import style from "./style.module.scss"
import DashHeader from "../Header";
import {useTheme} from "@material-ui/core/styles";
import {Button, Collapse} from "@material-ui/core";
import {Add, Remove} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import config from "../../../config";
import {convertLanguagesToShort, countTermsWithPositions, isObjectEmpty, roundWithPrecision} from "../../../utils";
import {Redirect} from "react-router";

export default function TonalityComponent() {
    const {theme: {bgColors, colors}} = useTheme();

    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const archiveName = useSelector(state => state.analyseData.archives?.name);
    const benchmark = useSelector(state => state.analyseData.result.benchmark);

    const resultData = useSelector(state => state.analyseData.result.resultData);
    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);

    if (resultTemplate < 1)
        return <Redirect to={"/"}/>;

    const tonalityRows = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {
        if (!isObjectEmpty(resultData[key])) {
            const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1;
            const blocks = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep);
            const exactlyActual = resultData[key];
            const actualInRange = blocks.findIndex(val => exactlyActual <= val);
            const firstPartChart = val.firstPartChart.map((chartVal, index) => {
                return {
                    id: index,
                    name: t(chartVal.name),
                    value: resultData[chartVal.countBase] > 0 ? (countTermsWithPositions(resultData[chartVal.value], chartVal.type) * 100 / resultData[chartVal.countBase]) : 0
                }
            })
            const secondPartChart = val.secondPartChart.map((chartVal, index) => {
                return {
                    id: index,
                    name: t(chartVal.name),
                    value: resultData[chartVal.countBase] > 0 ? (countTermsWithPositions(resultData[chartVal.value], chartVal.type) * 100 / resultData[chartVal.countBase]) : 0
                }
            })
            return [
                ...acc,
                {
                    appearFirst: t(val.appearFirst),
                    appearSecond: t(val.appearSecond),
                    explanation: t(val.explanation),
                    firstPartChart,
                    secondPartChart,
                    name: key,
                    blocks,
                    leftTitle: t(val.scaleFrom[1]),
                    rightTitle: t(val.scaleTo[1]),
                    activeCell: blocks[actualInRange],
                }
            ]

        }
        return acc;
    }, []);
    console.log("tonalityRows", tonalityRows)

    return (
        <div className={style.mainCont}>
            <DashHeader archiveName={archiveName} benchmarkName={benchmark['name'][convertLanguagesToShort(i18nLanguage)]} />
            <div style={{backgroundColor: bgColors.bgPrimary0}} className={style.cells}>
                <h2 style={{color: colors.colorPrimary400}}>{t("Tonality")}</h2>
                <h3>{t("Dashboard Your text works")}</h3>
                <div>
                    {tonalityRows.map((r, i) => <CreateChart key={i} data={r}/>)}
                </div>
            </div>
        </div>
    )
};

const RangerBlocks = ({activeCell, blocks}) => (
    <div className={`${style.rangerBlocks}`}>
        {blocks.map((i) => <div key={i} className={`${style.rangerBlock} ${activeCell === i && style.activeBlock}`}/>)}
    </div>
)

const LinearChart = ({name, value}) => (
    <div className={style.linearChart}>
        <p>{name}</p>
        <div className={style.chart}>
            <div style={{width: `${value}%`}}/>
        </div>
    </div>
)


const CreateChart = ({data: {rightTitle, leftTitle, activeCell, blocks, firstPartChart, secondPartChart, appearFirst,
    appearSecond,  explanation}}) => {
    const [open, setOpen] = useState(false)
    const {theme: {bgColors, colors}} = useTheme()

    return (
        <div className={style.row} >
            <div className={style.head}>
                <p style={{color: colors.colorBlue1000}} className={`m-0 text-left`}>{leftTitle}</p>
                <RangerBlocks activeCell={activeCell} blocks={blocks}/>
                <div style={{justifySelf: "flex-end"}} className={`d-flex align-items-center`}>
                    <p style={{color: colors.colorBlue1000}} className={`mr-4 m-0 text-right`}>{rightTitle}</p>
                    <Button onClick={() => setOpen(!open)}
                            style={{backgroundColor: bgColors.bgPrimary400, minWidth: "34px"}}
                            className={`p-0 pt-1 pb-1`}>
                        {open ?
                            <Remove style={{fill: colors.colorPrimary0}}/> :
                            <Add style={{fill: colors.colorPrimary0}}/>}
                    </Button>
                </div>
            </div>
            <Collapse in={open}>
                <div className={style.desc} style={{backgroundColor: bgColors.bgPrimary100}}>
                    <div className={style.charts}>
                        <div className={style.firstPart}>
                            <p dangerouslySetInnerHTML={{"__html": appearFirst}}/>
                            {firstPartChart.map(c => <LinearChart value={c.value} name={c.name} key={c.id}/>)}
                        </div>
                        <div className={style.divider}/>
                        <div className={style.secondPart}>
                            <p dangerouslySetInnerHTML={{"__html": appearSecond}}/>
                            {secondPartChart.map(c => <LinearChart value={c.value} name={c.name} key={c.id}/>)}
                        </div>
                    </div>
                    <p className={style.explanation}  dangerouslySetInnerHTML={{"__html": explanation}}/>
                </div>
            </Collapse>
        </div>
    )
}
