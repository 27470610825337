export const excelModalData = (data) => {
  return {
    type: 'EXCEL_DATA',
    payload: data
  }
}
export const archiveSetFolderForceReload = (data) => {
  return {
    type: 'ARCHIVE_SET_FORCE_RELOAD',
    payload: data,
  }
}
export const archiveSetFolderId = (data) => {
  return {
    type: 'ARCHIVE_SET_FOLDER_ID',
    payload: data,
  }
}

export const archiveSetFolderName = (data) => {
  return {
    type: 'ARCHIVE_SET_FOLDER_NAME',
    payload: data,
  }
}

export const archiveSetArchiveType = (data) => {
  return {
    type: 'ARCHIVE_SET_ARCHIVE_TYPE',
    payload: data,
  }
}
export const archiveSetPredefined = (data) => {
  return {
    type: 'ARCHIVE_SET_PREDEFINED',
    payload: data,
  }
}
export const archiveSetSelectedFolder = (data) => {
  return {
    type: 'ARCHIVE_SET_SELECTED_FOLDER',
    payload: data,
  }
}

export const archiveSetRootCount = (tlService) => (type, token, id, locale_name = undefined) => (dispatch) => {
  dispatch({
    type: 'ARCHIVE_SET_ROOT_COUNT_REQUEST',
    payload: type + 'RootCount'
  })
  let filter = {
    exact_result: 2,
    locale_name,
    extended_filter: '[["|folder_id","="],["|folder_id","=0"]]',
    return_values: '["id"]'
  }
  switch (type) {
    case 'personal':
      filter['user_id'] = id
      break
    case 'group':
      filter['user_group_id'] = id
      break
    case 'company':
      filter['company_id'] = id
      break
  }
  tlService.getFilteredArchive(token, { limit: 1 }, filter)
    .then((data) => {
      dispatch({
        type: 'ARCHIVE_SET_ROOT_COUNT_RESPONSE',
        payload: { data: data?.archives_fullcount ? data.archives_fullcount : 0, type: type + 'RootCount' }
      })
    })
    .catch(() => dispatch({
      type: 'ARCHIVE_SET_ROOT_COUNT_RESPONSE',
      payload: { data: 0, type: type + 'RootCount' }
    }))
}

export const archiveArchiveFolders = (tlService) => (type, token, id, count_archives = 0, locale_name = undefined) =>
  (dispatch) => {
    dispatch({
      type: 'ARCHIVE_SET_FOLDERS_REQUEST',
      payload: type
    })
    let filter = { count_archives, order_by: 'folder_name', order: 0, count_subfolders: 1, locale_name }
    switch (type) {
      case 'personal':
        filter['user_id'] = id
        break
      case 'group':
        filter['user_group_id'] = id
        break
      case 'company':
        filter['company_id'] = id
        break
    }
    tlService.getFilteredArchiveFolders(token, {}, filter)
      .then((data) => {
        dispatch({ type: 'ARCHIVE_SET_ROOT_COUNT_RESPONSE', payload: { type, data } })
      })
      .catch(() => dispatch({ type: 'ARCHIVE_SET_ROOT_COUNT_RESPONSE', payload: { type, data: [] } }))
  }
