import MainPageWrapper from "../../components/Main-page-wrapper";
import React from "react";
import CLIXComponent from "../../components/Dashboard/CLIX";
import {useRouteMatch} from "react-router-dom";
import CLIXComponentCompare from "../../components/Dashboard/CLIX/compare";

export default function CLIX(){
  const {params: {type}} = useRouteMatch();

  return (
    <MainPageWrapper>
    {type === 'compare' ? <CLIXComponentCompare/> :<CLIXComponent/>}
    </MainPageWrapper>
  )
}
