import React from 'react';
import style from "../withBigRow.module.sass";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {useTheme} from '@material-ui/core/styles';
import {Button} from "@material-ui/core";

function Rows({row, isItemSelected, labelId, isEven, formik, index, name}) {
  const {theme: {bgColors}} = useTheme();

  return (
    <React.Fragment>
      <TableRow
        style={{backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100}}
        hover
        role="checkbox"
        className={`${style.row}`}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={`${style.cell} pl-4`}
                   style={{cursor: "pointer"}} component="th"
                   id={labelId} scope="row" padding="none">
          {formik.values[name][index].langType === "Eng" && row.rowNameEng}
          {formik.values[name][index].langType === "Rus" && row.rowNameRus}
          {formik.values[name][index].langType === "Deu" && row.rowNameDeu}
        </TableCell>
        <TableCell className={style.cell} align="left">
          <div className={`${style.bigCell}`}>
            {formik.values[name][index].langType === "Eng" && row.tagEng.map((b, i) => <div key={i}>{b}</div>)}
            {formik.values[name][index].langType === "Rus" && row.tagRus.map((b, i) => <div key={i}>{b}</div>)}
            {formik.values[name][index].langType === "Deu" && row.tagDeu.map((b, i) => <div key={i}>{b}</div>)}
          </div>
        </TableCell>
        <TableCell className={`${style.cell} d-flex flex-column justify-content-center pr-5`} align="right">
          <div style={{height: "80px", gridGap: "5px"}} className={`d-flex flex-column justify-content-between`}>
            {formik.values[name][index].langType !== "Eng" &&

            <Button onClick={() => formik.setFieldValue(`${name}[${index}].langType`, "Eng")} className={`pr-0 pl-0`}
                    color="primary" variant="contained">
              ENG
            </Button>}
            {formik.values[name][index].langType !== "Rus" &&
            <Button onClick={() => formik.setFieldValue(`${name}[${index}].langType`, "Rus")}
                    className={`pr-0 pl-0`} color="primary" variant="contained">
              RUS
            </Button>}
            {formik.values[name][index].langType !== "Deu" &&
            <Button onClick={() => formik.setFieldValue(`${name}[${index}].langType`, "Deu")}
                    className={`pr-0 pl-0`} color="primary" variant="contained">
              DEU
            </Button>}

          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Rows;
