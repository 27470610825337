import style from './style.module.sass'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Modal, TextField, Collapse } from '@material-ui/core'
import closeIcon from '../../../assets/close-circle.svg'
import { Col } from 'reactstrap'
import FormControl from '@material-ui/core/FormControl'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { Alert } from '@material-ui/lab'
import {
  analyseSetProgressBar
} from '../../../actions'
import { convertFromErrorObject, onlyUnique, removeTagMark, returnValuesFilter } from '../../../utils'
import ShowKeywords from './ShowKeywords.jsx'
import { useActions } from '../../../utils/action-helper'

const WordSaveNewVersion = ({ setOpen, modalState, setModalState }) => {
  const { theme: { bgColors } } = useTheme()
  const { t } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const saveArchive = useSelector(state => state.analyseData.saveArchive)
  const folderId = useSelector(state => state.analyseData.folderId)
  const analyseText = useSelector(state => state.analyseData.text)
  const analyseResult = useSelector(state => state.analyseData.result)
  const returnValues = useSelector(state => state.openData.return_values)

  const {
    aAnalyseSetProgressBar
  } = useActions({
    aAnalyseSetProgressBar: analyseSetProgressBar
  })

  const [version, setVersion] = useState('')
  const [localTags, setLocalTags] = useState(saveArchive?.tags?.join(', ') || '')
  const [errorState, setErrorState] = useState()

  useEffect(() => {
    if (Number.isInteger(parseInt(saveArchive?.version))) {
      setVersion(parseInt(saveArchive?.version) + 1)
    } else {
      setVersion('')
    }
  }, [saveArchive?.version])

  const postArchiveVersion = async () => {
    let resultIs = returnValuesFilter(analyseResult.resultData, returnValues)
    const splittedTags = localTags.split(',')
      .map(v => v.trim()).filter(v => v?.length).filter(onlyUnique).sort()
    try {
      if (saveArchive?.id) {
        await tlService.addArchivesVersion(token, saveArchive.id, String(version), parseInt(analyseResult.resultTemplate),
          removeTagMark(analyseText), JSON.stringify(resultIs), {}, 0, 0, folderId, splittedTags)
        aAnalyseSetProgressBar({ type: saveArchive.type, folder: saveArchive.folder })
        setModalState(false)
      }
    } catch (e) {
      console.log('error', e)
      if (e.message) {
        setErrorState(convertFromErrorObject(t, e))
      } else {
        setErrorState(t('errorMessage'))
      }
    }
  }

  return (
    <Modal open={modalState} onClose={() => setOpen('')}>
      <div className={`${style.modalMainContForVersion} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
             style={{ backgroundColor: bgColors.bgPrimary400 }}>
          <p className={`m-0 ${style.title}`}>{t('Save As New Version')}</p>
          <button className={`border-0 bg-transparent`} onClick={() => setOpen('')}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          <Col sm="12" className={'d-flex align-items-center pr-5 pl-5'} style={{ minHeight: 200 }}>
            <FormControl className={'d-flex py-2'} style={{ gridGap: 10 }} component="fieldset">
              <TextField onChange={(e) => setVersion(e.target.value)} label={t('Version')} type="text"
                         variant="outlined" size="small" value={version}/>
              <TextField label={localTags?.length === 0 && t('Keywords')} id="keywords" type="text" variant="outlined"
                         size="small"
                         onChange={(e) => setLocalTags(e.target.value)}
                         autoComplete="off"
                         value={!!localTags ? localTags : ''}
                         placeholder={t('keywords')}
              />
              <ShowKeywords keywordsString={localTags}/>
            </FormControl>
          </Col>
          <br/>
          <div className={`d-flex justify-content-center`}>
            <Button color="primary" variant="contained" onClick={postArchiveVersion}>
              {t('Export archive button')}
            </Button>
          </div>
          <br/>
          <div className={style.error}>
            <Collapse in={!!errorState}>
              <Alert
                className={`m-0`}
                severity="error">
                <p className={`m-0`}>{errorState}</p>
              </Alert>
            </Collapse>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WordSaveNewVersion