var _ = require("underscore");

var docxReader = require("./docx/docx-reader");
var docxStyleMap = require("./docx/style-map");
var DocumentConverter = require("./document-to-html").DocumentConverter;
var convertElementToRawText = require("./raw-text").convertElementToRawText;
var readStyle = require("./style-reader").readStyle;
var readOptions = require("./options-reader").readOptions;
var unzip = require("./unzip");
var Result = require("./results").Result;



function convertToHtml(input, options) {
    return convert(input, options);
}

function convertToMarkdown(input, options) {
    var markdownOptions = Object.create(options || {});
    markdownOptions.outputFormat = "markdown";
    return convert(input, markdownOptions);
}

function convertChunk(chunk){
    if (!chunk?.length){
        return '';
    }

    const headerEnd = chunk.indexOf("\n\n")
    const header = chunk.substring(0,headerEnd)

    if (!header.match(/boundary="(.+)"/)){
        return '';
    }
    const boundary = "--" + header.match(/boundary="(.+)"/)[1]
    chunk = chunk.substring(headerEnd);
    let returns = '';
    const parts = chunk.split(boundary);
    for (let i of parts){
        const prt = i.trim();
        const partHeaderEnd = prt.indexOf("\n\n")
        if (partHeaderEnd > -1){
            const partHeader = prt.substring(0,partHeaderEnd)

            if (partHeader.match(/Content-Type: text\/html/)){
                returns = prt.substring(partHeaderEnd).trim()
            } else if(partHeader.match(/Content-Type:/)) {
                const filename = partHeader.match(/Content-Location: ([^\n]+)/)[1]
                const contentType = partHeader.match(/Content-Type: ([^\n]+)/)[1]
                const content = prt.substring(partHeaderEnd).trim()
                returns = returns.replaceAll(filename, `data:${contentType};base64,${content}`)
            }
        }

    }
    return returns
}

function convert(input, options) {
    options = readOptions(options);

    return unzip.openZip(input)
        .tap(function(docxFile) {
            return docxStyleMap.readStyleMap(docxFile).then(function(styleMap) {
                options.embeddedStyleMap = styleMap;
            });
        })
        .tap(function(docxFile) {
            return docxStyleMap.readAfChunk(docxFile).then(function(chunk) {
                options.embeddedChunk = convertChunk(chunk);
            });
        })
        .then(function(docxFile) {
            return docxReader.read(docxFile, input)
                .then(function(documentResult) {
                    return documentResult.map(options.transformDocument);
                })
                .then(function(documentResult) {
                    return convertDocumentToHtml(documentResult, options);
                });
        });
}

function readEmbeddedStyleMap(input) {
    return unzip.openZip(input)
        .then(docxStyleMap.readStyleMap);
}

function convertDocumentToHtml(documentResult, options) {
    var styleMapResult = parseStyleMap(options.readStyleMap());
    var parsedOptions = _.extend({}, options, {
        embeddedChunk: options.embeddedChunk,
        styleMap: styleMapResult.value
    });

    var documentConverter = new DocumentConverter(parsedOptions);

    return documentResult.flatMapThen(function(document) {
        return styleMapResult.flatMapThen(function(styleMap) {
            return documentConverter.convertToHtml(document);
        });
    });
}

function parseStyleMap(styleMap) {
    return Result.combine((styleMap || []).map(readStyle))
        .map(function(styleMap) {
            return styleMap.filter(function(styleMapping) {
                return !!styleMapping;
            });
        });
}


function extractRawText(input) {
    return unzip.openZip(input)
        .then(docxReader.read)
        .then(function(documentResult) {
            return documentResult.map(convertElementToRawText);
        });
}

function embedStyleMap(input, styleMap) {
    return unzip.openZip(input)
        .tap(function(docxFile) {
            return docxStyleMap.writeStyleMap(docxFile, styleMap);
        })
        .then(function(docxFile) {
            return docxFile.toArrayBuffer();
        })
        .then(function(arrayBuffer) {
            return {
                toArrayBuffer: function() {
                    return arrayBuffer;
                },
                toBuffer: function() {
                    return Buffer.from(arrayBuffer);
                }
            };
        });
}

const styleMapping = function() {
    throw new Error('Use a raw string instead of mammoth.styleMapping e.g. "p[style-name=\'Title\'] => h1" instead of mammoth.styleMapping("p[style-name=\'Title\'] => h1")');
};

export default {
    convertToHtml,
    convertToMarkdown,
    convert,
    extractRawText,
    embedStyleMap,
    readEmbeddedStyleMap,
    styleMapping,
    images: require("./images"),
    transforms: require("./transforms"),
    underline: require("./underline")
}
