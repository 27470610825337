import ApexCharts from "apexcharts";
import {roundWithPrecision} from "../../utils";

// data for all charts, first is the year in seconds, second is value
export const linearChartData = [
  [1280060700000, 40.95],
  [1285060700000, 65.95],
  [1297964400000, 42.95],
  [1310569200000, 32.28],
  [1351766000000, 54.17],
  [1373058400000, 60.31],
  [1395304800000, 33.13],
  [1429106400000, 20.65],
  [1449106400000, 40.65],
  [1469106400000, 60.65],
  [1499106400000, 70.65],
  [1510106400000, 85.65],
  [1520772000000, 60.69],
  [1529549600000, 62.64],
  [1541314400000, 82.43],
  [1558437600000, 93.41],
  [1569042400000, 82.62],
  [1579820000000, 78.51],
  [1591029600000, 96.36],
  [1602329200000, 70.98],
  [1622415600000, 62.90],
  [1634575600000, 45.30],
  [1647254000000, 28.13],
  [1668895600000, 58.25],
  [1690018800000, 68.51],
  [1711228400000, 88.99],
  [1761919600000, 99.60],
];

export const getColor = (value) =>
  value <= 20 ? "#DD0D08"
    : value <= 40 ? "#E46D19"
    : value <= 60 ? "#EACD2A"
      : value <= 80 ? "#A1BE23"
        : "#43AB1B"


export function updateData(timeline, id, startDate, endDate) {

  switch (timeline) {
    case 'one_month':
      ApexCharts.exec(
        id,
        'zoomX',
        startDate,
        startDate + 7948800000
      )
      break
    case 'six_months':
      ApexCharts.exec(
        id,
        'zoomX',
        startDate,
        startDate + 15897600000
      )
      break
    case 'one_year':
      ApexCharts.exec(
        id,
        'zoomX',
        startDate,
        startDate + 31536000000
      )
      break
    case 'ytd':
      ApexCharts.exec(
        id,
        'zoomX',
        startDate,
        startDate + 63072000000
      )
      break
    case 'all':
      ApexCharts.exec(
        id,
        'zoomX',
        startDate,
        endDate
      )
      break
    default:
  }
}

const generateSeriesXAxis = (length) => {
  const seriesXAxis = [];

  for (let i = 0; i < length; i++){
    seriesXAxis.push(Math.floor(Math.random() * 90 + 10));
  }

  return seriesXAxis;
}

export const generateLinearSeries = (name, length, colorByValue, color) => {
  return {
    name: name,
    color: color ? color : "#415c98",
    data: generateSeriesXAxis(length).map(( yAxis, i) => {
      const data = {x: linearChartData[i][0], y: yAxis};
      if(colorByValue){
        data.fillColor = getColor(yAxis)
      }
      return data
    })
  }
}


export const setSeries = (name, dataList, benchmarks, colors) => {
  let returnSeries = [];
  let x = 1;
  let sum = 0;
  if (dataList && dataList?.length)
    for (let res of dataList) {
      if (res[name] !== undefined) {
        const val = benchmarks[res["benchmark_id"]]?.settings[name]?.values;
        let color = colors.colorBlue1000;
        sum += roundWithPrecision(res[[name]], 2);
        if (val) {
          color = res[name] < val.target / 2 ? "#dd0d08" : res[name] < val.target ? "#da7d27" : "#a8c721";
        }
        returnSeries.push({
          "color": color,
          "name": res["archive_name"],
          "data": [{x: '', y: roundWithPrecision(res[name], 2)}],
          "type": "column"
        });
        x++;
      }
    }
  return [returnSeries, roundWithPrecision((sum / (x - 1)), 2)];
}
