import React, { useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import GroupIcon from "@material-ui/icons/Group"
import style from "../style.module.sass"
import { Collapse } from "@material-ui/core"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"


function GroupList({ id }) {
    const { t } = useTranslation()
    const groups = useSelector(state => state.adminData.groups)
    const [bg, setBg] = useState(false)
    const [collapse, setCollapse] = useState(true)
    const { theme: { bgColors, colors } } = useTheme()

    const companyGroups = id ? Object.entries(groups).filter(([key, value]) => value.company_id === parseInt(id)).map(([key, value]) => {
        return { id: key, ...value }
    }) : []

    if (companyGroups.length === 0) {
        return null
    }

    return <>
        <div style={{ backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50 }}
            onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}
            className={`${style.benchmarks}`}>
            <div style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.header} pr-4`}>
                <h4 style={{ color: colors.colorPrimary0 }}
                    className={style.title}>{t('admin_company_group-list')}</h4>
                <div>
                    <button className={`ml-2 p-0`} onClick={(e) => {
                        e.preventDefault()
                        setCollapse(!collapse)
                    }}>
                        {!collapse ? <AddIcon style={{ fill: "white" }} fontSize="small" /> :
                            <RemoveIcon style={{ fill: "white" }} fontSize="small" />}
                    </button>
                </div>
            </div>
            <Collapse in={collapse}>
                <ul className={`mt-3 pb-4 d-flex flex-column`} >
                    {companyGroups.map((group) => (
                        <div key={group.id} style={{ display: 'flex', padding: '5px', marginBottom: '10px', alignItems: 'center' }}>
                            <GroupIcon style={{ fill: colors.colorPrimary1000 }} fontSize="small" />
                            <NavLink to={`/administration/group/update/${group.id}`}
                                style={{ color: colors.colorPrimary800 }} className={`m-0 ${style.subTitle}`}
                            >{group.name}</NavLink>
                        </div>
                    ))}
                </ul>
            </Collapse>
        </div>
    </>
}

export default GroupList
