import MessagesData from '../utils/messages'

const updateUserData = (state, action) => {

  const defaultState = {
    nnPaths: {},
    allowedLists: [],
    textTone: [],
    theme: 'light',
    benchmarks: {},
    defaultTextType: false,
    defaultLanguage: false,
    token: false,
    loading: false,
    error: null,
    alertState: false,
    user: {
      user_options: {}
    },
    archiveLanguage: 'de_DE',
    termCategories: [],
    messages: new MessagesData([])
  }

  if (state === undefined) {
    return defaultState
  }
  switch (action.type) {
    case 'FETCH_NN_PATHS_SUCCESS':
      return {
        ...state.userData,
        ...{
          nnPaths: action.payload
        }
      }
    case 'FETCH_NN_PATHS_FAILURE':
      return {
        ...state.userData,
        ...{
          nnPaths: {}
        }
      }

    case 'FETCH_ALLOW_LIST_SUCCESS':
      return {
        ...state.userData,
        ...{
          allowedLists: action.payload
        }
      }
    case 'FETCH_ALLOW_LIST_FAILURE':
      return {
        ...state.userData,
        ...{
          allowedLists: []
        }
      }

    case 'FETCH_TEXT_TONE_SUCCESS':
      return {
        ...state.userData,
        ...{
          textTone: action.payload
        }
      }
    case 'FETCH_TEXT_TONE_FAILURE':
      return {
        ...state.userData,
        ...{
          textTone: []
        }
      }

    case 'FETCH_USER_RENEW_SUCCESS':
      return {
        ...state.userData,
        ...{
          token: action.payload.token,
          error: null
        }
      }
    case 'FETCH_USER_RENEW_FAILURE':
      return {
        ...state.userData,
        ...{
          token: false,
          error: action.payload
        }
      }

    case 'USER_SET_OPTION':
      return {
        ...state.userData,
        user: {
          ...state.userData.user,
          user_options: {
            ...state.userData.user.user_options,
            [action.payload[0]]: action.payload[1]
          }
        }
      }

    case 'FETCH_USER_UPDATE_REQUEST':
      return {
        ...state.userData,
        loading: true,
        error: null
      }
    case 'FETCH_USER_UPDATE_SUCCESS':
      return {
        ...state.userData,
        user: {
          ...state.userData.user,
          ...action.payload
        },
        loading: false,
        error: null,
      }
    case 'FETCH_USER_UPDATE_FAILURE':
      return {
        ...state.userData,
        loading: false,
        error: action.payload
      }

    case 'FETCH_USER_SET_OPTION_REQUEST':
      return {
        ...state.userData,
        loading: true,
        error: null
      }
    case 'FETCH_USER_SET_OPTION_SUCCESS':
      return {
        ...state.userData,
        loading: false,
        error: null,
      }
    case 'FETCH_USER_SET_OPTION_FAILURE':
      return {
        ...state.userData,
        loading: false,
        error: action.payload
      }

    case 'FETCH_ALL_BENCHMARKS_REQUEST':
      return {
        ...state.userData,
        loading: true,
        error: null
      }
    case 'FETCH_ALL_BENCHMARKS_SUCCESS':
      return {
        ...state.userData,
        loading: false,
        error: null,
        benchmarks: action.payload.reduce((accumulator, currentValue) => {
          accumulator[currentValue['id']] = currentValue
          return accumulator
        }, {}),
      }
    case 'FETCH_ALL_BENCHMARKS_FAILURE':
      return {
        ...state.userData,
        benchmarks: defaultState.benchmarks,
      }
    case 'FETCH_TERM_CATEGORIES_REQUEST':
      return {
        ...state.userData,
        termCategories: state.userData.termCategories,
      }
    case 'FETCH_TERM_CATEGORIES_SUCCESS':
      return {
        ...state.userData,
        loading: false,
        termCategories: action.payload.reduce((accumulator, currentValue) => {
          accumulator[currentValue['id']] = currentValue
          return accumulator
        }, {}),
        error: null
      }
    case 'FETCH_TERM_CATEGORIES_FAILURE':
      return {
        ...state.userData,
        termCategories: defaultState.termCategories,
      }

    case 'FETCH_MESSAGES_REQUEST':
      return {
        ...state.userData,
        messages: state.userData.messages,
      }
    case 'FETCH_MESSAGES_SUCCESS':
      return {
        ...state.userData,
        loading: false,
        messages: new MessagesData(action.payload),
      }
    case 'FETCH_MESSAGES_FAILURE':
      return {
        ...state.userData,
        messages: defaultState.messages,
      }

    case 'FETCH_SIGNUP_REQUEST':
      return {
        user: defaultState.user,
        loading: true,
        error: null
      }
    case 'FETCH_SIGNUP_SUCCESS':
      return {
        ...state.userData,
        user: action.payload,
        loading: false,
        error: null

      }
    case 'FETCH_SIGNUP_FAILURE':
      return {
        ...state.userData,
        ...{
          user: defaultState.user,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_FORGOTPASSWORD_REQUEST':
      return {
        ...state.userData,
        user: defaultState.user,
        loading: true,
        error: null

      }
    case 'FETCH_FORGOTPASSWORD_SUCCESS':
      return {
        ...state.userData,
        user: action.payload,
        loading: false,
        error: null

      }
    case 'FETCH_FORGOTPASSWORD_FAILURE':
      return {
        ...state.userData,

        user: defaultState.user,
        loading: false,
        error: action.payload

      }
    case 'FETCH_MEUSER_REQUEST':
      return {
        ...state.userData,

        user: defaultState.user,
        loading: true,
        error: null

      }
    case 'FETCH_MEUSER_SUCCESS':
      return {
        ...state.userData,
        user: action.payload,
        loading: false,
        error: null

      }
    case 'FETCH_MEUSER_FAILURE':
      return {
        ...state.userData,
        token: false,
        user: defaultState.user,
        loading: false,
        error: action.payload

      }

    case 'FETCH_USER_LOGIN_REQUEST':
      return {
        ...state.userData,

        user: defaultState.user,
        token: false,
        loading: true,
        error: null

      }
    case 'FETCH_USER_LOGIN_SUCCESS':
      return {
        ...state.userData,
        user: defaultState.user,
        token: action.payload.token,
        loading: false,
        error: null

      }
    case 'FETCH_USER_LOGIN_FAILURE':
      return {
        ...state.userData,
        user: defaultState.user,
        token: false,
        loading: false,
        error: action.payload

      }
    case 'USER_CLEAR':
      return defaultState
    case 'USER_SET_TOKEN':
      return {
        ...state.userData,
        token: action.payload
      }
    case 'USER_SET_ALERT_STATE':
      return {
        ...state.userData,
        alertState: action.payload
      }
    case 'USER_SET_THEME':
      return {
        ...state.userData,
        theme: action.payload
      }
    case 'USER_SET_ARCHIVE_LANGUAGE':
      return {
        ...state.userData,
        archiveLanguage: action.payload
      }
    default:
      return state.userData
  }
}

export default updateUserData