import React, { useMemo } from "react";
import style from "./style.module.scss"
import DashHeader from "../Header";
import SentenceConst from "./SentenceConst";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { chooseColor, isObjectEmpty, roundWithPrecision } from "../../../utils";
import { Redirect, useLocation } from "react-router";
import config from "../../../config";
import { setSeries } from "../utils";
import { useTheme } from "@material-ui/core/styles";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function HIXComponentCompare() {
    const query = useQuery();
    const single = query.get('single') || '';
    const { t } = useTranslation();
    const { theme: { colors } } = useTheme();

    const benchmarks = useSelector(state => state.userData.benchmarks);
    const resultList1 = useSelector(state => state.dashboardData.resultList[0]?.results || []);
    const resultList2 = useSelector(state => state.dashboardData.resultList[1]?.results || []);
    const dataList1 = useSelector(state => state.dashboardData.resultList[0]?.data);
    const dataList2 = useSelector(state => state.dashboardData.resultList[1]?.data);
    const idList0 = useSelector(state => state.dashboardData.idList[0] || []);
    const idList1 = useSelector(state => state.dashboardData.idList[1] || []);
    const text1 = t('number of results {{num}}', { num: idList0?.length });
    const text2 = t('number of results {{num}}', { num: idList1?.length });

    /*** array2maxlist.moreWordsInLetters ***/
    let hixTableCells1 = [];
    if (!isObjectEmpty(resultList1[`arraymaxlist.moreWordsInLetters`])) {
        hixTableCells1 = Object.entries(resultList1[`arraymaxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length);
    }
    let hixTableCells2 = [];
    if (!isObjectEmpty(resultList2[`arraymaxlist.moreWordsInLetters`])) {
        hixTableCells2 = Object.entries(resultList2[`arraymaxlist.moreWordsInLetters`]).map(([word, freq]) => Object({ word, freq, length: word.length }))
            .sort((a, b) => b.word.length - a.word.length);
    }

    const setResult = (resultList) => {
        const ret = [];

        // get the benchId
        const benchSortedPair = Object.entries(resultList["benchmarkId"]).sort((a, b) => b[1] - a[1]);
        let benchmarkObj;

        for (let bh of benchSortedPair) {
            if (!isObjectEmpty(benchmarks[parseInt(bh[0])])) {
                benchmarkObj = benchmarks[parseInt(bh[0])];
            }
        }

        for (const val of config.compareShowValues) {
                // value exist
                const counterBase = config.fullCountBase[val.name];
                const countNumber = !!resultList[`${val.type}sum.${val.name}`] ? resultList[`${val.type}sum.${val.name}`] : 0
                const localTarget = benchmarkObj?.settings[val.name]?.values?.target || -100;
                const localTargetYellowGreen = benchmarkObj?.settings[val.name]?.values?.yellowGreen || -100;
                const localTargetYellowRed = benchmarkObj?.settings[val.name]?.values?.yellowRed || -100;
                const localPercent = countNumber / (resultList[`sum.${counterBase}`] / 100);
                const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
                // push to ret
                ret.push({
                    value: roundWithPrecision(localPercent, 0),
                    color: config.compareThemeColors[localColor],
                    text: [resultList[`${val.prefix}text.${val.name}`]],
                    title: t(val.title),
                    subTitle: t(val.subTitle),
                    lCount: countNumber,
                    lCountMax: resultList[`sum.${counterBase}`] || 0,
                    lText: [t(val.lText, { number: localTarget })],
                    lTitle: t(val.lTitle),
                    label: "",
                    withLinear: false,
                    textsComp: false
                })
        }
        return ret;
    }

    const seriesHIX1 = useMemo(() => setSeries('formulaHix', dataList1, benchmarks, colors), [dataList1, benchmarks]);
    const seriesHIX2 = useMemo(() => setSeries('formulaHix', dataList2, benchmarks, colors), [dataList2, benchmarks]);

    const sets = [{}, {}];
    if (!isObjectEmpty(resultList1)) {
        sets[0].benchmarkWarning = (Object.keys(resultList1["benchmarkId"]).length > 1);
        sets[0].counter = setResult(resultList1);
    }
    if (!isObjectEmpty(resultList2)) {
        sets[1].benchmarkWarning = (Object.keys(resultList2["benchmarkId"]).length > 1);
        sets[1].counter = setResult(resultList2);
    }

    if (isObjectEmpty(sets[0]) && isObjectEmpty(sets[1]))
        return <Redirect to={"/"} />;
    console.log("sets", sets)


    const double = !single && !isObjectEmpty(sets[0].counter) && !isObjectEmpty(sets[1].counter);

    if (double) {
        return (
            <div className={style.mainCont}>
                <div style={{ width: "98%" }}>
                    <DashHeader name={t('HIX_dashboard_header')}  textType={false} />
                </div>
                <div style={{ display: "table", width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                    <div style={{ display: "table-cell", width: "50%", textAlign: "center" }}>
                        <SentenceConst hixTableCells={hixTableCells1} seriesHIX={seriesHIX1} headText={text1} type={1} benchmarkWarning={sets[0].benchmarkWarning} collapseFile={true} counters={sets[0].counter} />
                    </div>
                    <div style={{ display: "table-cell", width: "50%", textAlign: "center" }}>
                        <SentenceConst hixTableCells={hixTableCells2} seriesHIX={seriesHIX2} headText={text2} type={2} benchmarkWarning={sets[1].benchmarkWarning} collapseFile={true} counters={sets[1].counter} />
                    </div>
                </div>
            </div>
        )
    }

    if (!isObjectEmpty(sets[0].counter) && (single === '0' || isObjectEmpty(sets[1].counter))) {
        return (
            <div className={style.mainCont}>
                <div style={{ width: "98%" }}>
                    <DashHeader name={t('HIX_dashboard_header')}  textType={false} />
                </div>
                <SentenceConst hixTableCells={hixTableCells1} seriesHIX={seriesHIX1} headText={text1} type={1} benchmarkWarning={sets[0].benchmarkWarning} collapseFile={true} counters={sets[0].counter} />
            </div>
        );
    }

    return (
        <div className={style.mainCont}>
            <div style={{ width: "98%" }}>
                <DashHeader name={t('HIX_dashboard_header')}  textType={false} />
            </div>
            <SentenceConst hixTableCells={hixTableCells2} seriesHIX={seriesHIX2} headText={text2} type={2} benchmarkWarning={sets[1].benchmarkWarning} collapseFile={true} counters={sets[1].counter} />
        </div>
    )
}
