import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import style from './style.module.sass'
import bigWhiteCloseIcon from './assets/bigWhiteCloseIcon.svg'
import { useTheme } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import config from '../../../config'
import { useTranslation } from 'react-i18next'
import {
  analyseSetAiMode,
  analyseSetText
} from '../../../actions'
import { useSelector } from 'react-redux'
import { useActions } from '../../../utils/action-helper.js'

const useStyles = makeStyles({
  list: {
    width: 306,
  },
  fullList: {
    width: 'auto',
  },
})

export default function Dashboard ({ open, setOpen }) {
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
  const userType = useSelector(state => state.userData.user.user_type_id)
  const oldText = useSelector(state => state.analyseData.oldText)

  const {
    actionAnalyseSetText,
    actionAnalyseSetAiMode
  } = useActions({
    actionAnalyseSetText: analyseSetText,
    actionAnalyseSetAiMode: analyseSetAiMode,
  })

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(open)
  }

  const linksArr = config['linksArr']

  const handleChangeAiMode = (name) => {
    if (name === 'home') {
      if (oldText?.length) {
        actionAnalyseSetText(oldText)
      }
      actionAnalyseSetAiMode(false)
    } else {
      actionAnalyseSetAiMode(true)
    }
  }

  const result = linksArr.map(l => {
    if (!l?.section || enabledSections.indexOf(l.section) > -1) {
      // if section == administration check for right
      // && (userType === 2 && !checkUserRight(user,[101,102,103,104,105,110,121,123,131,201,202,203,204,205,206,207,208,209,210,221,223,231,306,307,308,309])) 
      if (l?.section === 'administration' && userType === 3)
        return null

      // If the link is for the PDF, handle it differently
      if (l.name === 'view-pdf') {
        const Icon = l.icon
        return (
          <a
            href={l.to}
            key={t(l.text)}
            target="_blank"
            rel="noopener noreferrer"
            className={`${style.link} d-flex align-items-center pt-1 pb-1 pl-2`}
            style={{ borderColor: borderColors.borderPrimary800 }}>
            &nbsp;
            <Icon style={{ color: colors.colorPrimary50, position: 'absolute', left: 30 }} />
            <p style={{ color: colors.colorPrimary50, position: 'absolute', left: 70 }} className={`m-0`}>{t(l.text)}</p>
          </a>
        )
      }

      return (
        <NavLink
          to={l.to}
          key={t(l.text)}
          onClick={() => (l.name === 'home' || l.name === 'ai-mode') && handleChangeAiMode(l.name)}
          exact={true}
          activeStyle={{ borderColor: borderColors.borderPrimary100, backgroundColor: bgColors.bgPrimary500 }}
          style={{ borderColor: borderColors.borderPrimary800 }}
          className={`${style.link} d-flex align-items-center pt-1 pb-1 pl-2`}>
          &nbsp;
          <img className={`mr-3`} src={l.img} alt="" style={{ position: 'absolute', left: 30 }}/>
          <p style={{ color: colors.colorPrimary50, position: 'absolute', left: 70 }} className={`m-0`}>{t(l.text)}</p>
        </NavLink>
      )
    }
    return null
  })

  const list = () => (
    <div
      className={`${classes.list} ${style.mainCont}`}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{ backgroundColor: bgColors.bgPrimary500 }}
    >
      <div className={style.headPart}>
        <button onClick={() => setOpen(false)} className={style.closeBtn}><img src={bigWhiteCloseIcon} alt=""/></button>
      </div>
      {
        result
      }
    </div>
  )

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={'left'} open={open} onClose={toggleDrawer(false)}>
          {list('left')}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
