import React, { Fragment, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton, TableCell, TableRow, TableHead, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { useActions } from '../../../utils/action-helper'
import {
  tableSetDisabledColumns,
  tableSetOrder,
  tableSetOrderBy,
  tableSetSelected,
  userSaveOption,
  userSetOption
} from '../../../actions'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

export default function TableHeader ({
  reloadAll,
  handleSelectAll,
  getAllIds,
  style,
  MassActionComponent,
  enableSelectAll = true,
  enableDisabledColumns = true,
  type,
  selectAllText = ''
}) {
  const { theme: { bgColors, colors } } = useTheme()
  //const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)
  const inscription = useSelector(state => state.tableData.inscription)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const columns = useSelector(state => state.tableData.columns)
  const token = useSelector(state => state.userData.token)
  const userId = useSelector(state => state.userData.user.id)
  const rowCount = useSelector(state => state.tableData.rowCount)
  const selected = useSelector(state => state.tableData.selected)
  const orderBy = useSelector(state => state.tableData.orderBy)
  const order = useSelector(state => state.tableData.order)
  const data = useSelector(state => state.tableData.data)


  const {
    aTableSetOrderBy,
    aTableSetOrder,
    aUserSaveOption,
    actionUserSetOption,
    aTableSetDisabledColumns,
    aTableSetSelected
  } = useActions({
    aTableSetDisabledColumns: tableSetDisabledColumns,
    aTableSetOrderBy: tableSetOrderBy,
    aTableSetOrder: tableSetOrder,
    aTableSetSelected: tableSetSelected,
    aUserSaveOption: userSaveOption(tlService),
    actionUserSetOption:userSetOption
  })

  const handleSelectAllOnPage = () => {
    const newSelection = Object.values(data).map(v=>v.id).sort()
    if (JSON.stringify(newSelection) === JSON.stringify(selected)){
      aTableSetSelected([])
    } else {
      aTableSetSelected(newSelection)
    }
  }

  const handleSetSort = (orderBy_value, order) => {
    aTableSetOrderBy(orderBy_value)
    aTableSetOrder(order)
  }

  const handleSetDisabledColumns = (id) => {
    const newDisabledColumns = [...disabledColumns]
    const inx = newDisabledColumns.indexOf(id)
    if (inx === -1) {
      newDisabledColumns.push(id)
    } else {
      newDisabledColumns.splice(inx, 1)
    }
    //console.log('newDisabledColumns',inx ,newDisabledColumns)
    aTableSetDisabledColumns(newDisabledColumns)
    actionUserSetOption(`disabled_${type}_columns`, newDisabledColumns)
    aUserSaveOption(token, userId, `disabled_${type}_columns`, newDisabledColumns)
  }

  const [menuPosition, setMenuPosition] = useState(null)

  const handleRightClick = (event) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    event.stopPropagation()

    setMenuPosition({
      top: event.clientY,
      left: event.clientX
    })
  }

  return (
    <Fragment>
      <TableHead style={{ backgroundColor: bgColors.bgBlue300 }} className={`${style.filterDashboard}`}>
        <TableRow>
          <TableCell colSpan={12} padding="checkbox">
            <MassActionComponent
              reloadAll={reloadAll}
              getAllIds={getAllIds}
            />
          </TableCell>
        </TableRow>
      </TableHead>

      <TableHead style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.tableHead}`}>
        <TableRow>
          <TableCell padding="checkbox" colSpan={4} style={{ color: 'white' }}>
            {enableSelectAll && <div className={'d-flex align-content-center align-items-center'}>
              <div><Checkbox
                checked={selectAll}
                onChange={() => handleSelectAll()}
                inputProps={{ 'aria-label': 'select all desserts' }}
              /></div>
              <div>{selectAllText}</div>
            </div>}
          </TableCell>
          <TableCell colSpan={8} style={{ color: 'white', textAlign: 'right' }}>
            <b>{inscription}</b>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableHead style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.tableHead}`}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < rowCount}
              checked={rowCount > 0 && selected.length === rowCount}
              onChange={handleSelectAllOnPage}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {columns.map(({
            id,
            label, unSortable, orderBy_value, filter_value
          }, index) => (
            (!disabledColumns.includes(id)) ?
              <Fragment key={index}>
                {!unSortable ?
                  <TableCell
                    style={{textAlign: 'center', paddingLeft:  '50px'}}
                    key={id}
                    sortDirection={orderBy === orderBy_value ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === orderBy_value}
                      direction={orderBy === orderBy_value ? order : 'asc'}
                      style={{ flexDirection: 'row' }}
                      onClick={() => handleSetSort(orderBy_value, order === 'asc' ? 'desc' : 'asc')}
                      IconComponent={props =>
                        <ArrowDownwardIcon style={{ order: '9', color: colors.colorPrimary0 }} alt="" {...props} />}>
                      <p style={{ color: colors.colorPrimary0}} className={`m-0`}>
                        {label}
                      </p>
                    </TableSortLabel>
                  </TableCell> :
                  <TableCell
                    style={{textAlign: 'center'}}
                    key={id}
                  >
                      <p style={{ color: colors.colorPrimary0}} className={`m-0`}>
                        {label}
                      </p>

                  </TableCell>
                }
              </Fragment> :
              <Fragment key={index}>
                  <TableCell
                    style={{textAlign: 'center'}}
                    key={id}
                  />
              </Fragment>
          ))}
          {enableDisabledColumns && <Fragment key={'arrow'}>
            <TableCell
              align={'right'}
              padding={'normal'}
              className="d-flex align-items-center justify-content-center" onClick={handleRightClick}>
              <IconButton className="ml-auto">
                <MoreVert style={{ fill: '#ccc' }}/>
              </IconButton>
              <Menu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
              >
                {columns.map(({
                  id,
                  label
                }, index) =>
                  <MenuItem
                    key={index}
                    style={{ color: !disabledColumns.includes(id) ? '#415c98' : '#333' }}
                    onClick={() => handleSetDisabledColumns(id)}
                  >
                    {label}
                  </MenuItem>)}
              </Menu>
            </TableCell>
          </Fragment>}
        </TableRow>
      </TableHead>

    </Fragment>

  )
}