import React from "react";
import MainPageWrapper from "../../Main-page-wrapper";
import Dashboard from "./Dashboard/Dashboard";
import style from "./style.module.sass"

function UserProfileWrapper({children}) {
  return (
    <MainPageWrapper>
      <div className={`${style.mainCont}`}>
        <Dashboard/>
        <div className={`${style.overflow}`}>
          {children}
        </div>
      </div>
    </MainPageWrapper>
  )
}

export default UserProfileWrapper;
