import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import {Box, Button} from "@material-ui/core";
import deleteIcon from "../assets/deleteIcon.svg";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Table from '@material-ui/core/Table';
import { useTranslation } from "react-i18next";

const headCells = [
  {id: 'Replacement term', numeric: false, disablePadding: true, label: 'Group name'},
  {id: 'arrow', numeric: false, disablePadding: false, label: 'editMissing'},
];

function EnhancedTableHead({onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort}) {
  const {theme: {bgColors, colors}} = useTheme();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const t = useTranslation();

  return (
    <Table>
      <TableHead style={{backgroundColor: bgColors.bgBlue300}} className={`${style.filterDashboard}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Collapse in={numSelected ? true : false} timeout="auto" unmountOnExit>
            <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
              <p style={{color: colors.colorPrimary1000}} className={`m-0`}>{numSelected} {t('selected')}</p>
              <Button className={style.export} variant="contained" color="primary"
                      style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}>
                {t('export')}
              </Button>
              <button className={`${style.delete} pr-0 pl-0 ml-2`}
                      style={{backgroundColor: bgColors.bgBlue1000}}>
                <img src={deleteIcon} alt=""/>
              </button>
            </Box>
          </Collapse>
        </TableCell>
        </TableRow>
      </TableHead>
      <TableHead style={{backgroundColor: bgColors.bgPrimary500}} className={`${style.tableHead}`}>

          <TableRow className={`p-0`}>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{'aria-label': 'select all desserts'}}
              />
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  style={{flexDirection: "row"}}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={(props) => (
                    <ArrowDownwardIcon style={{order: "9", color: colors.colorPrimary0}} alt="" {...props}/>)}
                >
                  <p style={{color: colors.colorPrimary0}} className={`m-0`}>{headCell.label}</p>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>

      </TableHead>
    </Table>
  );
}

export default EnhancedTableHead
