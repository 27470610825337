import React, {useEffect, useRef, useState} from 'react';
import style from "./style.module.sass";
import {Checkbox, TableCell, TextField} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import {useTheme} from '@material-ui/core/styles';
import deleteIcon from "../assets/deleteIcon.svg"

function Rows({row, isItemSelected, labelId, checkBoxHandleClick, isEven, setRows, afterAddField, rows}) {
  const {theme: {bgColors, colors}} = useTheme()
  const [open, setOpen] = useState(false)

  const node = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false)
    return document.addEventListener("mousedown", handleClick, false)
  }, [node])

  const handleClick = e => {
    e.preventDefault()
    if (node?.current?.contains(e.target)) {
      setOpen(true)
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    !!(afterAddField && rows[0].id === row.id) && setOpen(true)
  }, [afterAddField, rows.id, rows, row.id])

  function handleOnInput(e) {
    setRows(s => s.map(obj => row.id === obj.id ? {id: obj.id, replacementTerm: e.target.value} : obj))
  }

  return (
    <TableRow role="checkbox" className={`${style.row} d-flex`} hover>
      <TableCell
        style={{
          backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100,
          width: "100%"
        }}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        className={`d-flex align-items-center p-2 pl-0 pr-3`}
      >
        <div className={`${style.cell} pl-1`} onClick={(event) => checkBoxHandleClick(event, row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{'aria-labelledby': labelId}}
          />
        </div>
        <div className={`${style.cell} d-flex align-items-center`}
             style={{color: colors.colorStatusInfo, height: "100%", cursor: "pointer", margin: "0 auto 0 0"}}>
          {
            open
              ? <div>
                <TextField
                  ref={node}
                  variant="standard"
                  autoFocus={open}
                  defaultValue={row.replacementTerm}
                  onChange={handleOnInput}
                  style={{width: "300px", height: "100%"}}
                  label={"Group name"}
                />
              </div> :
              row.replacementTerm === "" ?
                <TextField
                  ref={node}
                  variant="standard"
                  size="small"
                  error
                  defaultValue={row.replacementTerm}
                  onChange={handleOnInput}
                  style={{width: "300px", height: "100%"}}
                  label={"Fill in the field"}
                />
                : <p ref={node} className={`m-0`} onClick={handleClick}>{row.replacementTerm}</p>
          }
        </div>
        <div className={`${style.cell} d-flex`}>
          <button className={`${style.iconBtn} pr-0 pl-0 ml-2 d-flex align-items-center justify-content-center`}
                  style={{backgroundColor: bgColors.bgBlue1000}}>
            <img src={deleteIcon} alt=""/>
          </button>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default Rows;
