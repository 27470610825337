import React from "react"
import AuthForm from "../../components/Authorization/ga2fa";

export default function AuthorizationGA() {
    return (
        <section>
            <AuthForm/>
        </section>
    )
}
