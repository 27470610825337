import React, { useState, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import style from "../UI/style.module.scss";
import { useSelector } from "react-redux";
import { roundWithPrecision, isObjectEmpty, deprecatedBenchmarkCheck } from "../../../utils";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

export default function AveragePanel({ benchmarkExist1, benchmarkExist2 }) {
    const { theme: { bgColors } } = useTheme();
    const { t } = useTranslation();
    const resultList1 = useSelector(state => state.dashboardData.resultList[0]);
    const resultList2 = useSelector(state => state.dashboardData.resultList[1]);
    const resultListResult = useSelector(state => state.dashboardData.resultList[0]?.results || []);
    const benchmarks = useSelector(state => state.userData.benchmarks);

    const text1 = resultList1?.data?.length ? resultList1.data.length : '';
    const text2 = resultList2?.data?.length ? resultList2.data.length : '';
    const clix1 = resultList1?.results && resultList1.results["mean.formulaCLIX"] !== undefined ? roundWithPrecision(resultList1.results["mean.formulaCLIX"], 2) : '';
    const clix2 = resultList2?.results && resultList2.results["mean.formulaCLIX"] !== undefined ? roundWithPrecision(resultList2.results["mean.formulaCLIX"], 2) : '';
    const hix1 = resultList1?.results && resultList1.results["mean.formulaHix"] !== undefined ? roundWithPrecision(resultList1.results["mean.formulaHix"], 2) : '';
    const hix2 = resultList2?.results && resultList2.results["mean.formulaHix"] !== undefined ? roundWithPrecision(resultList2.results["mean.formulaHix"], 2) : '';
    const flesch1 = resultList1?.results && resultList1.results["mean.formulaFlesch"] !== undefined ? roundWithPrecision(resultList1.results["mean.formulaFlesch"], 2) : '';
    const flesch2 = resultList2?.results && resultList2.results["mean.formulaFlesch"] !== undefined ? roundWithPrecision(resultList2.results["mean.formulaFlesch"], 2) : '';

    const sets = [{}];
    if (!isObjectEmpty(resultListResult)) {
        sets[0].benchmarkWarning = (Object.keys(resultListResult["benchmarkId"]).length > 1);
    }

    // CHecking benchmark exist or not?:
    benchmarkExist1.current = deprecatedBenchmarkCheck(benchmarks, resultList1)
    benchmarkExist2.current = deprecatedBenchmarkCheck(benchmarks, resultList2)

    const Panel = ({ header, text1, text2, withNavLink, info }) => {
        return (
            <div style={{ display: "table-cell", paddingRight: "30px" }}>
                <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0 }}>
                    <div className={style.header}>{header}</div>
                    <div className={style.main_div}>
                        {!!text1 && <span className={`${style.res0}`}>{text1}</span>}
                        {!!text2 && <> / <span className={`${style.res1}`}>{text2}</span></>}
                    </div>
                    <br />
                    {(!hix1 && !clix1 && resultList1?.length) ? <Alert
                        className={`m-0`}
                        severity='error'
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "40px"
                        }}
                    >
                        <h6 className={`m-0`}>
                            {t('no-hix-clix')}
                        </h6>
                    </Alert> : <div />
                    }
                    <div className={`${style.footer} mt-2`}>
                        {
                            withNavLink ? <NavLink to={withNavLink}>
                                <button>{t("Detail view")}</button>
                            </NavLink> : info === "info" ? <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                                {t('text types is different')}
                            </h6>} className='ml-2 mr-auto'>
                                <Info style={{ fill: bgColors.bgPrimary500 }} />
                            </Tooltip> :
                                info === "noBenchmark" ?
                                <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                                    {t('text-types-does-not-exist')}
                                </h6>} className='ml-2 mr-auto'>
                                    <Info style={{ fill: bgColors.bgPrimary500 }} />
                                </Tooltip> :
                                 info === "flesch" &&
                                 <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                                     {t('FLESCH count bar')}
                                 </h6>} className='ml-2 mr-auto'>
                                     <Info style={{ fill: bgColors.bgPrimary500 }} />
                                 </Tooltip>
                        }
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{ display: "table", width: "100%", marginLeft: "auto", marginRight: "auto", marginBottom: "30px" }}>
            <Panel header={t('Analyses count bar')} text1={text1} text2={text2} />
            {clix1 !== '' ? ((!isObjectEmpty(sets[0]) && sets[0].benchmarkWarning) ?
                <Panel header={t('CLIX count bar')} text1={clix1} text2={clix2} info={"info"} /> :
                ((benchmarkExist1.current === false || benchmarkExist2.current === false)) ?
                    <Panel header={t('CLIX count bar')} text1={clix1} text2={clix2} info={"noBenchmark"} /> :
                    <Panel withNavLink={"/dashboard/compare/CLIX"} header={t('CLIX count bar')} text1={clix1} text2={clix2} />) : <></>}
            {hix1 !== '' ? <Panel withNavLink={"/dashboard/compare/HIX"} header={t('HIX count bar')} text1={hix1} text2={hix2} /> : 
            flesch1 !== '' && <Panel  header={t('FLESCH count bar')} text1={flesch1} text2={flesch2} info={"flesch"} /> 
            }
        </div>
    );
}