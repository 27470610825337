import {isObjectEmpty} from "./index";

export async function getResource(apiBase, url, method = 'GET', body = null, token = null, json = true, extraHeaders = {}) {
    let headers = token ? {Authorization: `Bearer ${token}`} : {};
    if (json) {
        headers['Content-Type'] = 'application/json';
        headers['Accept'] = 'application/json';
    }

    if (!isObjectEmpty(extraHeaders)) {
        headers = {...headers , ...extraHeaders};
    }

    headers['Access-Control-Max-Age'] = 600;

    const sendBody = (body && typeof body === 'object') ? JSON.stringify(body) : body;

    const res = await fetch(`${apiBase}${url}`, {
        async: true,
        method,
        headers,
        body: sendBody ? sendBody : null
    });
    if (!res.ok) {
        if (res.status && res.status >= 400) {
            const resTextBody = await res.text();
            let resBodyObj;
            try {
                resBodyObj = JSON.parse(resTextBody);
            } catch (e) {
                resBodyObj = {}
            }
            throw new Error(resBodyObj.message !== undefined ? JSON.stringify({...resBodyObj, number: res.status}) : JSON.stringify({message: 'error number {{number}}', number: res.status}));
        }
       // throw new Error(JSON.stringify({message: 'error number {{number}}', number: res.status}))
    }
    let resBody = await res.text();
    resBody = resBody ? JSON.parse(resBody) : false;
    return resBody;
}

export function appendLimits(limits, filter) {
    limits['limit'] && filter.append("limit", limits['limit']);
    limits['offset'] && filter.append("offset", limits['offset']);
    limits['order_by'] && filter.append("order_by", limits['order_by']);
    limits['order'] && filter.append("order", limits['order'].toString());
    limits['company_id'] && filter.append("company_id", limits['company_id']);
    limits['user_group_id'] && filter.append("user_group_id", limits['user_group_id']);
    return filter;
}

export function appendFilters(varFilter, filter) {
    if (varFilter && !isObjectEmpty(varFilter)) {
        for (const [keyFilter, valFilter] of Object.entries(varFilter)) {
            if (valFilter !== undefined)
                filter.append(keyFilter, valFilter.toString());
        }
    }
    return filter;
}
