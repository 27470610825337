import CustomSelect from "../../../Fields/Select";
// import Switch from "../../../Fields/Switch";
import { Switch } from "@material-ui/core";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import { useActions } from "../../../../utils/action-helper";
import { userSaveOption, userSetOption, userUpdateUser } from "../../../../actions";
import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import siteLanguages from "../../../../config/sitelanguages.json"
import FormControl from '@material-ui/core/FormControl';
import config from "../../../../config"
import { convertLanguagesToShort } from "../../../../utils"

export default function NearTermTabs({ currentTerm,
  setCategoryType, categoryType, setParentId, parentId,
  visibility, setVisibility, letterRows, setTermLanguage, termLanguage }) {
  // const [lang, setLang] = useState('');
  // const [catType, setCatType] = useState('');
  // const [par, setPar] = useState('');
  // const [, setVis] = useState(false)
  const { t, i18n: { language: i18nLanguage } } = useTranslation();
  const [categoryName, setCategoryName] = useState();
  const token = useSelector(state => state.userData.token);
  const userId = useSelector(state => state.userData.user.id);
  // const handleLang = e => setLang(e.target.value);
  // const handleCatType = e => setCategoryType(e.target.value);
  // const handlePar = e => setPar(e.target.value);

  /**BIND ACTIONS**/
  const tlService = React.useContext(TLServiceComponent);
  const { actionUserUpdateUser } = useActions({
    actionUserSetOption: userSetOption,
    actionUserSaveOption: userSaveOption(tlService),
    actionUserUpdateUser: userUpdateUser(tlService),
  })

  function handleOnChangeLanguage(e) {
    const userLanguage = e.target.value;
    if (userLanguage) {
      setTermLanguage(userLanguage)
      // i18n.changeLanguage(userLanguage);
      // actionUserUpdateUser(token, userId, { language_id: userLanguage });
    }
  }

  const getListTerm = async () => {
    try {
      let terms = await tlService.getFilteredTermCategories(token, {}, {});
      const newArray = terms?.data?.length ? terms.data.map(({ id, name,locale_name }) => {
        return { id, name: `(${id}) ${name[i18nLanguage] || ''}`, locale_name }
      }) : [];
      newArray.unshift({ id: 0, name: t('No parent category') });
      setCategoryName(newArray);
    } catch (e) {
      console.log('error', e)
    }
  }

  // Get list of term category Name:
  useEffect(() => {
    getListTerm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  const handleChange = (e) => {
    setParentId(e.target.value)
    const parentCatlanguage = categoryName.find(row => row.id === e.target.value)?.locale_name
    setTermLanguage(convertLanguagesToShort(parentCatlanguage) || i18nLanguage)
  }

  return (
    <div className={`d-flex flex-column`} style={{ gridGap: 15 }}>
      <FormControl>
        <Select label={'language'}
        disabled={!!parentId}
          // value={lang}
          options={[{ id: '1', name: "Deutsch" }, { id: 2, name: "English" },
            //  {id: 3, name: "Russian"}
          ]}
          value={termLanguage}
          onChange={handleOnChangeLanguage}
        >
          {
            Object.keys(siteLanguages).map(lang =>
              <MenuItem key={lang} value={lang}>{siteLanguages[lang]['nativeName']}</MenuItem>
            )
          }
        </Select>
      </FormControl>
      <CustomSelect label={t('category_type')}
        options={
          config.categoryTypeList
        }
        value={categoryType}
        handleChange={e => setCategoryType(e.target.value)}
      />
      <div>
        <Switch label={'Visibility (will the category visible to all users?)'} onChange={e => setVisibility(!visibility)}
          checked={visibility}
        // labelPlacementEnd={visibility}
        /> {t('visibility-to-user')}
      </div>
      {!!currentTerm ? <CustomSelect disabled label={t('Parent Category')} value={parentId}
        options={
          categoryName?.length ? categoryName : []
        } /> :
        <CustomSelect label={t('Parent Category')} handleChange={handleChange} value={parentId}
          options={
            categoryName?.length ? categoryName : []
          } />
      }
    </div>
  )
}
