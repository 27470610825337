import style from "./style.module.scss";
import React from "react";
import {useTheme} from "@material-ui/core/styles";

export default function TonalityRangerBlocks({activeCell, activeCell2, blocks}) {
    const {theme: {bgColors}} = useTheme();

    return (
        <div className={`${style.rangerBlocks}`}>
            {
                blocks.map((i) =>
                    <div key={i} className={`${style.rangerBlock}`}>
                        {i === activeCell ? <div style={{backgroundColor: (i === activeCell) ? bgColors.bgBlue1000 : bgColors.bgRadial}}/> : ""}
                        {(i === activeCell2) ? <div style={{backgroundColor: i === activeCell2 ? bgColors.bgYellow1000 : bgColors.bgRadial}}/> : ""}
                    </div>
                )
            }
        </div>
    )
}
