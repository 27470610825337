import React, { useMemo, useState } from "react";
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles";
import "./tag-cloud.css"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import { isObjectEmpty } from "../../../../utils";
import TagCloud from "react-tag-cloud";
import Button from "@material-ui/core/Button";

export default function CompareVocabularyComponent() {
    const [dictionary, setDictionary] = useState(false)
    const [funcWord, setFuncWord] = useState(false)
    const [terms, setTerms] = useState(50)
    const { theme: { bgColors, colors } } = useTheme();
    const [sort, setSort] = useState("");
    const archiveResult = useSelector(state => state.dashboardData.archiveResult);
    const { t } = useTranslation();

    function calculateWordsCloud(archiveResult, sort, terms, funcWord) {

        let wordsWithFilters = [];
        if (sort === "Adjectives" || !sort) {

            wordsWithFilters = [...wordsWithFilters, ...archiveResult['countAdjectives'].map(position => {
                return {
                    position,
                    color: "#3fbdff",
                    type: 'Adjectives',
                    length: archiveResult['dataTokenizer'][position].lemma.length,
                    num: archiveResult['dataTokenizer'].filter(val => val.lemma === archiveResult['dataTokenizer'][position].lemma).length,
                    text: archiveResult['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }
        if (sort === "Nouns" || !sort) {

            wordsWithFilters = [...wordsWithFilters, ...archiveResult['countNouns'].map(position => {
                return {
                    color: "#415c98",
                    type: 'Nouns',
                    length: archiveResult['dataTokenizer'][position].lemma.length,
                    num: archiveResult['dataTokenizer'].filter(val => val.lemma === archiveResult['dataTokenizer'][position].lemma).length,
                    text: archiveResult['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }
        if (sort === "Verbs" || !sort) {

            wordsWithFilters = [...wordsWithFilters, ...archiveResult['countVerbs'].map(position => {
                return {
                    color: "#5e47d9",
                    type: 'Verbs',
                    length: archiveResult['dataTokenizer'][position].lemma.length,
                    num: archiveResult['dataTokenizer'].filter(val => val.lemma === archiveResult['dataTokenizer'][position].lemma).length,
                    text: archiveResult['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }

        if (sort === "") {
            // If alle anzeigen or all view, display higest number of words:
            wordsWithFilters.sort((a, b) => b.num - a.num);
        }

        // filter for function words
        if (!funcWord && !sort) {
            const allFunctionWords = [];

            // Add respective item of below array when if it is available:
            if (archiveResult['countConjunctions']?.length) {
                allFunctionWords.push(
                    ...archiveResult['countConjunctions']
                )
            }
            if (archiveResult['countArticles']?.length) {
                allFunctionWords.push(
                    ...archiveResult['countArticles']
                )
            }
            if (archiveResult['countPronouns']?.length) {
                allFunctionWords.push(
                    ...archiveResult['countPronouns']
                )
            }
            if (archiveResult['countPrepositions']?.length) {
                allFunctionWords.push(
                    ...archiveResult['countPrepositions']
                )
            }
            if (archiveResult['countParticles']?.length) {
                allFunctionWords.push(
                    ...archiveResult['countParticles']
                )
            }

            wordsWithFilters = [...wordsWithFilters, ...allFunctionWords.map(position => {
                return {
                    color: "#192439",
                    type: 'Function',
                    length: archiveResult['dataTokenizer'][position].lemma.length,
                    num: archiveResult['dataTokenizer'].filter(val => val.lemma === archiveResult['dataTokenizer'][position].lemma).length,
                    text: archiveResult['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }

        // Remove all unknown words and uncosistent
        wordsWithFilters = wordsWithFilters.filter(el => el.text !== '<unknown>' && !el.text.match(/[@]/));

        //  uniqueficalion
        wordsWithFilters = wordsWithFilters.filter((value, index, self) => {
            return self.findIndex(val => val.text === value.text) === index
        })

        // sort
        wordsWithFilters.sort((a, b) => a.num > b.num ? -1 : 1)

        // and set limit
        wordsWithFilters = wordsWithFilters.slice(0, terms);

        // calculate word sizes
        const maxMinSize = wordsWithFilters.reduce((acc, val) => [acc[0] < val.num ? val.num : acc[0], acc[1] > val.num ? val.num : acc[1]], [0, 10000]);
        wordsWithFilters = wordsWithFilters.map(v => {
            return { ...v, fontSize: Math.ceil(v.num / (maxMinSize[0] / 70)) }
        })

        return wordsWithFilters;
    }

    // memorisation result
    const wordsWithFilters = useMemo(() => !isObjectEmpty(archiveResult) && calculateWordsCloud(archiveResult, sort, terms, funcWord), [archiveResult, sort, terms, funcWord])

    function handleOnSetTerms(event, newValue) {
        setTerms(newValue);
    }

    return (
        <div className={`${style.vocabulary}`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
            {wordsWithFilters?.length && <div className={style.vocMain} style={{ height: "380px" }}>
                <div className={"mainTagCloud"}>
                    <div className={"app-outer"}>
                        <div className={"app-inner"}>
                            <TagCloud className='tag-cloud' style={{ padding: 6, fontFamily: 'Roboto', width: "500", marginLeft: "-100px" }}>
                                {wordsWithFilters.map(w => (
                                    <div key={w.text} rotate={w.rotate} style={{
                                        fontSize: w.fontSize,
                                        fontWeight: "bolder",
                                        color: w.color,
                                    }}>{w.text}</div>
                                ))}
                            </TagCloud>
                        </div>
                    </div>
                </div>
                <div className={style.legends}>
                    <div className={`mb-4 d-flex flex-column`} style={{ paddingBottom: "100px" }}>
                        <h4 className={`mb-2`}>{t("Parts of speech")}</h4>
                        <Button variant={sort !== "Adjectives" ? "text" : "contained"}
                            color={sort !== "Adjectives" ? "default" : "primary"}
                            className={`d-flex justify-content-start p-0 pl-1`}
                            style={{ color: sort !== "Adjectives" ? "#3fbdff" : colors.colorPrimary0 }}
                            onClick={() => setSort("Adjectives")}>{t("Adjectives")}</Button>
                        <Button variant={sort !== "Nouns" ? "text" : "contained"}
                            color={sort !== "Nouns" ? "default" : "primary"}
                            className={`d-flex justify-content-start p-0 pl-1`}
                            style={{ color: sort !== "Nouns" ? "#415c98" : colors.colorPrimary0 }}
                            onClick={() => setSort("Nouns")}>{t("Nouns")}</Button>
                        <Button variant={sort !== "Verbs" ? "text" : "contained"}
                            color={sort !== "Verbs" ? "default" : "primary"}
                            className={`d-flex justify-content-start p-0 pl-1`}
                            style={{ color: sort !== "Verbs" ? "#5e47d9" : colors.colorPrimary0 }}
                            onClick={() => setSort("Verbs")}>{t("Verbs")}</Button>
                        <Button variant={sort !== "" ? "text" : "contained"}
                            color={"primary"}
                            className={`d-flex justify-content-start p-0 pl-1`}
                            onClick={() => setSort("")}>{t("Show all")}</Button>
                    </div>
                    <br />
                    <br />
                    <h4>
                        {t("numberOfWords_dashboard_pdf")}  : </h4>
                    <b> {terms}
                    </b>
                </div>
            </div>}
        </div>
    )
}
