import MainPageWrapper from "../../components/Main-page-wrapper";
import React from "react";
import DashHomeSingle from "../../components/Dashboard/Home/single";
import {useRouteMatch} from "react-router-dom";
import DashHomeCompare from "../../components/Dashboard/Home/compare";

export default function DashboardHome(){
    const {params: {type}} = useRouteMatch();
  return <MainPageWrapper>
      {type === 'single' ? <DashHomeSingle/> : <DashHomeCompare/>}
  </MainPageWrapper>
}