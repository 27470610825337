import React from "react";
import style from "./style.module.scss"
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PDFDownloadLink, PDFViewer, Page, Text, View, Document, StyleSheet } from 'react-pdf-renderer-v2';
import { isObjectEmpty, deprecatedBenchmarkCheck } from "../../../utils";

export default function DashHeaderCompare({ name, defaultSet}) {
  const { pathname } = useLocation();
  const { params: { type } } = useRouteMatch();
  const { t } = useTranslation();
  const idList0 = useSelector(state => state.dashboardData.idList[0]);
  const idList1 = useSelector(state => state.dashboardData.idList[1]);
  const benchmarks = useSelector(state => state.userData.benchmarks);
  const userName = useSelector(state => state.userData.user.user_name);
  const resultData2 = useSelector(state => state.dashboardData?.resultList[1]);
  const resultListResult = useSelector(state => state.dashboardData.resultList[0]?.results || []);
  const resultListCheck = useSelector(state => state.dashboardData.resultList[0]);

  const OneBenchmark = () => {
    if (!!resultListResult["benchmarkId"]) {
      if (Object.keys(resultListResult["benchmarkId"]).length === 1) {
        return true
      }
    }
    return false
  }

  return (
    <div className={`${style.mainCont}`}>
      <div className={`${style.firstRow}`}>
        <div className={`d-flex align-items-center`} style={{ gridGap: "10px" }}>
          <NavLink to={'/archive'}>
            <Button variant="contained" color="primary" className={`${style.breadcrumbs}`}>
              <ArrowLeftIcon />
              {t('archive')}
            </Button>
          </NavLink>
          {pathname !== `/dashboard/${type}` &&
            <NavLink to={`/dashboard/${type}`}>
              <Button variant="contained" color="primary" className={`${style.breadcrumbs}`}>
                <ArrowLeftIcon />
                {t('dashFiltersboard')}
              </Button>
            </NavLink>
          }
        </div>
        <div className={`${style.variants}`}>
          <div className={`${style.variantsCont}`}>
            {!idList0?.length && <div className={`${style.variant}`} style={{ backgroundColor: "#cee0ff", color: "#00091D" }}>
              <NavLink to={'/dashboard/compare/filters/single'}>
                <p className={`m-0 ${style.text}`}>{t('Auswahll1')}</p>
              </NavLink>
            </div>}
            {!idList1?.length && <div className={`${style.variant}`} style={{ marginRight: "27px", backgroundColor: "#fae9c8", color: "#efa90b" }}>
              <NavLink to={'/dashboard/compare/filters/double'}>
                <p className={`m-0 ${style.text}`}>{t('Auswahll2')}</p>
              </NavLink>
            </div>}
          </div>
        </div>
      </div>
      <div className={`${style.secondRow} p-2`} style={{ width: "98%" }}>
        <div className={`d-flex justify-content-between col-12 col-lg-7`} style={{ gridGap: "15px" }}>
          <h1 className={`col-12 col-lg-5 mb-2 m-md-0`}>{t("compare-dashboard")} {name}</h1>
        </div>
        {defaultSet && <p style={{ fontStyle: "italic" }} className={'pl-4 pr-4 pt-2 pb-0'} dangerouslySetInnerHTML={{ __html: t('defaultDashboardText') }} />}
      </div>
    </div>
  )
}
