import React from "react";
import style from "./style.module.sass";
import {NavLink} from "react-router-dom";
import closeModalWhite from "./assets/ionic-ios-close-circle.svg"
import arrowTop from "./assets/arrorTop.svg"
import errorIcon from "./assets/errorIcon.svg";
import infoCircle from "./assets/info-circle.svg";
import rightArrow from "./assets/rightArrow.svg";
import {useTheme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

function Notification({open, setOpen}) {
  const {theme: {bgColors, borderColors, colors}} = useTheme();
  const {t} = useTranslation();

    // get reducers
    const messages = useSelector(state => state.userData.messages).getWarningsAndInfo();

  return (
    <div style={{backgroundColor: bgColors.bgPrimary0, transform: open ? "translateY(10px)" : "translateY(-1200px)"}}
         className={style.select}>
      <img className={style.triangle} src={arrowTop} alt=""/>
      <div onClick={() => setOpen(false)}
           className={`d-flex align-items-center pb-1 ${style.options}`}>
        <img className={`mr-2 ml-0 ${style.close}`} src={closeModalWhite} alt=""/>
        <p className={`m-0`}>{t("Notifications")}</p>
      </div>
      {messages[0]?.length && messages[0].map(o => (
        <NavLink
            style={{borderColor: borderColors.borderPrimary300, gridGap: "5px"}}
            key={o.text}
            to={`/notification/${o.id}`}
            exact={true}
            className={`d-flex align-items-center ${style.options}`}>
          <img src={o.level === 'info' ? infoCircle : errorIcon} alt=""/>
          <p style={{color: !o.read_time  ? colors.colorStatusError : colors.colorPrimary200}} className={`m-0 `}>
              {o.text.substr(0, 20)}
          </p>
          <img className={style.arrowRight} src={rightArrow} alt=""/>
        </NavLink>
      ))}
    </div>
  )
}

export default Notification;
