import React from "react";
import Chart from "react-apexcharts";
import style from "./style.module.scss"
import {useTheme} from "@material-ui/core/styles";
import {useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ColumnCharts({seriesClauses, seriesSentences, seriesWords, categories}) {
  const {params: {type}} = useRouteMatch();
  const {theme: {bgColors}} = useTheme();
  const {t} = useTranslation();
  const textsColors = ["#415c98", "#efa90b"];

  const optionsClauses = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: Object.keys(categories.clauses),
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + "%";
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " %"
        }
      }
    }
  };
  const optionsSentences = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: Object.keys(categories.sentences),
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + "%";
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " %"
        }
      }
    }
  };
  const optionsWords = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: Object.keys(categories.words),
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + "%";
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " %"
        }
      }
    }
  };


  return (
    <div className={`${style.charts} ${style.cells}`} style={{backgroundColor: bgColors.bgPrimary0}}>
      <div>
        <h2>{t("CLAUSES LENGTH")}</h2>
        <Chart options={{colors: type !== "texts" ? ['#7798bd'] : textsColors, ...optionsClauses}} series={seriesClauses} type="bar" height={350}/>
      </div>
      <div>
        <h2>{t("SENTENCES LENGTH")}</h2>
        <Chart options={{colors: type !== "texts" ? ['#69499d'] : textsColors, ...optionsSentences}} series={seriesSentences} type="bar" height={350}/>
      </div>
      <div>
        <h2>{t("WORDS LENGTH")}</h2>
        <Chart options={{colors: type !== "texts" ? ['#9d499a'] : textsColors, ...optionsWords}} series={seriesWords} type="bar" height={350}/>
      </div>
    </div>
  )
}
