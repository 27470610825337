import React from "react";
import MainPageWrapper from "../../../Main-page-wrapper";
import Form from "./Form/Form";

function GroupUpdateComponent() {
  return (
    <MainPageWrapper>
      <Form/>
    </MainPageWrapper>
  )
}

export default GroupUpdateComponent;
