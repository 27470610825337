import React from "react";
import style from "./style.module.scss"
import VocabularyChart from "./VocabularyChart";
import {useTheme} from "@material-ui/core/styles";
import DashHeader from "../Header";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {convertLanguagesToShort} from "../../../utils";


export default function SpeechClimateComp() {
    const {theme: {colors}} = useTheme();
    const {i18n: {language: i18nLanguage}} = useTranslation();
    const archiveName = useSelector(state => state.analyseData.archives?.name);
    const benchmark = useSelector(state => state.analyseData.result.benchmark);

    return (
        <div className={style.mainCont}>
            <DashHeader archiveName={archiveName} benchmarkName={benchmark['name'][convertLanguagesToShort(i18nLanguage)]}/>
            <div className={style.tagCont}>
                <VocabularyChart termCategory={"colorYellow"} color={colors.colorProgress40} title={"INNOVATIV"}/>
                <VocabularyChart termCategory={"colorRed"} color={colors.colorProgress20} title={"EMOTIONAL"}/>
            </div>
            <div className={style.tagCont}>
                <VocabularyChart termCategory={"colorBlue"} color={colors.colorBlue1000} title={"RATIONAL"}/>
        <VocabularyChart termCategory={"colorGreen"} color={colors.colorProgress80} title={"KONSERVATIV"}/>
      </div>
    </div>
  )
}
