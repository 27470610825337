import style from "./style.module.scss";
import ColumnChart from "../../../UI/Charts/Column";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";

export default function SingleDistribution() {
    const { theme: { bgColors } } = useTheme();
    const { params: { type } } = useRouteMatch();
    const { t } = useTranslation();
    const archiveResult = useSelector(state => state.dashboardData.archiveResult);

    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const wordSizes = [archiveResult['countArticles'].length, archiveResult['countPronouns'].length,
    archiveResult['countNouns'].length, archiveResult['countVerbs'].length,
    archiveResult['countAdjectives'].length, archiveResult['countAdverbs'].length]

    //const maxLength = wordSizes.reduce((acc, val) => val > acc ? val : acc, 0);
    const maxLength = archiveResult['countWords'] || 1;

    const yourText = [
        currentBenchmarkSettings?.settings?.countArticles?.values["mean"],
        currentBenchmarkSettings?.settings?.countPronouns?.values["mean"],
        currentBenchmarkSettings?.settings?.countNouns?.values["mean"],
        currentBenchmarkSettings?.settings?.countVerbs?.values["mean"],
        currentBenchmarkSettings?.settings?.countAdjectives?.values["mean"],
        currentBenchmarkSettings?.settings?.countAdverbs?.values["mean"]
    ]
    //const lowerArticles = currentBenchmarkSettings?.settings?.countArticles?.values?.lower || 0;

    const upperArticles = currentBenchmarkSettings?.settings?.countArticles?.values?.upper || 0;
    const upperNouns = currentBenchmarkSettings?.settings?.countNouns?.values?.upper || 0;
    const upperVerbs = currentBenchmarkSettings?.settings?.countVerbs?.values?.upper || 0;
    const upperPronouns = currentBenchmarkSettings?.settings?.countPronouns?.values?.upper || 0;
    const upperAdjectives = currentBenchmarkSettings?.settings?.countAdjectives?.values?.upper || 0;
    const lowerAdjectives = currentBenchmarkSettings?.settings?.countAdjectives?.values?.lower || 0;
    const lowerPronouns = currentBenchmarkSettings?.settings?.countPronouns?.values?.lower || 0;

    let bottomText = [];

    if (archiveResult['countArticles'].length * 100 / maxLength > upperArticles && archiveResult['countNouns'].length * 100 / maxLength > upperNouns) {
        let textIs;
        try {
            textIs = JSON.parse(t("nouns(+articles)>typical"))
        } catch (e) {
            textIs = {}
        }
        bottomText.push({ text: textIs, textEnd: "primary", descr: t("nouns(+articles)>typical.description") })
    }

    if (archiveResult['countVerbs'].length * 100 / maxLength > upperVerbs) {
        let textIs;
        try {
            textIs = JSON.parse(t("verbs>typical"))
        } catch (e) {
            textIs = {}
        }
        bottomText.push({ text: textIs, textEnd: "primary", descr: t("verbs>typical.description") })
    }

    if (archiveResult['countPronouns'].length * 100 / maxLength > upperPronouns) {
        let textIs;
        try {
            textIs = JSON.parse(t("pronouns>typical"))
        } catch (e) {
            textIs = {}
        }
        if (bottomText.length > 0)
            bottomText.push({ text: textIs, textEnd: "secondary", descr: t("pronouns>typical.description") })
        else
            bottomText.push({ text: textIs, textEnd: "primary", descr: t("pronouns>typical.description") })
    }

    if (archiveResult['countAdjectives'].length * 100 / maxLength > upperAdjectives) {
        let textIs;
        try {
            textIs = JSON.parse(t("adjectives>typical"))
        } catch (e) {
            textIs = {}
        }
        if (bottomText.length > 0)
            bottomText.push({ text: textIs, textEnd: "secondary", descr: t("adjectives>typical.description") })
        else
            bottomText.push({ text: textIs, textEnd: "primary", descr: t("adjectives>typical.description") })
    }

    if (archiveResult['countAdjectives'].length * 100 / maxLength < lowerAdjectives) {
        let textIs;
        try {
            textIs = JSON.parse(t("adjectives<typical"))
        } catch (e) {
            textIs = {}
        }
        if (bottomText.length > 0)
            bottomText.push({ text: textIs, textEnd: "secondary", descr: t("adjectives<typical.description") })
        else
            bottomText.push({ text: textIs, textEnd: "primary", descr: t("adjectives<typical.description") })
    }

    if (archiveResult['countPronouns'].length * 100 / maxLength < lowerPronouns) {
        let textIs;
        try {
            textIs = JSON.parse(t("pronouns<typical"))
        } catch (e) {
            textIs = {}
        }
        if (bottomText.length > 0)
            bottomText.push({ text: textIs, textEnd: "secondary", descr: t("pronouns<typical.description") })
        else
            bottomText.push({ text: textIs, textEnd: "primary", descr: t("pronouns<typical.description") })
    }

    const wordFuncCharts = [
        {
            id: 0,
            firstCol: {
                color: "rgb(164, 198, 255)",
                value: wordSizes[0] / (Math.max(...wordSizes) / 100)
            },
            secondCol: {
                value: currentBenchmarkSettings?.settings?.countArticles?.values["mean"] / (Math.max(...yourText) / 100) || 0,
                color: "#415c91"
            },
            name: t("Articles")
        },
        {
            id: 1,
            firstCol: {
                color: "rgb(164, 198, 255)",
                value: wordSizes[1] / (Math.max(...wordSizes) / 100)
            },
            secondCol: {
                value: currentBenchmarkSettings?.settings?.countPronouns?.values["mean"] / (Math.max(...yourText) / 100) || 0,
                color: "#415c91"
            },
            name: t("Pronouns")
        }
    ]
    const contentCharts = [
        {
            id: 2,
            firstCol: {
                color: "rgb(164, 198, 255)",
                value: wordSizes[2] / (Math.max(...wordSizes) / 100)
            },
            secondCol: {
                value: currentBenchmarkSettings?.settings?.countNouns?.values["mean"] / (Math.max(...yourText) / 100) || 0,
                color: "#415c91"
            },
            name: t("Nouns")
        },
        {
            id: 3,
            firstCol: {
                color: "rgb(164, 198, 255)",
                value: wordSizes[3] / (Math.max(...wordSizes) / 100)
            },
            secondCol: {
                value: currentBenchmarkSettings?.settings?.countVerbs?.values["mean"] / (Math.max(...yourText) / 100) || 0,
                color: "#415c91"
            },
            name: t("Verbs")
        },
        {
            id: 4,
            firstCol: {
                color: "rgb(164, 198, 255)",
                value: wordSizes[4] / (Math.max(...wordSizes) / 100)
            },
            secondCol: {
                value: currentBenchmarkSettings?.settings?.countAdjectives?.values["mean"] / (Math.max(...yourText) / 100) || 0,
                color: "#415c91"
            },
            name: t("Adjectives")
        },
        {
            id: 5,
            firstCol: {
                color: "rgb(164, 198, 255)",
                value: wordSizes[5] / (Math.max(...wordSizes) / 100)
            },
            secondCol: {
                value: currentBenchmarkSettings?.settings?.countAdverbs?.values["mean"] / (Math.max(...yourText) / 100) || 0,
                color: "#415c91"
            },
            name: t("Adverbs")
        },
    ]

    console.log('wordFuncCharts', wordFuncCharts)

    return (
        <div className={`${style.distribution_main}`} style={{padding : "15px"}}>
          <>
             <div className={`${style.distribution_head} mb-3`}>
            </div>
            <div className={`d-flex`}>
                <div className={`${style.dist_cols} col-8 p-0`}>
                    <div className={`${style.dist_col} ${style.dist_col1}`}>
                        <h4>{t("Function words")}</h4>
                        <ColumnChart charts={wordFuncCharts} />
                    </div>
                    <div className={`${style.dist_col} ${style.dist_col2}`}>
                        <h4>{t("Content words")}</h4>
                        <ColumnChart charts={contentCharts} />
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginTop: "-160px", marginLeft: "150px" }}>
                <div className={`${style.distribution_head} mb-3`}>
                    <div className={`${style.distDesc} col-4 p-0`} >
                        {type !== "texts" ?
                            <>
                                <div className={`d-flex align-items-center mr-3`}>
                                    <div style={{ backgroundColor: bgColors.bgBlue300 }} className={style.colorBlock} />
                                    <p>{t("Your text")}</p>
                                </div>
                                <br />
                                <div className={`d-flex align-items-center`}>
                                    <div style={{ backgroundColor: bgColors.bgBlue1000 }} className={style.colorBlock} />
                                    <p>{t("Typical letter")}</p>
                                </div>
                            </>
                            : ""
                        }
                        <br />
                        {bottomText.map((v, i) =>
                            <p key={i}>
                                <span style={{ wordBreak: "break-all" }}>
                                    <br />
                                    {v.text[v.textEnd]}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            </div></>
        </div>
    )
}
