import style from "./style.module.sass"
import React, {useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import {Modal} from "@material-ui/core";
import closeIcon from "../../../assets/close-circle.svg";
import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {Button} from "@material-ui/core";
import classnames from 'classnames';
import { useTranslation } from "react-i18next";

export default function WordExportModal({ setOpen, title = 'Save'}) {
  const {theme: {bgColors}} = useTheme();
  const [activeTab, setActiveTab] = useState('1');
  const [personalValue, setPersonalValue] = React.useState('female');
  const { t } = useTranslation();

  const handleChange = (event) => {
    setPersonalValue(event.target.value);
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  return (
    <Modal open={true} onClose={() => setOpen('')}>
      <div className={`${style.modalMainCont} pb-3`} style={{backgroundColor: bgColors.bgPrimary0}}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
             style={{backgroundColor: bgColors.bgPrimary400}}>
          <p className={`m-0 ${style.title}`}>{title}</p>
          <button className={`border-0 bg-transparent`} onClick={() => setOpen('')}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '1'})}
                onClick={() => {
                  toggle('1');
                }}
              >
               {t('personal-templates')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '2'})}
                onClick={() => {
                  toggle('2');
                }}
              >
                {t('group-templates')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '3'})}
                onClick={() => {
                  toggle('3');
                }}
              >
                {t('company-templates')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12" className={'d-flex align-items-center pr-5 pl-5'} style={{minHeight: 200}}>
                  <FormControl className={'d-flex'} component="fieldset">
                    <RadioGroup aria-label="personal-save" value={personalValue} onClick={handleChange} name="position"
                                defaultValue="top">
                      <Button color='primary' variant='contained' size='small'>{t('do-not-use')}</Button>
                      <FormControlLabel
                        value="with-label"
                        control={<Radio color="primary"/>}
                        label="lorem ipsum dolor"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12" className={'d-flex align-items-center pr-5 pl-5'} style={{minHeight: 200}}>
                  <FormControl className={'d-flex'} component="fieldset">
                    <RadioGroup aria-label="personal-save" value={personalValue} onClick={handleChange} name="position"
                                defaultValue="top">
                      <Button color='primary' variant='contained' size='small'>{t('do-not-use')}</Button>
                      <FormControlLabel
                        value="with-label"
                        control={<Radio color="primary"/>}
                        label="lorem ipsum dolor"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12" className={'d-flex align-items-center pr-5 pl-5'} style={{minHeight: 200}}>
                  <FormControl className={'d-flex'} component="fieldset">
                    <RadioGroup aria-label="personal-save" value={personalValue} onClick={handleChange} name="position"
                                defaultValue="top">
                      <Button color='primary' variant='contained' size='small'>{t('do-not-use')}</Button>
                      <FormControlLabel
                        value="with-label"
                        control={<Radio color="primary"/>}
                        label="lorem ipsum dolor"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        <div className={`d-flex justify-content-center pt-3`} style={{gridGap: 10}}>
          <Button color='primary' variant='contained' >
            {t('export')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
