import TagCloud from "react-tag-cloud";
import React from "react";


export default function HomeWordsCloud({wordsWithFilters}) {

  return (
    <div className={"mainTagCloud"}>
      <div className={"app-outer"}>
        <div className={"app-inner"}>
          <TagCloud className='tag-cloud' style={{padding: 6, fontFamily: 'Roboto'}}>
            {wordsWithFilters.map(w => (
                <div key={w.text} rotate={w.rotate} style={{
                  fontSize: w.fontSize,
                  fontWeight: "bolder",
                  color: w.color,
                }}>{w.text}</div>
            ))}
          </TagCloud>
        </div>
      </div>
    </div>
  )
}
