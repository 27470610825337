import React, { useEffect } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ApartmentIcon from '@material-ui/icons/Apartment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { archiveSetArchiveType, tableSetFilters, tableSetSelectAll, tableSetSelected } from '../../../actions'

import ArchiveManager from '../../common/ArchiveManager'
import { useActions } from '../../../utils/action-helper'
import { checkArchiveRights, getFolderFilter } from '../../../utils/archives'

export default function ArchiveDashboard () {
  const { theme: { bgColors, colors } } = useTheme()
  const { t } = useTranslation()
  const archiveType = useSelector(state => state.archiveData.archiveType)
  const userArchives = useSelector(state => state.userData.user.archives)
  const userType = useSelector(state => state.userData.user.user_type_id)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const filters = useSelector(state => state.tableData.filters)

  const filterList = {}
  filterList['personal'] = {
    value: 'personal',
    filter: [['&user_id', `=${useSelector(state => state.userData.user.id)}`]]
  }
  filterList['group'] = {
    value: 'group',
    filter: [['&user_group_id', `=${useSelector(state => state.userData.user.user_group_id)}`]]
  }
  filterList['company'] = {
    value: 'company',
    filter: [['&company_id', `=${useSelector(state => state.userData.user.company_id)}`]]
  }

  const buttons = {
    personal: { text: `${t('personal-archive')}`, img: <PersonIcon className={`mr-2`}/> },
    group: { text: `${t('group-archive')}`, img: <PeopleAltIcon className={`mr-2`}/> },
    company: { text: `${t('company-archive')}`, img: <ApartmentIcon className={`mr-2`}/> }
  }

  const { aArchiveSetArchiveType, aTableSetFilters, aTableSetSelected, aTableSetSelectAll } = useActions({
    aArchiveSetArchiveType: archiveSetArchiveType,
    aTableSetFilters: tableSetFilters,
    aTableSetSelected: tableSetSelected,
    aTableSetSelectAll: tableSetSelectAll
  })

  function handleClickButtonType (enterType) {
    aTableSetSelected([])
    aTableSetSelectAll(false)
    aArchiveSetArchiveType(enterType)
    aTableSetFilters({ ...filters, type: filterList[enterType] })

  }

  const ShowButton = ({ type, active }) => {
    return <button key={type} style={active ? {
      color: colors.colorBlue1000,
      backgroundColor: bgColors.bgBlue200,
    } : { color: colors.colorBlue1000 }}
                   onClick={() => handleClickButtonType(type)}
                   className={`${style.archiveBtns} ${active && style.archiveWithFilter} pl-4 pr-2 mt-1 mb-2 d-flex align-items-center`}>
      {buttons[type].img}
      {buttons[type].text}
    </button>
  }

  const handleSelectFolder = (id, tFolders, names) => {
    aTableSetSelected([])
    aTableSetSelectAll(false)
    aTableSetFilters(getFolderFilter(id, filters))
  }

  const handleMassSelectFolder = (folderSelected) => {
    if (folderSelected.includes(0)) {
      aTableSetFilters({
        ...filters,
        folder_id: {
          value: folderSelected,
          filter: [['|folder_id', `i=${JSON.stringify(folderSelected)}`], ['|folder_id', `=`]]
        }
      })
    } else {
      aTableSetFilters({
        ...filters,
        folder_id: { value: folderSelected, filter: [['&folder_id', `i=${JSON.stringify(folderSelected)}`]] }
      })
    }
  }

  useEffect(() => {
    aTableSetFilters({ ...filters, type: filterList[archiveType] })
  }, [archiveType])

  return (
    <div className={`${style.mainCont}`}>
      <NavLink to="/" exact={true}>
        <button style={{ color: colors.colorPrimary500 }}
                className={`d-flex align-items-center ${style.breadcrumb} pl-3`}>
          <svg className={`mr-3`} width="10" height="17" viewBox="0 0 10 17" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.88112 8.39403L9.05912 2.22103C9.16773 2.11304 9.25392 1.98465 9.31273 1.84324C9.37154 1.70183 9.40181 1.55018 9.40181 1.39703C9.40181 1.24387 9.37154 1.09223 9.31273 0.950819C9.25392 0.809407 9.16773 0.681015 9.05912 0.573029C8.83901 0.355564 8.54205 0.233614 8.23262 0.233614C7.9232 0.233614 7.62624 0.355564 7.40613 0.573029L0.407125 7.56803C0.195563 7.78035 0.0739754 8.06605 0.0676431 8.36572C0.0613108 8.66538 0.170721 8.95596 0.373125 9.17703L7.40112 16.22C7.61966 16.4392 7.91633 16.5626 8.22586 16.5631C8.53539 16.5636 8.83242 16.4411 9.05162 16.2225C9.27083 16.004 9.39423 15.7073 9.3947 15.3978C9.39517 15.0883 9.27266 14.7912 9.05412 14.572L2.88112 8.39403Z"
              fill="#707070"/>
          </svg>
          {t('start')}
        </button>
      </NavLink>

      <div className={`d-flex flex-column mt-5`}>
        <ShowButton type={'personal'} active={archiveType === 'personal'}/>
        {archiveType === 'personal' && <ArchiveManager
          type={'personal'}
          forceReloadOnOpen={true}
          localeName={archiveLanguage}
          externalFolderClick={handleSelectFolder}
          externalShowSelectedClick={handleMassSelectFolder}
        />}
        {checkArchiveRights('group', userType, userArchives) ?
          <ShowButton type={'group'} active={archiveType === 'group'}/> : null}
        {archiveType === 'group' && <ArchiveManager
          type={'group'}
          forceReloadOnOpen={true}
          localeName={archiveLanguage}
          externalFolderClick={handleSelectFolder}
          externalShowSelectedClick={handleMassSelectFolder}
        />}
        {checkArchiveRights('company', userType, userArchives) ?
          <ShowButton type={'company'} active={archiveType === 'company'}/> : null}
        {archiveType === 'company' && <ArchiveManager
          type={'company'}
          forceReloadOnOpen={true}
          localeName={archiveLanguage}
          externalFolderClick={handleSelectFolder}
          externalShowSelectedClick={handleMassSelectFolder}
        />}
      </div>
    </div>
  )
}


