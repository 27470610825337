import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import TypeOfField from "../../../../FormikFields/TypeOfField";
import AutoFill from "../../../../FormikFields/AutoFill";
import { useTranslation } from "react-i18next";
import config from "../../../../../config"

function Options({ formik, optionList }) {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const { theme: { bgColors } } = useTheme();
  const [autoFillStatus, setAutoFillStatus] = useState(null);
  const [autoFillStatusForMouse, setAutoFillStatusForMouse] = useState(null);

  //AutoFill functions to change state
  const handleCompany = () => {
    setAutoFillStatus('company')
    formik.setFieldValue("showSpelling", true)
    formik.setFieldValue("showArchive", true)
    formik.setFieldValue("saveTextAfterSession", true)
    formik.setFieldValue("emailNotificationOnWord", 1)
    formik.setFieldValue("defaultBenchmark", 1)
    formik.setFieldValue("defaultLanguage", 1)
  }

  const handleGroup = () => {
    setAutoFillStatus('group')
    formik.setFieldValue("showSpelling", false)
    formik.setFieldValue("showArchive", false)
    formik.setFieldValue("saveTextAfterSession", false)
    formik.setFieldValue("emailNotificationOnWord", 2)
    formik.setFieldValue("defaultBenchmark", 2)
    formik.setFieldValue("defaultLanguage", 2)
  }

  // const benchmarks = [
  //   { id: 1, name: "[de] Analyse Brief" },
  //   { id: 2, name: "[dA] Analyse Brief" },
  //   { id: 3, name: "[dU] Analyse Brief" },
  // ];
  // const languages = [
  //   { id: 1, name: "Deutsch" },
  //   { id: 2, name: "English" },
  //   { id: 3, name: "Russian" },
  // ];
  // const emailNotify = [
  //   { id: 1, name: t("no notifications") },
  //   { id: 2, name: t("with notifications") },
  // ];
  // const fields = [
  //   { id: "2", control: "select", name: "defaultLanguage", label: t("Default language"), options: languages },
  //   {
  //     id: "3",
  //     control: "select",
  //     name: "emailNotificationOnWord",
  //     label: t("Email notification on word suggestions"),
  //     options: emailNotify
  //   },
  //   { id: "1", control: "select", name: "defaultBenchmark", label: t("Default benchmark"), options: benchmarks },
  // ];
  // const fieldsCompany = [
  //   { id: "2", control: "select", value: 1, label: t("Default language"), options: languages },
  //   {
  //     id: "3", value: 1,
  //     control: "select",
  //     label: t("Email notification on word suggestions"),
  //     options: emailNotify
  //   },
  //   { id: "1", control: "select", value: 1, label: t("Default benchmark"), options: benchmarks },
  // ];
  // const fieldsGroup = [
  //   { id: "2", control: "select", value: 2, label: t("Default language"), options: languages },
  //   {
  //     id: "3", value: 2,
  //     control: "select",
  //     label: t("Email notification on word suggestions"),
  //     options: emailNotify
  //   },
  //   { id: "1", control: "select", value: 2, label: t("Default benchmark"), options: benchmarks },
  // ];

  const Input = (f, value) => {
    const label = `admin_user_option_${f.name}`
    if (f.type === "boolean") {
      return <TypeOfField disabled={value} control="switch" formik={formik} name={f.name} style={{ maxWidth: "300px", width: "400px" }}
        {...f} />
    } else if (f.type === "datetime") {
      return <TypeOfField disabled={value} control="date" formik={formik} name={f.name} label={t(label)} {...f} />
    } else {
      return <TypeOfField disabled={value} control="text" formik={formik} name={f.name} style={{ maxWidth: "300px", width: "400px" }}
        label="value" {...f} />
    }
  }

  return (
    <div style={{ backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50 }} onMouseEnter={() => setBg(true)}
      onMouseLeave={() => setBg(false)} className={`position-relative`}>
      <AutoFill title={t("Options")} state={autoFillStatus} setAutoFillState={setAutoFillStatus} stateForMouse={autoFillStatusForMouse}
        setStateForMouse={setAutoFillStatusForMouse} handleCompany={handleCompany} handleGroup={handleGroup} />
      <div>
        <br />
        {/* <div className={`d-flex justify-content-between pr-4 pl-4 ml-2 mr-2 pt-3`}>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"} formik={formik}
                       name={'showSpelling'}
                       label={t('Show spelling')}/>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"} formik={formik}
                       name={'showArchive'}
                       label={t('Show archive')}/>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"} formik={formik}
                       name={'saveTextAfterSession'}
                       label={t('Save text after session')}/>
        </div> */}
        <ul className={`mb-0`}>
          {
            // optionList?.length && optionList.filter(opt => opt.type !== 'object')
            optionList?.length && optionList.filter(opt => config.enabledUserOptions.includes(opt.name))
            .map((f, i) => (
              <li key={i}>
                  <div style={{ display: "flex", width:"100%"}}>
                  {(formik.values.company === '') ?
                    Input(f, true) :
                    Input(f, false)
                  }
                </div>
              </li>
            )
            )
          }
        </ul>
      </div>
      {/* {autoFillStatusForMouse === "company" &&
      <div className={`position-absolute ${style.autofillBlock}`}>
        <div style={{backgroundColor: bgColors.bgBlue50}}
             className={`d-flex justify-content-between pr-4 pl-4 ml-2 mr-2  pt-3`}>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"}
                       status={"company"}
                       formik={formik}
                       label={t('Show spelling')}/>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"}
                       status={"company"}
                       formik={formik}
                       label={t('Show archive')}/>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"}
                       status={"company"}
                       formik={formik}
                       label={t('Save text after session')}/>
        </div>
        <ul style={{backgroundColor: bgColors.bgBlue50}}
            className={`mb-0`}>
          {
            fieldsCompany.map(
              f => (
                <li key={f.id}>
                  <TypeOfField setAutoFillState={setAutoFillStatus} value={1} control={f.control} formik={formik}
                               name={f.name} label={f.label} {...f}/>
                </li>
              )
            )
          }
        </ul>
      </div>}
      {autoFillStatusForMouse === "group" &&
      <div className={`position-absolute ${style.autofillBlock}`}>
        <div style={{backgroundColor: bgColors.bgBlue50}}
             className={`d-flex justify-content-between pr-4 pl-4 ml-2 mr-2 pt-3`}>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"} status={"group"}
                       formik={formik}
                       label={t('Show spelling')}/>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"} status={"group"}
                       formik={formik}
                       label={t('Show archive')}/>
          <TypeOfField setAutoFillState={setAutoFillStatus} labelPlacementEnd={true} control={"switch"} status={"group"}
                       formik={formik}
                       label={t('Save text after session')}/>
        </div>
        <ul style={{backgroundColor: bgColors.bgBlue50}}
            className={`mb-0`}>
          {
            fieldsGroup.map(
              f => (
                <li key={f.id}>
                  <TypeOfField setAutoFillState={setAutoFillStatus} value={2} control={f.control} formik={formik}
                               label={f.label} {...f}/>
                </li>
              )
            )
          }
        </ul>
      </div>} */}
    </div>
  )
}

export default Options;
