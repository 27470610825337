export const BgColors = {
  bgColors: {
    //light white background color variables
    bgPrimary0: "#ffffff",
    bgPrimary100: "#F0F0F0",
    bgPrimary200: "#dedede",
    bgPrimary300: "#A0A0A0",
    bgPrimary400: "#888888",
    bgPrimary500: "#585858",
    bgPrimary600: "#434343",
    bgPrimary900: "#171717",
    bgPrimary1000: "#000000",
    bgRadial:"#e2e1e1",

    //light status background color variables
    bgStatusError: "#BF1A1A",
    bgStatusTrue: "#3EAA1B",
    bgStatusInfo: "#415C98",

    //light progress background color variables
    bgProgress20: "#DD0D08",
    bgProgress40: "#E46D19",
    bgProgress60: "#EACD2A",
    bgProgress80: "#A1BE23",
    bgProgress100: "#43AB1B",
    bgProgress120: "#415C98",

    // light blue background color variables
    bgBlue50: "#f8fff7",
    bgBlue100: "#F4F7FC",
    bgBlue150: "#e7effc",
    bgBlue200: "#CEE0FF",
    bgBlue300: "#A4C6FF",
    bgBlue1000: "#3F51B5",
    bgYellow1000: "#efa90b",

    // light color variables for analysis color buttons
    bgLightGreen: "#90EE90",
    bgLightYellow : "#FCF787",
    bgLightPink : "#FADADD"

  },
};
