import { FormControl, Button, Select, MenuItem, Modal, TextField, Collapse } from '@material-ui/core'
import React, { useEffect, useState, useMemo } from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import style from './batch.module.sass'

import closeIcon from '../Overview/Orthography/SpellCheck/assets/close-circle.svg'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {
  convertJSONToObject,
  convertLanguagesToFull,
  convertLanguagesToShort,
  isObjectEmpty,
  onlyUnique
} from '../../../utils'
import { useSelector, useDispatch } from 'react-redux'
import config from '../../../config'
import { filterBenchmark } from '../analysisFunctions'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import BatchProcessingServiceComponent from './BatchProcessingServiceComponent'
import ArchiveManager from '../../common/ArchiveManager'
import { checkArchiveRights } from '../../../utils/archives'

export default function BatchModal ({ open, setOpen, files, setError }) {

  const { theme: { colors, bgColors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const dispatch = useDispatch()
  const tlService = React.useContext(TLServiceComponent)
  const batchProcessingService = React.useContext(BatchProcessingServiceComponent)

  const storageLanguageTextType = convertJSONToObject(
    useSelector((state) => state.userData?.user?.user_options?.analyse_language_texttype),
    config.defaultAnalyseLanguageTextType
  )
  if (typeof storageLanguageTextType.textType !== 'object') {
    storageLanguageTextType.textType = {}
  }

  //const backendLanguages= useSelector(state => state.userData.user?.backend_languages || {})

  const token = useSelector(state => state.userData.token)
  const userId = useSelector(state => state.userData.user.id)
  const userGroupId = useSelector(state => state.userData.user.user_group_id)
  const companyId = useSelector(state => state.userData.user.company_id)
  const userArchives = useSelector(state => state.userData.user.archives)
  const userType = useSelector(state => state.userData.user.user_type_id)
  const userName = useSelector(state => state.userData.user.user_name)
  const maxRequestSize = useSelector(state => state.openData?.ping?.maxRequestSize || 1000000)
  const maxAnalizeTextSize = useSelector(state => state.openData?.ping?.maxAnalizeTextSize || 50000)
  const returnValues = useSelector(state => state.openData.return_values)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})

  // analyse_language_texttype(pin): "{"language":"de_DE","textType":85}"
  const defaultLang = storageLanguageTextType.language === 'auto' ? convertLanguagesToFull(i18nLanguage) : storageLanguageTextType.language

  const allFilteredBenchmarks = useMemo(() => Object.keys(backendLanguages).reduce((acc, curr) => {
    acc[curr] = filterBenchmark(benchmarks, curr, 'locale_name')
    return acc
  }, {}), [benchmarks, backendLanguages])

  const defaultBenchmark = storageLanguageTextType.textType[defaultLang] ? storageLanguageTextType.textType[defaultLang] :
    isObjectEmpty(allFilteredBenchmarks[defaultLang]) ? '0' : allFilteredBenchmarks[defaultLang][Object.keys(allFilteredBenchmarks[defaultLang])[0]].id.toString()

  const [valBenchmark, setValBenchmark] = useState(defaultBenchmark)
  const [valLanguage, setValLanguage] = useState(defaultLang)
  const [currentBenchmarkList, setCurrentBenchmarkList] = useState(allFilteredBenchmarks[valLanguage] || {})

  const [archiveType, setArchiveType] = useState('personal')
  const [keywords, setKeywords] = useState('')
  const [formData, setFormData] = useState({
    personal: {
      pathName: [t('root_folder')],
      folder: 0
    },
    group: {
      pathName: [t('root_folder')],
      folder: 0
    },
    company: {
      pathName: [t('root_folder')],
      folder: 0
    }
  })

  // update the valBenchmark after a list on enabled benchmarks is loaded
  useEffect(() => {
      if (defaultBenchmark > 0) {
        setValBenchmark(defaultBenchmark)
        setCurrentBenchmarkList(allFilteredBenchmarks[valLanguage] || {})
      }
    }
    , [allFilteredBenchmarks, defaultBenchmark, valLanguage])

  // update on language change
  useEffect(() => {
      if (defaultBenchmark > 0) {
        setValBenchmark(storageLanguageTextType.textType[valLanguage] ? storageLanguageTextType.textType[valLanguage] :
          isObjectEmpty(allFilteredBenchmarks[valLanguage]) ? '0' : allFilteredBenchmarks[valLanguage][Object.keys(allFilteredBenchmarks[valLanguage])[0]].id.toString())
      }
      setCurrentBenchmarkList(allFilteredBenchmarks[valLanguage] || {})
    }
    , [valLanguage])

  const handleSubmit = async () => {

    const splitKeywords = keywords.split(',')
      .map(v => v.trim()).filter(v => v?.length).filter(onlyUnique)

    const data = {
      folderID: formData[archiveType]?.folder ? formData[archiveType]?.folder : 0,
      folderType: archiveType,
      keywords: splitKeywords,
      textType: valBenchmark,
      language: valLanguage,
      files: files
    }

    setOpen(false)
    console.log('handleSubmit', data)

    await batchProcessingService.startBatchProcessing(token, dispatch, userName, benchmarks, archiveType, userId, userGroupId, companyId, tlService,
      t, data, returnValues, (type, payload) => {
        dispatch({ type: type, payload: payload })
      }, maxRequestSize, maxAnalizeTextSize)

  }
  if (!open) {
    return null
  }

  return (
    <Modal
      open={open}
      onSubmit={() => {
        setOpen(false)
      }}
      onClose={() => setOpen(false)}
      className="d-flex align-items-center justify-content-center">
      <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
        <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
             style={{ backgroundColor: bgColors.bgPrimary400 }}>
          <p className={`m-0 ${style.title}`}>{t('Where do you want to save your batch?')}</p>
          <button className={`border-0 bg-transparent`} onClick={() => setOpen(false)}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          <Nav tabs fill justify="true" style={{ cursor: 'pointer' }}>
            <NavItem>
              <NavLink
                className={classnames({ active: archiveType === 'personal' })}
                onClick={() => {
                  setArchiveType('personal')
                }}
              >
                {t('Personal archive')}
              </NavLink>
            </NavItem>
            {checkArchiveRights('group', userType, userArchives) ?
              <NavItem>
                <NavLink
                  className={classnames({ active: archiveType === 'group' })}
                  onClick={() => {
                    setArchiveType('group')
                  }}
                >
                  {t('Group archive')}
                </NavLink>
              </NavItem> : null}
            {checkArchiveRights('company', userType, userArchives) ?
              <NavItem>
                <NavLink
                  className={classnames({ active: archiveType === 'company' })}
                  onClick={() => {
                    setArchiveType('company')
                  }}
                >
                  {t('Company archive')}
                </NavLink>
              </NavItem> : null}
          </Nav>
          <TabContent className={'p-3'} activeTab={archiveType}>
            <TabPane tabId="personal">
              <ArchiveTab
                formData={formData}
                setFormData={setFormData}
                setKeywords={setKeywords}
                keywords={keywords}
                type="personal"/>
            </TabPane>
            <TabPane tabId="group">
              <ArchiveTab
                formData={formData}
                setFormData={setFormData}
                setKeywords={setKeywords}
                keywords={keywords}
                type="group"/>
            </TabPane>
            <TabPane tabId="company">
              <ArchiveTab
                formData={formData}
                setFormData={setFormData}
                setKeywords={setKeywords}
                keywords={keywords}
                type="company"/>
            </TabPane>
          </TabContent>
          <div className={`px-3 d-flex flex-column pt-3`} style={{ gridGap: 10 }}>
            <TextField
              label={t('Keywords')}
              size="small"
              variant="outlined"
              value={keywords}
              onChange={e => setKeywords(e.target.value)}/>
            <ShowKeywords keywordsString={keywords}/>
          </div>
          <div className={`px-3 d-flex align-items-center pt-2 justify-content-between`}
               style={{ gridGap: 10 }}>
            <FormControl fullWidth variant="outlined">
              <h6 className={`m-0`}
                  style={{ color: colors.colorPrimary600 }}>{t('Language setting')}</h6>
              <Select
                onChange={e => setValLanguage(e.target.value)}
                variant="standard"
                value={valLanguage}
              >
                {Object.entries(backendLanguages).map(([key, val]) =>
                  <MenuItem key={key} value={key}>
                    {val['nativeName']}
                  </MenuItem>
                )}

              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <h6 className={`m-0`}
                  style={{ color: colors.colorPrimary600 }}>{t('Default Text Type')}</h6>
              <Select
                onChange={e => setValBenchmark(e.target.value)}
                variant="standard"
                value={valBenchmark.toString()}
              >
                <MenuItem key="0" value="0">{t('Set default text type')}</MenuItem>
                {Object.entries(currentBenchmarkList)
                  .map(([keyBench, valBench]) =>
                    <MenuItem
                      key={keyBench.toString()}
                      value={keyBench.toString()}>
                      {valBench['name'][convertLanguagesToShort(i18nLanguage)] || keyBench}
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={`d-flex justify-content-center pt-3`}>
          <Button type="submit" className="col-8" color="primary" variant="contained" size="small"
                  onClick={handleSubmit}>
            {t('batchProcessingModalButton')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const ArchiveTab = ({ type, formData, setFormData }) => {
  const { t } = useTranslation()
  const [openFolders, setOpenFolders] = useState(false)
  const [folderPath, setFolderPath] = useState(formData[type].pathName)

  const localFormData = formData[type]
  if (!localFormData)
    return null

  const handleFolderClick = (id, tFolders, names) => {
    setOpenFolders(false)
    setFormData(d => {
      return { ...d, [type]: { ...d[type], pathName: names, folder: id } }
    })
    setFolderPath(names)
  }

  return (
    <div className={`d-flex flex-column pt-3`} style={{ gridGap: 10 }}>
      <div className="d-flex align-items-center pb-3">
        <div onClick={() => setOpenFolders(s => !s)}>{t('Folder name')} <strong>{folderPath.reverse().join(' / ')}</strong>
          {openFolders ? <ExpandLess/> : <ExpandMore/>}
        </div>
      </div>
      <Collapse className={style.file} in={openFolders} timeout="auto">
        <ArchiveManager
          type={type}
          defaultFolderId={0}
          showCheckbox={false}
          showRootCheckbox={false}
          showAllFiles={false}
          showAddIcon={true}
          showEditIcon={false}
          showDeleteIcon={false}
          showArchivesCount={false}
          showMassFunctions={false}
          externalFolderClick={handleFolderClick}
        />
      </Collapse>
    </div>
  )
}

const ShowKeywords = ({ keywordsString }) => {
  const splitKeywords = keywordsString.split(',').filter(onlyUnique)
  return (
    <div key={'keywords'} className={'d-flex flex-wrap'}>{splitKeywords.map((word, idx) => word?.length ?
      <div key={idx} style={{ backgroundColor: '#c9c9c9', fontSize: '10px' }}
           className={'text-nowrap mr-1 mb-1 p-1 rounded'}>{word.trim()}</div> : null)}</div>
  )
}

