import { Button, Modal, Tooltip, LinearProgress, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import LabeledSwitch from "../../../../components/Fields/LabeledSwitch";
import style from "./style.module.sass";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dnd } from "./Dnd";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import config from "../../../../config";
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { calculateSize, clearCache, clrModal, runBunchUpload } from "./functions"
import { readUploadedFileAsBinary } from "../../../../utils";
import * as XLSX from 'xlsx';
import * as Papa from "papaparse";

export default function TermUploadModal({ open, setOpen, reloadPage }) {
    const { t } = useTranslation();
    const tlService = React.useContext(TLServiceComponent);
    const token = useSelector(state => state.userData.token);
    const { categoryID } = useParams();
    const running = useRef(false);
    const [success, setSuccess] = useState(false);
    const [uploadBarVisible, setUploadBarVisible] = useState(false);
    const [uploadBarNow, setUploadBarNow] = useState(0);
    const [uploadBarText, setUploadBarText] = useState('');
    const [fullCounterText, setFullCounterText] = useState('');
    const [alert, setAlert] = useState('');
    const [disableUpload, setDisableUpload] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [uploadText, setUploadText] = useState(t("Data Upload"));
    const [uploadFile, setUploadFile] = useState(null);
    const [deleteTerms, setDeleteTerms] = useState(false);
    const [clearCategory, setClearCategory] = useState(false);
    const [checkWords, setCheckWords] = useState(false);
    const [updateTerms, setUpdateTerms] = useState(false);
    const [updateDescription, setUpdateDescription] = useState(false);
    const [updateReplacements, setUpdateReplacements] = useState(false);
    const [deletedTerms, setDeletedTerms] = useState('');

    const updateAlert = (text) => {
        setAlert(t(text));
        setTimeout(() => {
            setAlert('');
        }, config.timeOutDuration)
    }

    const updateSuccessTrue = () => {
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
        }, config.timeOutDuration)
    }

    const handleStop = () => {
        running.current = false;
    }

    const handleDND = (acceptedFiles) => {
        setDisableUpload(true);
        if (acceptedFiles?.length !== 1) {
            updateAlert('wrong number of upload files')
            setUploadText(t("Data Upload"));
            setDisableUpload(false);
            setDisableButton(true);
            setUploadFile(null);
            setDisableUpload(false);
        }
        const file = acceptedFiles[0];
        if (
            file['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file['type'] === "application/vnd.ms-excel" ||
            file['type'] === "text/csv"
        ) {
            setUploadFile(file);
            setUploadText(file?.name);
            setDisableButton(false);
        } else {
            setUploadFile(null);
            updateAlert('wrong type of file')
            setDisableUpload(false);
            setDisableButton(true);
            setUploadText(t("Data Upload"));
        }
        setDisableUpload(false);
    }

    const closeModal = () => {
        handleStop();
        reloadPage();
        setOpen(false);
        setUploadText(t("Data Upload"));
        setUploadBarText('');
        setDeletedTerms('');
        setDisableUpload(false)
    }

    const uploadTerms = async () => {
        setDisableButton(true);
        setDisableUpload(true);
        setUploadBarText('');
        setFullCounterText('');
        let sendJSON = [];

        // run
        const maxSize = 50;
        let data = [];
        if (
            uploadFile['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            uploadFile['type'] === "application/vnd.ms-excel"
        ) {
            const fileContents = await readUploadedFileAsBinary(uploadFile, false);
            const workbook = XLSX.read(fileContents, { type: 'binary' })
            if (workbook && workbook.SheetNames[0] && workbook.Sheets[workbook.SheetNames[0]]) {
                data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 0 });
            } else {
                updateAlert('Wrong data');
                clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
                return false;
            }
        } else if (uploadFile['type'] === "text/csv") {
            const fileContents = await readUploadedFileAsBinary(uploadFile, true);
            const res = Papa.parse(fileContents, { header: true, delimiter: "" });
            data = res.data;
        } else {

            updateAlert('Wrong data');
            clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
            return false;
        }
        if (data && data.length > 0) {

            setUploadBarVisible(true);
            setUploadBarNow(0);
            //run!

            if (clearCategory) {
                setUploadBarText(t('deleting...'));
                await tlService.deleteTermCategory(token, categoryID, 1);
                setUploadBarText("")
            }

            let lineCounter = 0;
            let fullCounter = 0;
            for (let tmpData of data) {
                const tmpDataHeaders = Object.keys(tmpData)
                const wordColumn = Object.values(config.termHeaders.words).find(v=> tmpDataHeaders.includes(v))
                const descriptionColumn = Object.values(config.termHeaders.description).find(v=> tmpDataHeaders.includes(v))
                const replacementsColumn = Object.values(config.termHeaders.replacements).find(v=> tmpDataHeaders.includes(v))
                if (lineCounter >= maxSize) {
                    if (!running.current) {
                        await clearCache(token, categoryID, tlService);
                        await calculateSize(token, categoryID, tlService);
                        clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
                        return false;
                    }
                    //send!!
                    if (!await runBunchUpload(tlService, token, categoryID, fullCounter, sendJSON, setUploadBarText, t, updateAlert)) {
                        clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
                        return false;
                    }
                    setUploadBarNow(Math.round(fullCounter / (data.length / 100)))
                    setFullCounterText(`${fullCounter}/${data.length}`)
                    // reset object
                    sendJSON = [];
                    // reset counter
                    lineCounter = 0;
                }
                if (wordColumn) {
                    // if (tmpData[config.termHeaders.description] && tmpData[config.termHeaders.replacements]) {
                        // increase a lineCounter
                        lineCounter++;
                        fullCounter++;
                        if (deleteTerms) {
                            // set deleted terms list to display:
                            setDeletedTerms(s => s + tmpData[wordColumn] + "</br>")
                            sendJSON.push({
                                global_visible: 1,
                                check_words: 0,
                                text: tmpData[wordColumn],
                                enabled: 0
                            })
                        } else {
                            let replacements = [];
                            if (replacementsColumn) {
                                const tRepl = String(tmpData[replacementsColumn]).split("|")
                                for (let text of tRepl) {
                                    replacements.push({ text, global_visible: 1 });
                                }
                            }
                            sendJSON.push({
                                global_visible: 1,
                                enabled: 1,
                                text: tmpData[wordColumn],
                                description: (descriptionColumn !== undefined && tmpData[descriptionColumn]) ? tmpData[descriptionColumn] : '',
                                check_words: checkWords ? 1 : 0,
                                update_replace: updateTerms ? 2 : 1,
                                update_replacement: (updateTerms && updateReplacements),
                                update_description: (updateTerms && updateDescription),
                                replacements: replacements
                            })
                        }
                    // } else {
                    //     setUploadBarVisible(false);
                    //     updateAlert(t("header-description-replacement_words-missing"));
                    // }
                } else {
                    setUploadBarVisible(false);
                    updateAlert(t("header-word-missing"));
                }
            }

            if (deleteTerms) {
                // set deleted terms End Text:
                setDeletedTerms(s => s + "</br>" + "<h6>" + t("have-been-deleted") + "</h6>")
            }

            //send again!!
            if (!await runBunchUpload(tlService, token, categoryID, fullCounter, sendJSON, setUploadBarText, t, updateAlert)) {
                clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
                return false;
            }
            await clearCache(token, categoryID, tlService);
            await calculateSize(token, categoryID, tlService);
        } else {
            updateAlert('Data is empty');
            clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
            return false;
        }
        if (sendJSON?.length) {
            clrModal(setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t);
            setUploadText(t("Data Upload"));
            updateSuccessTrue();
            return true;
        }
        // else{
        //     setUploadBarVisible(false);
        //     updateAlert('Wrong data');
        // }
    }

    const handleUpload = () => {
        setSuccess(false);
        running.current = true;
        uploadTerms();
    }

    return (
        <Modal open={open} onClose={() => closeModal()}>
            <div className={`${style.termModal} col-5`} style={{ margin: "0 auto", marginTop: "10vh" }}>
            <IconButton
            className={`p-0 m-0`}
            style={{float: "right"}}
            onClick={() => {
                closeModal();
            }}
          >
            <Close />
          </IconButton>
                <h6 className={`mb-4`} dangerouslySetInnerHTML={{ __html: t('term-upload-modal') }} style={{textAlign: "center", fontSize: "20px"}}/>
                {!!alert && <Alert className={`mb-1`} severity="error">{alert}</Alert>}
                {!!success && <Alert className={`mb-1`} severity="success">{t('upload is done')}</Alert>}
                <div className={`d-flex flex-row p-3`} style={{marginBottom:"30px", justifyContent: "center"}}>
                <span className={`d-flex flex-row w-50 p-2 ${style.uploadBtn}`}>
                    <Dnd onReadDropFiles={handleDND} text={uploadText} disabled={disableUpload} />
                </span>
                <span  className={`d-flex flex-row p-2 ${style.rightBox}`}>
                    <i dangerouslySetInnerHTML={{ __html: t('term_upload_info_message') }}>
                    </i>
                </span>
                </div>
                {uploadBarVisible &&
                    <div className={`d-flex flex-row justify-content-center w-100 p-1`}>
                        <div className={'flex-column w-50 my-auto'}><LinearProgress color="primary"
                            variant="determinate"
                            value={uploadBarNow} /></div>
                        <div className={'pl-1 flex-column my-auto'}>{fullCounterText}</div>
                        <div className={'pl-1 flex-column my-auto'}>
                            <Button color='default' onClick={handleStop}>
                                {t('stop upload')}
                            </Button></div>
                    </div>}

                {!!deletedTerms && <Alert className={`mb-1`} severity="success">
                    <p dangerouslySetInnerHTML={{ __html: deletedTerms }} />
                </Alert>}

                {(!!uploadBarText && !deleteTerms) && <Alert style={{
                    overflow: "auto"
                }} action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setUploadBarText('');
                        }}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>
                } className={`mb-1`} severity="warning"><p dangerouslySetInnerHTML={{ __html: uploadBarText }} /></Alert>}
                    <div className={`d-flex font-weight-bold flex-row w-100 p-1 mt-2 ${style.alignItem}`} >
                    {t("What-should-happen-to-terms")}
                    </div>
                <div className={`d-flex flex-row w-100 p-1 ${style.alignItem}`} >
                    <LabeledSwitch defaultChecked={deleteTerms} handleChange={setDeleteTerms}
                        labelBegin={t("update terms?")} labelEnd={t("delete terms?")}
                        labelPlacementBegin={true} labelPlacementEnd={true} />
                </div>
                {!deleteTerms && <>
                    <div className={`d-flex flex-row w-100 p-1 ${style.alignItem}`}>
                        <div><LabeledSwitch defaultChecked={clearCategory} color={'secondary'}
                            handleChange={setClearCategory} labelEndClass={style.careful}
                            labelBegin={t("keep category")}
                            labelEnd={t("clear category before")} labelPlacementBegin={true}
                            labelPlacementEnd={true} /></div>
                    </div>
                    <div className={`d-flex font-weight-bold flex-row w-100 p-1 mt-2 ${style.alignItem}`}>
                    {t('automatically-fill-missing-word')}
                    </div>
                    <div className={`d-flex flex-row w-100 p-1 ${style.alignItem}`}>
                        <LabeledSwitch defaultChecked={checkWords} handleChange={setCheckWords}
                            labelBegin={t("use-lemma")}
                            labelEnd={t("only exact wording")} labelPlacementBegin={true}
                            labelPlacementEnd={true} />
                    </div>
                    <div className={`d-flex font-weight-bold flex-row w-100 p-1 mt-2 ${style.alignItem}`}>
                        {t('what to do with exist terms?')}
                    </div>
                    <div className={`d-flex flex-row w-100 p-1 ${style.alignItem}`}>
                        <LabeledSwitch defaultChecked={updateTerms} handleChange={setUpdateTerms}
                            labelBegin={t('replace terms')}
                            labelEnd={t('update terms')} labelPlacementBegin={true}
                            labelPlacementEnd={true} />
                    </div>
                    {updateTerms &&
                        <>
                            <div className={`d-flex font-weight-bold flex-row w-100 p-1 mt-2 ${style.alignItem}`}>
                                {t('for existing Terms')}
                            </div>
                            <div className={`d-flex my-auto flex-row w-100 p-1 ${style.alignItem}`}>
                                <div className={'my-auto font-weight-bold'}>{t('for descriptions')}</div>
                                <div className={'my-auto pr-2'}>
                                    <Tooltip
                                        title={<h6 style={{ fontSize: "15px" }}>{t('for descriptions tooltip')}</h6>}>
                                        <Info className={style.info} />
                                    </Tooltip>
                                </div>
                                <div className={'my-auto'}><LabeledSwitch defaultChecked={updateDescription}
                                    handleChange={setUpdateDescription}
                                    labelBegin={t('rewrite record')}
                                    labelEnd={t('update record')}
                                    labelPlacementBegin={true}
                                    labelPlacementEnd={true} /></div>
                            </div>
                            <div className={`d-flex m-auto flex-row w-100 ${style.alignItem}`}>
                                <div className={'my-auto font-weight-bold'}>{t('for replacements')}</div>
                                <div className={'my-auto pr-2'}>
                                    <Tooltip
                                        title={<h6 style={{ fontSize: "15px" }}>{t('for replacements tooltip')}</h6>}>
                                        <Info className={style.info} />
                                    </Tooltip>
                                </div>
                                <div className={'my-auto'}><LabeledSwitch defaultChecked={updateReplacements}
                                    handleChange={setUpdateReplacements}
                                    labelBegin={t('rewrite record')}
                                    labelEnd={t('update record')}
                                    labelPlacementBegin={true}
                                    labelPlacementEnd={true} /></div>
                            </div>
                        </>
                    }
                </>}
                <div className={`d-flex align-items-start w-100 ${style.alignItem}`}>
                    <div className={`d-flex flex-column`}>
                        <Button style={{ color: "#3f51b5" }} disabled={disableButton} onClick={handleUpload}>
                            {t('upload')}
                        </Button>
                    </div>
                    {/* <div className={`d-flex flex-column`}>
                        <Button color='default' onClick={closeModal}>
                            {t('close')}
                        </Button>
                    </div> */}
                </div>
            </div>
        </Modal>
    )
}
