import {Modal} from "reactstrap";
import {Button, Collapse, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import TLServiceComponent from "../../../contexts/TLServiceComponent";
import {convertLanguagesToShort} from "../../../utils";
import {Alert} from "@material-ui/lab";

export default function ResetModal({open, setOpen}) {
    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const [openAlert, setOpenAlert] = useState('');
    const [resetPageDone, setResetPageDone] = useState(false);

    // bind actions
    const tlService = React.useContext(TLServiceComponent);

    //state validation by YUP
    const validationSchema = yup.object({
        email: yup.string()
            .email(t("Must be a valid email"))
            .required(t('Required field')),
    });

    // effects
    // eslint-disable-next-line
    useEffect(() => {
        if (!open) setOpenAlert('');
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async ({email}) => {
            setOpenAlert('');
            try {
                await tlService.forgotPassword(convertLanguagesToShort(i18nLanguage), email);
                setResetPageDone(true);
                //setOpen(s => !s);
            } catch (e) {
                console.log(e.message);
                setOpenAlert(e.message);
            }
        },
    });

    return (
        <Modal isOpen={open} className={`d-flex align-items-center justify-content-center`}
               toggle={() => setOpen(false)}>
            <div className={`pt-4 pb-4 pl-5 pr-5`}>
                <p>{resetPageDone ? t("Reset Page Done") : t("Reset Page Text")}</p>
                {!resetPageDone &&
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        helperText={formik.touched.email && formik.errors.email}
                        error={formik.touched.email && !!formik.errors.email}
                        onChange={formik.handleChange}
                        fullWidth
                        color='primary'
                        name={'email'}
                        className={`mt-3`}
                        variant="filled"
                        label={t("E-mail")}/>
                    <Collapse in={!!openAlert}>
                        <Alert
                            className={`m-0`}
                            severity='error'>
                            <p className={`m-0`}>{openAlert}</p>
                        </Alert>
                    </Collapse>
                    <Button
                        type='submit'
                        className={`mt-3`}
                        fullWidth
                        color="primary"
                        variant='contained'>
                        {t("Request link")}
                    </Button>
                </form>
                }
            </div>
        </Modal>
    )
}
