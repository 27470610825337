import React from "react";
import UserProfileWrapper from "../../UserProfileWrapper/UserProfileWrapper";
import GroupsTable from "./Table/Table";

function GroupsComponent() {
  return (
    <UserProfileWrapper>
      <GroupsTable/>
    </UserProfileWrapper>
  )
}

export default GroupsComponent;
