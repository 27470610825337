import JSZip from 'jszip'
import { addFiles, generateDocument } from './internal'

export async function asBlob (html, options) {
  const zip = new JSZip()
  addFiles(zip, html, options)
  return await generateDocument(zip)
}


