import {createTheme} from "@material-ui/core";
import {Overrides} from "./Overrides";
import {BgColors as LightBgColors} from "./LightTheme/bgColors";
import {Colors as LightColors} from "./LightTheme/colors";
import {BorderColors as LightBorderColors} from "./LightTheme/borderColors";
import {BgColors as DarkBgColors} from "./DarkTheme/bgColors";
import {Colors as DarkColors} from "./DarkTheme/colors";
import {BorderColors as DarkBorderColors} from "./DarkTheme/borderColors";

export const ToggleTheme = (theme) => {
    // here we are looking at the theme, switch MUI overrides
    if (theme === "light") {
        return createTheme({
            overrides: Overrides(
                LightBgColors.bgColors,
                LightBorderColors.borderColors,
                LightColors.colors
            ).overrides,
            theme: {
                bgColors: LightBgColors.bgColors,
                colors: LightColors.colors,
                borderColors: LightBorderColors.borderColors
            }
        })
    } else {
        return createTheme({
            overrides: Overrides(
                DarkBgColors.bgColors,
                DarkBorderColors.borderColors,
                DarkColors.colors
            ).overrides,
            theme: {
                bgColors: DarkBgColors.bgColors,
                colors: DarkColors.colors,
                borderColors: DarkBorderColors.borderColors
            }
        })
    }
};
