import React from "react";
import UserProfileWrapper from "../../UserProfileWrapper/UserProfileWrapper";
import FirmaTable from "./Table/Table";

export default function FirmaComponent() {
  return (
    <UserProfileWrapper>
      <FirmaTable/>
    </UserProfileWrapper>
  )
}
