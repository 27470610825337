import style from "./style.module.scss"
import { Alert } from "@material-ui/lab";
import React, { useMemo } from "react";
import ColumnChart from "../UI/ColumnChart";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setSeries } from "../utils";
import VColoredLinearChart from "../UI/VColoredLinearChart";
import {
    isObjectEmpty,
} from "../../../utils";

export default function SentenceConst({seriesCLIX, clixList, heading, type = 0, headText = "", counters, benchmarkWarning }) {
    const { theme: { bgColors, colors } } = useTheme();
    const { t } = useTranslation();
    const color = type === 1 ? { backgroundColor: "#cee0ff", color: "#00091d" } : {
        backgroundColor: "#fae9c8",
        color: "#efa90b"
    };

    if (!counters?.length) {
        return null;
    }

    return (
        <>
            {type > 0 && <div style={{ backgroundColor: bgColors.bgPrimary0, width: "100%", minHeight: "147px" }} className={`${style.cells} ${style.sentenceConst}`}>
                <div className={style.nameRow}>
                    <div className={style.textWrap} style={color}>
                        <p className={`m-0 ${style.text}`}>{headText}</p>
                    </div>
                </div>
                {benchmarkWarning && <Alert className={`mb-1`} severity="error">
                    {t('text types is different')}
                </Alert>}
            </div>}
            <br />
            <div style={{ gridGap: "30px", margin: "0 0 30px" }}>
                <ColumnChart mainText={t("dash_clix_main_text")}
                    series={seriesCLIX[0]} mean={seriesCLIX[1]}
                    height={350}
                    chartId={t("CLIX_dashboard_chartID")} min={0} max={100} />
            </div>
            {!isObjectEmpty(clixList) ?
                <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: "10px", margin: "30px 0 0 0" }} className={style.cells}>
                    <div className={`mb-5`}>
                        <VColoredLinearChart
                            cells={clixList} heading={heading}
                        />
                    </div>
                </div>
                : <div />}
        </>
    )
}
