import { onlyUnique } from "../../../utils"

const ShowKeywords = ({ keywordsString }) => {
    const splitKeywords = keywordsString.split(',')
    .map(v => v.trim()).filter(v => v?.length).filter(onlyUnique)
    return (
        <div key={'keywords'} className={'d-flex flex-wrap'}>{splitKeywords.map((word, idx) => word?.length ?
            <div key={idx} style={{ backgroundColor: '#c9c9c9', fontSize: '10px' }}
                 className={'text-nowrap mr-1 mb-1 p-1 rounded'}>{word.trim()}</div> : null)}</div>
    )
}

export default ShowKeywords