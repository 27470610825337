import {Popover} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import config from "../../config";
import {stripHtml} from "../../utils";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));
export default function ComponentWithPop({children, text, html = false, dangerousTags = config.dangerousTags}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
      <span aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
      >
        {children}
      </span>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {
                    html ? <p className={`m-0`} style={{fontSize: "16px"}}
                              dangerouslySetInnerHTML={{__html: stripHtml(text, dangerousTags)}}/>
                        : <p className={`m-0`} style={{fontSize: "16px"}}>{text}</p>
                }
            </Popover>
        </>
    )
}
