import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import {
  Button, IconButton, Paper,
  InputBase, TableRow, TableHead, TableCell, Box, TextField, Collapse
} from '@material-ui/core'
import { FilterList, Search, KeyboardArrowLeft, KeyboardArrowRight, Close } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import ExtraFilter from './ExtraFilter'

export default function TableFilter (
  {
    style,
    extraSettings,
    enableTopPagination = true,
    enableFullSearch = true,
    enableExtraFilters = true,
    localFilter,
    handleSearchButtonClick,
    handleChangeValue,
    handleChangeFullSearchString,
    handleResetSearchClick,
    handleNextPage,
    handleChangePage
  }
) {

  const { theme: { bgColors, colors, borderColors } } = useTheme()
  const { t } = useTranslation()

  // table
  const currentPage = useSelector(state => state.tableData.currentPage)
  const pages = useSelector(state => state.tableData.pages)
  const extraFilters = useSelector(state => state.tableData.extraFilters)

  const [openFilter, setOpenFilter] = useState(false)
  const [editCurrentPage, setEditCurrentPage] = useState(1)

  useEffect(()=>setEditCurrentPage(currentPage),[currentPage])
  const handleBlurPageEditor = (e)=>{
    handleChangePage(parseInt(e.target.value))
  }
  const handleChangePageEditor = (e)=>{
    setEditCurrentPage(e.target.value)
  }
  const handleOnPageEditorEnter = (event) => {
    if (event.keyCode === 13) {
      event.target.blur()
    }
  }
  // fullSearchFilter
  const handleOnEnter = (event) => {
    if (event.keyCode === 13) {
      handleSearchButtonClick(localFilter)
    }
  }

  return (
    <>
      <TableHead style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.tableFilter}`}>
        <TableRow>
          <TableCell colSpan={12} padding="checkbox">
            <Box
              style={{ color: colors.colorPrimary500 }}
              className={`d-flex justify-content-between align-items-center p-1 pt-2 pb-2`}
              margin={1}>

              {/** OPEN FILTER BUTTON **/}
              {enableExtraFilters &&
                <Button className={`${style.filterBtn} ml-2 mr-3`} onClick={() => setOpenFilter(s => !s)}
                        variant="contained"
                        style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
                        startIcon={<FilterList/>}>
                  {t('filter')}
                </Button>}

              {/** FULL SEARCH **/}
              {enableFullSearch && <>
                <Paper
                  className={`${style.search} d-flex`}
                  style={{ backgroundColor: bgColors.bgPrimary0, borderColor: borderColors.borderPrimary400 }}>
                  <IconButton onClick={()=>handleSearchButtonClick(localFilter)} style={{ padding: '9px' }} type="submit" aria-label="search">
                    <Search/>
                  </IconButton>
                  <InputBase
                    onKeyUp={handleOnEnter}
                    value={localFilter?.full_text_search_filter?.value || ''}
                    id="searchBox"
                    placeholder={t('search')}
                    onChange={handleChangeFullSearchString}
                  />
                </Paper>
                <Button
                  className={`${style.filterBtn} ml-2 mr-3`}
                  onClick={()=>handleSearchButtonClick(localFilter)}
                  variant="contained"
                  style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
                >
                  {t('apply-filter')}
                </Button>
                <Button
                  className={`${style.filterBtn} ml-2 mr-3`}
                  onClick={handleResetSearchClick}
                  variant="contained"
                  style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
                >
                  {t('reset-filter')}
                </Button>
              </>}

              {enableTopPagination ? <div className={style.pagination}>
                <TextField
                  inputProps={{ maxLength: 3 }}
                  type="number"
                  value={editCurrentPage}
                  onBlur={handleBlurPageEditor}
                  onChange={handleChangePageEditor}
                  onKeyUp={handleOnPageEditorEnter}
                  style={{ backgroundColor: bgColors.bgBlue100, borderRadius: '4px' }}/>
                <span className={`mr-2 ml-2`}>{t('of')}</span>
                <p className={`mr-2 m-0`}>{pages}</p>
                <IconButton color="primary" onClick={() => {handleNextPage(-1)}} disabled={currentPage === 1}>
                  <KeyboardArrowLeft/>
                </IconButton>
                <IconButton color="primary" onClick={() => {handleNextPage(1)}}
                            disabled={currentPage === pages}>
                  <KeyboardArrowRight/>
                </IconButton>
              </div> : <div className={style.pagination}/>}
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>

      {/** FILTERS **/}

      {enableExtraFilters &&
        <TableHead style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.filterDashboard}`}>
          <TableRow>
            <TableCell colSpan={12} padding="checkbox">
              <Collapse in={openFilter} timeout="auto" unmountOnExit>
                <Box style={{ color: colors.colorPrimary500 }} className={`mb-4 pt-2 pl-3 pr-3`} margin={1}>
                  <div style={{ borderColor: borderColors.borderPrimary300 }}
                       className={`${style.secondRow} mt-2`}>
                    <div className="d-flex align-items-center flex-wrap" style={{ gridGap: 20 }}>
                      <Close onClick={() => setOpenFilter(false)}/>
                      {extraFilters.map((data, index) => <ExtraFilter
                        localFilter={localFilter}
                        key={index}
                        handleChangeValue={handleChangeValue}
                        handleOnEnter={handleOnEnter}
                        extraSettings={extraSettings}
                        data={data}
                        index={index}/>)}
                    </div>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableHead>}


    </>
  )
}