import React, { useMemo, useState } from 'react'
import style from './../../Footer/style.module.sass'
import arrowDown from './assets/arrow-down.svg'
import { Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  chooseColor,
  convertJSONToObject,
  convertLanguagesToFull,
  convertLanguagesToShort,
  getValue,
  isObjectEmpty
} from '../../../utils'
import { useSelector } from 'react-redux'
import config from '../../../config'

export default function AnalyseFooter ({
  charactersCount,
  wordsCount,
  handleOnLanguageChange,
  handleOnTextTypeChange
}) {

  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const [filter, setFilter] = useState('')
  const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate)
  const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
  const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
  const textLanguage = useSelector(state => state.analyseData.textLanguage)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
  const aiMode = useSelector(state => state.analyseData.aiMode)
  const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
  const autoTextLanguage = useSelector(state => state.analyseData.autoTextLanguage)
  const userTypeId = useSelector(state => state.userData.user.user_type_id)
  const languageLevel = useSelector(state => state.analyseData.result.resultData.formulaLanguageLevel || -1)
  const [openDrop, setOpenDrop] = useState('')
  const shortLanguage = convertLanguagesToShort(i18nLanguage)
  const storageLanguageTextType = convertJSONToObject(
    useSelector(
      (state) => state.userData?.user?.user_options?.analyse_language_texttype
    ),
    config.defaultAnalyseLanguageTextType
  )
  if (typeof storageLanguageTextType.textType !== 'object') {
    storageLanguageTextType.textType = {}
  }

  const isAutoLang = storageLanguageTextType.language === 'auto'
  const fullLanguage = convertLanguagesToFull(i18nLanguage)
  const currentLanguage = backendLanguages[textLanguage] || backendLanguages[fullLanguage]
  const currentTextType = enabledBenchmarks[useBenchmark] || enabledBenchmarks[Object.keys(enabledBenchmarks)[0]]

  let countCharacterSettingsColor = '#9a9797'
  let countWordsSettingsColor = '#9a9797'
  if (currentBenchmarkSettings?.settings) {
    const countCharacterSettings = currentBenchmarkSettings.settings['countCharactersNoSpaces']?.values
    const countCharacterSettingsTargetYellowGreen = getValue(countCharacterSettings, 'yellowGreen', 50)
    const countCharacterSettingsTargetYellowRed = getValue(countCharacterSettings, 'yellowRed', 50)
    countCharacterSettingsColor = chooseColor(charactersCount, countCharacterSettingsTargetYellowGreen, countCharacterSettingsTargetYellowRed)

    const countWordsSettings = currentBenchmarkSettings.settings['countWords']?.values
    const countWordsSettingsTargetYellowGreen = getValue(countWordsSettings, 'yellowGreen', 50)
    const countWordsSettingsTargetYellowRed = getValue(countWordsSettings, 'yellowRed', 50)
    countWordsSettingsColor = chooseColor(wordsCount, countWordsSettingsTargetYellowGreen, countWordsSettingsTargetYellowRed)
  }
  const realEnabledBenchmarks = useMemo(() => {
    if (filter.length > 0)
      return Object.entries(enabledBenchmarks).filter(v => v[1]?.name[shortLanguage].indexOf(filter) > -1)
    else
      return Object.entries(enabledBenchmarks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledBenchmarks, filter])

  const languageLevelToString =  config.languageLevelToString
  const textLanguageLevel = languageLevelToString.find(v => v.threshold < languageLevel)

  return (
    <>
      {resultTemplate > 0 && !aiMode &&
        <div className={`${style.results}`}>
          <div>
            <p className={`m-0 d-flex`}>{t('Characters')}:</p>
            <p className={`mt-1 font-weight-bold`} style={{ color: countCharacterSettingsColor }}>{charactersCount}</p>
          </div>
          <div>
            <p className={`m-0 d-flex`}>{t('Words')}:</p>
            <p className={`mt-1 font-weight-bold`} style={{ color: countWordsSettingsColor }}>{wordsCount}</p>
          </div>
          {languageLevel > -1 &&
            <div className={``}>
            <p className={`m-0 d-flex`}>{t('analyse_footer_language_level')}:</p>
            <p className={`mt-1 font-weight-bold`} style={{ color: textLanguageLevel.color }}>
              {textLanguageLevel ? textLanguageLevel.level : ''}</p>
          </div>}
        </div>
      }
      <div className={`${style.selects} d-flex align-items-center mr-2 pr-1`}
           style={{ marginTop: '-30px', marginLeft: '10px', maxWidth: '100%' }}>
        <div className={`${style.dropLanguage} mr-5`}>
          <div
            onClick={() => setOpenDrop(s => s !== 'lang' ? 'lang' : '')}
            className={`d-flex justify-content-between align-items-center`}>
            <p className={`m-0 pr-1 font-weight-bold`}>{t('Language')}:</p>
            <div className={`${style.selectField}`} style={{ marginRight: '100px', minWidth: '210px' }}>
              <p className={`m-0 pr-1`}><b>{currentLanguage?.nativeName} {isAutoLang && ' (auto)'}</b></p>
              <img src={arrowDown}
                   className={`${style.icon} ${openDrop === 'lang' && style.iconReversed}`} alt=""/>
            </div>
          </div>
          <Collapse className={`position-absolute d-flex flex-column-reverse`}
                    style={{ top: '-75px', right: '29px' }} in={openDrop === 'lang'}>
            <ul className={`${style.select}`}>
              {
                Object.entries(backendLanguages).map(([key, value]) =>
                  <li key={key} onClick={() => {
                    setOpenDrop('')
                    handleOnLanguageChange(key)
                  }}>
                    {value['nativeName']} {autoTextLanguage === key && ' (auto)'}
                  </li>
                )
              }
            </ul>
          </Collapse>

        </div>
        {!isObjectEmpty(enabledBenchmarks) &&
          <div className={`${style.dropTextType}  `} style={{ marginLeft: '30px' }}>
            {userTypeId === 1 && <input type="text" value={filter} onChange={e => setFilter(e.target.value)}/>}
            <div onClick={() => setOpenDrop(s => s !== 'text' ? 'text' : '')}
                 className={`d-flex justify-content-between align-items-center`} style={{ marginLeft: '-80px' }}>
              <p className={` m-0 pr-0 font-weight-bold`} style={{ minWidth: '80px' }}>{t('Text Type')}:</p>
              <div className={`${style.selectField}`} style={{ minWidth: '210px' }}>
                <p className={`m-0 pr-1`}><b>{currentTextType?.name[shortLanguage] || currentTextType?.id}</b></p>
                <img src={arrowDown}
                     className={`${style.icon} ${openDrop === 'text' && style.iconReversed}`} alt=""/>
              </div>
            </div>
            <Collapse className={`position-absolute d-flex flex-column-reverse`}
                      style={{ top: '-75px', right: '-10px' }} in={openDrop === 'text'}>
              <ul className={`${style.select}`}>
                {
                  realEnabledBenchmarks.map(([key, val]) =>
                    <li key={key} onClick={() => {
                      setOpenDrop('')
                      handleOnTextTypeChange(parseInt(key.toString()))
                    }}>{val['name'][shortLanguage] || key}
                    </li>
                  )
                }
              </ul>
            </Collapse>
          </div>
        }
      </div>
    </>
  )
}