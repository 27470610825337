import React from "react";
import style from "./style.module.sass";
import LinguisticClimate from "./LinguisticClimate";
import Tonality from "./Tonality";
import { useSelector } from "react-redux";
import config from "../../../../config";

function LinguisticParams() {

    const resultData = useSelector(state => state.analyseData.result.resultData);

    const allColors = ["colorGreen","colorBlue","colorYellow","colorRed"]

    const showColor = Object.keys(resultData).reduce((acc,key) => allColors.indexOf(key) > -1 || acc, false);

    return <div className={`${style.mainCont} d-flex`}>
        {showColor && <LinguisticClimate />}
        {Object.keys(config.tonalityParameters).filter(v => resultData[v] !== undefined).length > 0 && <Tonality />}
    </div>;
}

export default LinguisticParams;
