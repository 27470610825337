import React from 'react'
import style from './style.module.sass'
import arrow from '../../../assets/arrow.svg'
import FilterPanel from './FilterPanel'
import { useRouteMatch, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Spinner from '../../Spinner'
import { dashboardSetLanguage, userSetArchiveLanguage } from '../../../actions'
import { MenuItem, Select, Button } from '@material-ui/core'
import { useActions } from '../../../utils/action-helper'

export default function DashFilters () {
  const { params: { type } } = useRouteMatch()
  const { t } = useTranslation()
  // redux
  const loading = useSelector(state => state.dashboardData.loading)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})

  const { actionUserSetArchiveLanguage, aDashboardSetLanguage } = useActions({
    actionUserSetArchiveLanguage: userSetArchiveLanguage,
    aDashboardSetLanguage: dashboardSetLanguage
  })

  function handleOnChangeLanguage (e) {
    actionUserSetArchiveLanguage(e.target.value)
    aDashboardSetLanguage(e.target.value)
  }

  const LanguageSelector = () => {
    return (
      <div style={{ float: 'right', marginTop: '-20px' }}>
        <Select
          onChange={handleOnChangeLanguage}
          labelId="language-select-label"
          id="language-select"
          defaultValue={archiveLanguage}
          style={{ borderRadius: 18, width: '220px' }}
          variant="outlined"
          className={`p-0`}
        >
          {
            Object.keys(backendLanguages).map(lang =>
              <MenuItem key={lang} value={lang}>{backendLanguages[lang]['nativeName']}</MenuItem>
            )
          }
        </Select>
      </div>
    )
  }

  return (
    <div className={`${style.mainCont}`}>
      <NavLink className={'p-0 '} to="/dashboard/compare">
        <Button style={{ backgroundColor: 'green', color: 'white' }} variant="contained">
          <img src={arrow} className={`mr-3`} alt=""/>
          {t('to dashboard')}
        </Button>
      </NavLink>
      <LanguageSelector/>
      <div className={`${style.tableWrapper} pb-5 mt-1`}>
        <div className={`d-flex ${style.mediaHandler}`} style={{ gridGap: 10 }}>
          <FilterPanel panel={0}/>
          {type === 'double' && <FilterPanel panel={1}/>}
        </div>
      </div>
      {loading && <Spinner/>}
    </div>
  )
}