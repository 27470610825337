const updateAdminData = (state, action) => {
  const defaultState = {
    options: {},
    companies: {},
    settings_dir: {},
    calcelements_dir: {},
    return_values_dir: {},
    benchmark_templates_dir: [],
    groups: {},
    rights: {},
    loading: false,
    error: null,
  }

  if (state === undefined) {
    return defaultState
  }
  switch (action.type) {

    case 'FETCH_ALL_SETTINGS_REQUEST':
      return {
        ...state.adminData, ...{
          settings_dir: defaultState.settings_dir,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_SETTINGS_SUCCESS':
      return {
        ...state.adminData, ...{
          settings_dir: action.payload.reduce((accumulator, currentValue) => {
            const { setting_name, category, lib, setting_values, limits } = currentValue
            accumulator[setting_name] = {
              category,
              lib,
              settings: setting_values['settings'],
              values: setting_values['values'],
              limits
            }
            return accumulator
          }, {}),
          error: null
        }
      }
    case 'FETCH_ALL_SETTINGS_FAILURE':
      return {
        ...state.adminData, ...{
          settings_dir: defaultState.settings_dir,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_ALL_BENCHMARK_TEMPLATES_REQUEST':
      return {
        ...state.adminData, ...{
          benchmark_templates_dir: defaultState.benchmark_templates_dir,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_BENCHMARK_TEMPLATES_SUCCESS':
      return {
        ...state.adminData, ...{
          benchmark_templates_dir: action.payload.reduce((accumulator, currentValue) => {
            const {
              id,
              name,
              description,
              text_type_id,
              default_calcelements,
              return_values,
              locale_name,
              settings
            } = currentValue
            accumulator[id] = {
              text_type_id,
              name,
              description,
              default_calcelements,
              return_values,
              locale_name,
              settings
            }
            return accumulator
          }, {}),
          error: null
        }
      }
    case 'FETCH_ALL_BENCHMARK_TEMPLATES_FAILURE':
      return {
        ...state.adminData, ...{
          benchmark_templates_dir: defaultState.benchmark_templates_dir,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_ALL_CALCELEMENTS_REQUEST':
      return {
        ...state.adminData, ...{
          calcelements_dir: defaultState.calcelements_dir,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_CALCELEMENTS_SUCCESS':
      return {
        ...state.adminData, ...{
          calcelements_dir: action.payload.reduce((accumulator, currentValue) => {
            const { id, settings, depend_of, default_sort, locale_name } = currentValue
            accumulator[id] = { settings, depend_of, default_sort, locale_name }
            return accumulator
          }, {}),
          loading: false,
          error: null
        }
      }
    case 'FETCH_ALL_CALCELEMENTS_FAILURE':
      return {
        ...state.adminData, ...{
          calcelements_dir: defaultState.calcelements_dir,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_ALL_RETURN_VALUES_REQUEST':
      return {
        ...state.adminData, ...{
          return_values_dir: defaultState.return_values_dir,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_RETURN_VALUES_SUCCESS':
      return {
        ...state.adminData, ...{
          return_values_dir: action.payload.reduce((accumulator, currentValue) => {
            const { category, lib, value_name, value_type } = currentValue
            accumulator[value_name] = { category, lib, value_type }
            return accumulator
          }, {}),
          loading: false,
          error: null
        }
      }
    case 'FETCH_ALL_RETURN_VALUES_FAILURE':
      return {
        ...state.adminData, ...{
          return_values_dir: defaultState.return_values_dir,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_ALL_COMPANIES_REQUEST':
      return {
        ...state.adminData, ...{
          companies: defaultState.companies,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_COMPANIES_SUCCESS':
      return {
        ...state.adminData, ...{
          companies: action.payload.reduce((accumulator, currentValue) => {
            const { id, company_options, company_licenses, name } = currentValue
            accumulator[id] = { company_options, company_licenses, name }
            return accumulator
          }, {}),
          loading: false,
          error: null
        }
      }
    case 'FETCH_ALL_COMPANIES_FAILURE':
      return {
        ...state.adminData, ...{
          companies: defaultState.companies,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_ALL_GROUPS_REQUEST':
      return {
        ...state.adminData, ...{
          groups: defaultState.groups,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_GROUPS_SUCCESS':
      return {
        ...state.adminData, ...{
          groups: action.payload.reduce((accumulator, currentValue) => {
            const { id, company_id, create_time, update_time, user_group_options, name } = currentValue
            accumulator[id] = { company_id, create_time, update_time, user_group_options, name }
            return accumulator
          }, {}),
          loading: false,
          error: null
        }
      }
    case 'FETCH_ALL_GROUPS_FAILURE':
      return {
        ...state.adminData, ...{
          groups: defaultState.groups,
          loading: false,
          error: action.payload
        }
      }
    case "CLEAR_ALL_GROUPS":
      return {
        ...state.adminData, ...{
          groups: defaultState.groups,
          loading: false,
          error: null
        }
      }

    case 'UPDATE_ALL_RIGHTS':
      return {
        ...state.adminData, ...{
          rights: action.payload,
          loading: false,
          error: null
        }
      }

    case 'FETCH_ALL_OPTIONS_REQUEST':
      return {
        ...state.adminData, ...{
          options: defaultState.options,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_OPTIONS_SUCCESS':
      return {
        ...state.adminData, ...{
          options: action.payload.reduce((accumulator, currentValue) => {
            const { name } = currentValue
            accumulator[name] = { name }
            return accumulator
          }, {}),
          loading: false,
          error: null
        }
      }
    case 'FETCH_ALL_OPTIONS_FAILURE':
      return {
        ...state.adminData, ...{
          options: defaultState.options,
          loading: false,
          error: action.payload
        }
      }

    case 'FETCH_ALL_RIGHTS_REQUEST':
      return {
        ...state.adminData, ...{
          rights: defaultState.rights,
          loading: true,
          error: null
        }
      }
    case 'FETCH_ALL_RIGHTS_SUCCESS':
      return {
        ...state.adminData, ...{
          rights: action.payload.reduce((accumulator, currentValue) => {
            accumulator[currentValue.id] = currentValue
            return accumulator
          }, {}),
          loading: false,
          error: null
        }
      }
    case 'FETCH_ALL_RIGHTS_FAILURE':
      return {
        ...state.adminData, ...{
          rights: defaultState.rights,
          loading: false,
          error: action.payload
        }
      }

    default:
      return state.adminData
  }
}

export default updateAdminData