import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ArchiveManager from "../../common/ArchiveManager"
import { Collapse } from "@material-ui/core"

const ArchiveTab = ({ type, formData, setFormData }) => {
    const { t } = useTranslation()
    const [openFolders, setOpenFolders] = useState(false)
    const [folderPath, setFolderPath] = useState(formData[type].pathName)

    const localFormData = formData[type]
    if (!localFormData)
        return null

    const handleFolderClick = (id, tFolders, names) => {
        setOpenFolders(false)
        setFormData(d => {
            return { ...d, [type]: { ...d[type], pathName: names, folder: id } }
        })
        setFolderPath(names.reverse().join('/'))
    }

    return (
        <div className={`d-flex flex-column pt-3`} style={{ gridGap: 10 }}>
            <div className="d-flex align-items-center pb-3">
                <div onClick={() => setOpenFolders(s => !s)}>{t('Folder name')} <strong>{folderPath}</strong>
                    {openFolders ? <ExpandLess /> : <ExpandMore />}
                </div>
            </div>
            <Collapse in={openFolders} timeout="auto"
                style={{
                    overflowY: 'scroll', minWidth: 250, maxHeight: 320, top: 70, backgroundColor: '#ffffff', padding: 5, zIndex: 10, position: 'absolute', border: '1px solid #000000'
                }}
            >
                <ArchiveManager
                    type={type}
                    defaultFolderId={0}
                    showCheckbox={false}
                    showRootCheckbox={false}
                    showAllFiles={false}
                    showAddIcon={true}
                    showEditIcon={false}
                    showDeleteIcon={false}
                    showArchivesCount={false}
                    showMassFunctions={false}
                    externalFolderClick={handleFolderClick}
                />
            </Collapse>
        </div>
    )
}

export default ArchiveTab