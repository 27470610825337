import React, { useEffect, useRef, useState } from 'react'
import style from './style.module.sass'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Rows from './Rows'
import { useTheme } from '@material-ui/core/styles'
import EnhancedTableHead from './TableHead'
import TableFilter from './TableFilter'
import TableFooter from './TableFooter'
import UserProfileWrapper from '../../UserProfileWrapper/UserProfileWrapper'
import AddNew from './AddNew'
import TermUploadModal from './TermUploadModal'
import AddNewTermModal from '../../../ModalWindows/TermModal/TermModal.jsx'
import EditTermModal from './EditTermModal'
import { useParams } from 'react-router'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../Spinner'
import { TableCell, TableRow } from '@material-ui/core'

function createData (id, words, term_replacement, description, check_word, lemma, tag) {
  return { id, words, term_replacement, description, check_word, lemma, tag }
}

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

export default function TermsByCategory () {
  const [noData, setNoData] = useState(false)
  const { theme: { bgColors } } = useTheme()
  const [openAddNewFields,] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [selected, setSelected] = useState([])
  const [uploadModal, setUploadModal] = useState(false)
  const [addNewTermModal, setAddNewTermModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [letterRows, setLetterRows] = useState([])
  const [allDataCount, setAllDataCount] = useState()
  const [limitIs, setLimitIs] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [pageData, setPageData] = useState()
  const [deleteRecall, setDeleteRecall] = useState()
  const forward = useRef(0)
  const [filter, setFilter] = useState(1)
  const handleFilter = e => setFilter(e.target.value)
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const [deleteCheckedTermModal, setDeleteCheckedTermModal] = useState(false)
  const [deleteAlertMsg, setDeleteAlertMsg] = useState(false)
  // const [terminologyModal, setTerminologyModal] = useState(false);
  const [orderByValue, setOrderByValue] = useState('words')
  const [orderASCDESC, setOrderASCDESC] = useState(0)
  const [currentTerm, setCurrentTerm] = useState()
  const [textValue, setTextValue] = useState('')
  const [termCategoryName, setTermCategoryName] = useState()
  const [localeName, setLocaleName] = useState()
  const [termRights, setTermRights] = useState([])
  const [termParentId, setTermParentId] = useState(undefined)
  const [termOwner, setTermOwner] = useState(0)
  // const [termReplacementEdit, setTermReplacementEdit] = useState();
  const [tableMessage, setTableMessage] = useState('no-data-term')
  const [searchPlaces, setSearchPlaces] = useState({ term: true, replacement: true, description: true })
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const { categoryID } = useParams()

  const handleRequestSort = (event, property, orderBy_value) => {
    // setPage(0)
    // setRowsPerPage(10)
    setOrderBy(property)
    setOrderByValue(orderBy_value)
    if (orderASCDESC === 1) {
      setOrder('asc')
      setOrderASCDESC(0)
    } else {
      setOrder('desc')
      setOrderASCDESC(1)
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = letterRows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  // Set page to 1 & offset to 0 and checked archive to unchecked when limit is changed or language is changed or archive is changed :
  useEffect(() => {
    setCurrentPage(1)
    forward.current = 0
    setSelected([])
  }, [limitIs])

  const pageSetter = async (val) => {
    setCurrentPage(val)
    forward.current = val * limitIs - 10
    await callEndPoint()
  }

  // Call Term category api for name and locale:
  const callTermCategory = async () => {
    let terms
    try {
      terms = await tlService.getFilteredTermCategories(token, {}, {
        id: categoryID,
        return_values: JSON.stringify(['id', 'owner', 'name', 'locale_name', 'rights', 'parent_id'])
      })
    } catch (error) {
      console.log(error)
    }
    if (terms?.data?.length) {
      setTermCategoryName(terms.data[0].name[i18nLanguage])
      setLocaleName(terms.data[0].locale_name)
      setTermRights(terms.data[0].rights || [])
      setTermParentId(terms.data[0].parent_id)
      setTermOwner(terms.data[0].owner || 0)
    }
  }

  useEffect(() => {
    callTermCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const callEndPoint = async (val) => {
    setTableMessage('no-data-term')
    setSpinner(true)
    switch (val) {
      case 0:
        // setTextValue('')
        forward.current = forward.current - limitIs
        if (selected.length !== 0) {
          setSelected([])
        }
        break
      case 1:
        // setTextValue('')
        forward.current = forward.current + limitIs
        if (selected.length !== 0) {
          setSelected([])
        }
        break
      default:
        break
    }
    let response
    let filterIs
    if (val === 'delete') {
      forward.current = 0
      filterIs = {
        offset: forward.current,
        category_id: categoryID,
        order_by: orderByValue,
        order: orderASCDESC
      }
    } else if (textValue?.length) {
      if (val !== 0 && val !== 1) {
        forward.current = 0
      }
      // [A-Za-z0-9äöüÄÖÜß-]
      const preparedValues = textValue.replace(/"/g, ' " ').replace(/([,.;:!?])/g, ' $1').split(/ +/)
      let wordSearch = ''
      let descrSearch = ''
      let insideBrackets = false
      for (const word of preparedValues) {
        if (word.length === 0)
          continue
        if (word === '"') {
          insideBrackets = !insideBrackets
          continue
        }
        if (word.match(/^[,.;:!?]$/)) {
          descrSearch = descrSearch.replace(/ $/, '')
        }
        if (insideBrackets) {
          wordSearch = wordSearch.length === 0 ? ' ' : wordSearch
          wordSearch = wordSearch.replace(/([^ ])$/, '$1 ')
          descrSearch = descrSearch.length === 0 ? ' ' : descrSearch
          descrSearch = descrSearch.replace(/([^ ])$/, '$1 ')
          wordSearch += word + ' '
          descrSearch += word + ' '
        } else {
          wordSearch += '%' + word + '% '
          descrSearch += '%' + word + '%'
        }
      }
      //searchPlaces
      const extendedFilter = []
      if (searchPlaces['term']) {
        extendedFilter.push(['|words.', `atl%${wordSearch}%`])
      }
      if (searchPlaces['replacement']) {
        extendedFilter.push(['|replacement_words.', `atl%${wordSearch}%`])
      }
      if (searchPlaces['description']) {
        extendedFilter.push(['|description', `l=%${descrSearch}%`])
      }
      if (descrSearch.match(/^ /)) {
        const descrFirstWordSearch = descrSearch.replace(/^ +/, '')
        extendedFilter.push(['|description', `l=${descrFirstWordSearch}%`])
      }
      filterIs = {
        offset: forward.current,
        extended_filter: JSON.stringify(extendedFilter),
        category_id: categoryID,
        order_by: orderByValue,
        order: orderASCDESC
      }
    } else {
      filterIs = {
        offset: forward.current,
        category_id: categoryID,
        order_by: orderByValue,
        order: orderASCDESC
      }
    }
    try {
      response = await tlService.getFilteredTermByCategory(token, { limit: limitIs }, filterIs)
      setSpinner(false)
      setNoData(false)
    } catch (e) {
      if (e.message) {
        try {
          const error = JSON.parse(e.message)
          if (error.number === 404 && error.message?.length) {
            setTableMessage(error.message)
          }
        } catch (e) {

        }
      }
      console.log('error', e)
      setSpinner(false)
      setPageData('')
      setNoData(true)
      switch (val) {
        case 0:
          forward.current = forward.current + limitIs
          break
        case 1:
          forward.current = forward.current - limitIs
          break
        default:
          break
      }
      setLetterRows('')
      // Setting Total page to 0 if no data found:
      setAllDataCount(0)
      // setting current input page no. to 1 and all data to empty as it will be 1 as well
      if (val !== 0 && val !== 1 && !!val) {
        setCurrentPage(1)
        // setAllData('');
      }
    }
    if (response?.fullcount) {
      setAllDataCount(response.fullcount)
    }
    if (response?.data) {
      setPageData(response?.data)
    }
    if (response?.data) {
      let arry = []
      response?.data.map(el => {
        if (el !== undefined) {
          //  let createTime = el?.create_time?.replace(/[^\0-9\:]/g, '').slice(0, -1);
          arry.push(createData(el.id, el.words, el.term_replacement, el.description, el.check_words, el.lemma, el.tag))
        }
      })
      if (arry?.length && !!arry) {
        setLetterRows(arry)
      }
      let pageNumber = Math.ceil(allDataCount / limitIs)
      // When user delete, it should go to first page:
      if (val === 'delete') {
        setCurrentPage(1)
      }
      if (forward.current >= 0) {
        let currentPageIs
        if (currentPage < pageNumber) {
          if (val === 0) {
            currentPageIs = Math.floor(forward.current / limitIs - 1)
            if (currentPageIs <= 0) {
              setCurrentPage(1)
            } else {
              if (currentPageIs > 0) {
                setCurrentPage(currentPageIs)
              }
            }
          } else if (val === 1) {
            currentPageIs = Math.floor(forward.current / limitIs + 1)
            if (currentPageIs > 0) {
              setCurrentPage(currentPageIs)
            }
          }
        }
        // here currently when user page no. input and total page is same, this func run to decrease the page no. :
        if (val === 0) {
          // currentPageIs = Math.floor(forward.current / limitIs);
          currentPageIs = currentPage - 1
          if (currentPageIs > 0) {
            setCurrentPage(currentPageIs)
          }
        }
      }
      // to clean the rows for next page filter by id
      // setRows([]);
    }
  }

  // Consecutive call!
  useEffect(() => {
    callEndPoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitIs, deleteRecall, orderBy, orderASCDESC])

  // deleteRecall, orderBy, orderASCDESC
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }
  const isSelected = (id) => selected.indexOf(id) !== -1

  const deleteChecked = async () => {
    const elementsId = letterRows?.length ? letterRows.map(
      element => selected.includes(element.id) ? element.id : null) : []
    setSpinner(true)
    if (filter === 1) {
      for (let element of elementsId) {
        try {
          if (!!element) {
            await tlService.deleteTerm(token, categoryID, element)
            setDeleteCheckedTermModal(false)
          }
        } catch (e) {
          setDeleteAlertMsg(e.message)
          console.log('error', e.message)
          break
        }
      }
    } else if (filter === 2) {
      for (let element of letterRows) {
        try {
          if (!!element) {
            await tlService.deleteTerm(token, categoryID, element.id)
            setDeleteCheckedTermModal(false)
          }
        } catch (e) {
          setDeleteAlertMsg(e.message)
          console.log('error', e.message)
          break
        }
      }
    }
    // try to reduce offset by limit amount if delete whole data on one page:
    if (selected?.length === letterRows?.length || filter === 2) {
      forward.current = forward.current - limitIs
      // if the offset is negative than make it 0:
      if (forward.current < 0) {
        forward.current = 0
      }
    }
    //  Calculate the Term Category size N Clear Cache
    try {
      await tlService.calculateCategory(token, Number(categoryID))
      await tlService.clearAllCache(token, 1, Number(categoryID))
    } catch (error) {
      console.log(error)
    }
    setSelected([])
    callEndPoint()
  }

  const handleDownload = () => console.log('download')

  return (
    <UserProfileWrapper>
      <div className={`${style.mainCont}`}>
        {spinner && <Spinner/>}
        <Paper className={``}>
          <TermUploadModal reloadPage={callEndPoint} open={uploadModal} setOpen={setUploadModal}/>
          <AddNewTermModal
            component="TermList"
            callEndPoint={callEndPoint}
            localeName={localeName}
            setDeleteRecall={setDeleteRecall}
            currentTerm={currentTerm}
            open={addNewTermModal} setOpen={setAddNewTermModal}/>
          <EditTermModal
            open={editModal} setOpen={setEditModal}/>
          <TableContainer>
            <Table
              style={{ backgroundColor: bgColors.bgPrimary100, borderColor: 'transparent' }}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <AddNew
                termRights={termRights}
                termParentId={termParentId}
                termOwner={termOwner}
                localeName={localeName}
                termCategoryName={termCategoryName} setCurrentTerm={setCurrentTerm}
                setUploadModal={setUploadModal} setAddNewTermModal={setAddNewTermModal}
                handleDownload={handleDownload}/>
              <TableFilter
                searchPlaces={searchPlaces}
                setSearchPlaces={setSearchPlaces}
                setDeleteRecall={setDeleteRecall}
                setTextValue={setTextValue}
                textValue={textValue}
                searchTerm={callEndPoint}
              />
              <EnhancedTableHead
                deleteAlertMsg={deleteAlertMsg}
                deleteCheckedTermModal={deleteCheckedTermModal}
                setDeleteCheckedTermModal={setDeleteCheckedTermModal}
                deleteChecked={deleteChecked}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                openAddNewFields={openAddNewFields}
                rowCount={letterRows.length}
              />
              <TableBody>
                {letterRows?.length ? letterRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <Rows key={row.id} handleClick={handleClick}
                            isItemSelected={isItemSelected} labelId={labelId}
                            row={row}
                            localeName={localeName}
                            isEven={index % 2 === 0}
                            setSelected={setSelected}
                            setAddNewTermModal={setAddNewTermModal}
                            setCurrentTerm={setCurrentTerm}
                            callEndPointReCall={callEndPoint}
                      />
                    )
                  })
                  :
                  (noData && <TableRow>
                    <TableCell colSpan="10"
                               style={{ height: '400px', textAlign: 'center', marginTop: '140px' }}>
                      <h2>   {t(tableMessage)} </h2>
                    </TableCell>
                  </TableRow>)
                }
              </TableBody>
              <TableFooter
                handleFilter={handleFilter}
                allDataCount={allDataCount}
                pageSetter={pageSetter}
                currentPage={currentPage}
                pageData={pageData}
                callEndPointForward={callEndPoint}
                callEndPointBackward={callEndPoint}
                setLimitIs={setLimitIs}
                filter={filter}
                selected={selected}/>

            </Table>
          </TableContainer>
        </Paper>
      </div>
    </UserProfileWrapper>
  )
}
