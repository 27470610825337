import React from "react";
import style from "./style.module.sass";
import Header from "./Header";
import Dashboard from "./Dashboard";
import {useTheme} from '@material-ui/core/styles';

function MainPageWrapper({children, showDouble= false}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <div style={{backgroundColor: theme.theme.bgColors.bgPrimary100}} className={style.mainCont}>
      <Header setOpen={setOpen} showDouble={showDouble} />
      <Dashboard open={open} setOpen={setOpen}/>
      {children}
    </div>
  )
}

export default MainPageWrapper;
