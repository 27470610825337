import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, } from "@material-ui/core";
import Draggable from 'react-draggable';
import { useDispatch } from "react-redux";
import {
    analyseSetFileName
} from '../../actions/analyse.js'

export default function ModalDialog({ handleClose, open, title, text, yesText, noText }) {
    const dispatch = useDispatch();
    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(null)}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!!yesText && <Button onClick={() => {
                    handleClose(true);
                   // dispatch(analyseSetArchive(''))
                    dispatch(analyseSetFileName(""))
                }}
                    color="primary" autoFocus>{yesText}</Button>}
                {!!noText && <Button onClick={() => handleClose(false)} color="secondary">{noText}</Button>}
            </DialogActions>
        </Dialog>
    )
}