import React, { useEffect, useState } from "react"
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

function IframeComponent() {
    const { theme: { bgColors } } = useTheme();
    const [text, setText] = useState("");
    const [textType, setTextType] = useState("");
    const [textLocale, setLocale] = useState("");
    const { t } = useTranslation();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const service_id = parseInt(query.get("service_id"));
    const locale = query.get("locale");
    const token = query.get("token");
    const url = (window.location !== window.parent.location)
        ? document.referrer
        : document.location.href;
    console.log("url", url);

    function receiveMessage({ data }) {
        if (data?.type === "text") {
            setText(data?.text);
            setLocale(data?.locale)
            setTextType(data?.text_type)
        }
    }

    function sendMessage() {
        window.parent.postMessage({ "type": "result", text: text }, url)
    }

    function sendError() {
        window.parent.postMessage({ "type": "error", message: "reason of error" }, url)
    }

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        window.parent.postMessage({ type: "ready" }, url)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (service_id !== 2 || !locale || !token) {
        return (
            <div style={{ backgroundColor: bgColors.bgPrimary100 }}>
                <h1>
                    {t('url-error')}
                </h1>
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: bgColors.bgPrimary100 }}>
            <h1>
                {t('frame-test')}
            </h1>
            <div>
                <p>{t('token')} : {token}</p>
                <p>{t('service_id')} : {service_id}</p>
                <p>{t('locale')} : {locale}</p>
            </div>
            {text ?
                <div className={"p-2 m-2"}>
                    <p>{t('text-type')} : {textType}</p>
                    <p>{t('text-locale')} : {textLocale}</p>
                    <p><textarea onChange={event => setText(event.target.value)}>{text}</textarea></p>
                    <p><button className={"btn btn-success"} onClick={sendMessage}>
                        {t('send-back')}
                    </button></p>
                    <p><button className={"btn btn-danger"} onClick={sendError}>
                        {t('send-error')}
                    </button></p>
                </div>
                :
                <div>
                    <p>{t('url-is')} {url}</p>
                    <p>{t('wait')}...</p>
                </div>
            }


        </div>
    )
}

export default IframeComponent;