import style from "./style.module.scss"
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

export default function RenderSpeechCLTextComparisonHChartLines({title, color, texts}){
  const {theme: {colors}} = useTheme();

  const { t } = useTranslation();

  return (
    <div className={`${style.RSCLTCHCLines} pt-3 pb-3`}>
      <h3 style={{color: color}}>{title}</h3>
      {
        texts.map(({name, ziel, wert}, i) => {
          return (

            <div key={`RenderSpeechCLTextComparisonHChartLines${i}`} className={`d-flex align-items-center justify-content-between`}>
              <h4 style={{color: i < 1 ? colors.colorBlue1000 : colors.colorProgress60}}>{name}</h4>

              <div className={style.chart}>
                <div style={{opacity: i < 1 ? 1: 0.5, backgroundColor: color, width: `${wert}%`}} className={`${style.filled}`}/>
                <div className={`${style.indicator}`} style={{left: `${ziel}%`}}/>
              </div>

              <div className={`d-flex align-items-center justify-content-between`}>
                <p className={`mr-1`}><b>{t('value')} {wert}%</b>,</p>
                <p> {t('goal')} {ziel}%</p>
              </div>
            </div>

          )
        })
      }
    </div>
  )
}
