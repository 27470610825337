export const BorderColors = {
  borderColors: {
    borderPrimary100: "#de1818",//E9E9F0
    borderPrimary200: "#358323",//CCCCCC
    borderPrimary300: "#2a794b",//C3C3C3
    borderPrimary400: "#680572",//B9B9BA
    borderPrimary500: "#f34787",//B2B2B2
    borderPrimary700: "#6ffb00",//B4B4B4
    borderPrimary800: "#283ab6",//7C7B7B
    borderPrimary1000: "#81ae44",//000000
  },
};
