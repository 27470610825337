import style from "./style.module.scss";
import {Button} from "@material-ui/core";
import TonalityCollapsableChart from "../UI/TonalityCollapsableChart";
import React, {useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const tonalityRows = [
  {id: 1, text1: "bürokratisch", text2: "modern", activeBlock: 4, activeBlock2: 7},
  {id: 2, text1: "neutral, förmlich", text2: "persönlich", activeBlock: 3, activeBlock2: 5},
  {id: 3, text1: "inhaltsbetont, abstrakt", text2: "lebendig, ansprechend", activeBlock: 2, activeBlock2: 3},
  {id: 4, text1: "knapp, präzise", text2: "ungenau, abschwächend", activeBlock: 7, activeBlock2: 7},
  {id: 5, text1: "sachlich, objektiv", text2: "cccc", activeBlock: 6, activeBlock2: 7}
]

export default function TonalityTexts() {
  const [totalOverTime, setTotalOverTime] = useState(false);
  const {theme: {colors}} = useTheme();

  const { t } = useTranslation();

  return (
    <div>
      <div className={`d-flex justify-content-between align-items-center ${style.withBorder}`}>
        <h2 className={`m-0 p-0 w-100 ${style.withoutBorder}`} style={{color: colors.colorPrimary400}}>
          {t('tonality')}
        </h2>
        <div className={`d-flex w-100 justify-content-end align-items-center ${style.textTitles}`}>
          <p className={`m-0`} style={{color: colors.colorBlue1000}}>{t('optional')}  1</p>
          <p className={`m-0`} style={{color: colors.colorProgress60}}>{t('optional')} 2</p>
        </div>
      </div>
      <div className={`d-flex align-items-center justify-content-between`}>
        <p className={`m-0 font-weight-bold`}>
          {t('selection-of-text')}
        </p>
        <div>
          <Button size='small' color={totalOverTime ? 'primary' : 'default'}
                  onClick={() => setTotalOverTime(true)}>
                    {t('comparison')}
                  </Button>
          <Button size='small' color={!totalOverTime ? 'primary' : 'default'}
                  onClick={() => setTotalOverTime(false)}>
            {t('over time')}
          </Button>
        </div>
      </div>

      {tonalityRows.map((row) => <TonalityCollapsableChart key={`dashboard-home-tonality-ranger-${row.id}`} {...row}/>)}
    </div>
  )
}
