import React from "react"
import { TextField } from "@material-ui/core"

function Field({ formik, name, type, value = null, error = null, ...rest }) {
  const stringValue = value && typeof value === 'object' ? JSON.stringify(value) : value
  return (
    <TextField
      fullWidth
      name={name && name}
      type={type ? type : "text"}
      {...rest}
      variant="outlined"
      value={stringValue || (formik?.values[name] && typeof formik?.values[name] === 'object' ? JSON.stringify(formik?.values[name]) : formik?.values[name])}
      onChange={formik?.handleChange}
      error={error || (formik?.touched[name] && Boolean(formik?.errors[name]))}
      helperText={error || (formik?.touched[name] && formik?.errors[name])}
    />
  )
}

export default Field
