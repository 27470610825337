import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Notification from '../Notification'
import logo from './assets/Component-3–1.svg'
import burger from './assets/burger.svg'
import tlIcon from './assets/tl-Icon.png'
import style from './style.module.sass'
import { useSelector } from 'react-redux'
import BatchProcessingProgressbar from '../../Analysis/BatchProcessing/BatchProcessingProgressbar'
import ArchiveProgressbar from '../../Archive/ArchiveProgressBar'
import DropdownUser from './DropdownUser'
import { isObjectEmpty } from '../../../utils'


function Header ({ setOpen, showDouble }) {

  // const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || []);
  const [openPersonalModalWin, setOpenPersonalModalWin] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  const batchProcessingState = useSelector(state => state.batchData)
  const analyseProgressBar = useSelector(state => state.analyseData.analyseProgressBar)

  // t('analyseSaved')
  return (
    <header className={`${style.header} d-flex justify-content-between`}>
      <div className={style.firstPart}>
        <img src={burger} onClick={() => setOpen(true)} className={style.burger}
             alt="burger svg"/>
        <NavLink to="/" exact={true} className={style.logo}><img src={logo} alt=""/></NavLink>
      </div>
      <div className={`${style.secondPart}`} style={{ marginRight: '-75px' }}>
        <div style={{
          position: 'absolute',
          right: 0,
          marginRight: '100px',
          top: 0,
          marginTop: '20px',
        }}>

          {(batchProcessingState.running || batchProcessingState.done || batchProcessingState.hasError) ?
            <BatchProcessingProgressbar/> : <></>
          }
          {!isObjectEmpty(analyseProgressBar) ? <ArchiveProgressbar/> : <></>}

        </div>
        <div style={{ marginRight: '-215%' }}>
          <img onClick={() => {
            setOpenPersonalModalWin(!openPersonalModalWin)
            setOpenNotify(false)
          }} src={tlIcon} className={style.avatar}
               alt="burger svg"/>
        </div>
      </div>
      <DropdownUser setOpenPersonalModalWin={setOpenPersonalModalWin}
                    openPersonalModalWin={openPersonalModalWin}/>
      <Notification setOpen={setOpenNotify} open={openNotify}/>
    </header>
  )
}

export default Header
