import React, { useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal } from '@material-ui/core'
import ComponentWithPop from '../common/ComponentWithPop'

export default function Footer ({ children = <></> }) {

  const { t } = useTranslation()
  const { theme: { bgColors, colors } } = useTheme()
  const [textModal, setTextModal] = useState(null)

  // get reducers
  const backendVersion = useSelector(state => state.openData.ping.version)
  const thinclient = useSelector(state => state.openData.ping?.thinclient)
  const frontVersion = `${process.env.REACT_APP_MAJOR_VERSION}.${process.env.REACT_APP_MINOR_VERSION}.${process.env.REACT_APP_PATCH_COUNTER}`
  const popText = `Backend: <strong>${backendVersion}</strong><br/>Frontend: <strong>${frontVersion}</strong><br/>Thinclient: <strong>${thinclient ? 'enabled' : 'disabled'}</strong>`

  return (
    <footer style={{ color: colors.colorPrimary300, backgroundColor: bgColors.bgPrimary100 }}
            className={`${style.mainCont}`}>
      <Modal open={!!textModal} className="d-flex align-items-center justify-content-center"
             onClose={() => setTextModal(null)}>
        <div className={style.textModal} dangerouslySetInnerHTML={{ __html: textModal }}
             style={{ color: colors.colorPrimary600 }}/>
      </Modal>
      <div className={`m-0 ${style.license}`}>
        <p style={{ cursor: 'pointer' }}><ComponentWithPop text={popText}
                                                           html={true}>{t('TextLab Version')}</ComponentWithPop></p>
        <span style={{ color: colors.colorBlue1000, cursor: 'pointer' }}
              onClick={() => setTextModal(t('Imprint Text'))}>{t('Imprint')} </span> | &nbsp;
        <span style={{ color: colors.colorBlue1000, cursor: 'pointer' }}
              onClick={() => setTextModal(t('Privacy Text'))}>{t('Privacy')}</span>
      </div>
      {children}
    </footer>
  )
}