import React from "react";
import MainPageWrapper from "../../../Main-page-wrapper";
import Dashboard from "./Dashboard/Dashboard";
import style from "./style.module.sass"

function CRWrapper({children}) {
  return (
    <MainPageWrapper>
      <div className={`${style.mainCont}`}>
        <Dashboard/>
        {children}
      </div>
    </MainPageWrapper>
  )
}

export default CRWrapper;