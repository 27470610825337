import React, { useState } from 'react'

const updateTableData = (state, action) => {
  const defaultState = {
    data: [],
    pagination: [],
    pages: 1,
    currentPage: 1,
    filters: {},
    extraFilters: [],
    inscription: '',
    selectAll: false,
    selectAllIds: [],
    disabledColumns: [],
    columns: [],
    rowCount: 0,
    selected: [],
    order: 'asc',
    orderBy: '',
    limit: 100,
    otherData: {},
    redirect: '',
    companyId: 0,
    groupId: 0,
    dialog: {
      type: '',
      open: false,
      RequestComponent: () => null,
      response: null,
      buttons: {
        yes: false,
        no: false,
        close: false,
        save: false
      }
    }
  }

  if (state === undefined) {
    return defaultState
  }

  switch (action.type) {
    case 'TABLE_SET_SELECT_ALL_IDS':
      return {
        ...state.tableData,
        selectAllIds: action.payload
      }
    case 'TABLE_SET_DEFAULTS':
      return {
        ...state.tableData,
        pagination: action.payload.pagination,
        extraFilters: action.payload.extraFilters,
        disabledColumns: action.payload.disabledColumns,
        columns: action.payload.columns,
        order: action.payload.order,
        orderBy: action.payload.orderBy
      }
    case 'TABLE_SET_CURRENT_PAGE_AND_LIMIT':
      return {
        ...state.tableData,
        currentPage: action.payload.currentPage,
        limit: action.payload.limit
      }
    case 'TABLE_SET_PAGINATION':
      return {
        ...state.tableData,
        pagination: action.payload
      }
    case 'TABLE_SET_PAGES':
      return {
        ...state.tableData,
        pages: action.payload
      }
    case 'TABLE_SET_REDIRECT':
      return {
        ...state.tableData,
        redirect: action.payload
      }
    case 'TABLE_SET_DIALOG':
      return {
        ...state.tableData,
        dialog: action.payload
      }
    case 'TABLE_DIALOG_RESET':
      return {
        ...state.tableData,
        dialog: defaultState.dialog
      }

    case 'TABLE_SET_DATA':
      return {
        ...state.tableData,
        data: action.payload
      }
    case 'TABLE_SET_OTHER_DATA':
      return {
        ...state.tableData,
        otherData: action.payload
      }
    case 'TABLE_SET_LIMIT':
      return {
        ...state.tableData,
        limit: action.payload
      }
    case 'TABLE_SET_ORDER':
      return {
        ...state.tableData,
        order: action.payload
      }
    case 'TABLE_SET_ORDER_BY':
      return {
        ...state.tableData,
        orderBy: action.payload
      }
    case 'TABLE_SET_ROW_COUNT':
      return {
        ...state.tableData,
        rowCount: action.payload
      }
    case 'TABLE_SET_SELECTED':
      return {
        ...state.tableData,
        selected: action.payload
      }
    case 'TABLE_SET_COLUMNS':
      return {
        ...state.tableData,
        columns: action.payload
      }
    case 'TABLE_SET_DISABLED_COLUMNS':
      return {
        ...state.tableData,
        disabledColumns: action.payload
      }
    case 'TABLE_SET_SELECT_ALL':
      return {
        ...state.tableData,
        selectAll: action.payload
      }
    case 'TABLE_SET_INSCRIPTION':
      return {
        ...state.tableData,
        inscription: action.payload
      }
    case 'TABLE_SET_CURRENT_PAGE':
      return {
        ...state.tableData,
        currentPage: action.payload
      }
    case 'TABLE_SET_FILTERS':
      return {
        ...state.tableData,
        filters: action.payload
      }
    case 'TABLE_SET_EXTRA_FILTERS':
      return {
        ...state.tableData,
        extraFilters: action.payload
      }
    case 'TABLE_SET_COMPANY_ID':
      return {
        ...state.tableData,
        companyId: action.payload
      }
    case 'TABLE_SET_GROUP_ID':
      return {
        ...state.tableData,
        groupId: action.payload
      }
    case 'TABLE_CLEAR_ALL':
      return defaultState

    default:
      return state.tableData
  }

}

export default updateTableData