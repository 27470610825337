export const BorderColors = {
  //light border color variables
  borderColors: {
    borderPrimary100: "#E9E9F0",
    borderPrimary200: "#CCCCCC",
    borderPrimary300: "#B9B9BA",
    borderPrimary400: "#B2B2B2",
    borderPrimary500: "#B4B4B4",
    borderPrimary700: "#7C7B7B",
    borderPrimary800: "#585858",
    borderPrimary900: "#434343",
    borderPrimary1000: "#000000",
  },
};
