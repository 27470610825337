import React, { useState } from "react"
import style from "../style.module.sass"
import { useTheme } from "@material-ui/core/styles"
import TypeOfField from "../../../../../FormikFields/TypeOfField"
import { Collapse } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { checkUserRight } from "../../../../../../utils"

export default function CompanyProfile({ formik, licenses, id }) {
  const [bg, setBg] = useState(false)
  const { t } = useTranslation()
  const user = useSelector(state => state.userData.user)
  const [collapse, setCollapse] = useState(true)
  const { theme: { bgColors, colors } } = useTheme()

  const userRight = checkUserRight(user, [308])

  const fields = [
    { id: "1", control: "text", name: "companyName", label: t("admin_company_company-name"), disabled: !userRight },
    { id: "2", control: "select", name: "companyLicenses", label: t("admin_company_license"), multiple: true, options: licenses, disabled: !userRight },
  ]

  switch (!!id) {
    case true:
      fields.push(
        { id: "3", control: "text", name: "companyId", label: t("admin_company_companyId"), disabled: true },
        { id: "4", control: "date", name: "create_time", label: t("admin_company_create-time"), disabled: true },
        { id: "5", control: "date", name: "update_time", label: t("admin_company_update-time"), disabled: true }
      )
      break
    default:
      break
  }

  return (
    <div style={{ backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50 }}
      onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div style={{ backgroundColor: bgColors.bgPrimary500 }}
        className={`${style.header} ${style.borderTop} d-flex align-items-center justify-content-between pr-4`}>
        <h4 style={{ color: colors.colorPrimary0 }}
          className={style.title}>{t('admin_group_group-profile')}</h4>
        <button className={`ml-2 p-0`} onClick={(e) => {
          e.preventDefault()
          setCollapse(!collapse)
        }}>
          {!collapse ? <AddIcon style={{ fill: "white" }} fontSize="small" /> :
            <RemoveIcon style={{ fill: "white" }} fontSize="small" />}
        </button>
      </div>
      <Collapse in={collapse}>
        <ul className={`mb-0`}>
          {
            fields.map(
              f => (
                <li key={f.id}>
                  <TypeOfField control={f.control} formik={formik} name={f.name} label={f.label} {...f} />
                </li>
              )
            )
          }
        </ul>
      </Collapse>
    </div>
  )
};
