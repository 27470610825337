import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { Col } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ShowKeywords from "./ShowKeywords"
import ArchiveTab from "./ArchiveTab"

const CreateTabContent = ({ setVersion, version, setTags, setName, ApiOption, name, formData, setFormData
}) => {
    const saveArchive = useSelector(state => state.analyseData.saveArchive)
    const [localTags, setLocalTags] = useState(saveArchive?.tags?.join(', ') || '')
    const { t } = useTranslation()

    useEffect(() => {
        setTags(localTags)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localTags])

    return (
        <Col sm="12" className={'d-flex align-items-center pr-5 pl-5'} style={{ minHeight: 200 }}>
            <FormControl className={'d-flex py-2'} style={{ gridGap: 10 }} component="fieldset">
                <br />
                <ArchiveTab type={ApiOption} formData={formData} setFormData={setFormData} />
                {<TextField value={name} style={{ width: "250px" }} label={t('Name')} type='text' variant='outlined' size='small' onChange={(e) => setName(e.target.value)} />}
                <TextField style={{ width: "250px" }} onChange={(e) => setVersion(e.target.value)} label={t('Version')} type='text' variant='outlined' size='small' value={version} />
                <TextField style={{ width: "250px" }} label={localTags?.length === 0 && t("Keywords")} id='keywords' type='text' variant='outlined' size='small'
                    onChange={(e) => setLocalTags(e.target.value)}
                    autoComplete='off'
                    value={!!localTags ? localTags : ''}
                    placeholder={t('keywords')}
                />
                <ShowKeywords keywordsString={localTags}/>
            </FormControl>
        </Col>
    )
}

export default CreateTabContent