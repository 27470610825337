import React from 'react';
import style from "./style.module.sass";
import Accordion from "./Accordion";
import InitialTab from "./InitialTab";
import {useSelector} from "react-redux";

export default function Tabs({
                                 handleModalAccordionOpen,
                                 handleTopAccordionOpen,
                                 getNextPopup,
                                 handleOnPopupClick,
                                 handleChangeAnalyseTextType,
                                 handleChangeAnalyseTonality,
                                 handleOnAnalyseClick,
                                 analyseButtonEnable,
                                 setAnalyseButtonEnable,
                                 handleSaveAnalyseTonality
                             }) {
    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);

    return (
        <div className={`${style.mainCont}`}>
            <div className={style.onDesktop}>
                {resultTemplate ?
                    <Accordion
                        handleModalAccordionOpen={handleModalAccordionOpen}
                        handleTopAccordionOpen={handleTopAccordionOpen}
                        handleOnPopupClick={handleOnPopupClick}
                        getNextPopup={getNextPopup}
                    />
                    :
                    <InitialTab
                        handleOnAnalyseClick={handleOnAnalyseClick}
                        analyseButtonEnable={analyseButtonEnable}
                        setAnalyseButtonEnable={setAnalyseButtonEnable}
                        handleChangeAnalyseTextType={handleChangeAnalyseTextType}
                        handleChangeAnalyseTonality={handleChangeAnalyseTonality}
                        handleSaveAnalyseTonality={handleSaveAnalyseTonality}
                    />
                }
            </div>
        </div>
    );
}
