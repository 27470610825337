import React from "react"
import style from "./style.module.scss"
import DashHeader from "../Header"
import VColoredLinearChart from "../UI/VColoredLinearChart";
import {useTheme} from "@material-ui/core/styles";
import VocabularyComponent from "../UI/tags-cloud/VocabularyComponent";
import SingleDistribution from "../Home/SingleDistribution";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    chooseColor,
    convertLanguagesToShort,
    countTermsWithPositions,
    getScale,
    getValue,
    isObjectEmpty,
    roundWithPrecision
} from "../../../utils";
import {Redirect} from "react-router-dom";

export default function VocabularyComp() {
   
    const {theme: {bgColors, colors}} = useTheme();
    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const archiveName = useSelector(state => state.analyseData.archives?.name);
    const benchmark = useSelector(state => state.analyseData.result.benchmark);
    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);

    if (resultTemplate < 1)
        return <Redirect to={"/"}/>;

    let listIs = [];


    // go through [dataCLIX] value to find clix > 0
    if (!isObjectEmpty(resultData.dataTerms)) {
        listIs = Object.keys(resultData.dataTerms).reduce((acc, val, index) => {

            // if it's a number - it's  Terms
            const countNumber = countTermsWithPositions(resultData.dataTerms[val].result);
            const termsSettings = currentBenchmarkSettings?.settings['listTerms']?.values['_list'] || {};
            const localSettings = termsSettings[val];
            const localTarget = getValue(localSettings, "target", 50);
            const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
            const localScaleTo = getValue(localSettings, "scaleTo", 100);
            const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
            const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);

            const localPercent = countNumber / (resultData.countWords / 100);
            const name = resultData.dataTerms[val]?.category?.name ? resultData.dataTerms[val].category.name[convertLanguagesToShort(i18nLanguage)] : ''

            // convert real % into  scale size percent
            const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
            const targetPercent = targetPercentTmp;
            const scalePercent = scalePercentTmp;
            const color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
            return [...acc, {
                    id: index,
                    localTarget: roundWithPrecision(localTarget, 1),
                    targetPercent,
                    scalePercent,
                    localPercent: roundWithPrecision(localPercent, 1),
                    name,
                    val,
                    color,
                    countNumber,
                }];
        }, []);
    }

    return (
        <div className={style.mainCont}>
            <DashHeader archiveName={archiveName} benchmarkName={benchmark['name'][convertLanguagesToShort(i18nLanguage)]} />
            <div className={`${style.cells}`}>
                <SingleDistribution/>
            </div>
            <div className={`${style.vocabulary} ${style.cells}`}>
                <VocabularyComponent/>
            </div>
            {/* <div style={{backgroundColor: bgColors.bgPrimary0, margin: "30px 0 0 0"}} className={style.cells}>
                <h2 style={{color: colors.colorPrimary400}}>
                    {t("Terminology")}
                </h2>
                <div className={`mb-5`}>
                    <VColoredLinearChart
                        cells={listIs} noClix={true}
                         />
                </div>
            </div> */}
        </div>
    )
}