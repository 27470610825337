import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { InputLabel, MenuItem, Paper, Select } from '@material-ui/core'
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { checkUserRight, convertJSONToObject, convertLanguagesToFull, convertLanguagesToShort, isObjectEmpty, onlyUnique } from "../../../../utils"
import { useActions } from "../../../../utils/action-helper"
import {
  adminGetAllCompanies,
  adminGetAllGroups,
  clearAllGroups,
  tableSetCompanyId,
  tableSetData, tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount, tableSetSelectAll, tableSetSelectAllIds, userSetArchiveLanguage
} from "../../../../actions"
import UserProfileWrapper from "../../UserProfileWrapper/UserProfileWrapper"
import Spinner from "../../../Spinner"
import UniversalTable from "../../../common/UniversalTable"
import TableMassActionComponent from "./TableMassActionComponent/TableMassActionComponent"
import TableInnerComponent from "./TableInnerComponent/TableInnerComponent"
import style from './style.module.sass'
import { Redirect } from "react-router-dom"

export default function Table() {

  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
  const interfaceLanguage = useSelector(state => state.userData.user.language_id) || i18nLanguage
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const token = useSelector(state => state.userData.token)
  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order || 'desc')
  const orderBy = useSelector(state => state.tableData.orderBy || 'update_time')
  const currentPage = useSelector(state => state.tableData.currentPage)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const rowCount = useSelector(state => state.tableData.rowCount)
  const selectedCompany = useSelector(state => state.tableData.companyId)
  const selectedGroup = useSelector(state => state.tableData.groupId)
  const companyId = useSelector(state => state.userData.user.company_id)
  const companies = useSelector(state => state.adminData.companies)
  const user = useSelector(state => state.userData.user)
  const textDisabledColumns = useSelector(state => state.userData.user?.user_options?.disabled_benchmarks_columns || '[]')
  const disabledColumns = convertJSONToObject(textDisabledColumns, [])

  const {
    aTableSetDefaults,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll,
    actionUserSetArchiveLanguage,
    aAdminClearAllGroups,
    aTableSetCompanyId,
    aAdminGetAllCompanies,
    aAdminGetAllGroups
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
    actionUserSetArchiveLanguage: userSetArchiveLanguage,
    aTableSetCompanyId: tableSetCompanyId,
    aAdminClearAllGroups: clearAllGroups,
    aAdminGetAllCompanies: adminGetAllCompanies(tlService),
    aAdminGetAllGroups: adminGetAllGroups(tlService)
  })

  const [spinner, setSpinner] = useState(false)
  const shortArcLan = convertLanguagesToShort(archiveLanguage)

  const defaultPagination = [
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'id',
      type: 'numeric',
      disablePadding: false,
      label: `${t('admin_texttypes_column-id')}`,
      filter_value: 'id',
      orderBy_value: 'id'
    },
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_texttypes_column-name')}`,
      filter_value: `${interfaceLanguage}name`,
      orderBy_value: `${interfaceLanguage}name`
    },
    {
      id: 'owner',
      type: 'numeric',
      unSortable: true,
      disablePadding: false,
      label: `${t('admin_texttypes_column-user')}`,
      filter_value: 'owner',
      orderBy_value: 'owner'
    },
    {
      id: 'companies',
      type: 'array',
      unSortable: true,
      disablePadding: false,
      label: `${t('admin_texttypes_column-companies')}`,
      filter_value: 'companies',
      orderBy_value: 'companies'
    },
    {
      id: 'groups',
      type: 'array',
      unSortable: true,
      disablePadding: false,
      label: `${t('admin_texttypes_column-groups')}`,
      filter_value: 'groups',
      orderBy_value: 'groups'
    },
    {
      id: 'update_time',
      type: 'date',
      disablePadding: false,
      label: `${t('admin_texttypes_column-update-time')}`,
      filter_value: 'update_time',
      orderBy_value: 'update_time'
    },
  ]
  const defaultFilters = [
    {
      id: 'company_id',
      name: 'Company',
      type: 'catalog',
      searchField: 'company_id',
      searchTemplate: '={value}',
      source: 'companies',
      label: t('Company')
    },
    {
      id: 'group_id',
      name: 'Group',
      type: 'catalog',
      searchField: 'user_group_id',
      searchTemplate: '={value}',
      source: 'groups',
      label: t('Groups')
    },
  ]
  if (user?.user_type_id !== 1) {
    defaultFilters.splice(0, 1)
  }

  const extraSettings = {
    language: archiveLanguage,
    fullSearchFilter: [['|id', '={value}'], [`|name.${shortArcLan}`, 'lc=%{value}%']],
  }

  useEffect(() => {
    if (user?.user_type_id !== 1) {
      const defaultCompanyId = Object.keys(companies)[0]
      aTableSetCompanyId(defaultCompanyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, defaultFilters, disabledColumns, defaultColumns, 'desc', 'update_time')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveLanguage])

  // get all companies & groups on mount
  useEffect(() => {
    aAdminGetAllCompanies(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const company = user.user_type_id === 1 ? selectedCompany : companyId
    if (company) {
      aAdminGetAllGroups(token, company)
    } else {
      aAdminClearAllGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, companyId])


  const processBenchmarkData = (data) => {
    return data.map(d => {
      const { rights } = d
      let companies = []
      let groups = []
      let users = []
      if (rights.length) {
        companies = rights.map(item => item[2][1]).filter(company => company !== "")
        groups = rights.map(item => item[1][1]).filter(group => group !== "")
        users = rights.map(item => item[0][1]).filter(user => user !== "")
      }
      return { ...d, opened: false, versions: [], companies, groups, users }
    })
  }
  
  const runRequest = async (limit, requestLimits, requestFilters, defaultCompanyId) => {
    let res
    let currentData = []
    try {
      res = await tlService.getFilteredBenchmarks(token, requestLimits, requestFilters)
      if (res?.data?.length) {
        currentData = processBenchmarkData(res.data)
        aTableSetData(currentData)
        aTableSetOtherData({ benchmarks_fullcount: res.fullcount })
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.fullcount / limit))
        }
      }

    } catch (e) {
      console.log(e)
      aTableSetData([])
      aTableSetOtherData({})
      aTableSetRowCount(0)
    }
    if (selectedGroup) {
      const defaultRequestLimits = {
        limit,
        company_id: defaultCompanyId,
        order: order === 'asc' ? 0 : 1,
        order_by: orderBy,
        offset: limit * (currentPage - 1),
      }
      try {
        const defaultRes = await tlService.getFilteredBenchmarks(token, defaultRequestLimits, requestFilters)
        if (defaultRes?.data?.length) {
          const newData = processBenchmarkData(defaultRes.data)
          const allData = [...currentData, ...newData]
          const uniqueIds = {}
          const uniqueData = allData.filter(item => {
            if (!uniqueIds[item.id]) {
              uniqueIds[item.id] = true
              return true
            }
            return false
          })
          aTableSetData(uniqueData)
          aTableSetOtherData({ benchmarks_fullcount: uniqueData.length })
          aTableSetRowCount(uniqueData.length)
          if (limit > 0) {
            aTableSetPages(Math.ceil(uniqueData.length / limit))
          }
        }
        return true

      } catch (e) {
        console.log(e)
      }
    }
    else if (selectedCompany) {
      let companyGroups = []
      try {
        const response = await tlService.getFilteredGroups(token, {}, { company_id: selectedCompany })
        if (response?.data?.length) {
          companyGroups = response.data
        }
      } catch (error) {
        console.log(error)
      }
      const groupsIdsToSendRequest = companyGroups.map(group => group.id)
      const groupsIds = groupsIdsToSendRequest.filter(group => group !== 0)
      let allData = [...currentData]
      for (const group of groupsIds) {
        const newRequestLimits = {
          limit,
          user_group_id: group,
          order: order === 'asc' ? 0 : 1,
          order_by: orderBy,
          offset: limit * (currentPage - 1),
        }

        try {
          res = await tlService.getFilteredBenchmarks(token, newRequestLimits, requestFilters)
          if (res?.data?.length) {
            const newData = processBenchmarkData(res.data)
            allData = [...allData, ...newData]
          }
        } catch (e) {
          console.log(e)
        }
      }
      const uniqueIds = {}
      const uniqueData = allData.filter(item => {
        if (!uniqueIds[item.id]) {
          uniqueIds[item.id] = true
          return true
        }
        return false
      })
      aTableSetData(uniqueData)
      aTableSetOtherData({ benchmarks_fullcount: uniqueData.length })

      aTableSetRowCount(uniqueData.length)
      if (limit > 0) {
        aTableSetPages(Math.ceil(uniqueData.length / limit))
      }
      return true

    }
  }


  const prepareRequest = (locale_name, limit, order, orderBy, currentPage, filters, company_id) => {
    const company = user.user_type_id === 1 ? selectedCompany : company_id
    let requestLimits = {
      limit,
      company_id: company,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy,
      offset: limit * (currentPage - 1),
    }
    if (selectedGroup) {
      requestLimits = { ...requestLimits, user_group_id: selectedGroup, company_id: 0 }
    }
    //filters
    let extendedFilter = []
    for (const fil of Object.values(filters)) {
      if (fil !== undefined && !isObjectEmpty(fil.filter)) {
        extendedFilter = [...extendedFilter, ...fil.filter]
      }
    }
    const filtersToKeep = extendedFilter.filter(pair => pair[0] === `|name.${shortArcLan}` || pair[0] === '|id')

    const requestFilters = {
      locale_name,
      extended_filter: JSON.stringify(filtersToKeep)
    }
    return { requestLimits, requestFilters, company }
  }

  const makeRequest = async (locale_name, limit, order, orderBy, currentPage, filters, company_id) => {
    const {
      requestLimits,
      requestFilters,
      company
    } = prepareRequest(locale_name, limit, order, orderBy, currentPage, filters, company_id)
    await runRequest(limit, requestLimits, requestFilters, company)
  }

  const getAllIds = async (locale_name, fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const company = user.user_type_id === 1 ? selectedCompany : companyId
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest(locale_name, limit, order, orderBy, currentPage, filters, company)
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredBenchmarks(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    if(selectedGroup){
      const defaultRequestLimits = {
      company_id: company,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy,
    }
      for (let offset = 0; offset < fullCount; offset += maxChunk) {
        const currLimit = { ...defaultRequestLimits, limit: maxChunk, offset }
        try {
          const res = await tlService.getFilteredBenchmarks(token, currLimit, requestFilters)
          if (res?.data?.length) {
            for (const re of res.data) {
              ids.push(re.id)
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    } else if(selectedCompany){
      let companyGroups = []
      try {
        const response = await tlService.getFilteredGroups(token, {}, { company_id: selectedCompany })
        if (response?.data?.length) {
          companyGroups = response.data
        }
      } catch (error) {
        console.log(error)
      }
      const groupsIdsToSendRequest = companyGroups.map(group => group.id)
      const groupsIds = groupsIdsToSendRequest.filter(group => group !== 0)
      for (const group of groupsIds) {
        const newRequestLimits = {
          limit: maxChunk,
          user_group_id: group,
          order: order === 'asc' ? 0 : 1,
          order_by: orderBy,
          offset: 0,
        }

        try {
          const res = await tlService.getFilteredBenchmarks(token, newRequestLimits, requestFilters)
          if (res?.data?.length) {
            for (const re of res.data) {
              ids.push(re.id)
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  
    return ids.filter(onlyUnique) // remove duplicates
  }

  function reloadAll(locale_name, limit, order, orderBy, currentPage, filters, company_id, setSpinner) {
    setSpinner(true)
    makeRequest(locale_name, limit, order, orderBy, currentPage, filters, company_id).then(() => setSpinner(false))
  }

  // filter if change
  useEffect(() => {
    reloadAll(convertLanguagesToFull(archiveLanguage), limit, order, orderBy, currentPage, filters, companyId, setSpinner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, selectedCompany,selectedGroup, archiveLanguage])

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = rowCount || 0
    if (!selectAll) {
      const allIds = await getAllIds(convertLanguagesToFull(archiveLanguage), fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  function handleOnChangeLanguage(e) {
    actionUserSetArchiveLanguage(e.target.value)
  }

  if (!checkUserRight(user, [601, 602]))
    return <Redirect to={"/"} />

  return (
    <UserProfileWrapper>
      <div className={`${style.mainCont}`}>
        <Paper className={`mb-5`}>
          {spinner && <Spinner />}
          <div style={{ float: 'right', marginBottom: '10px' }}>
            <InputLabel id="language-select-label" className="pl-2 font-weight-bold">{
              t('admin_texttypes_language-select')
            }</InputLabel>
            <Select
              onChange={handleOnChangeLanguage}
              labelId="language-select-label"
              id="language-select"
              defaultValue={archiveLanguage}
              style={{ borderRadius: 18, width: '220px' }}
              variant="outlined"
              className={`p-0`}
            >
              {
                Object.entries(backendLanguages).map(([key, val]) =>
                  <MenuItem key={key} value={key}>{val['nativeName']}</MenuItem>
                )
              }
            </Select>
          </div>
          <UniversalTable
            defaultColumns={defaultColumns}
            reloadAll={reloadAll}
            handleSelectAll={handleSelectAll}
            getAllIds={getAllIds}
            noDataText={t('no-text-types')}
            selectAllText={t('admin_texttypes_select-all-benchmarks')}
            MassActionComponent={TableMassActionComponent}
            InnerComponent={TableInnerComponent}
            extraSettings={extraSettings}
            style={style}
            type="benchmarks"
            enableExtraFilters
          />
        </Paper>
      </div>
    </UserProfileWrapper>
  )
}