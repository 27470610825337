import style from './style.module.sass'
import { Checkbox } from '@material-ui/core'
import Select from '../../../components/Fields/Select'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActions } from '../../../utils/action-helper'
import {
  dashboardSetArchiveList,
  dashboardSetIdList,
  dashboardSetLimit,
  dashboardSetOffset,
  dashboardSetSort,
} from '../../../actions'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'

export default function ArchivesSelectionTable ({ panel, runRequest }) {
  const { t } = useTranslation()
  const { theme: { colors } } = useTheme()
  const {
    aDashboardSetArchiveList,
    aDashboardSetLimit,
    aDashboardSetSort,
    aDashboardSetIdList,
    aDashboardSetOffset
  } = useActions({
    aDashboardSetOffset: dashboardSetOffset,
    aDashboardSetLimit: dashboardSetLimit,
    aDashboardSetSort: dashboardSetSort,
    aDashboardSetArchiveList: dashboardSetArchiveList,
    aDashboardSetIdList: dashboardSetIdList
  })
  const token = useSelector(state => state.userData.token)
  const limit = useSelector(state => state.dashboardData.limit[panel])
  const fullcount = useSelector(state => state.dashboardData.fullcount[panel])
  const sort = useSelector(state => state.dashboardData.sort[panel])
  const offset = useSelector(state => state.dashboardData.offset[panel])
  const language = useSelector(state => state.dashboardData.language)
  const idList = useSelector(state => state.dashboardData.idList[panel] || [])
  const archivesList = useSelector(state => state.dashboardData.archivesList[panel] || [])
  const folderSelected = useSelector(state => state.dashboardData.folderSelected[panel])
  const userId = useSelector(state => state.userData.user.id)
  const userGroup = useSelector(state => state.userData.user.user_group_id)
  const userCompany = useSelector(state => state.userData.user.company_id)
  const activeTab = useSelector(state => state.dashboardData.folderType[panel])
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
      runRequest(folderSelected, userId, userGroup, userCompany, token, panel, sort, language, limit, offset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, offset, limit, activeTab])

  useEffect(() => {
    setSelectAll(archivesList.every(v => idList.indexOf(v.id) > -1))
  }, [idList, archivesList])

  const onChangeLimit = e => {
    aDashboardSetLimit(panel, parseInt(e.target.value))
  }

  const onChangeOrder = e => {
    aDashboardSetSort(panel, e.target.value)
  }

  const onSelectAll = () => {
    let newList = []
    if (selectAll) {
      newList = idList.filter(v => archivesList.findIndex(ar => v === ar.id) === -1)
    } else {
      for (let addId of archivesList) {
        if (idList.findIndex(v => v === addId.id) === -1) {
          newList.push(addId.id)
        }
      }
      newList = [...idList, ...newList]
    }
    aDashboardSetIdList(panel, newList)
  }

  function onSelectArchive (id) {
    let idListCopy = [...idList]
    const index = idListCopy.indexOf(id)
    if (index > -1) {
      idListCopy = idListCopy.filter((v, i) => i !== index)
    } else {
      idListCopy.push(id)
    }
    aDashboardSetIdList(panel, idListCopy)
  }

  function onChangeVersion (e, index) {
    // clear select
    const copyIndex = idList.indexOf(archivesList[index].id)
    let idListCopy = [...idList]
    if (copyIndex > -1) {
      //delete idListCopy[copyIndex]
      idListCopy = idListCopy.filter((v, i) => i !== copyIndex)
      aDashboardSetIdList(panel, idListCopy)
    }
    const newArchivesList = [...archivesList]
    newArchivesList[index].id = e.target.value
    if (idListCopy.indexOf(e.target.value) === -1) {
      aDashboardSetIdList(panel, [...idListCopy, e.target.value])
    }
    aDashboardSetArchiveList(panel, newArchivesList)
  }

  function onChangeOffset (off) {
    aDashboardSetOffset(panel, off)
  }

  const Paginator = () => {
    const maxNumber = 2
    const fullNumber = limit > 0 ? Math.floor(fullcount / limit) : 1
    const currentNumber = limit > 0 ? Math.floor(offset / limit) : 1
    const arrPages = Array(fullNumber).fill(0)

    if (fullcount <= limit) {
      return <></>
    }
    return (
      <>
        {arrPages.map((m, i) => {
          if (fullNumber > maxNumber * 2) {
            if (i > 0 && i < fullNumber - 1) {
              if (i < currentNumber - maxNumber) {
                return null
              } else if (i === currentNumber - maxNumber) {
                return (<span key={panel.toString() + '_' + i.toString()} style={{ color: colors.colorPrimary800 }}
                              className={'p-1'}>...</span>)
              }
              if (i > currentNumber + maxNumber) {
                return null
              } else if (i === currentNumber + maxNumber) {
                return (<span key={panel.toString() + '_' + i.toString()} style={{ color: colors.colorPrimary800 }}
                              className={'p-1'}>...</span>)

              }
            }
          }
          if (currentNumber === i) {
            return (<span key={panel.toString() + '_' + i.toString()} style={{ color: colors.colorPrimary800 }}
                          className={'p-1'}>{i + 1}</span>)
          }
          return (<span key={panel.toString() + '_' + i.toString()} className={'p-1'}
                        style={{ cursor: 'pointer', color: colors.colorBlue1000 }}
                        onClick={() => onChangeOffset(i * limit)}>{i + 1}</span>)
        })}
      </>
    )
  }

  const RenderRows = ({ value, name, versions, index }) => (
    <tr>
      <td style={{ backgroundColor: '#e7e7e7' }}>
        <div className="d-flex align-items-center justify-content-center">
          <Checkbox id={value.toString()} className={panel + '_arrays'} value={value}
                    checked={idList.indexOf(value) > -1} onChange={() => {
            onSelectArchive(value)
          }} color="primary"/>
        </div>
      </td>
      <td colSpan={2}>
        <p className="m-0 mr-auto">{name}</p>
      </td>
      <td colSpan={2}>
        <Select
          value={value}
          handleChange={e => {
            onChangeVersion(e, index)
          }} options={
          versions.map(v => {
            return { id: v.id, name: v.version + ' ' + v.create_time }
          })
        } label={t('Version')} size="small"/></td>
    </tr>
  )

  return (
    <table border={'1px solid #000'} className={`${style.table}`}>
      <thead>
      <tr style={{ backgroundColor: '#d8d8d8' }}>
        <th width={56}>
          <div className="d-flex align-items-center justify-content-center">
            {archivesList?.length ?
              <Checkbox checked={selectAll} onChange={onSelectAll} color="primary" id="name-selects"/> :
              <div/>}
          </div>
        </th>
        <th>
          <h6 className={`m-0 mr-auto`}>{t('Archives')}</h6>
        </th>
        <th>
          <div className={`m-0 mr-auto text-right`}><Paginator/></div>
        </th>
        <th>
          <Select value={limit} handleChange={onChangeLimit} options={
            [
              { id: 10, name: '10' },
              { id: 50, name: '50' },
              { id: 100, name: '100' },
              { id: 500, name: '500' },
            ]
          } label={t('Limit')} size="small"/>
        </th>
        <th>
          <Select value={sort} handleChange={onChangeOrder} options={
            [
              { id: 'archive_name', name: t('Archive Name') },
              { id: 'create_time', name: t('Archive Creation Time') },
            ]
          } label={t('OrderBy')} size="small"/>
        </th>
      </tr>
      </thead>
      <tbody>
      {archivesList.map((row, index) =>
        <RenderRows id={index} key={index} index={index} value={row.id} name={row.archive_name}
                    versions={row.versions}/>
      )}
      </tbody>
    </table>
  )
}