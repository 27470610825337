import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ExpandMore, ChevronRight, Add, Edit, Delete, Visibility } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styles from './style.module.sass'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import {
    convertFromErrorObject,
    isObjectEmpty, onlyUnique

} from '../../../utils'
import { useActions } from '../../../utils/action-helper'
import {
    archiveArchiveFolders,
    archiveSetFolderForceReload,
    archiveSetPredefined,
    archiveSetRootCount,
} from '../../../actions'
import config from '../../../config'
import { Button, DialogActions, DialogContent, DialogContentText, Dialog } from '@material-ui/core'
import Spinner from '../../Spinner'

export default function ArchiveManager(
    {
        maxDeep = 2,
        rootElementName = 'root_folder',
        allFilesElementName = 'all-files',
        type,
        showCheckbox = true,
        showRootCheckbox = true,
        showAllFiles = true,
        localeName = undefined,
        showAddIcon = true,
        showEditIcon = true,
        showDeleteIcon = true,
        showArchivesCount = true,
        showMassFunctions = true,
        forceReloadOnOpen = false,
        defaultFolderId = -1,
        externalFolderClick = () => {
        },
        externalShowSelectedClick = () => {
        }
    }
) {

    const { t } = useTranslation()
    const tlService = React.useContext(TLServiceComponent)

    const {
        aArchiveArchiveFolders,aArchiveSetPredefined,
        aArchiveSetRootCount,
        aArchiveSetFolderForceReload
    } = useActions({
        aArchiveArchiveFolders: archiveArchiveFolders(tlService),
        aArchiveSetRootCount: archiveSetRootCount(tlService),
        aArchiveSetFolderForceReload:archiveSetFolderForceReload,
        aArchiveSetPredefined:archiveSetPredefined
    })

    const token = useSelector(state => state.userData.token)

    const fullIdLists = {}
    fullIdLists['personal'] = useSelector(state => state.userData.user.id)
    fullIdLists['group'] = useSelector(state => state.userData.user.user_group_id)
    fullIdLists['company'] = useSelector(state => state.userData.user.company_id)

    const predefinedFolder = useSelector(state => state.archiveData.folders.predefinedFolder)

    const archiveRootCount = {}
    archiveRootCount['personal'] = useSelector(state => state.archiveData.folders.personalRootCount)
    archiveRootCount['group'] = useSelector(state => state.archiveData.folders.groupRootCount)
    archiveRootCount['company'] = useSelector(state => state.archiveData.folders.companyRootCount)

    const archiveFolders = {}
    archiveFolders['personal'] = useSelector(state => state.archiveData.folders.personal)
    archiveFolders['group'] = useSelector(state => state.archiveData.folders.group)
    archiveFolders['company'] = useSelector(state => state.archiveData.folders.company)

    // requests
    const requestFolders = {}
    requestFolders['personal'] = useSelector(state => state.archiveData.folders.requests.personal)
    requestFolders['group'] = useSelector(state => state.archiveData.folders.requests.group)
    requestFolders['company'] = useSelector(state => state.archiveData.folders.requests.company)

    const requestRootCount = {}
    requestRootCount['personal'] = useSelector(state => state.archiveData.folders.requests.personalRootCount)
    requestRootCount['group'] = useSelector(state => state.archiveData.folders.requests.groupRootCount)
    requestRootCount['company'] = useSelector(state => state.archiveData.folders.requests.companyRootCount)

    const forceReload = useSelector(state => state.archiveData.folders.forceReload)

    const [folder, setFolder] = useState(-1)

    const [folderSelected, setFolderSelected] = useState([])
    const [folderPath, setFolderPath] = useState([])
    const [alert, setAlert] = useState('')

    const [modalRequest, setModalRequest] = useState('')
    const [modalData, setModalData] = useState({})

    const [editValue, setEditValue] = useState('')
    const [editPosition, setEditPosition] = useState('')
    const [editName, setEditName] = useState('Add Folder')
    const [editType, setEditType] = useState('add')
    const [editId, setEditId] = useState(0)
    const [spinner, setSpinner] = useState(false)
    const [spinnerMessage, setSpinnerMessage] = useState('')

    const reloadAll = (type, force = false) => {
        if (!requestFolders[type] && (force || archiveFolders[type] === undefined)) {
            aArchiveArchiveFolders(type, token, fullIdLists[type], showArchivesCount ? 1 : 0, localeName)
        }
        if (showAllFiles && !requestRootCount[type] && (force || archiveRootCount[type] === undefined)) {
            aArchiveSetRootCount(type, token, fullIdLists[type], localeName)
        }
    }

    useEffect(() => {
        reloadAll(type, forceReloadOnOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    useEffect(() => {
        if (forceReload){
            aArchiveSetFolderForceReload(false)
            reloadAll(type, forceReloadOnOpen)
        }
    }, [forceReload])



    // defaultFolderId
    const getCurrentFolder = (type) => {
        return [archiveFolders[type]?.data || [], archiveRootCount[type] ? archiveRootCount[type] : 0]
    }

    const calculateFullCount = (currentFolders, currentRootCount) => {
        let res = currentRootCount
        for (const currentFolder of currentFolders) {
            res += currentFolder?.count_archives ? currentFolder.count_archives : 0
        }
        return res
    }

    const [currentFolders, currentRootCount] = getCurrentFolder(type)
    const fullCount = calculateFullCount(currentFolders, currentRootCount)

    useEffect(()=> {
        if (predefinedFolder && !isObjectEmpty(currentFolders)){
            const paths = getReverseChild(currentFolders, predefinedFolder)
            setFolderPath(paths)
            setFolder(predefinedFolder)
            aArchiveSetPredefined(undefined)
        }

        if (defaultFolderId > -1 && !isObjectEmpty(currentFolders)){
            const paths = getReverseChild(currentFolders, defaultFolderId)
            setFolderPath(paths)
            setFolder(defaultFolderId)
            defaultFolderId = -1
        }

    },[currentFolders])


    function handleSubFolderClick(id) {
        let tmpPath = [...folderPath]
        const inx = tmpPath.indexOf(id)
        if (inx === -1) {
            tmpPath.push(id)
        } else {
            tmpPath.splice(inx, 1)
        }
        setFolderPath(tmpPath)
    }

    function handleExternalClick(id, currentFolders) {
        const names = []
        const ids = []
        let currId = id
        let filteredFolder
        do {
            if (currId === 0) {
                names.push(t('root_folder'))
                ids.push(currId)
            } else {
                filteredFolder = currentFolders.find((fl) => fl.id === currId)
                names.push(filteredFolder?.folder_name)
                ids.push(currId)
            }
            currId = filteredFolder?.parent
        } while (filteredFolder?.parent)
        externalFolderClick(id, ids, names)
    }

    function handleNameClick(id) {
        setFolder(id)
        handleExternalClick(id, currentFolders)

    }

    function handlePlusIconClick(id, element_id) {
        setEditPosition(element_id)
        setEditValue('')
        setAlert('')
        setEditType('add')
        setEditId(id)
        setEditName('Add Folder')
        // setTimeout(() => editRef.current.focus(), 100)
    }

    function handleEditIconClick(id, value, element_id) {
        setEditPosition(element_id)
        setAlert('')
        setEditValue(value)
        setEditType('edit')
        setEditId(id)
        setEditName('Edit Folder')
        //  setTimeout(() => editRef.current.focus(), 100)

    }

    function handleDeleteIconClick(id) {
        setModalData({ task: 'delete', id, subFolders: [] })
        setModalRequest(t('delete folder'))
    }

    function handleMassDeleteIconClick() {
        setModalData({ task: 'deleteMass', ids: folderSelected })
        setModalRequest(t('delete folder all checked folders?'))
    }

    function handleShowSelectedIconClick() {
        externalShowSelectedClick(folderSelected)
    }

    const handleEditBlur = () => {
        setEditPosition('')
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!editValue?.length) {
            return false
        }

        setEditPosition('')

        //addArchiveFolder
        if (editType === 'add') {
            const data = { folder_name: editValue, parent: 0, user_id: 0, user_group_id: 0, company_id: 0 }
            switch (type) {
                case 'personal':
                    data.user_id = fullIdLists[type]
                    data.parent = editId
                    break
                case 'group':
                    data.user_group_id = fullIdLists[type]
                    data.parent = editId
                    break
                case 'company':
                    data.company_id = fullIdLists[type]
                    data.parent = editId
                    break
                default:
                    return false
            }
            try {
                const res = await tlService.addArchiveFolder(token, data)
                if (!folderPath.includes(editId))
                    handleSubFolderClick(editId)
                currentFolders.push({ ...data, id: res.id })
                handleNameClick(res.id)
            } catch (error) {
                setTimeout(() => {
                    setAlert('')
                }, config.timeOutDuration)
                console.log('error', error)
                setAlert(convertFromErrorObject(t, error))
                reloadAll(type, true)
                handleEditBlur(e)
                return false
            }
        } else if (editType === 'edit') {
            //editArchiveFolder
            const data = { folder_name: editValue }
            try {
                await tlService.editArchiveFolder(token, editId, data)
            } catch (error) {
                setTimeout(() => {
                    setAlert('')
                }, config.timeOutDuration)
                console.log('error', error)
                setAlert(convertFromErrorObject(t, error))
                reloadAll(type, true)
                handleEditBlur(e)
                return false
            }
        }
        // reload
        reloadAll(type, true)
        handleEditBlur(e)
        return false
    }

    const handleCheckboxChange = (id) => {
        const tFolderSelected = [...folderSelected]
        const inx = tFolderSelected.indexOf(id)
        if (inx > -1) {
            tFolderSelected.splice(inx, 1)
        } else {
            tFolderSelected.push(id)
        }
        setFolderSelected(tFolderSelected)
    }

    const handleModalResponse = async (answer) => {
        setModalRequest('')
        if (!answer) {
            setModalData({})
            return false
        }

        // set spinner
        setSpinner(true)
        setAlert('')
        setSpinnerMessage('')

        if (modalData.task.startsWith('deleteMass')) {
            let stop = false
            switch (modalData.task) {
                case 'deleteMass':
                    for (const currId of modalData.ids) {
                        if (checkForChildFolders(currentFolders, currId)) {
                            setModalData({ task: 'deleteMassCheckSubFolders', ids: modalData.ids })
                            setModalRequest(t('selected folders contain subfolders, delete them anyways?'))
                            stop = true
                            break
                        }
                    }
                    if (stop) break
                case 'deleteMassCheckSubFolders':
                    for (const currId of modalData.ids) {
                        if (checkForChildArchives(currentFolders, currId, currentRootCount)) {
                            setModalData({ task: 'deleteMassCheckArchives', ids: modalData.ids })
                            setModalRequest(t('selected folders or subfolders contain archives, delete them anyways?'))
                            stop = true
                            break
                        }
                    }
                    if (stop) break
                case 'deleteMassCheckArchives':
                    await massDeleteArchives(modalData.ids)
                    break
            }
        } else if (modalData.task.startsWith('delete')) {
            switch (modalData.task) {
                case 'delete':
                    // check for child folders
                    if (checkForChildFolders(currentFolders, modalData.id)) {
                        setModalData({ task: 'deleteCheckSubFolders', id: modalData.id })
                        setModalRequest(t('This folder contains subfolders, delete them anyways?'))
                        break
                    }
                case 'deleteCheckSubFolders' :
                    // check for archives
                    if (checkForChildArchives(currentFolders, modalData.id, currentRootCount)) {
                        setModalData({ task: 'deleteCheckArchives', id: modalData.id })
                        setModalRequest(t('This folder or subfolders contains archives, delete them anyways?'))
                        break
                    }
                case 'deleteCheckArchives':
                    await deleteArchiveFolders(modalData.id, currentFolders, true)
                    break
                default:
                    break
            }
        }

        setSpinnerMessage('')
        setSpinner(false)
    }

    const getListOfArchives = async (folderId) => {
        try {
            const res = await tlService.getFilteredArchive(token, {}, {
                folder: folderId > 0 ? folderId : undefined,
                exact_result: 2,
                by_last: 0,
                return_values: '["id", "analyse_id"]'
            })
            return res?.data || []
        } catch (e) {
            console.log(e)
            return []
        }
    }

    const massDeleteArchives = async (ids) => {

        let tFolderSelected = [...ids]

        // if there is 0 in list remove everything
        if (tFolderSelected.includes(0)) {
            tFolderSelected = [0]
        }

        let tCurrentFolders = [...currentFolders]
        while (tFolderSelected?.length) {
            tCurrentFolders = await deleteArchiveFolders(tFolderSelected[0], tCurrentFolders, false)
            tFolderSelected.splice(0, 1)
        }
        reloadAll(type, true)
        setFolderSelected([])
    }

    const deleteArchiveFolders = async (id, folders, reload = true) => {
        let newArchivesList = [...folders]
        let subFolders = getChildFolders(newArchivesList, id)
        subFolders.unshift(id)
        subFolders = subFolders.reverse()

        for (const folderId of subFolders) {
            const filteredFolder = folders.find((fl) => fl.id === folderId)
            // delete archives in folder
            if (filteredFolder?.count_archives || folderId === 0) {
                let archives = []
                // remove the archives from the root
                if (folderId === 0) {
                    archives = await getListOfArchives(0)
                } else {
                    archives = await getListOfArchives(filteredFolder.id)
                }
                for (const archive of archives) {
                    try {
                        setSpinnerMessage(t('deleting_archives') + archive.analyse_id)
                        await tlService.deleteArchive(token, archive.analyse_id)
                    } catch (error) {
                        console.log('error', error)
                        setAlert(convertFromErrorObject(t, error))
                        if (reload) reloadAll(type, true)
                        return newArchivesList
                    }
                }
            }
            // delete folder
            if (folderId > 0) {
                try {
                    setSpinnerMessage(t('deleting_folders'))
                    await tlService.deleteArchiveFolder(token, folderId)
                    // remove a folder from the newArchivesList
                    newArchivesList = newArchivesList.filter(v => v.id !== folderId)
                } catch (error) {
                    console.log('error', error)
                    setAlert(convertFromErrorObject(t, error))
                    if (reload) reloadAll(type, true)
                    return newArchivesList
                }
            }
        }
        if (reload) reloadAll(type, true)
        return newArchivesList
    }

    const getChildFolders = (folders, id) => {
        let retIds = []
        const filteredFolder = folders.find((fl) => fl.id === id)
        if (id === 0 || filteredFolder?.count_subfolders) {
            const filteredFolder = folders.filter((fl) => fl.parent === id)
            for (const filteredFolderElement of filteredFolder) {
                retIds.push(filteredFolderElement.id)
                if (filteredFolderElement?.count_subfolders) {
                    const addFolders = getChildFolders(folders, filteredFolderElement.id)
                    retIds = [...retIds, ...addFolders]
                }
            }
        }
        return retIds.filter(onlyUnique)
    }

    const checkForChildFolders = (folders, id) => {
        if (id === 0) {
            if (folders.findIndex((fl) => fl.parent === 0) > -1)
                return true
        }
        const filteredFolder = folders.find((fl) => fl.id === id)
        if (filteredFolder?.count_subfolders)
            return true

        const filteredSubFolder = folders.filter((fl) => fl.parent === id)
        for (const filteredFolderElement of filteredSubFolder) {
            if (filteredFolderElement?.count_subfolders)
                return true
            if (getChildFolders(folders, filteredFolderElement.id))
                return true
        }
    }

    const checkForChildArchives = (folders, id, rootCount) => {

        if (id === 0 && rootCount > 0) {
            return true
        }
        const filteredFolder = folders.find((fl) => fl.id === id)
        if (filteredFolder?.count_archives) {
            return true
        }
        if (filteredFolder?.count_subfolders || id === 0) {
            const filteredFolder = folders.filter((fl) => fl.parent === id)
            for (const filteredFolderElement of filteredFolder) {
                if (checkForChildArchives(folders, filteredFolderElement.id)) {
                    return true
                }
            }
        }
        return false
    }

    const getReverseChild = (folders, id) => {
        let retIds = [id]
        let filteredFolder = folders.find((fl) => fl.id === id)
        while (filteredFolder?.parent) {
            retIds.push(filteredFolder.parent)
            filteredFolder = folders.find((fl) => fl.id === filteredFolder.parent)
        }
        return retIds
    }

    /** VIEWS **/


    const RecFolders = ({ folderPath, folderStructure, parent, deep }) => {
        const filteredFolders = folderStructure.filter((fl) => fl.parent === parent)

        return (
            <>
                {filteredFolders.map((element) => {
                    const inPath = folderPath.includes(element.id)
                    const selected = folder === element.id
                    return (
                        <Fragment key={`${parent}_${element.id}`}>
                            {(editPosition === `${parent}_${element.id}`) ?
                                <div className={styles.element}>
                                    <EditForm
                                        editValue={editValue}
                                        setEditValue={setEditValue}
                                        editName={editName}
                                        handleEditSubmit={handleEditSubmit}
                                        handleEditBlur={handleEditBlur}
                                        elementId={element.id}
                                    />
                                </div>
                                :
                                <div className={`${styles.element} ${selected ? styles.selected : ''}`}>
                                    {showCheckbox && <div><input checked={folderSelected.includes(element.id)}
                                                                 onChange={() => handleCheckboxChange(element.id)}
                                                                 type="checkbox"/>
                                    </div>}
                                    {element.count_subfolders > 0 ? !inPath ?
                                            <div className={styles.opener}>
                                                <ChevronRight onClick={() => handleSubFolderClick(element.id)}/>
                                            </div> :
                                            <div className={styles.opener}>
                                                <ExpandMore onClick={() => handleSubFolderClick(element.id)}/>
                                            </div>
                                        : <div style={{ marginLeft: '5px' }}/>}
                                    <div
                                        onClick={() => handleNameClick(element.id)}
                                        className={styles.name}>
                                        {element.folder_name} {showArchivesCount ? `(${element?.count_archives ? element?.count_archives : 0})` : ''}
                                    </div>
                                    <div className={styles.manipulate}>
                                        {(deep < maxDeep && showAddIcon) ? <div
                                            key={'Add'}
                                            className={styles.button}
                                            onClick={() => handlePlusIconClick(element.id, `${parent}_${element.id}`)}>
                                            <Add/></div> : <></>}
                                        {showEditIcon && <div
                                            key={'Edit'}
                                            className={styles.button}
                                            onClick={() => handleEditIconClick(element.id, element.folder_name, `${parent}_${element.id}`)}>
                                            <Edit/></div>}
                                        {showDeleteIcon &&
                                            <div
                                                key={'Delete'}
                                                className={styles.button}
                                                onClick={() => handleDeleteIconClick(element.id)}>
                                                <Delete/></div>}
                                    </div>
                                </div>
                            }

                            {folderPath.includes(element.id) &&
                                <div className={styles.children}>
                                    <RecFolders
                                        folderPath={folderPath}
                                        folderStructure={currentFolders}
                                        parent={element.id}
                                        deep={deep + 1}
                                    />
                                </div>
                            }
                        </Fragment>)
                })}
            </>)
    }

    if (spinner) {
        return <div>
            <Spinner/>
            {spinnerMessage && <div className={styles.center}><strong>{spinnerMessage}</strong></div>}
        </div>
    }

    return (
        <div className={styles.main}>

            {/* ALL FILES*/}
            {showAllFiles && <div
                style={{ marginBottom: '20px' }} key={`root`}
                className={`${styles.element} ${folder === -1 ? styles.selected : ''}`}
            >
                <div onClick={() => handleNameClick(-1)} style={{ marginLeft: '5px' }} className={styles.name}>
                    {t(allFilesElementName)} {showArchivesCount ? `(${fullCount})` : ''}
                </div>
            </div>}

            {/* MASS FUNCTIONS */}
            {(showMassFunctions && folderSelected?.length) ? <div className={styles.mass}>
                <div key={'Visibility'} style={{ width: '100%' }} className={styles.button}
                     onClick={() => handleShowSelectedIconClick()}><Visibility
                    title={t('show_selected_folders_button')}/></div>
                <div key={'Delete'} className={styles.button} onClick={() => handleMassDeleteIconClick()}><Delete
                    title={t('delete_all_button')}/></div>
            </div> : <></>}

            {/*the root directory*/}
            {(editPosition === `0_0`) ?
                <div key={`0_0`} className={styles.element}>
                    <EditForm
                        editValue={editValue}
                        setEditValue={setEditValue}
                        editName={editName}
                        handleEditSubmit={handleEditSubmit}
                        handleEditBlur={handleEditBlur}
                        fieldId={`0_0`}
                    />
                </div>
                : <div key={`0_0`}
                       className={`${styles.element} ${folder === 0 ? styles.selected : ''}`}>
                    {showRootCheckbox ?
                        <div><input checked={folderSelected.includes(0)} onChange={() => handleCheckboxChange(0)}
                                    type="checkbox"/></div> : <div style={{ marginLeft: '5px' }}/>}
                    <div onClick={() => handleNameClick(0)}
                         className={styles.rootName}>
                        {t(rootElementName)} {showArchivesCount ? `(${currentRootCount})` : ''}
                    </div>
                    {showAddIcon &&
                        <div className={styles.button} onClick={() => handlePlusIconClick(0, `0_0`)}><Add/></div>}
                </div>
            }


            {!isObjectEmpty(currentFolders) &&
                <RecFolders
                    folderPath={folderPath}
                    folderStructure={currentFolders}
                    parent={0}
                    deep={0}
                />}

            <Dialog
                open={!!alert}
                onClose={() => setAlert('')}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ minWidth: '300px' }}>
                    <DialogContentText id="alert-dialog-description">
                        {alert}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAlert('')} autoFocus>{t('Close')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={!!modalRequest}
                onClose={() => handleModalResponse(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ minWidth: '300px' }}>
                    <DialogContentText id="alert-dialog-description">
                        {modalRequest}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModalResponse(true)} autoFocus>{t('Yes')}</Button>
                    <Button onClick={() => handleModalResponse(false)}>{t('No')}</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

const EditForm = (
    {
        editValue,
        setEditValue,
        editName,
        handleEditSubmit,
        handleEditBlur,
        fieldId
    }
) => {

    return (
        <div className={styles.name}>
            <form onSubmit={handleEditSubmit}>
                <input
                    autoFocus={true}
                    key={fieldId}
                    type="text"
                    size={25}
                    value={editValue}
                    onChange={e => setEditValue(e.target.value)}
                    onBlur={handleEditBlur}
                    placeholder={editName}/>
            </form>
        </div>
    )

}