const indexClearAll = () => {
  return {
    type: 'CLEAR_ALL',
  }
}
const indexUserLogoutClear = () => {
  return {
    type: 'USER_LOGOUT_CLEAR',
  }
}

export {
  indexClearAll,
  indexUserLogoutClear
}

export * from './table'
export * from './dashboard'
export * from './users'
export * from './open'
export * from './modal'
export * from './admin'
export * from './analyse'
export * from './batch'
export * from './archive'