import MainPageWrapper from "../../components/Main-page-wrapper";
import React from "react";
import HIXComponent from "../../components/Dashboard/HIX";
import {useRouteMatch} from "react-router-dom";
import HIXComponentCompare from "../../components/Dashboard/HIX/compare";

export default function HIX (){
  const {params: {type}} = useRouteMatch();
  return <MainPageWrapper>
    {type === 'compare' ? <HIXComponentCompare/> :<HIXComponent/>}
  </MainPageWrapper>
}